.carouselBlock {
  background-color: #fff;
  /* border: 1px solid green; */
  border-radius: 8px;
  /* max-height: 326px; */
}
.cardContainerInner {
  /* max-height: 326px; */
  height: 100%;
  /* border: 1px solid red; */
}

.carousalBlockOneColumn {
  display: flex;
  flex-direction: row;
  color: red !important;
}

.carousalBlockOneColumn{
  text-align: left;
  height:100%;
}

.hidden {
  display: none;
}

.carouselTT li > div > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    padding: unset;
    height: 100%;
    padding-left: 24px;
    /* padding-top: 24px; */
    flex-grow: 1;
    /* padding-bottom: 68px; */
    /* padding-bottom: 41px; */
  }

.carouselBlock li > div > div:nth-child(2) > h3 + div,
.carousalBlockOneColumn li >div >div:nth-child(2) >h3 +div {
  padding-bottom: 20px;
  min-height: 50px;
  flex-grow: 1;
}

.showHolder button {
  font-size: 16px;
  color: var(--blue-900, #00012b) !important;
  font-family: Calibre-R;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.showHolder .eyeHolder {
  background: url("../../assets/carousal/Vector-eye-on.svg");
  width: 18px;
  height: 18px;
  padding-left: 1.2rem;
  text-align: right;
  background-repeat: no-repeat;
}

.textUnderline {
  text-decoration-line: underline;
}

.hideWrapper {
  padding: 16px 24px;
}

.title {
  color: var(--blue-900, #00012b);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  text-align: left;
}

.text-left {

}

.text-right {
  padding-bottom: 5px;
}

@media (max-width: 1349px) {
  .carouselTT li > div > div:nth-child(2) {
    padding-left: unset;
  }
}
@media (max-width: 500px) {
  .textUnderline {
    display: none;
  }
}
