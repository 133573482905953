.attendanceHolder {
  padding: 0 16px;
}
.pageContent {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
}
.headerBlocks {
  margin-bottom: 32px;
}
.linkSection {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  gap: 24px;
}
.attendanceHistory > div {
  margin: 0 0 32px 0;
}
.attendanceCard > div {
  margin: 32px 0;
}
.linkSection a {
  padding-left: 0;
}
.locationDropdown {
  margin: 0 0 32px 0;
}
.locationDropdown select {
  width: 100%;
  padding: 15px;
  color: var(--blue-900);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  border-radius: 8px;
  border: 1px solid var(--gray-100);
  background: var(--white);
  box-shadow: 2px 2px 8px 0px rgba(55, 58, 229, 0.1);
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../../assets//images/chevron-down.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
}
.tabLocationHolderAtt {
  margin: 0 0 32px 0;
  border-bottom: 1px solid var(--gray-100);
  /*margin-left: 16px;*/
}
.tabLocationHolderAtt .tabLocationItem,
.tabHolder .tabItem {
  display: inline-block;
  color: var(--blue-900);
  line-height: 135%;
  text-decoration: none;
  padding: 16px 2px;
  margin: 0 15px;
  border-radius: 0;
}
.tabLocationHolderAtt .tabLocationItem.active,
.tabHolder .tabItem.active {
  font-weight: 500;

  border-bottom: 2px solid var(--Gold-800, #baa173);
}
.tabLocationHolder .tabLocationItem,
.tabHolder .tabItem {
  display: inline-block;
  color: var(--blue-900);
  line-height: 135%;
  text-decoration: none;
  padding: 16px 2px;
  margin: 0 15px;
  border-radius: 0;
}
.tabLocationHolder .tabLocationItem.active,
.tabHolder .tabItem.active {
  color: var(--blue-500);
  font-weight: 500;
  border-bottom: 1px solid var(--blue-500);
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1100px) {
  .attendanceHolder {
    padding: 0 48px;
  }
  .pageContent {
    margin-top: 48px;
    flex-direction: row;
    gap: 4%;
  }
  .pageContent .leftContent {
    flex: 0 0 58%;
  }
  .pageContent .rightContent {
    flex: 0 0 38%;
  }
  .attendanceHistory > div {
    margin: 0 0 40px 0;
  }
  .attendanceCard > div {
    margin: 0;
  }
}
@media (min-width: 1300px) {
  .pageContent .leftContent {
    flex: 0 0 66%;
  }
  .pageContent .rightContent {
    flex: 0 0 30%;
  }
  .linkSection {
    flex-direction: row;
  }
  .linkSection a:first-child {
    flex: 0 0 30%;
  }
}
