.cardContainer {
  border-radius: 8px;
  margin: 0;
  padding: 24px !important;
  margin: 0 !important;
  /* max-height: 540px; */
}
.cardContainerInner {
  /* width: 66%;
    padding: 8px 16px; */
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--gray-100);
}
.inactive {
  color: var(--Gray-300, #a2a2a2) !important;
  text-align: center;
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

.comingSoonContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 107px;
  align-self: stretch;
  display: flex;
  height: 24px;
  max-height: 24px;
  padding: 0px 8px;
  align-items: center;
  width: 101px;
  gap: 4px;
  border-radius: 4px;
  background: var(--Gray-500, #747474);
  color: var(--White, #fff);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.springText {
  align-self: stretch;
  color: var(--Gray-500, #747474);
  text-align: center;
  padding-bottom: 5px;
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  padding-top: 8px;
}
.springDescription {
  align-self: stretch;
  color: var(--Gray-500, #747474);
  text-align: center;
  /* margin-bottom: 189px; */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  padding: 0px 24px;
}
.dropdownDisabled {
  /* color: var(--Gray-300, #a2a2a2); */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

.mobilenav select {
  /* color: var(--Gray-300, #a2a2a2); */
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  margin-bottom: 32px;
  border-radius: 8px;
  border: 1px solid var(--gray-100);
  background: var(--white);
  /* box-shadow: 2px 2px 8px 0px rgba(55, 58, 229, 0.1); */
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../../../assets/images/chevron-down.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
}
.tabHolder {
  margin: 0;
  /* border-bottom: 1px solid var(--gray-100); */
}
.tabHolder .tabItem {
  display: inline-block;
  color: var(--blue-900);
  line-height: 135%;
  text-decoration: none;
  padding: 16px 2px;
  margin: 0 15px;
  border-radius: 0;
}
.tabHolder .tabItem.active {
  color: var(--Blue-900, #00012b);
  font-weight: 500;
}

.tabHolder > button {
  color: var(--Blue-900, #00012b);
  border-radius: 0px;
  padding: 16px 0px;
  margin-right: 34px;
  text-align: left;
  min-width: fit-content;
  
}
.tabHolder > button:hover {
  color: var(--Blue-900, #00012b);
  font-weight: 500;
  border-bottom: 2px solid var(--Gold-900, #b19661);
}
.tabHolder .currActive {
  color: var(--Blue-900, #00012b) !important;
  font-weight: 500 !important;
  border-bottom: 2px solid var(--Gold-900, #b19661)!important;
}

@media (width<=768px) {
  /* .tabHolder {
    display: none;
  } */
  .cardContainer {
    /* border-radius: 8px;
       margin: 0; */
    padding: 24px !important;
  }
  .comingSoonContainer {
    margin-top: 60px;
  }
  .springDescription {
    margin-bottom: 80px;
  }
}
@media (width>992px) {
  .mobilenav {
    display: none;
  }
}
@media (width>1200px) {
  .mobilenav {
    display: none;
  }
}
