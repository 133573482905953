.nameSearchHolder {
  position: relative;
  width: 100%;
}

.formFieldSpacing {
  margin-top: 24px;
  width: 99%;
}

.formFieldSpacing:first-child {
  margin-top: 10px;
}

.formFieldSpacing p {
  margin-bottom: 16px;
}

.coButton {
  height: 56px;
  padding: 0 16px;
  font-size: 16px;
}
.chip {
  width: fit-content;
  height: 32px;
  border-radius: 20px;
  /*opacity: 0px;*/
  display: flex;
  align-items: center;
  background-color: var(--white);
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  color: var(--gray-900);
  padding: 16px;
  /* cursor: pointer; */
  gap: 8px;
  margin-bottom: 8px;
}
.cancelBtn {
  border: 1px solid var(--Blue-900, #00012b) !important;
  color: var(--Blue-900, #00012b) !important;
  background: var(--white) !important;
  outline:none !important;
}

.cancelBtn:hover:not([disabled]){
  background: var(--white) !important;
    color: #00012b !important;
    border-color: #00012b !important;
    outline: #00012b 1px solid !important;
}
button.submitBtn:disabled {
  background: var(--Gray-500, #747474);
  color: #fff;
}

button.submitBtn {
  background: var(--Blue-900, #00012b);
  color: #fff;
}

button.submitBtn:hover:not([disabled]) {
  background: var(--blue-800);
}

.formFieldSpacing p.formErrorF {
  color: var(--Alerts-Red-500, #f70800);
  font-size: 14px;
  /* todo: change the color */
}

.textareaField {
  color: var(--Gray-900, #171717);
  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  width: 100%;
  height: 90px;
  border-radius: 8px 8px 0 0;
  padding: 17px 24px;
  border-top: #fff;
  border-left: #fff;
  border-right: #fff;
  border-bottom: 0.5px solid var(--Gray-400, #8b8b8b);
}

.des p {
  font-family: Calibre-R;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  text-align: left;
  color: var(--Gray-500);
}

.mainContainer {
  width: 100%;
  padding: 16px 16px 16px 0;
}

.indvItem {
  margin-bottom: 4px;
}
.indvMainItem {
  margin-bottom: 16px;
}

.allLabel {
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  text-align: left;
}

.coStarField {
  color: var(--Alerts-Red-500, #f70800);
  margin-left: 2px;
}

.errorSuccess {
}

.btnHolder {
  margin-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: center;
}

.errorHolder,
.sucessHolder {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.esIcon {
  padding-bottom: 16px;
}

.esHead {
  font-family: Calibre-R;
  font-size: 18px;
  font-weight: 500;
  line-height: 24.3px;
  text-align: center;
  color: var(--Blue-900);
  padding-bottom: 16px;
}

.esBody {
  padding-bottom: 16px;
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: center;
  color: var(--Blue-900);
}

/* //overrite the css */
/* we can write in the main file */
li[class^="customSelectDropdownWithIcon_optionList__"] {
  margin-left: 0 !important;

  &:active,
  &:focus,
  &:hover,
  &[aria-selected="true"] {
    /* background-color: var(--blue-25, #f4f3fc); */
    background-color: #e8e8e8 !important;
    /* need the exact color from Max- in figma purple color is given */
  }

  &:focus:last-child,
  &:active:last-child,
  &:hover:last-child,
  &:last-child[aria-selected="true"] {
    border-radius: 0;
  }
}

/* MultiSelection */
div[class^="MultiSelection_chip__"] {
  margin-right: 8px;
  padding: 8px 16px;
  gap: 4px;
}
span[class^="MultiSelection_chipCross__"] {
  display: flex;
}
span[class^="MultiSelection_chipCross__"] svg {
  margin-right: 2px;
}

div[class^="MultiSelection_searchInputHolder__"] {
  border-bottom: 1px solid #8b8b8b;
}
/* div[class^="customSelectDropdownWithIcon_textWrap__"], */
input[class^="MultiSelection_searchInput__"] {
  color: var(--Gray-900, #171717);
}

input::placeholder,
textarea::placeholder {
  color: var(--Gray-300, #a2a2a2);
  opacity: 1;
}
div[class^="customSelectDropdownWithIcon_textWrap__"] {
  color: var(--Gray-300, #a2a2a2);
}

div[class^="MultiSelection_searchInputHolder__"] {
  padding-right: 12px;
}
/* Badge color text - dark color - to do - JS css condition */
