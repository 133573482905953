.filterModal {
  color: #00012b;
  padding: 8px;
}

.filterModal h2 {
  font-size: 18px;
  margin-bottom: 16px;
}

.checkboxGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.checkbox {
  display: flex;
  margin-bottom: 16px;
  width: 50%;
  /* font-size: 16px; */
  /* margin-left: 16px; */
}
.uncheckedBox{
  display: flex;
  margin-bottom: 16px;
  width: 50%;
    
}
.uncheckedBox>div>div>div:focus-within{
  outline: 2px var(--blue-900);

}
.checkbox>div>div>div{
  background: var(--blue-900, #00012b);
    outline: 2px solid #00012b;
}
.checkbox>div>div>div:focus{
  background: var(--blue-900, #00012b);
    outline: 2px solid #00012b;
}
.checkboxLabels {
  font-size: 16px;
  margin-left: 16px;
}

.buttonGroup {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
}
.buttonGroup .applyButton {
  padding: 26px; /* //16px */
  background: var(--blue-900) !important;
  color: #fff;
  font-size: 16px;
}
.buttonGroup .applyButton:hover{

  background: var(--blue-800) !important;
}


.buttonGroup .clearButton {
  font-size: 14px;
  text-decoration: underline;
  border: none;
  margin-right: 24px;
  padding: 0;

  background: transparent;
  color: var(--Blue-900, #00012b);
  white-space: nowrap;
}
.filterModal .buttonGroup .clearButton:hover {
  background: transparent;
  color: var(--Blue-900, #00012b);
  font-weight: 500;
}
@media (max-width: 768px) {
  .checkboxLabels {
    font-size: 12px;
    
  }
  .checkbox {
    width:100%;
  }
  .uncheckedBox{
    width:100%;
  }
  .buttonGroup
  {
    justify-content:center;
  }
}
/* .modal-mobile {
  border-radius: 16px;
  background: var(--gold-25, #faf9f5);
}

@media (max-width: 999px) {
  .modal-mobile {
    width: 100% !important;
    height: 100% !important;
    border-radius: none !important;
  }
} */
