.carousel-root {
  /* margin-left: -2px; */
}
.carousel .slide img,
.carousel img {
  /* margin-left: 1px; */
  /* height: 50vw; */
}
.carousel .slide .title {
  color: var(--blue-900, #00012b);

  /* Additional styles/med/text-xl-med */
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 27px */
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.carousel .slide .row-h {
  /* padding-top: 24px; */
  /* min-height: 25px; carousel tt desktop mobile OK  */
}
@media (max-width: 1199px) {
  .carousel .slide .row-h {
    /* padding-top: 24px; */
    min-height: 25px; /* carousel tt desktop mobile OK  */
  }
}

.carousel.carousel-slider {
  display: flex;
  flex-direction: row-reverse;
}

.carousel .control-dots {
  /* text-align: right; */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 40px;
  padding-bottom: 20px;
}
.carousel .control-dots .dot {
  background: rgba(0, 1, 43, 0);
  /* background: pink; */
  /* opacity: 0; */
  border: 1px solid #00012b;
  width: 10px;
  height: 10px;
  /* width: 32px;
  height: 4px; */
  border-radius: 30px;
  margin: 0 3px;
}
.carousel .control-dots .dot.selected {
  background: rgb(0, 1, 43);
}

.globalContentHolderC {
  padding: 16px;
}
/* .global_learnmorebtn_s {
  margin-top: 14px;
  display: flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--blue-900);
  border: 1px solid red;
  background: var(--Blue-900, #00012B);
  color: var(--blue-900);
  text-align: center;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  background-color: transparent;
  margin-top: 14px;
} */

.text-left {
  text-align: left;
  min-height: 20px;
}

.text-right {
  text-align: right;
}
.hide {
  display: none;
}

@media (min-width: 1024px) {

  .carourselTTArrowLeft {
    /* position: absolute; */
    bottom: 30px;
    right: 140px;
    z-index: 20;
    cursor: pointer;
    /* border: 1px solid red; */
    max-height: 20px;
    width: 20px;
    user-select: none;
    margin-right: 4px;
  }

  .carourselTTArrowLeft > img,
  .carourselTTArrowRight > img {
    max-height: 20px;
    width: 20px;
  }
  .carourselTTArrowRight > img {
    transform: rotate(180deg);
  }
  .carourselTTArrowRight {
    /* position: absolute; */
    cursor: pointer;
    bottom: 30px;
    right: 15px;
    z-index: 20;
    max-height: 20px;
    width: 20px;
    user-select: none;
    margin-left: 3px;
  }
}
@media (max-width: 1023px) {
  .carourselTTArrowLeft {
    position: absolute;
    bottom: 15px;
    right: 70px;
    z-index: 20;
    cursor: pointer;
    max-height: 35px;
    width: 35px;
    user-select: none;
  }

  .carourselTTArrowLeft > img,
  .carourselTTArrowRight > img {
    max-height: 35px;
    width: 35px;
  }
  .carourselTTArrowRight > img {
    transform: rotate(180deg);
  }
  .carourselTTArrowRight {
    position: absolute;
    cursor: pointer;
    bottom: 15px;
    right: 25px;
    z-index: 20;
    max-height: 35px;
    width: 35px;
    user-select: none;
  }
}

@media (max-width: 1024px) {
  .carousel .control-dots .dot {
    display: none;
  }
  .carousel .control-dots {
    padding-left: 20px;
    /* margin: 10px 0; */
    /* margin: 16px 0; */

  }
  .p-l-16 {
    padding-left: 25px;
  }
}

@media (min-width: 769px) {
  .carousel .slide img {
    /* margin-left: 1px; */
    /* height: 265px; */
    /* width: 590px; */
    /* height: 294px;
    width: 494px; */
  }
  .carousel .control-dots {
    /* padding-left: 55.33%; */
    margin: 14px 0;
  }
}

@media (min-width: 1024px) {
  .carousel .control-dots {
    /* padding-left: 52.33%; */
    margin: 8px 0;
    padding-right: 20px;
    max-width: 300px;
  }
}
@media (max-width: 1349px) {
  .text-left {
    /* padding-top: 12px; */
  }
  .hideOnMobile {
    min-height: unset !important;
    max-height: 0px;
    display: none;
  }
}
