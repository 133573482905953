.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.25);
}
.modal {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 30;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.modal > div {
  display: flex;
  flex-direction: column;
  pointer-events: all;
  width: 90%;
  max-height: 80%;
  background-color: #f2f2f2;
  padding: 24px;
  border-radius: 14px;
}
.modal .modalHeader {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 24px; */
  gap: 24px;
  align-items: center;
}
.modal .closeButton {
  flex: 0 0 32px;
  padding: 8px;
}
.modal .closeButton:hover {
  background-color: rgba(0, 1, 43, 0.1);
  border-radius: 50px;
}
.modal .closeButton img {
  width: 24px;
  height: 24px;
}
.modalHeader h3 {
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 27px */
}
.modal .content {
  overflow-y: auto;
  scrollbar-width: thin;
  padding-right: 6px;
  padding-left: 2px;
}
@media (max-width: 1023px) {
  .modal {
    z-index: 101;
  }
  .modal > div {
    width: 100%;
    height: 100%;
    padding-right: 0px;
    padding-left: 8px;
    border-radius: 0px;
    max-height: none;
  }
}
@media (min-width: 1024px) {
  .modal > div {
    width: 60%;
    max-width: 696px;
  }
}
