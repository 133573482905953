.jobCardQuickViewContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  /* background: var(--White, #fff); */
  /* padding: 24px; */
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  min-width: 502px;
  /* min-height: 70%; */
}
.checkboxWrapper {
    margin-bottom: 10px;
    width: 50%;
    padding-right: 30px;
}
.checkboxWrapper > div > div {
  background: var(--Blue-900, #00012b);
}

.jobCardQuickViewContainer section {
  padding-bottom: 36px;
}

.jobCardQuickViewContainer .title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 27px */
  margin-bottom: 12px;
}

.dateContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dateContainer > :nth-child(n) {
  margin-right: 44px;
}

.secondLine {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  padding: 14px 0 10px 0;
}

.thirdLine {
  color: var(--Gray-500, #747474);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  padding-bottom: 20px;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.leftBtn {
  text-wrap: nowrap;
  margin-right: 30px;
}

.description {
  color: var(--Blue-900, #00012b);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}
.modal-header {
    display: none;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* gap: 20px; */
  /* margin-top: 20px; */
}
@media (max-width: 1150px) {
  .dateContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .dateContainer > :nth-child(n) {
    margin-top: 15px;
  }
}

@media (max-width: 850px) {
  .checkboxContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* gap: 20px; */
    /* margin-top: 20px; */
  }
  .checkboxWrapper {
    margin-bottom: 10px;
    width: 100%;
    padding-right: 30px;
}
}

@media (max-width: 690px) {
  .jobCardQuickViewContainer {
    min-width: unset;
  }

  .jobCardQuickViewContainer section {
    padding-bottom: 32px;
  }
  .jobCardQuickViewContainer section > div:nth-child(2){
    display: flex;
    flex-direction: column;
  }
  .checkboxWrapper {
    width: 100%;
}
}