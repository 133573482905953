.fiftyFiftyPhoto {
  /* margin: 32px 0; */
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* gap: 12px; */
  padding: 16px;
}


.fiftyFiftyPhoto > div {
  /* flex: 0 0 50%; */
  width: 100%;
  height: 100%;
}

.fiftyFiftyPhoto > div > img {
  border-radius: 8px;
  width: 100%;
  /* height: 100%; */
  pointer-events: auto;
  object-fit: fill;
}

.fiftyFiftyPhoto > div:nth-child(2) {
  /* flex: 0 0 50%; */
  /* padding: 16px 16px 0px 24px; */
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fiftyFiftyPhoto h3 {
  width: 90%;
  /* width: 80%; */
  text-align: left;
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; 
  margin-bottom: 9px;
}

.fiftyFiftyPhoto p,
.fiftyFiftyPhoto li,
.fiftyFiftyPhoto span {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  margin: 0;
}
.fiftyFiftyPhoto .buttonHolder {
  /* position: absolute; */
  position: relative;
  bottom: 0;
  left: 0;
  /* left: 24px; */
  /* margin-top: 24px; */
  margin-top: auto;
}

.fiftyFiftyPhoto .buttonHolder button {
  background-color: var(--blue-900);
  color: white;
  /* margin-bottom: 10px; */
}

.fiftyFiftyPhoto .buttonHolder button:hover {
  background-color: var(--blue-800);
  color: white;
  outline: none;
}

.slideDescription {
  text-align: left;
  overflow-y: hidden !important;
  text-overflow: ellipsis;
  /* display: -webkit-box; */
  /* margin-bottom: 32px; */
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}
.textUnderline {
  text-decoration-line: underline;
}
.divSpace {
  height: 27px;
}
.hideContainer {
  z-index: 1;
  position: absolute;
  right: 10px;
  top: 0;
}

.hideHolder {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  /* gap: 4px; */
  /* padding-top: 16px; */
  /* padding-right: 16px; */
  color: var(--blue-900, #00012b) !important;
  font-family: Calibre-R;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.hideHolder button {
  font-size: 16px;
  color: var(--blue-900, #00012b) !important;
  font-family: Calibre-R;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.hideHolder .eyeHolder {
  background: url("../../assets/carousal/Vector-eye-off.svg");
  width: 18px;
  height: 18px;
  padding-left: 1.5rem;
  background-repeat: no-repeat;
}
.visibilityHide {
  visibility: hidden;
  pointer-events: none;
}

.headerContainer {
  position: relative;
}
.fiftyFiftyPhoto > .FFcontainer {
  position: relative;
}

.fiftyFiftyPhoto > .FFcontainer .buttonHolder > button {
  /* margin-top: 20px; */
  position: relative;
  border: 1px solid var(--Blue-900, #00012b);
  color: var(--white);
  border-radius: 6px;
  background: var(--Blue-900, #00012b);
}
.fiftyFiftyPhoto > .FFcontainer .buttonHolder > button:hover {
  background: var(--blue-800);
}


.fiftyFiftyPhoto > .FFcontainerOneCol .buttonHolder > button {
  background: rgba(0, 0, 0, 0);
  color: var(--Blue-900, #00012b);

  /* z-index: 10; */
}
.fiftyFiftyPhoto > .FFcontainerOneCol .buttonHolder > button:hover {
  background: rgba(0, 0, 0, 0);
  color: var(--Blue-900, #00012b);
  outline: rgb(0, 1, 43) solid 1px;
}

.fiftyFiftyPhoto > .FFcontainerOneCol {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 8px;
  /* padding: 0 24px; */
  left: 0;
  position: relative;
}

.fiftyFiftyPhoto > .FFcontainerOneCol .slideDescription {
  margin-bottom: 32px;
}

.fiftyFiftyPhoto > .FFcontainerOneCol .linkHolder > a {
  /* margin-top: 20px; */
  position: relative;
  /* left: -1px; */
  /* left:0; */
}

@media (min-width: 1024px) {
  .FFcontainer {
    padding-right: 2rem;
  }
}

@media (max-width: 1349px) {
  .fiftyFiftyPhoto > div:nth-child(2) {
    /* flex: 0 0 50%; */
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 16px;
  }
  .fiftyFiftyPhoto {
    flex-direction: column;
    align-items: flex-start;
  }
  .hideHolder {
    padding-top: 16px;
  }
  .hideOnMobile {
    min-height: unset;
    max-height: 0px;
  }
  .fiftyFiftyPhoto h3 {
    max-width: 90%;
  }
}
@media (max-width: 700px) {
  .fiftyFiftyPhoto h3 {
    max-width: 75%;
  }
}

@media (max-width: 500px) {

  .fiftyFiftyPhoto .hideHolder span:nth-child(1) {
    display: none;
  }
  .fiftyFiftyPhoto h3 {
    max-width: 90%;
  }
  .fiftyFiftyPhoto > div {
    height: unset;
  }
  
}