.promoGroup {
  overflow: hidden;
  margin-bottom: 24px;
}
.promoGroup h2 {
  margin-bottom: 24px;
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.promoGroup .group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;
}
.promoGroup .card {
  display: flex;
  flex: 0 0 32%;
}
.promoGroup .image {
  min-width: 148px;
  min-height: 148px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.promoGroup .image img {
  max-width: 100%;
}
.promoGroup .content {
  margin-left: 16px;
}
.promoGroup .content h3 {
  margin-bottom: 4px;
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.promoGroup .content p,
.promoGroup .content span {
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.promoGroup .content button {
  margin-top: 16px;
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1300px) {
  .promoGroup .group {
    flex-direction: row;
    gap: 2%;
  }
}
