.bookmark {
  border: 1px solid var(--blue-900);
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  padding: 5px;
  position: relative;
  padding: 0 !important;
}

.bookmark:hover{
  
    stroke: var(--blue-900);
    border: 2px solid var(--blue-900);
    stroke-width: 1px;
  
}
.selectedBookmark {
  padding: 5px;
  border: 1px solid var(--blue-900);
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue-900);
  position: relative;
  padding: 0 !important;
}

.selectedBookmark svg, .bookmark svg {
  margin: 2px  0 0 0 !important;
}
