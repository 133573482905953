.row {
  display: flex;
  padding: 8px 16px;
  width: 100%;
  background: var(--gray-50);
  height: 64px;
  align-items: center;
  gap: 36px;
  border-bottom: 1px solid var(--gray-50);
}

.topDataHolder {
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.stockDesktop {
  display: flex;
  /*padding: 8px 16px;*/
  width: 100%;
  background: var(--gray-50);
  align-items: center;
  /*min-height: 48px;*/
}

.dStockName {
  margin-right: 16px;
}

.colxs6 {
  width: 50%;
}

.stockName {
  color: var(--Black);

  /* Additional styles/reg/text-lg-reg */
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  /* 24.3px */
}

.dateTimeHolder {
  color: var(--Black);
  /*padding-top: 4px;
   Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  display: flex;
  align-items: center;
  /* 18.9px */
}

.stockDetails {
  display: flex;
  align-items: center;
}

.price {
  color: var(--Black);
  text-align: right;

  /* Additional styles/med/text-lg-med */
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  margin-right: 8px;
  /* 24.3px */
}
.zero {
  background: url("../../../assets/stock/greenTriangle.svg");
  width: 16px;
  height: 16px;
  transform: rotate(90deg);
  /*display: block;*/
  margin-left: 8px;
  margin-right: 8px;
}
.indicatorred {
  background: url("../../../assets/stock/redTriangle.svg");
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
  display: inline-block;
  /*display: block;*/
  margin-left: 8px;
  margin-right: 8px;
}

.indicator {
  background: url("../../../assets/stock/greenTriangle.svg");
  width: 16px;
  height: 16px;
  /*transform: rotate(-90deg);
    display: block;*/
  margin-left: 8px;
  margin-right: 8px;
}

.down {
  width: 16px;
  height: 16px;
  transform: rotate(-90deg);
  flex-shrink: 0;
  background: url("../../../assets/stock/down.svg");
  margin-left: 8px;
  cursor: pointer;
}

.up {
  transform: rotate(180deg);
}

.percent {
  color: var(--Black);

  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  margin-right: 8px;
  /* 18.9px */
}
.dateTime {
  color: var(--Black, #000);
  text-align: right;
  /* Additional styles/reg/text-xs-reg */
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 16.2px */
}
.toggHolder {
  display: none;
}

.openToggle {
  display: block;
}

.show {
  display: block;
}

.hide {
  display: none;
}
.iconHolder {
  margin-right: 8px;
}
@media (min-width: 769px) {
  .topDataHolder {
    float: left;
    width: auto;
  }
  .dateTimeHolder {
    float: left;
  }
}
@media (max-width: 768px) {
  .stockDesktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
  }
  .topDataHolder {
    justify-content: center;
    margin-bottom: 8px;
  }
  .dateTimeHolder {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.stockErrorContainer h6 {
  color: var(--Black, #000);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
  margin-right: 8px;
  margin-left: 4px;
}

.stockErrorContainer p {
  color: var(--Black, #000);
  margin-right: 4px;
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  display: inline-block;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stockErrorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1024px) {
  .stockErrorContainer {
    display: flex;
    flex-direction: row;
  }
  .stockErrorContainer p {
    display: inline-block;
    text-align: left;
  }
}
/*@media (max-width:768px) {
    .row {
        position: absolute;
       
        left: 0;
    }

    .stockMobile {
        display: flex;
    }

    .stockDesktop {
        display: none;
    }
}

@media (min-width:769px) {
    .stockMobile {
        display: none;
    }

    .stockDesktop {
        display: block;
    }

}

@media (min-width:320px) {
    .row {
        gap:0;
        align-items: flex-start;
    }

}*/
