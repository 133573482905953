.messageContainer{
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
}

.messageBox {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}
.messageBox > div {
  flex: 0 0 40%;
}
.messageBox p {
  margin: 0;
}
.messageBox h2 {
  margin: 0;
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 40.5px */
}
.messageBox .notification {
  margin-top: 16px;

}
.messageBox .notification>div {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background: var(--gold-100);
}
.messageBox .notification p {
  font-size: 14px;
  margin: 0;
}
@media (max-width: 768px) {
  .messageBox
  {
    /* margin-top: 88px; */
  }
}
@media (min-width: 1024px) {
  .messageBox {
    flex-direction: column;
    align-items: right;
    max-width: 160px;
  }
  .messageBox > div {
    flex: 0 0 30%;
  }
  .messageBox .notification {
    margin-top: 0;
  }
  .messageBox .notification>div {
    padding: 12px 16px;
    border-radius: 8px;
  }
}
