.aucontainer {
  padding: 16px;
}

.occupancyHeader h3 {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  text-transform: capitalize;
}
.readMore {
  margin-top:16px;
}

.occupancyHeader div {
  padding-left: 0.5rem;
  font-size: 14px;
}

.occupanyDetails {
  display: flex;
  justify-content: space-around;
}

.rightBorder {
  position: relative;

  &::before {
    border: solid transparent;
    content: " ";
    height: 50px;
    width: 0;
    top: -7px;
    position: absolute;
    pointer-events: none;
    border-right: 1px solid lightgray;
  }
}

.selectDropdown {
  padding: 10px 15px;
  width: 100%;
  color: var(--blue-900);
  font-size: 14px;
  font-style: normal;
  line-height: 120%;
  border-radius: 20px;
  border: 1px solid var(--gray-100);
  background: var(--white);
  appearance: none;
  background-image: url("../../../assets/images/chevron-down.svg");
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
}

.occupancyRooms {
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}

.navHolder {
  display: flex;
  justify-content: right;
}

.occuValue {
  color: var(--blue-900, #00012b);
  text-align: center;
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 27px */
}

.rightnavP {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 0px 8px 8px 0px;
  border: 1px solid var(--blue-900);
  align-items: center;
  cursor: pointer;

  /* &:after {
    content: "";
    width: 22px;
    height: 22px;
    background: url("../../../assets/center-of-ex-facilities/chevron-right.svg")
      no-repeat;
    cursor: pointer;
  } */
}

.leftnavP {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px 0px 0px 8px;
  border: 1px solid var(--blue-900);
  align-items: center;
  cursor: pointer;

  /* &:after {
    content: "";
    width: 22px;
    height: 22px;
    background: url("../../../assets/center-of-ex-facilities/chevron-left.svg")
      no-repeat;
    cursor: pointer;
  } */
}
/* .leftnavP:hover,
.leftnavP:focus,
.leftnavP:active,
.rightnavP:hover,
.rightnavP:active,
.rightnavP:focus {
  outline: #00012b 1px solid;
} */

.leftnavP:hover,
.rightnavP:hover {
  outline: #00012b 1px solid;
}

.leftnavP:hover svg path, .rightnavP:hover svg path {
  fill: #00012b;
}

.scrollDiv {
  width: 768px;
}

.hscroll::-webkit-scrollbar {
  display: none;
}

.hscroll {
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (min-width: 768px) {
  .scrollDiv {
    width: 100%;
  }

  .hscroll {
    overflow-x: hidden;
  }
}
