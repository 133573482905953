.container {
  padding: 1rem;
  border: 1px solid #dcdcdc;
  /* border-radius: 6px; */
  background: #fff;
  position: absolute;
  z-index: 1;
  /* border-radius: 0; */
}

.setDWidth {
  width: 390px;
  max-height: 636px;
}

.setMWidth {
  width: 100%;
  height: 100vh;
}

.scrollingContainer {
  overflow-y: auto;
  /* padding: 0 1rem 0 0; */
  background: #fff;
  max-height: 50vh;
}

.scrollingContainer::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scrollingContainer::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
.scrollingContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--gray-100);
}

.container > p {
  font-size: 11px;
  margin: 7px 0;
}
.container > hr {
  border: 1px solid #99845d;
}
/* tab */
.container > .button {
  padding: 3px 0;
  width: 100%;
  display: flex;
}

.container > button:last-child {
  margin: 10px 0;
}

.bookmarkHeader {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  border-bottom: 0.5rem;
  border-bottom: 1px solid var(--gold-500);
  /* box-shadow: 0px 2px 16px 0px rgba(177, 150, 97, 0.2); */
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.bookmarkHeader a {
  text-align: right;
  color: var(--blue-900);
  text-decoration: underline;
  font-size: 14px;
}

.bookmarkItem {
  display: flex;
  padding: 8px 0 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  text-decoration: none !important;
  min-height: 55px;
}
.bookmarkItem:hover{
  background-color: var(--gray-50, "#E8E8E8");
}

.bookmarkItem:not(:last-child) {
  border-bottom: 1px solid var(--gold-500) !important;
}

.bookmarkItem h4 {
  color: var(--Blue-900, #00012b);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

.bookmarkItem span {
  color: var(--Gray-600, #5d5d5d);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

.h2 {
  color: var(--Blue-900, #00012b);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

.noBookmark {
  justify-content: left;
  display: flex;
  padding: 40px;
  margin: 0;
  padding: 0;
  margin-top: 32px;
  margin-bottom: 32px;
  color: var(--blue-900, #00012b);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

@media (min-width: 1024px) {
  .container {
    padding: 1rem;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    background: #fff;
    position: absolute;
    z-index: 1;
    -webkit-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 20%);
    -moz-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 20%);
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 20%);
    &::after {
      content: "";
      position: absolute;
      right: 112px;
      top: -15px;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 28px solid #ffffff;
      clear: both;
      z-index: 0;
    }
  }
}
