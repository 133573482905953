.messageBox1 {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}
.gap30 {
  gap: 8px;
  /* width: 50px; */
  background: var(--Gray-50, #e8e8e8);
}
/* .activeTab {
  background: green;
} */
.announcebox {
  display: flex;
  gap: 8px;
  align-items: center;
}
.messageBox1 p {
  margin: 0;
}
.messageBox1 h2 {
  margin: 0;
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 40.5px */
}
.messageBox1 .notification {
  margin-top: 16px;
}
.messageBox1 .notification > div {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background: var(--gold-100);
}
.messageBox1 .notification p {
  font-size: 14px;
  margin: 0;
}
.announcements {
  /* display: flex; */
  /* width: 50px; */
  height: auto;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
}

.imgSize {
  width: 24px;
}
.description {
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  place-self: center;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
.tabs button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: red;
  border: none;
  outline: none;
}
.tabs button:hover {
  background-color: blue;
}
.tab-content > div {
  padding: 20px;
}
.tabLocationHolder {
  margin: 0 0 32px 0;
  border-bottom: 1px solid var(--gray-100);
}
.tabLocationHolderAtt {
  margin: 0 0 32px 0;
  border-bottom: 1px solid var(--gray-100);
  margin-left: 16px;
  width: 100vw;
}

.tabLocationHolderAtt .tabLocationItem,
.tabHolder .tabItem {
  display: inline-block;
  color: var(--blue-900);
  line-height: 135%;
  text-decoration: none;
  padding: 16px 2px;
  margin: 0 15px;
  border-radius: 0;
}
.tabLocationHolderAtt .tabLocationItem.active,
.tabHolder .tabItem.active {
  color: var(--blue-500);
  font-weight: 500;
  border-bottom: 1px solid var(--blue-500);
}
.tabLocationHolder .tabLocationItem,
.tabHolder .tabItem {
  display: inline-block;
  color: var(--blue-900);
  line-height: 135%;
  text-decoration: none;
  padding: 16px 2px;
  margin: 0 15px;
  border-radius: 0;
}
.tabLocationHolder .tabLocationItem:hover,
.tabHolder .tabItem:hover {
  color: var(--blue-900);
}

.tabLocationHolder .tabLocationItem.active,
.tabHolder .tabItem.active {
  font-weight: 500;

  border-bottom: 2px solid var(--Gold-800, #baa173);
}
.messageBox1 .viewfeedback {
  color: var(--Blue-900, #00012b);

  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  text-decoration-line: underline;
}
.isMobileContainer {
  display: grid;
  grid-auto-flow: column;
}
.wrapperContainer {
  display: flex;
  gap: 8px;
  justify-content: end;
  align-items: end;
}
.feedbackIconWrapper {
  /* margin-top: 40px; */
  display: inline-grid;
  /* gap: 10px;
  width: 100%; */
  /* float: right; */
  grid-auto-flow: column;
  justify-content: end;
  justify-items: end;
  /* margin-top: -38%; */
  /* padding-top: -20%; */
  /* margin-top: 42px; */
  margin-top: 32px;
  /* margin-bottom: 8px; */
}

/* for last tab */
.contentBorderR {
  border-radius: 8px 0 8px 8px !important;
}
.content {
  margin-top: -2px;
  width: 100%;
  position: relative;
  /* left: 3%; */
  /* right: 2%; */
  border-radius: 8px;
  background: var(--Gray-50, #e8e8e8);
  height: auto;
  padding: 16px 16px;
  display: block;
}

@media (max-width: 768px) {
  .messageBox1 {
    /* padding-bottom: 30px; */
    /* margin-top: 88px;
    margin-bottom: -40px; */
  }
  .announcements {
    /* display: flex; */
    /* width: 50px; */
    height: 48px;
    /* padding: 8px 16px; */
    padding: 16px 16px;
    align-items: center;
    gap: 8px;

    border-radius: 8px;
    border: 1px solid var(--Gray-50, #e8e8e8);
  }
  .activeAnnounce {
    outline: 0 !important;
    background: var(--Gray-50, #e8e8e8);
    margin-top: 8px;
    border-radius: 8px 8px 0 0;
    height: 50px;
    padding: 12px 12px;

    /* outward round corner from bottom */
    /* --r: 0.8em; 
    border: solid #0000;
    border-width: 0 var(--r);
    border-radius: calc(2 * var(--r)) calc(2 * var(--r)) 0 0 / var(--r);
    mask: radial-gradient(var(--r) at var(--r) 0, #0000 98%, #000 101%)
        calc(-1 * var(--r)) 100%/100% var(--r) repeat-x,
      conic-gradient(#000 0 0) padding-box; */
    /* width: 50px; */
    /* margin-bottom: 30px; */
  }
  /* .feedbackIconWrapper:last-child .activeAnnounce {
    border-right-width: 0;
    border-top-right-radius: var(--r);
  } */

  .inActiveAnnounce {
    border-radius: 8px !important;
    border: 1px solid var(--Gray-50, #e8e8e8) !important;
    width: 50px;
    height: 48px;
    padding: 8px 12px;
    margin-bottom: 8px;
  }
}
@media (min-width: 1024px) {
  .announcements {
    display: flex;
    /* width: 50px; */
    height: auto;
    /* padding: 8px 16px; */
    padding: 16px;
    align-items: center;
    gap: 8px;
    background: var(--Gray-50, #e8e8e8);
    border-radius: 8px;
    border: 1px solid var(--Gray-50, #e8e8e8);
  }

  .messageBox1 {
    flex-direction: column-reverse;
    margin-top: 0;
    align-self: end;
    /* flex-direction: row; */
    /* align-items: center; */
    align-items: end;
  }
  .messageBox1 > div {
    flex: 0 0 70% !important;
  }
  .messageBox1 .notification {
    margin-top: 0;
  }
  .messageBox1 .notification > div {
    padding: 12px 16px;
    border-radius: 8px;
  }
}
