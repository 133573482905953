.titleLinkedIn{
    color: var(--Blue-900, #00012B);
/* Additional styles/med/text-2xl-med */
font-family: Calibre-R;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 135%; 
vertical-align: bottom;
display: flex;
align-items: flex-start;
}
.linkedIntile{
    background-color: var(--white);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 24px;
    position: relative;
    /* flex: 0 0 32%; */
    
}
.modalRedirect {
    color: var(--Blue-900, #00012B);
    text-align: right;
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    /* text-decoration-line: */
  }
  .modalRedirect:hover{
    /* color: var(--blue-500); */
    color: var(--Blue-900, #00012B);
    font-weight:500;
  }
.sprinklrStyle{
    display: none;
}
.linkedIncontent{
    padding-bottom: 72px;
    padding: 16px;
    width:290px;
}
.parentText{
    display: flex;
    margin-bottom: 8px;
    cursor:default !important;
}
.linkedInTextStyle,.linkedInTextStyle>div {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 135%;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor:default !important;
}

.linkedIncontent>h3{
    margin-bottom: 4px;
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; 
}
.linkedInimage{
    width: 100%;
    height: 218px;
    text-align: center;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center 25% !important;
    border-radius: 8px 8px 0px 0px;
}
.carousalParent{
    align-items: center;
    background-color: white;
    display: flex;
    cursor:default !important;
    font-size: 32px;
    font-weight: 800;
    height: '388px';
    justify-content: center;
    width: 290px;
    flex-direction: column;
    border-radius: 8px 8px 8px 8px;
}
.linkedInimageMob{
    width: 100%;
    height: 218px;
    cursor:default !important;
    text-align: center;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center 20% !important;
    border-radius: 8px 8px 0px 0px;
}
.linkedInIcon{
    vertical-align: top;
    /*margin-top:2px;*/
    margin-right: 8px;
}
.readLink {
    padding-left: unset !important;
}
.readLink>span{
    color: var(--Blue-900, #00012B);
font-family: Calibre-R;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
text-decoration-line: underline;
    cursor: pointer;
    padding:0px !important;
    margin-top:8px;
    /* flex: 1 0 0 */
}
.buttonHolder{
    display:flex;
    /* flex-direction: row;
    gap:32px; */
    justify-content: space-between;
}
.buttonHolder>img{
  cursor:default !important;
}
.readLink>span:hover{
    /* color: #373ae5; */
    color: var(--Blue-900, #00012B);
    font-weight: 500;
    font-family: Calibre-R;
    /* font-weight: 400; */
    line-height: 24px;
    font-size: 16px;
    flex: 1 0 0
}
.linkedinContainer{
    background: var(--Gray-50, #e8e8e8);
    padding:40px;
    margin-left:-48px;
    margin-right:-48px;
}
.linkedinContainer>div>div>div:nth-child(2){
    padding-top:24px;
    touch-action: pan-y;
}
.linkedinContainer>div>div>div:first-child>div{
    padding-bottom: 40px;
}
   
.linkedInText{
    color: var(--Gray-500, #747474);
/* Additional styles/reg/text-sm-reg */
font-family: Calibre-R;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 135%;
}
.linkedInSubText{
    color: var(--Gray-500, #747474);
/* Additional styles/reg/text-sm-reg */
font-family: Calibre-R;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 135%;
}
.linkedLine{
    display:flex;
    gap:10px;
    align-items: center;
}
.linkedLineMob{
    display:flex;
    gap:8px;
    align-items: flex-start;
}
.linkedinList{
    display: grid;
    color: #00012b;
    grid-column-gap: 2%;
    grid-row-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
}
.linkedInProfileCont{
    /* display:flex;
    flex-direction: column; */
}
.linkedInProfileHeader{
    display:flex;
    align-items: center;

}

.linkedInLogo{
    width:20px;
    height:20px;
}
.linkedInProfilePosition{
    margin-top:8px;
}
.linkedinCard{
    width: 100%;
    background-color: var(--white);
    border-radius: 8px;
    border: 1px solid var(--gray-50);
}
.linkedinCardTile{
    border-radius: 8px 8px 8px 8px;
    /* border: 1px solid var(--gray-50); */
    overflow: hidden;
    /* margin-bottom: 24px; */
    position: relative;
    cursor: pointer;
    gap: 24px;
    background: var(--white, #fff);
}
@media (max-width: 769px){
    .linkedinContainer>div:nth-child(3)>div>div:first-child{
        flex-direction: column;
        align-items: flex-start;
    }
    .linkedinContainer>div>div>div:first-child>div{
        margin-left: 0 !important;
        /*margin-top: 8px;*/
        margin-left: 60px !important;
        padding-bottom: 0 !important;
    }
}
@media (max-width: 1024px) {
    .linkedinContainer{
        padding-top:32px;
        padding-bottom: 32px;
        padding-left:16px;
        padding-right:16px;
        margin-left: -16px;
        margin-right:-16px;
        background: var(--Gray-50, #e8e8e8);

    }
    .linkedinContainer>div>div>div:first-child>div{
        padding-bottom: 40px;
        padding-right:16px;
        align-self: flex-start !important;
        margin-left:24px;
    }
    .linkedinContainer>div>div>div:first-child>div>div{
        margin-right:16px !important;
        /*max-width: 100px;*/
    }
    .linkedinContainer>div>div>div:first-child>div>div>div>a{
        padding:8px 0px 0px 8px !important;
    }
    .linkedinContainer>div:nth-child(3)>div>div:first-child{
        height:fit-content;
        margin-bottom: 0px !important;
    }
    .linkedLineMob{
        display:flex;
        margin-bottom:8px;
        align-items: flex-start;
    }
    .modalRedirect {
        text-align: left;
    }
}