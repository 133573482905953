.OfferModal {
  color: #00012b;
  padding: 8px;
  z-index: 50;
}

.checkboxGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.checkbox {
  display: flex;
  margin-bottom: 16px;
  width: 50%;
  /* font-size: 16px; */
  /* margin-left: 16px; */
}
.checkboxLabels {
  font-size: 16px;
  margin-left: 16px;
}

.buttonGroup {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 16px;
}
.buttonGroup .applyButton {
  padding: 26px; /* //16px */
  background-color: #292cb7; /* //gradient */
  color: #fff;
  font-size: 16px;
}
.buttonGroup .clearButton {
  font-size: 14px;
  text-decoration: underline;
  border: none;
  margin-right: 24px;
  padding: 0;
}
.offerImageContainerOuter {
  height: min-content;
  max-height: calc(100vh - 200px);
  overflow: auto;
  margin-bottom: 16px;
}
.offerImageContainer {
  overflow: hidden;
  display: flex;
  border-radius: 16px;
  align-items: center;
  margin-bottom: 24px;
}
img.offerImage {
  width: 100%;
  /* margin-bottom: 24px; */
}

.OfferModal h3 {
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 8px;
  color: var(--bs-heading-color);
}

.OfferModal h2 {
  font-size: 18px;
  margin-bottom: 24px;
}

.innerHtml p {
  margin-bottom: 8px;
}
.innerHtml {
  margin-bottom: 24px;
}
.RedeemButton {
  padding-bottom: 24px;
}
a.redeemBtn {
  padding-bottom: 32px;
  /* cursor: pointer; */
  position: absolute;
  bottom: 24px;

  display: flex;
  height: 40px;
  /* padding-top:24px;
  padding-bottom:24px; */
  margin-bottom: 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--blue-900, #00012b);
  cursor: pointer;
  padding: 10px 16px;
  text-align: center;
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.edit-popup > div {
  z-index: 20;
}
/* .modal-mobile {
  border-radius: 16px;
  background: var(--gold-25, #faf9f5);
}

@media (max-width: 999px) {
  .modal-mobile {
    width: 100% !important;
    height: 100% !important;
    border-radius: none !important;
  }
} */

@media (min-width: 640px) {
  .offerImageContainer {
    height: 250px;
  }
}
