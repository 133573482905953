.secondaryNavigation {
    margin: 0 16px 32px 16px;
}
.secondaryNavigation select {
  width: 100%;
  padding: 15px;
  color: var(--blue-900);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  border-radius: 8px;
  border: 1px solid var(--gray-100);
  background: var(--white);
  box-shadow: 2px 2px 8px 0px rgba(55, 58, 229, 0.1);
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../../assets/images/chevron-down.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
}
