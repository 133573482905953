.managerMobilenav {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
}

.managerMobilenav ul li {
  font-size: 16px !important;
  white-space: break-spaces !important;
}

.managerMobilenav > div {
  width: fit-content;
}

.managerMobilenav > div > div {
  position: unset !important;
}

.mobilePaginationSection {
  margin-top: 24px;
}

.mobilePaginationSection {
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
}

.mobilePaginationSection button {
  color: var(--blue-900, "#00012B");
  font-size: 16px;
}

.tabHolder {
  margin: 0;
  border-bottom: 1px solid var(--gray-100);
  margin-bottom: 24px;
  display: none;
}
.tabHolder .tabItem {
  display: inline-block;
  color: var(--blue-900);
  line-height: 135%;
  text-decoration: none;
  padding: 16px 2px;
  margin: 0 32px 0 0;
  border-radius: 0;
  &:hover {
    color: var(--Blue-900, #00012b);
  }
}
.tabHolder .tabItem.active {
  color: var(--Blue-900, #00012b);
  font-weight: 500;
  border-bottom: 1px solid var(--Rewards-Tier-Gold, #bda770);
}

.reportsContainer {
  display: grid;
  grid-gap: 8px;
}

.toptextholder {
  /* display: flex; */
  justify-content: center;
  margin-bottom: 24px;
  /* padding: 0 16px; */
}

.subTextHolder {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
}

.managerTitle {
  color: #282827;
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
}

.managerRightSection {
  color: var(--blue-900, #00012b);
  text-align: right;
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  right: 0;
  text-transform: capitalize;
  display: flex;
  gap: 8px;
  align-items: center;
}

.managerRightSection a {
  color: var(--blue-900, #00012b);
  font-weight: 400;
}

.managerTitle a:hover {
  color: var(--blue-500);
  font-weight: 500;
}
.managerMobilenav button > div {
  white-space: break-spaces;
}
.managerMobilenav ul {
  box-shadow: 2px 2px 8px rgba(55, 58, 229, 0.1);
  background-color: #ffffff;
  /* border-color: #00000000; */
  border: none !important;
  border-radius: 0px 0px 8px 8px !important;
  bottom: auto !important;
  margin-top: 0.5rem;
  width: 93% !important;
  overflow-y: auto !important;
  max-height: 500px !important;
}
@media (min-width: 1024px) {
  .reportsContainer {
    grid-template-columns: repeat(3, 1fr);
  }
  .managerMobilenav  #dateDropdown button {
    max-width: fit-content !important;
    width: 100% !important;
    grid-gap: 16px !important;
  }

  .managerMobilenav button > div, .managerMobilenav ul li {
    white-space: nowrap;
  }

  .managerMobilenav ul {
    width: 100% !important;
    max-width: fit-content !important;
    border-radius: 0 0 8px 8px !important;
    border: none !important;
  }
}
