.chart {
  margin-top: 16px;
}
.chart:first-child {
  margin-top: 6px;
}
.chart p {
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 18.9px */
  margin-bottom: 8px;
}
.currentPeriod {
  background-color: var(--blue-500);
  color: var(--white);
}
.previousPeriod {
  color: var(--white);
  background: var(--blue-900);
}
.currentPeriod p,
.previousPeriod p {
  color: var(--gray-600);
  margin: 0;
  padding: 3px 0 2px 8px;
  font-weight: 400;
  position:relative;
  left:100%;
  overflow-wrap: normal;
}
