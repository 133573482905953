.notEnrolledContainer {
  height: 100%;
  justify-content: center;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.notEnrolledContainer .dollarGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 500px;
}
.notEnrolledContainer .dollarGroup .dollarElement {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.notEnrolledContainer .dollarGroup .dollarElement:last-child .innerhtmlText p {
  margin-top: 18px;
}
.notEnrolledContainer .dollarGroup .dollarElement .innerhtmlText {
  text-align: center;
}
.notEnrolledContainer .dollarGroup .dollarElement .innerhtmlText span {
  margin-bottom: 8px;
  display: block;
}
.notEnrolledContainer .dollarGroup .dollarElement .innerhtmlText p {
  font-size: 14px;
}
.notEnrolledContainer .dollarGroup .iconSign {
  width: 31px;
  height: 60px;
}

.notEnrolledContainer .dollarGroup .dollarElement .iconDollarBig {
  width: 106px;
  margin: 0px 100px;
}
.notEnrolledContainer .dollarGroup .dollarElement .iconDollar {
  width: 71px;
  height: 106px;
}

.notEnrolledContainer .getStartedBtn {
  margin-bottom: 32px;
  margin-top: 24px;
  color: var(--Blue-900, #00012b);
}

@media (min-width: 1024px) {
  .notEnrolledContainer {
    margin-right: 30px;
    width: 268px;
  }
}

@media (min-width: 1054px) {
  .notEnrolledContainer {
    width: 100%;
    margin-right: -30px;
  }
}

@media (min-width: 1440px) {
  .notEnrolledContainer {
    margin-top: 60px;
  }
  .notEnrolledContainer .dollarGroup {
    flex-direction: row;
    align-items: baseline;
    height: auto;
  }
  .notEnrolledContainer .dollarGroup .dollarElement {
    flex-direction: column;
    align-items: left;
    /* width: 133px; */
  }
  .notEnrolledContainer .dollarGroup .dollarElement .innerhtmlText {
    margin-top: 30px;
    /* width: 133px; */
  }
  .notEnrolledContainer .dollarGroup .dollarElement .innerhtmlText span {
    margin-bottom: 15px;
  }
  .notEnrolledContainer .dollarGroup .iconSign {
    height: 106px;
  }

  .notEnrolledContainer .getStartedBtn {
    margin-top: 30px;
  }

  .notEnrolledContainer .dollarGroup .dollarElement .iconDollarBig {
    width: 106px;
    margin: 0px;
  }

  .notEnrolledContainer
    .dollarGroup
    .dollarElement:last-child
    .innerhtmlText
    p {
    margin-top: 0;
  }
  .notEnrolledContainer .dollarGroup {
    min-height: unset;
  }
}
