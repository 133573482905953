.cardContainerShowmore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  border-bottom: 1px solid var(--gray-100);
}
.showMoreLabel {
  padding: 16px 0px 16px 0px;
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.viewAll {
  display: flex;
  align-items: center;
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
}
.fmlaHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  border-bottom: 1px solid var(--gray-100);
  padding: 25px 0px 25px 0px;
}
.fmlaLeft {
  display: flex;
  gap: 17px;
}
.fmla {
  color: var(--Blue-900, #00012b);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.fmlaContent {
  color: var(--Gray-600, #5d5d5d);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.fmlaHours {
  display: flex;
  gap: 7px;
  align-items: flex-end;
  white-space: nowrap;
}
.fmlaNumber {
  color: var(--Blue-900, #00012b);
  /* Additional styles/med/text-2xl-med */
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
}
.fmlaText {
  padding-bottom: 5px;
  color: var(--Gray-600, #5d5d5d);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.loaItem {
  display: flex;
  align-items: center;
  gap: 17px;
  padding: 15px 0px 15px 0px;
  background-color: var(--white);
  border-bottom: 1px solid var(--gray-100);
}
.loaLabel {
  color: var(--Blue-900, #00012b);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.loaType {
  color: var(--Blue-900, #00012b);
  /* Additional styles/med/text-sm-med */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 18.9px */
}
.loaDuration {
  color: var(--Gray-600, #5d5d5d);
  /* Additional styles/med/text-sm-med */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.fmlaNodata {
  color: var(--Gray-600, #5d5d5d);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.loaFallbackMessage {
  color: var(--Gray-600, #5d5d5d);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.loaPending {
  color: var(--Gray-600, #5d5d5d);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

@media (max-width: 1023px) {

.fmlaHours {
  display: flex;
  gap: 0px;
  align-items: flex-end;
  white-space: nowrap;
  flex-direction: column;
}
}