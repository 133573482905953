.header {
  padding: 24px;
  text-align: center;
}

@media (min-width: 1024px) {
  .header {
    padding: 32px;
  }
}
