.customDropdownType1 .dropDownButton {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 3px;
  align-items: center;
  border: 0;
  background-color: var(--black, #ffffff);
  border-radius: 25px;
  width: 100%;
  /* padding: 0.4rem 1rem; */
  padding: 0.6rem 1rem !important;
  grid-template-columns: 90% 10%;
  justify-content: flex-start;
  text-align: left;
  border: 1px solid black;
  color: var(--black, #000000);
  font-size: 14px;
  font-weight: 500;
  font-family: Calibre-R;

  &:hover,
  &:not(:disabled):hover {
    cursor: pointer;
    background-color: var(--white, #ffffff);
    color: var(--black, #000000);
  }
}

.textWrap {
  white-space: nowrap;

  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  color: var(--Gray-300, #a2a2a2);
  display: flex;
  align-items: center;
}

.customDropdownType1 .optionList {
  white-space: nowrap;
  padding: 1rem;
  color: var(--blue-900, #00012b);
  cursor: pointer;
  font-size: 14px;

  &:active,
  &:focus,
  &:hover,
  &[aria-selected="true"] {
    background-color: var(--blue-25, #f4f3fc);
    color: var(--blue-900, #00012b);
    border-color: var(--blue-900, #00012b);
    border-radius: 25px 25px 0 0;
  }

  &:focus:not(:first-child),
  &:active:not(:first-child),
  &:hover:not(:first-child),
  &:not(:first-child)[aria-selected="true"] {
    border-radius: 0;
  }

  &:focus:last-child,
  &:active:last-child,
  &:hover:last-child,
  &:last-child[aria-selected="true"] {
    border-radius: 0 0 25px 25px;
  }
}

.customDropdownType1 .container {
  position: relative;
}

.customDropdownType1 .customList {
  background-color: var(--white, #ffffff);
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 40px;
  height: fit-content;
  list-style-type: none;
  text-align: left;
  border: 1px solid var(--black-100, #000000);
  list-style: none;
  padding: 0;
  position: absolute;
  width: 100%;
  border-radius: 25px;
  top: 55px;
  z-index: 2;
}

.customDropdownType1 .customList::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.customDropdownType1 .customList::-webkit-scrollbar {
  margin-top: 10px;
  width: 8px;
  background-color: #f5f5f5;
}

.customDropdownType1 .customList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--gray-100);
}

.customDropdownType1 .arrowDown {
  transform: rotate(0deg);
  transition: 0.3s ease all;
}

.customDropdownType1 .arrowDownActive {
  transform: rotate(180deg);
  transition: 0.3s ease all;
}

.customDropdownType2 .dropDownButton {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 9px;
  align-items: center;
  border: 0;
  background-color: var(--black, #ffffff);
  width: 100%;
  /* padding: 0.4rem 1rem; */
  padding: 0.6rem 0 0.6rem 1.6rem !important;
  grid-template-columns: 85% 10%;
  justify-content: flex-start;
  text-align: left;
  border-bottom: 1px solid #8b8b8b;
  color: var(--black, #000000);
  font-size: 14px;
  font-weight: 500;
  font-family: Calibre-R;
  height: 58px;
  margin-top: 6px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  &:hover,
  &:not(:disabled):hover {
    cursor: pointer;
    background-color: var(--white, #ffffff);
    color: var(--black, #000000);
  }
}

.customDropdownType2 .optionList {
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;

  white-space: nowrap;
  padding: 1rem;
  color: var(--gray-900);
  cursor: pointer;
  margin-left: 8px;
  display: flex;
  align-items: center;

  &:active,
  &:focus,
  &:hover,
  &[aria-selected="true"] {
    background-color: var(--blue-25, #f4f3fc);
    color: var(--blue-900, #00012b);
    border-color: var(--blue-900, #00012b);
    /* border-radius: 8px 8px 0 0; */
  }

  &:focus:not(:first-child),
  &:active:not(:first-child),
  &:hover:not(:first-child),
  &:not(:first-child)[aria-selected="true"] {
    border-radius: 0;
  }

  &:focus:last-child,
  &:active:last-child,
  &:hover:last-child,
  &:last-child[aria-selected="true"] {
    border-radius: 0 0 25px 25px;
  }
}

.customDropdownType2 .container {
  position: relative;
  margin-top: 10px;
}

.customDropdownType2 .customList {
  background-color: var(--white, #ffffff);
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 40px;
  height: fit-content;
  list-style-type: none;
  text-align: left;
  /* for submit recog - border removed from dropdown  */
  /* border: 1px solid var(--black-100, #000000); */
  list-style: none;
  padding: 0;
  position: absolute;
  width: 100%;
  border-radius: 8px;
  /* top: 55px; */
  z-index: 2;
  overflow-y: scroll;
  max-height: 140px;
  bottom: 80%;
}

.customDropdownType2 .customList::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.customDropdownType2 .customList::-webkit-scrollbar {
  margin-top: 10px;
  width: 8px;
  background-color: #f5f5f5;
}

.customDropdownType2 .customList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--gray-100);
}

.arrow {
  text-align: end;
}

.customDropdownType2 .arrowDown {
  transform: rotate(0deg);
  transition: 0.3s ease all;
}

.customDropdownType2 .arrowDownActive {
  transform: rotate(180deg);
  transition: 0.3s ease all;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.iconLabel {
  margin-left: 8px;
}

/* //type 3 - opening the dropdown in the bottom - overiting the type 2 css*/
.customDropdownType3 .customList {
  position: relative;
  border-radius: 0px 0px 8px 8px;
  max-height: 170px;
  box-shadow: 0px 2px 16px 0px rgba(177, 150, 97, 0.2);
}
.customDropdownType3 .container {
  margin-top: 0;
}

.customDropdownType3 .optionList {
  &:focus:last-child,
  &:active:last-child,
  &:hover:last-child,
  &:last-child[aria-selected="true"] {
    border-radius: 0;
  }
}

@media (max-width: 999px) {
  .customDropdownType2 .dropDownButton {
    width: 100%;
  }

  .customDropdownType2 .customList {
    width: 100%;
  }
}
