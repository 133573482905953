.linkSetHolder {
  margin: 32px 0;
}
.linkSetHolder .center {
  text-align: center;
}
.linkSetHolder .right {
  text-align: right;
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .linkSetHolder {
    margin: 48px 0;
  }
}
