.volunteer {
  padding: 24px 16px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid var(--gray-100);
}

.dateTimeWrapper {
  display: inline-flex;
  gap: 20px;
}

.singleCardImgWrap {
  min-height: 170px;
  max-height: 170px;
  overflow: hidden;
}

.cardContainerInner {
  display: grid;
  height: 100%;
  grid-gap: 16px;
}

.btnContainer {
  align-self: flex-end;
}

.locationBlock {
  display: inline-flex;
  gap: 8px;
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.locationDetails{
  min-height: 42px;
}

.dateBlock {
  display: inline-grid;
  grid-gap: 8px;
  grid-auto-flow: column;
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  align-items: center;
}

.dateBlock img {
  width: 24px;
  height: 24px;
}

.volunteerOppBlock h6 {
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  /* min-height: 40px; */

}

.buttonContainer {
  color: var(--Blue-900, #00012b);
  text-align: center;
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

.volunteerOppBlock {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 16px;
}
.volunteerOppBlockTitle{
  min-height: 58px;
}
.volunteerContainer {
  display: inline-grid;
  gap: 16px;
  grid-auto-flow: column;
  grid-template-columns: 80px auto;
  /* padding-bottom: 16px; */
}

.singleVolunteer {
  display: inline-grid;
  gap: 32px;
}

.volunteerOppBlock {
  width: 100%;
}

.volunteerContainer .ImgWrapper img {
  border-radius: 8px;
}

.singleVolunteer .ImgWrapper img {
  border-radius: 8px 8px 0 0;
}

@media (min-width: 768px) {
  .volunteer {
    min-height: 90px;
  }
  .singleVolunteer {
    display: inline-grid;
    gap: 35px;
    grid-auto-flow: column;
    grid-template-columns: 278px auto;
  }
  .singleVolunteer .ImgWrapper img {
    border-radius: 8px;
  }
  .volunteerOppBlockTitle{
    min-height: 0;
  }
  .locationDetails{
    min-height: 0;
  }
}

@media (min-width: 1024px) {
  .volunteer {
    padding: 24px;
  }
  .volunteerContainer {
    display: inline-grid;
    gap: 24px;
    grid-auto-flow: column;
    grid-template-columns: 100px auto;
  }
  .volunteerOppBlockTitle{
    min-height: 0;
  }
  .locationDetails{
    min-height: 0;
  }
}

@media (min-width: 1280px) {
  .volunteerContainer {
    padding-bottom: 0;
  }
  .btnContainer {
    margin-left: 124px;    /* 100px + spacing 24px  */
  }
  .volunteerOppBlockTitle{
    min-height: 0;
  }
  .locationDetails{
    min-height: 0;
  }
}
