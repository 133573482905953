.tabHolder {
  margin: 0;
  border-bottom: 1px solid var(--gray-100);
}
.tabHolder .tabItem {
  display: inline-block;
  color: var(--blue-900);
  line-height: 135%;
  text-decoration: none;
  padding: 16px 2px;
  margin: 0 15px;
  border-radius: 0;
}
.tabHolder .tabItem:hover {
  color: var(--blue-900);
}
.tabHolder .tabItem.active {
  color: var(--Blue-900, #00012B);
  font-weight: 500;
  border-bottom: 1px solid var(--Rewards-Tier-Gold, #BDA770);
}
.detailsHolder.hideDetails {
  display: none;
}
/*
.hideMyPay {
  margin-top: 16px;
}
.hideMyPay > div {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}
.hideMyPay > div > span {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
*/
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1100px) {
  /*
  .cardContainerInner {
    padding: 16px 40px 40px;
  }
  .hideMyPay {
    margin-top: 24px;
  }
  */
}
@media (min-width: 1300px) {
}
