
.tabs {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
}

.tabsType2 {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 20px;
    justify-content: center;
}

.tab {
    color: #000000;
    /* padding: 0.8125rem; */
    letter-spacing: 0.01rem;
    font-weight: 400;
    text-transform: capitalize;
    border: 1px solid #000000;
    border-radius: 20px;
    font-size: 14px;
    line-height: 100%;
    height: 40px;
}

.selectedTab {
    background: var(--blue-25, #F4F3FC);
    border: 1px solid var(--blue-500);
}

.selectedTabType2 {
    border-bottom: 2px solid var(--blue-500) !important;
    color: var(--blue-500);
    padding-bottom: 10px;
}

.tabType2 {
    border-bottom: 2px solid transparent;
    background-color: transparent;
    padding-bottom: 10px;
}

/* golden tabs */
.golden button {
  font-size: 16px;
  color: var(--Blue-900, #00012b);
  font-weight: 400;
}
.golden button.selectedTabTheme {
  font-weight: 500;
}
.golden button:disabled {
  color: var(--Gray-300, #a2a2a2);
  font-weight: 400;
}
.golden .selectedTabType2 {
  border-color: var(--Gold-700, #c2ad86) !important;
}
div.golden div.tabLine {
  border-bottom: 1px solid var(--Gray-100, #d1d1d1);
}

@media (min-width: 500px) {
  div.golden div.tabLine {
    inline-size: fit-content;
  }
}
