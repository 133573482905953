.employeeCardBlock {
  width: 100%;
}
.employeeDetails {
  display: grid;
}
.directReportsContainer {
  text-decoration: none;
}
.reportsContainer {
  /* display: flex;
  gap: 35px; */
  display: inline-grid;
  gap: 16px;
  grid-auto-flow: column;
}
.reports {
  /* display: flex; */
  /* flex: 50%; */
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  height: 100%;
  /* margin-bottom: 20px; */
}
/* .cardContainerInner {
  padding: 24px;
} */
.directReportIcon,
.iconContainer a.directReportIcon {
  background-color: var(--gold-900);
  min-width: 48px;
  height: 48px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  color: var(--black, #000);
  position: relative;
}
.goldColor {
  background: #fff;
  /* border: 1px solid var(--gray-100); */
  box-shadow: 0px 0px 4px 0px #00000026;
  
  border-radius: 8px;
  border: 1px solid transparent;
  height: 100%;
  &:hover,
  &:focus,
  &:focus-visible {
    /* border: 1px solid var(--Gray-100, #d1d1d1); */
    box-shadow: 0px 0px 4px 0px #0000004D;
    /* box-shadow: 2px 2px 8px 0px rgba(55, 58, 229, 0.1); */
  }
}
.greyColor {
  cursor: default;
}
.greyColor .directReportIcon {
  border: 1px solid var(--Gray-100, #d1d1d1);
  background: #fff;
  background-color: var(--Gray-50, #e8e8e8);
  cursor: default;
}
.greyColor .employeeName {
  color: var(--Gray-700, #464646);
}
.greyColor .employeeDescp1 {
  color: var(--Gray-700, #464646);
}
.greyColor .employeeDescp2 {
  color: var(--Gray-700, #464646);
}
.employeeName {
  color: var(--Blue-900, #00012b);
  /* Additional styles/med/text-lg-med */
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
  text-transform: capitalize;
}
.employeeDescp1 {
  color: var(--Gray-900, #171717);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  padding-top: 8px;
}
.employeeDescp2 {
  color: var(--Gray-700, #464646);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.iconBackground {
  color: var(--Blue-900, #00012b);
  text-align: center;
  /* Additional styles/reg/text-xl-reg */
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 27px */
  position: relative;
}

.smallIcon {
  display: flex;
  position: absolute;
  min-width: 32px;
  height: 20px;
  padding: 0px 2.5px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid var(--White, #FFF);;
  background: var(--Blue-900, #00012b);
  bottom: -6px;
  left: 77%;
  transform: translateX(-34%);
}
.iconText {
  color: var(--White, #fff);
  text-align: center;
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 8px */
}
