.timesheetRow {
  display: flex;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid var(--gold-900);
}

.timesheetDashboard {
  display: flex;
  width: 100%;
  padding: 5px 0 10px;
  border-bottom: 1px solid var(--gold-900);
  /*label for 1.5 
  padding: 5px 0 16px;
  */
}

.date {
  padding-top: 5px;
  min-width: 30%;
  color: var(--blue-900, #00012b);
  font-size: 16px;
  font-weight: 500;
  padding-right: 0.5rem;
}

.dateDashboard {
  padding-top: 10px;
  min-width: 30%;
  color: var(--blue-900, #00012b);
  font-size: 16px;
  font-weight: 500;
  padding-right: 0.5rem;
  /*label for 1.5 
  padding-top: 16px;
  */
}

.innerRowContainer {
  display: grid;
  width: 100%;
}

.pto {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.leftValue {
  font-size: 14px;
  color: var(--Gray-600, #5d5d5d);
  font-style: normal;
  font-weight: 400;
}

.rightValue {
  font-size: 16px;
  color: var(--Blue-900, #00012b);
  font-weight: 500;
}

.totalHours {
  display: flex;
  align-items: flex-end;
  padding: 5px 0px;
  font-size: 16px;
  color: var(--Blue-900, #00012b);
  font-weight: 500;
}

.totalHoursMulti {
  display: flex;
  align-items: center;
  padding: 5px 0px;
  font-size: 16px;
  color: var(--Blue-900, #00012b);
  font-weight: 500;
}

@media (max-width: 1023px) {
  .timesheetRowM {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    border-bottom: 1px solid var(--gold-900);
  }
}
