.horizontalGroup .horizontalHeading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.horizontalGroup .horizontalHeading h2 {
  color: #282827;
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.horizontalGroup .horizontalHeading .navigation {
  display: flex;
  align-items: center;
}
.horizontalGroup .horizontalHeading .navigation p {
  color: #000;
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-right: 16px;
}
.horizontalGroup .horizontalHeading .navigation .leftArrow,
.horizontalGroup .horizontalHeading .navigation .rightArrow {
  cursor: pointer;
  display: flex;
  width: 32px;
  height: 32px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px 0px 0px 8px;
  border: 1px solid var(--blue-900, #00012b);

  &:hover,
  &:not(:disabled):hover {
    cursor: pointer;
    background-color: var(--white, #ffffff);
    color: var(--black, #000000);
  }
}
.horizontalGroup .horizontalHeading .navigation .rightArrow {
  border-radius: 0px 8px 8px 0px;
  margin-left: 0;
}
.horizontalGroup .horizontalHeading .navigation .leftArrow.disable,
.horizontalGroup .horizontalHeading .navigation .rightArrow.disable {
  pointer-events: none;
  opacity: .3;
}
.horizontalGroup .horizontalBody {
  width: 100%;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  position: relative;
}
.horizontalGroup .itemHolder {
  flex-shrink: 0;
  height:512px;
  /* height: 100%; */
  width: 80%;
  margin-right: 24px;
  scroll-snap-align: start;
}
.horizontalGroup .itemHolder:last-child {
  margin-right: calc(30% + 24px);
  
}
.horizontalGroup .fiftyFifty {
  display: flex;
  flex-direction: column;
  gap: 4%;
  max-height:512px;
}
.horizontalGroup .fiftyFifty > div:first-child {
  margin-bottom: 32px;
}
.horizontalGroup .onMobile > * {
  margin-top: 0;
  height:512px;
  overflow:scroll
}
@media (min-width: 1024px) {
  .horizontalGroup .itemHolder {
    width: 100%;
  }
  .horizontalGroup .fiftyFifty {
    flex-direction: row;
  }
  .horizontalGroup .fiftyFifty > * {
    flex: 0 0 48%;
  }
  .horizontalGroup .fiftyFifty > div:first-child {
    margin-bottom: 0;
  }
  .horizontalGroup .horizontalBody {
    overflow-x: auto;
    -webkit-overflow-scrolling: auto;
  }
}
