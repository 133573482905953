.pageAlertHolder {
  padding: 16px;
  display: flex;
  gap: 8px;
  text-align: left;
  border-radius: 8px;
  border: 1px solid var(--blue-500, #373ae5);
  background: var(--blue-25, #f4f3fc);
  position: relative;
}
.pageAlertHolder .iconHolder svg {
  width: 24px;
  height: 24px;
}
.pageAlertHolder .iconHolder img {
   width: 24px;
   height: 24px;
}
.pageAlertHolder h4 {
  margin-bottom: 4px;
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
  padding-right: 10px;
}
.pageAlertHolder p {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  margin-bottom: 4px;
}
.pageAlertHolder .buttonHolder a {
  padding-left: 0;
}
.pageAlertHolder .closeButton {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}
.pageAlertHolder .closeButton svg {
  width: 24px;
  height: 24px;
}
