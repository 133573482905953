.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.container h2 {
  color: var(--gray-700);
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  margin-top: 30px;
}
.container p {
  color: var(--gray-800);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  margin-top: 8px;
  margin-bottom: 0;
  max-width: 420px;
}
.buttonHolder {
  margin-top: 40px;
}
.buttonHolder button
{
  background: var(--blue-900);
}
.buttonHolder button:hover
{
  background: var(--blue-800);
}