.heading {
    display: flex;
    justify-content: space-between;
}

.title {
    color: var(--Blue-900, #00012B);
    font-family: Calibre-R;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    /* 32.4px */
    width: 80%;
}

.viewMoreLink {
    color: var(--Blue-900, #00012B);
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    /* 18.9px */
    text-decoration-line: underline;
    display: flex;
    width: 20%;
    justify-content: flex-end;
    align-items: center;
}

.viewMoreLink a {
    gap: 16px;
    white-space: nowrap;
}

.upcomingEventsContainer {
    padding-top: 16px;
}

.carousalArrow {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
}

@media (max-width:768px) {
    .heading {
        display: flex;
    }
    
    .upcomingEventsContainer {
        padding-top: 12px;
    }

}