.eLetterButton .generateBtn {
    color: var(--white, #fff);
  /* Additional styles/reg/text-xs-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 16.2px */
    background: var(--blue-900) !important;
    &:hover {
        background-color: var(--blue-900) !important;  
    }
}

@media(max-width: 768px) {
    .eLetterButton .generateBtn  {
      display: flex;
      justify-content: center;
      width:100%;
    }
}