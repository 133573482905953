.profileSection h3 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  margin-bottom: 6px;
}
.profileSection p {
  color: var(--gray-700);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.profileSection .radioButtonBlock {
  /* display: flex;
  cursor: pointer;
  margin: 20px 40px 0 0;
  align-items: center;
  gap: 10px; */

  display: grid;
  cursor: pointer;
  margin: 20px 40px 0 0;
  align-items: center;
  grid-gap: 10px;
  grid-auto-flow: column;
  justify-items: start;
  grid-template-columns: 25px auto;
}
.profileSection .radioButtonBlock label {
  cursor: pointer;
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}

@media (min-width: 1024px) {
}
