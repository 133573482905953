.main {
  margin-top: 32px;
  background: var(--Gray-50, #e8e8e8);
}

.offerHolder::-webkit-scrollbar {
  display: none;
}

.offerHolder {
  height: auto;
  padding-bottom: 45px;
  background: var(--Gray-50, #e8e8e8);
  padding: 0 48px 45px 48px;
}

.toptextholder {
  display: flex;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 16px;
  padding-left: 48px;
  padding-right: 48px;
  background: var(--Gray-50, #e8e8e8);
}

.subTextHolder {
  display: flex;
  width: 100%;
  position: relative;
}

.offerTitle {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}



.imageHolder {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  border: 1px solid var(--gray-50);
  border-radius: 8px 8px 8px 8px;
}

.offerCard {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--white, #fff);
}

.cardimg {
  width: 240px;
  border-radius: 8px 8px 0 0;
  height: 162px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.cardimg img {
  width: 100%;
  height: auto;
}
.innerHtml {
  margin-bottom: 24px;
}
.cardSubHolder {
  position: relative;
  padding: 24px 16px;
  /* EmployOffer Fix: Commented as it is not a part of 1.1 Release  */
  /* height: 220px; */
  width: 100%;
  height: 223px;
}

.cardDate {
  color: var(--gray-600, #5d5d5d);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  margin-bottom: 10px;
}

.cardTitle {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 1rem;
}

.cardBtn {
  position: absolute;
  bottom: 24px;

  display: flex;
  height: 40px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--blue-900, #00012b);
  cursor: pointer;
  margin-right: 16px;

  text-align: center;
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

.allCardHolder {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
}

.modelPopup img {
  width: 100%;
  margin-bottom: 24px;
  border-radius: 8px;
}
.modelPopup h3 {
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 8px;
}
.modelPopup h2 {
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 27px */
  margin-bottom: 24px;
}
.modelPopup .description {
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  margin-bottom: 24px;
}
.modelPopup .redeemButton {
  /* margin-bottom: 24px; */
  /* padding-left: 0; */
}
.RedeemButton {
  padding-bottom: 24px;
}
.offerImageContainerOuter {
  height: min-content;
  max-height: calc(100vh - 200px);
  overflow: auto;
  margin-bottom: 16px;
}
.offerImageContainer {
  /* height: 250px; */
  overflow: hidden;
  display: flex;
  border-radius: 16px;
  align-items: center;
  margin-bottom: 24px;
}
.description {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /*word-break: break-all;*/
  color: var(--Gray-900, #171717);
}

@media (min-width: 768px) {
  .main {
    margin-left: -32px;
    margin-right: -32px;
    padding-left: 0;
    padding-right: 0;
  }
  .offerLink {
    position: absolute;
    color: var(--blue-900, #00012b);
    text-align: right;
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
    right: 0;
  }
  .imageHolder,
  .offerCard,
  .cardimg {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .offerTitle {
    color: var(--blue-900, #00012b);
    font-family: Calibre-R;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    flex:1 1 auto;
    word-break: break-word;
  }
  .offerLink {
    /* position: absolute; */
    color: var(--blue-900, #00012b);
    text-align: right;
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
    right: 0;
    white-space: nowrap;
  }
  .main {
    margin-left: -16px;
    margin-right: -16px;
  }
  .toptextholder,
  .offerHolder {
    padding-right: 16px;
    padding-left: 16px;
  }
  .offerHolder {
    height: auto;
    padding-bottom: 45px;

    overflow-x: auto;
    position: relative;
    clear: both;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: grid;
    justify-items: stretch;
    align-items: start;
  }
}

@media (min-width: 1024px) {
  .main {
    margin-left: -48px;
    margin-right: -48px;
  }
}

@media (min-width: 768px) {
  .allCardHolder {
    grid-auto-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 640px) {
  .offerImageContainer {
    height: 250px;
  }
}
