.siteNotification {
  background: #fcb64f;
}
.marginSpace {
  margin-top: 2px;
}
.siteNotification .container{
    padding: 16px 24px;
    margin: 0 auto;
    max-width: 1440px;
}
.siteNotification .heading {
  display: flex;
  gap: 8px;
  margin-bottom: 4px;
  align-items: center;
}
.siteNotification h3 {
  color: var(--Gray-900, #171717);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.siteNotification .innerHtml,
.siteNotification .innerHtml p,
.siteNotification .innerHtml a,
.siteNotification .innerHtml span {
  color: var(--Gray-800, #2E2E2E);
font-family: Calibre-R;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 135%;
letter-spacing: 0em;
text-align: left;
}
 
.siteNotification .exclamationMark {
    width: 24px;
    height: 24px;
    
    /*background: url("../../assets//sitemaintanance/exclamation_point_triangle.svg");*/
}
@media (max-width: 767px) {
  .siteNotification .heading {

    align-items: flex-start;
  }
  .siteNotification h3 {
    line-height: 100%;
  }
  
  .siteNotification .exclamationMark {
    width: 33px;
  }
}