.relatedNews h3 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.relatedNews hr {
  margin: 4px 0 24px 0;
}
.relatedNews .news {
  margin-bottom: 32px;
  cursor: pointer;
}
.relatedNews .news:hover h4 {
  color: var(--blue-500);
}
.relatedNews .news img {
  margin-bottom: 10px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  height: 153px;
  background-color: #e4e4e4;
}
.relatedNews .news h4 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.relatedNews .news h5 {
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  margin-bottom: 8px;
}
@media (max-width: 768px) {
  .relatedNews {
    width: 100%;
  }
  .relatedNews .news img {
    height: 238px;
  }
}