.upcomingEventsDetails h4 {
  color: var(--blue-900);
  font-size: 16px;
}

h4,
.link {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: var(--blue-900);
}

.upcomingEventsDetails p {
  font-size: 12px;
  /* border-bottom: 1px solid var(--gold-500); */
  padding-bottom: 1rem;
}
.deailsSection {
  display: flex;
  border-bottom: 1px solid var(--gold-500);
  padding-top: 10px;
}
.upcomingEventsDetails > div:first-child {
  /* padding-top:0px; */
}

.deailsSection:hover,
.deailsSection:focus,
.deailsSection:focus-visible {
  /* background-color: var(--Gray-25, "#F5F5F5"); */
  background-color: var(--gray-50, "#E8E8E8");
}
/* .deailsSection:hover{
  color:var(--Gray-50, #E8E8E8);
} */

.featuresContainer::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.featuresContainer::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.featuresContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--gray-100);
}

.viewMore {
  color: var(--blue-900) !important;
}

.eventContainer {
  width: 48px;
  height: 48px;
  margin-left: 16px;
  margin-right: 14px;
  margin-bottom: 16px;
  flex-shrink: 0;
  border-radius: 8px;
  background: rgb(255, 255, 255);
  padding: 8px;
}
.eventDay {
  color: #282827;
  text-align: center;

  /* Additional styles/reg/text-xs-reg */
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 16.2px */
}

.eventDate {
  color: #282827;
  text-align: center;

  /* Additional styles/sb/text-xs-sb */
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 16.2px */
}

.eventWrapper {
  padding: 0 10px 10px 10px;
}
.eventWrapper > p {
  color: var(--gray-600, #5d5d5d);
}

.upcomingHeader {
  border-bottom: 1px solid var(--gold-500);
  padding: 1rem 0;
}
.wrapperMargin {
  margin-top: 10px;
}

.paginationContainer {
  margin-top: 24px;
}
.paginationContainer button {
  width: 130px;
}

@media screen and (min-width: 769px) {
  .featuresContainer {
    height: calc(100vh - 25vh);
    overflow-x: hidden;
    overflow-y: auto;
  }
}
@media screen and (min-width: 1600px) {
  .featuresContainer {
    height: calc(100vh - 40vh);
  }
}
