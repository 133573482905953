.innerRow {
  display: grid;
  grid-template-columns: 30% 30% 40%;
  grid-auto-flow: column;
  color: var(--gray-600, #5d5d5d);
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  padding: 5px 0px;
  align-items: center;
  grid-gap: 0.5rem;
}

.amtRow {
  visibility: hidden;
}
.tipAmt {
  display: inline-block;
  padding-right: 4px;
  position: relative;
}

.tipAmt:hover {
  color: #373ae5;
}

.tipAmt:hover div svg path {
  fill: #373ae5;
}

.in,
.out {
  display: flex;
  align-items: center;
}

.in img,
.out img {
  padding-right: 5px;
}
.tipRow {
  display: grid;
  grid-auto-flow: column;
  gap: 16px;
}
.tipRow > span {
  color: var(--Gray-900, #171717);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  margin-top: 8px;
  margin-bottom: 8px;
}
.tipRow > span:nth-child(2) {
  color: var(--Gray-900, #171717);
  text-align: right;
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

.iconContainer {
  padding: 1rem;
  border: 1px solid var(--Gray-50, #e8e8e8);
  border-radius: 8px;
  background: var(--Gray-25, #f5f5f5);
  position: absolute;
  z-index: 1;
  margin-top: 14px;
  right: 0;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 20%);
  width: 230px;
  box-sizing: border-box;

  &:after {
    content: "";
    position: absolute;
    top: -17px;
    width: 0;
    height: 0;
    right: 20%;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 28px solid var(--Gray-25, #f5f5f5);
    box-sizing: border-box;
    /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75); */
    z-index: 103;
  }
}
.iconContainer > div:not(:last-child) {
  border-bottom: 1px solid var(--Gray-50, #e8e8e8);
}
.tips {
  width: 85%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 48% 40%;
  align-items: center;
}

.tipsCursor {
  width: 85%;
}

.hours {
  font-size: 16px;
  color: var(--Blue-900, #00012b);
  font-weight: 500;
}

.hours img {
  padding-right: 8px;
}

.missedPunchText {
  color: var(--alerts-red-600, #d20b02);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
@media (max-width: 1023px) {
  .tips {
    gap: 16px;
  }
}
