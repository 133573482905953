.sscontainer {
  width: 100%;
  /* margin-top: 40px; */
}

.categoryHeader {
  color: var(--blue-900);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  margin-bottom: 16px;
  margin-top: 40px;
}

.categoryHeaderSection {
  border-bottom: 1px solid var(--gold-500);
}

.mobilenav select {
  width: 100%;
  padding: 15px;
  color: var(--blue-900);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  margin-bottom: 0;
  border-radius: 50px;
  border: 1px solid var(--gray-100);
  background: var(--white);
  box-shadow: 2px 2px 8px 0px rgba(55, 58, 229, 0.1);
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../../assets/images/chevron-down.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
}

.sssubheader,
.sssubheader p {
  color: var(--Gray-700);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  /* margin-top: -0.8rem; */
  padding-bottom: 1rem;
}

.categoryDescription {
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.service_border {
  border-bottom: 1px solid var(--gold-500);
}

.sssection {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ssdate {
  color: var(--blue-900);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.ssdate {
  margin-right: 24px;
}
.ssarrow {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.categoryTabStyle {
  display: none;
}

.categoryTabStyle > div {
  display: block;
}

.categoryTabStyle > div button {
  padding: 12px 22px;
  margin-right: 10px;
  margin-top: 20px;
}
@media (min-width: 992px) {
  .ssDetailsSection {
    /*margin-top: 20px;*/
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.5rem;
  }
  .categoryHeaderSection {
    /*margin-bottom: 32px;*/
  }
  
  .mobilenav {
    display: none;
  }
  .categoryTabStyle {
    display: block;
    margin-bottom: 2rem;
  }
  .ssDetailsSection {
    /*margin-top: 16px;*/
  }
}
