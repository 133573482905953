.global_nopadding
{
    padding:0;
}
.global_justifyspacebetween
{
    display: flex;
    justify-content: space-between;
}
.global_justifyspacebetween_new
{
    display: flex;
    justify-content: space-between;
}
.global_headercontainer
{
    border-bottom:1px solid var(--gold-500);
    padding:16px 0px 25px;
    margin:0;
}
.global_headercontainer_tf
{
    border-bottom:1px solid var(--gold-500);
    padding:0px 0px 25px;
    margin:0;
}
.padding-left-zero
{
    padding-left: 0;
}
.global_buttonsection
{
    display: flex;
    justify-content: flex-end;
    padding:0;
    margin-bottom: 24px;
}
.global_headertext
{
    color: var(--blue-900, #00012B);

    /* Additional styles/med/text-3xl-med */
    font-family: Calibre-R;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 40.5px */
}
.global_subheadertext{
    color: var(--blue-900, #00012B);

/* Additional styles/reg/text-base-reg */
font-family: Calibre-R;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 21.6px */
}
.global_button
{
    display: inline-flex;
    align-self: flex-end;
    align-items: center;
    padding: 16px;
    height:56px;
    background-image:linear-gradient(225deg, #373AE5 0%, #292CB7 50%);
    color:#fff !important;
    
text-align: center;

/* Additional styles/reg/text-base-reg */
font-family: Calibre-R;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 21.6px */
}
.global_button:active, .global_button:hover
{
    color: white;
}
main>section>section:nth-child(2)>div>section:nth-child(2)>div:nth-child(2){
    background: white;
    border-radius: 10px;
    border: 1px solid var(--gray-100);
    width: 38%;
    padding: 40px;
    height: fit-content;
}

.timeoff_section
{
    /* background:white;
    border:1px solid var(--gray-100);
    border-radius:10px; */
    margin:0;
    margin-top:48px;
    /* padding:40px 40px 24px; */
}
.timeoff_status
{
    border-top:1px solid var(--gold-500);
}

.timeoff_header
{
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    margin-bottom:24px;
    color: var(--blue-900);
}
.timeoff_header_new{
    color: var(--blue-900, #00012B);

/* Additional styles/med/text-2xl-med */
font-family: Calibre-R;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 0.5; /* 32.4px */
margin-bottom: 15px;
}
.timeoff-balance{
    color: var(--gray-700, #464646);

/* Additional styles/reg/text-sm-reg */
font-family: Calibre-R;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 0; /* 18.9px */
margin-bottom: 30px;
}
.timeoff_section .timeoff_header:nth-of-type(2)
{
    margin-bottom:16px;
}
.timeoff_leftsection
{
    /* background: white;
    border: 1px solid;
    border-radius: 10px;
    padding: 40px;
    margin-right: 20px; */
        border-radius: 8px;
    border: 1px solid var(--gray-100, #D1D1D1);
    background: var(--White, #FFF);
    padding: 40px;
}
.timeoff_line{
    width: auto;
    height: 1px;
    background: var(--gold-500, #D8C5A5);
}
.timeoff_line{
    width: auto;
    height: 1px;
    background: var(--gold-500, #D8C5A5);
}
.timeoff_statusheader{
    color: var(--blue-900);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; 
}
.timeoff_statussubheader
{
    color: var(--gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
}
.timeoff_statussubheader_tf
{
    color: var(--gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    padding-top: 0;
}
.timeoff_statussent{
    color: var(--gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; 
}
.timeoff_statusheader_new{
    color: var(--blue-900, #00012B);

/* Additional styles/med/text-base-med */
font-family: Calibre-R;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 135%; /* 21.6px */
}
.timeoff_statussubheader_new{
    color: var(--gray-600, #5D5D5D);

/* Additional styles/reg/text-sm-reg */
font-family: Calibre-R;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 18.9px */
}
.timeoff-balance-values{
    /* display: flex;
    gap:66px; */
}
.timeoff-available{
   
    color: var(--blue-900, #00012B);

    /* Additional styles/med/text-base-med */
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 21.6px */
}
.timeoff-available-label{
    color: var(--blue-900, #00012B);

/* Additional styles/reg/text-sm-reg */
font-family: Calibre-R;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 18.9px */
}
.timeoff-assured{
    color: var(--gray-600, #5D5D5D);

    /* Additional styles/reg/text-sm-reg */
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 18.9px */
}
.global_learnmoreheader{
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; 
}
.global_learnmorepara
{
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    margin-top:16px;
    color: var(--blue-900);
}
.global_learnmorebtn
{
    display: flex;
    height: 40px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border:1px solid var(--blue-900);
    color: var(--blue-900);
    text-align: center;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 18.9px */
    background-color: transparent;
    margin-top:24px;
}
.global_listpadding
{
    padding-left:1rem;
}
.global_listpadding li
{
    color: var(--blue-900);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; 
}
.timeoff_section .progress
{
    height:12px;
    border-radius:24px;
    background-color: var(--gray-100);
    margin-bottom:16px;
}
.timeoff_section .progress-bar
{
    background-color: var(--secondary-teal-400);
}
.timeoff_minmax span{
    color: var(--blue-900);
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 27px */
}
.timeoff_days span
{
    color: var(--gray-600);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    margin-bottom:24px;
}
.timeoff_disclaimer
{
    color: var(--gray-600);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    margin-bottom:5px;
    margin-top: 20px;
}
.timeoff_ptolink
{
    color: var(--gray-600);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    text-decoration: underline;
    cursor: pointer;
}
.links_container
{
    background-color: white;
    border:1px solid var(--gray-100);
    border-radius: 8px;
    padding:40px;
}
.links_text
{
    color: var(--blue-900);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
}
.timeoff_linkssection
{
    margin-bottom: 60px;
}
.timeoff_statusindicator img
{
    top: -2px;
    position: relative;
    right: 3px;
}
.timeoff_pendingstatus-pending
{
    color: var(--alerts-orange-800);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
}
.timeoff_pendingstatus-approved
{
    color: var(--alerts-green-700);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
}
.timeoff_nopending
{
    justify-content: center;
    display: flex;
    padding: 40px;
    border-top: 1px solid var(--gold-500);
    color: var(--blue-900, #00012B);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; 
}

.global_learnmorecontainer
{
    margin-bottom:40px;
    margin-top: 40px;
}
.global_resourcesheader{
    color: var(--blue-900);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: bold;
    font-weight: 500;
    line-height: 135%;
    margin-top:8px;
}
.links_header{
    color: var(--blue-900, #00012B);

    /* Additional styles/med/text-lg-med */
    font-family: Calibre-R;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 24.3px */
}
.links_link
{
    display: flex;
    flex-direction: column;
    gap:24px;
}
.links_link a
{
    color:var(--blue-link);
}
.links_link img
{
    margin-left:8px;
}
.timeoff_main
{
    padding:10px 16px;
}
@media (min-width: 1024px) {
    .timeoff_main
    {
        padding:0 48px;
    }
    .timeoff_statusheader_new{
        width: 70%;
    }
    
}
@media (max-width: 768px) {
    main{
        flex-direction: column;
    }
    .global_headersection
    {
        padding: 0;
        border-bottom:1px solid var(--gold-500);
    }
    .global_button
    {
        justify-content: center;
        margin-top:24px;
        width: 100%;
        height:40px;
    }
    .global_headercontainer
    {
        border-bottom: none;
    }
    
    .timeoff_section_sup
    {
        margin-top: 32px;
    }
    .timeoff_section
    {
        margin-top: 32px;
    }
    .global_headercontainer
    {
        padding-bottom:0px;
    }
    .links_container
    {
        padding:24px;
    }
    /* .timeoff_section
    {
        padding:24px 16px 0px;
    } */
    .timeoff_leftsection {
        /* margin-top: 20px;
    border: 1px solid;
    border-radius: 10px;
    padding: 20px;
    background: white; */
        border-radius: 8px;
    border: 1px solid var(--gray-100, #D1D1D1);
    background: var(--White, #FFF);
    padding: 24px;
    margin-top: 20px;
    }
    .timeoff_rightsection
    {
        margin-top: 20px;
        border-radius: 8px;
        border: 1px solid var(--gray-100, #D1D1D1);
        background: var(--White, #FFF);
        padding: 24px;
    }
    .timeoff_section .progress
    {
        height:16px;
        border-radius: 4px;
    }
    .timeoff-balance-values{
        display: flex;
        gap:66px;
    }
}

/* suprio  */
.f-left
{
    float:left;
}
.f-right
{
    float:right;
}

.c-both
{
    clear:both;
}
.m-b-20
{
    margin-bottom: 20px;
}
.circle
{
    border-radius: 50%;
    width: 34px;
    height: 34px;
    padding: 5px;
    background: #fff;
    border: 1px solid grey;
    color: #000;
    text-align: center;
    display: block;
}
.spreadOut
{
    padding: 2px 0 10px 22px;
}
.bulletHolder
{
    padding: 10px 0 10px 0;
}
.p-t-10
{
    padding-top: 10px;
}
.p-t-40
{
    padding-top: 40px;
}
.p-t-15
{
    padding-top: 15px;
    color: var(--blue-900);
}
.p-r-20
{
    padding-right: 20px;
}
.p-l-20
{
    padding-left: 20px;
}
.m-b-40
{
    margin-bottom: 40px;
}
.open-case-btn
{
    width: auto;
    justify-content: center;
    margin-top:24px;
    height:40px;
    display: inline-flex;
    align-self: flex-end;
    align-items: center;
    padding: 16px;
    height:56px;
    background-image:linear-gradient(225deg, #373AE5 0%, #292CB7 50%);
    color:#fff ;
}
.open-case-btn:active, .open-case-btn:hover
{
    color: white;
}
.no-left-pad
{
  padding-left: 0;
}
.no-right-pad
{
  padding-right: 0;
}
.global-top-btn-holder
{
  display: flex;
  justify-content: right;
  height: 100%;
}
.timeoff_section_sup
{
    background:white;
    border:1px solid var(--gray-100);
    border-radius:10px;
    margin:0;
    margin-top:48px;
    padding:40px 40px 24px;
}
@media (max-width: 768px)
{
    .space-top
    {
        padding-top: 20px;
    }
    .bHolderContainer
    {
        border-top: 1px solid var(--gold-500);
    }
   .bottomBorder
   {
       border-bottom: 1px solid var(--gold-500);
       margin-bottom: 0;
       padding-bottom: 20px;
   }
   @media (width<=992px){
    .global_justifyspacebetween_new{
        display: block;
    }
    .timeoff-available{
        display:flex !important;
        /* gap:40px; */
    }
   }
    
}
/* suprio end */