.openRequest {
  display: flex;
  /* align-items: center; */
  width: fit-content;
  background: #fff;
  /* justify-content: space-between; */
  flex-direction: column;
  gap: 8px;
  /* margin-bottom: 16px; */
  /* margin-top: 24px; */
  align-items: stretch;
  width: 100%;
  position: relative;
}
.openRequestWrapper {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 92% 8%;
  background-color: #fff;
  /* grid-gap: 16px; */
  margin-bottom: 16px;
  border-radius: 8px;
  padding: 8px 8px;
}
.caseBoxTitle {
  color: var(--Gray-500, #747474);  
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  text-wrap: wrap;
}
.caseBoxContent {
  color: var(--Blue-900, #00012b);
  /* Additional styles/med/text-base-med */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  text-wrap: wrap;
}
.textGreenAlert {
  color: var(--Alerts-Green-900, #002712);
  /* Additional styles/med/text-sm-med */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.textRedAlert {
  color: var(--Alerts-Red-900, #310200);
  /* Additional styles/med/text-sm-med */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

.defaultText {
  color: var(--Blue-900, #00012b);
  /* Additional styles/med/text-sm-med */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.openRequestLink {
  display: flex;
  gap: 24px;
}
.cases {
  border-radius: 6px;
  background: var(--Gray-50, #e8e8e8);
  width: 100%;
  display: grid;
  align-items: center;
}
.casesTitle {
  padding: 16px;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
}
.RequestDate {
  border-radius: 6px;
  border: 1px solid var(--Gray-50, #e8e8e8);
  /* min-height: 84px; */
  width: 100%;
  display: grid;
  align-items: center;
}
.Category {
  border-radius: 6px;
  border: 1px solid var(--Gray-50, #e8e8e8);
  width: 100%;
  display: grid;
  align-items: center;
}
.SubCategory {
  border-radius: 6px;
  border: 1px solid var(--Gray-50, #e8e8e8);
  width: 100%;
  display: grid;
  align-items: center;
}
.openRequestStatus {
  /* min-height: 84px; */
  padding: 16px;
  border-radius: 8px;
  background: var(--Alerts-Green-25, #e6f9ef);
  display: grid;
  align-items: center;
  width: 100%;
}
.viewall {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  text-decoration-line: underline;
}

.headingSection {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 24px;
}
.Title {
  color: var(--Blue-900, #00012b);
  /* Additional styles/med/text-2xl-med */
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
}
.viewCase {
  color: #282827;
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 8px;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  text-align: center;
}
.iconStyle {
  display: flex;
  gap: 10px;
}
.icon {
  align-self: center;
  justify-self: center;
}
.mobileIcon {
  margin-bottom: 8px;
  align-self: center;
  justify-self: center;
}
.showMoreLabel {
  color: var(--Gray-600, #5d5d5d);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
}

@media (min-width: 1280px) {
  .openRequest {
    flex-direction: row;
  }
  .openRequestWrapper {
    grid-template-columns: 92% 8%;
    grid-template-rows: revert;
  }
  .cases {
    width: 15%;
  }
  .RequestDate {
    width: 16%;
  }
  .Category {
    width: 15%;
  }
  .SubCategory {
    width: 15%;
  }
  .openRequestStatus {
    width: 40%;
  }
  .casesTitle {
    display: block;
  }
}

@media (max-width: 1024px) {
  .openRequest {
    margin-bottom: 16px;
    margin-top: 0px;
  }
  .showMoreLabel {
    margin-bottom: 16px;
  }
}
