.oneColumnV2 .notEnrolledLContainer {
  background: var(--Gray-25, #f5f5f5);
  border-radius: 8px 8px 0 0;
  border-right-width: 0;
  height: 100%;
  padding: 32px;
  color: var(--Blue-900, #00012b);
}
.notEnrolledLContainer h2 {
  font-size: 24px;
}
.notEnrolledLContainer ul {
  padding: 0;
}
.notEnrolledLContainer ul li {
  list-style-type: none;
  margin-top: 32px;
}
.notEnrolledLContainer ul li h5 {
  font-size: 18px;
  line-height: 135%;
  margin-bottom: 8px;
}
.notEnrolledLContainer ul li p.content div[name="inner-html-parent"] {
  font-size: 14px;
}
.notEnrolledLContainer ul li p.content div[name="inner-html-parent"] span {
  font-size: 14px;
}
.notEnrolledLContainer ul li a {
  padding-left: 0;
  margin-top: 8px;

  &:hover {
    font-weight: 500;
    color: var(--Blue-900, #00012b);
  }
}

@media (min-width: 1024px) {
  .oneColumnV2 .notEnrolledLContainer {
    /* width: 268px; */
    border-radius: 8px 0 0 8px;
    min-height: 500px;
  }
  .notEnrolledLContainer ul li a {
    margin-top: 0;
  }
  .notEnrolledLContainer ul li h5 {
    margin-bottom: 0;
  }
}

@media (min-width: 1100px) {
  .oneColumnV2 .notEnrolledLContainer {
    margin-right: 30px;
    width: 100%;
    min-height: 500px;
  }
}
@media (min-width: 1440px) {
  .oneColumnV2 .notEnrolledLContainer {
    height: auto;
    min-height: unset;
  }
}
