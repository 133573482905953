.fiftyFiftyCard {
  display: flex;
  height: 100%;
}
.cardContainerInner {
  padding: 40px;
  /* added by suprio */
  position: relative;
  height: 100%;
  margin-bottom: 0;
}
.cardContainerInner.withCta {
  padding-bottom: 64px;
}
/* suprio */
.bodyContentH {
  padding-bottom: 40px;
}
.fiftyFiftyCard .iconHolder {
  /* margin-bottom: 8px; */
}
.fiftyFiftyCard .iconHolder img {
  width: 48px;
  height: 48px;
}
.fiftyFiftyCard h3 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  margin-bottom: 16px;
}
.fiftyFiftyCard p,
.fiftyFiftyCard li,
.fiftyFiftyCard span {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  margin: 0;
}
.fiftyFiftyCard strong,
.fiftyFiftyCard b {
  font-weight: 600;
}
.fiftyFiftyCard .buttonHolder {
  /*margin-top: 24px;*/
  /* suprio */
  position: absolute;
  bottom: 42px;
  margin-right: 16px;
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
