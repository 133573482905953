.inputcomph {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /*position: sticky;
    top: 0;*/
  display: flex;
  min-height: calc(100vh - 112px);
}

.filterH {
  width: var(--asFilterWidth);
  /*float: left;*/
  background-color: var(--white);
  box-shadow: 0px 2px 16px 0px rgba(177, 150, 97, 0.2);
  align-self: flex-start;
  position: sticky;
  top: 112px;
  max-height: calc(100vh - 112px);
  overflow-y: auto;
  overflow-x: clip;
}

.inputHMain {
  float: left;
  width: calc(100% - var(--asFilterWidth));
  padding: 0 48px;
}

.inputHMainless {
  /*float: left;*/
  width: calc(100% - var(--asFilterWidth));
  padding: 0 48px;
}

.inputholderless {
  width: 100%;
  display: flex;
  height: 56px;
  padding: 0;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--blue-500);
  background: var(--White);
  margin-top: 40px;
}

.inputholder {
  width: 100%;
  display: flex;
  height: 56px;
  padding: 0;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--blue-500);
  background: var(--White);
  margin-top: 40px;
}
.button:disabled {
  background: #808080;
}
.button {
  padding: 0 16px;
  border-radius: 0px 8px 8px 0px;
  display: inline-flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--White);
  text-align: center;
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  flex-shrink: 0;
  background: var(--blue-900);
}
.button:hover
{
  background: var(--blue-800);
}
.input {
  display: flex;
  height: 54px;
  border: none;
  width: 100%;
  color: var(--gray-500);
  text-overflow: ellipsis;
  white-space: nowrap;
  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  /* 21.6px */
  color: var(--blue-900, #00012b);
}

.icon {
  display: flex;
  height: 56px;
  /*padding: 0px 16px;*/
  align-items: center;
  /*gap: 16px;*/
  align-self: stretch;
  width: 33px;
  background-image: url("../../assets/images/magnifying-glass.svg");
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 16px;
}
.appResultTile
{
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 32px;
  border-bottom: 1px solid var(--gold-500);
  box-sizing: border-box;
  min-height: 120px;
  max-height: 120px;
}
.resultTitle
{
  color: var(--blue-900);

  /* Additional styles/med/text-2xl-med */
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  margin: 24px 0;
}
.appTitle
{
  overflow: hidden;
  color: var(--blue-900);
  text-overflow: ellipsis;

  /* Additional styles/med/text-base-med */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
  
}
.iconHolder
{
  width: 72px;
  height: 72px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--gray-100);
  background: #FFF;
  display: flex;
}
.icon
{
  width: 56px;
  height: 30px;
  flex-shrink: 0;
  margin: auto;
}
.appResultTileHolder
{
  margin-bottom: 24px;
}
.appResultTileHolder a
{
  text-decoration: none;
}
.description
{
  color: var(--Gray-600, #5d5d5d);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.inputh {
  width: 85%;
}

.drop {
  /*display: flex;*/
  width: 100%;
  padding: 8px 24px;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 0px;
  /* border: 1px solid var(--Blue, #373AE5); */
  background: var(--White, #fff);
  /* box-shadow: 2px 2px 8px 0px rgba(55, 58, 229, 0.10); */
  color: var(--blue-900, #00012b);

  /* reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  /* 21.6px */
  cursor: pointer;
}

.suggestionH {
  width: 100%;
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--Blue, #373ae5);
  background: var(--White, #fff);
  margin-top: 8px;
  /* Interactive */
  box-shadow: 2px 2px 8px 0px rgba(55, 58, 229, 0.1);
}

.titleDoc {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  cursor: pointer;
}

.resultHC {
  display: flex;
  margin-top: 40px;
  align-items: baseline;
  border-bottom: 1px solid var(--gold-500);
  padding-bottom: 20px;
  flex-direction: row;
}

.resultitem {
  /*padding: 24px 0;*/
  padding-top: 24px;
  margin-bottom: 8px;
}

.linkh {
  display: flex;
  margin-top: 8px;
}

.topResult {
  color: var(--blue-500, #373ae5);

  /* Additional styles/sb/text-base-sb */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  /* 21.6px */
  margin-bottom: 16px;
}

.cat {
  color: var(--gray-600, #5d5d5d);

  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  /* 18.9px */
}

.highlight {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  /* 21.6px */
  margin-top: 8px;
}
.titleDoc {
  color: var(--blue-900, #00012b);

  /* Additional styles/sb/text-lg-sb */
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
}
.title {
  color: var(--blue-900, #00012b);

  /* Additional styles/sb/text-lg-sb */
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  /* 24.3px
    margin-top: 8px; */
}

.url {
  color: var(--blue-500, #373ae5);

  /* Additional styles/reg/text-xs-reg */
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  /* 16.2px */
  margin-top: 8px;
}
.con {
  padding: 24px;
  margin-top: 40px;
  margin-bottom: 16px;
}
.st {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  text-transform: capitalize;
}
.num {
  color: var(--blue-900, #00012b);

  /* Desktop base kit/Body & Label */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.preview {
  margin-right: 16px;
  cursor: pointer;
}
.clink {
  cursor: pointer;
}
.ddHolder {
  margin-left: auto;
  width: 106px;
}
.sortddHolder {
  /*display: flex;
    justify-content: end;*/
  margin-left: auto;
}
.iconmGlass {
  background-image: url("../../assets/images/magnifying-glass.svg");
  background-repeat: no-repeat;
}
