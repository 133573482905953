.slideBody {
  color: var(--blue-900, #00012b);

  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  padding-top: 1rem;
  padding-right: 1rem;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cholder {
  border-radius: 8px;
  border: 1px solid var(--light-gray-stroke, #d1d1d1);
  background: #fff;
  /*padding: 16px;*/
  margin-top: 24px;
}
.textUnderline {
  text-decoration-line: underline;
}
.hideHolder {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 4px;
  margin-right: 20px;
}
.hideHolder {
  color: var(--blue-900, #00012b) !important;
  font-family: Calibre-R;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.hideHolder .eyeHolder {
  background: url("../../../assets/carousal/eye-off.svg");
  width: 18px;
  height: 18px;
  padding-left: 1.2rem;
}

.global_learnmorebtn_s {
  margin-top: 14px;
  display: flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--blue-900);
  color: var(--blue-900);
  text-align: center;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  background-color: transparent;
  margin-top: 14px;
}

.hideWrapper {
  padding: 20px 24px;
}
.showHolder {
  text-align: right;
}

.showHolder .eyeHolder {
  background: url("../../../assets/carousal/eye-on.svg");
  width: 18px;
  height: 18px;
  padding-left: 1.2rem;
}

.title {
  color: var(--blue-900, #00012b);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  text-align: left;
}

.showHolder button {
  font-size: 16px;
  color: var(--blue-900, #00012b) !important;
  font-family: Calibre-R;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  /* text-decoration-line: underline; */
}
.noRightPadding
{
  padding-right: 0;
}
@media (min-width: 1024px) {
  .cholder {
    margin-top: 40px;
  }
}
@media (max-width: 768px) {
  .learnMoreHolder {
    margin-bottom: 33px;
  }
}
