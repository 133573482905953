/* common */
.container {
  border-radius: 8px;
  border: 1px solid var(--Light-gray-stroke, #d1d1d1);
  background: var(--White, #fff);
}
.sgStyle {
  margin-top: 8px;
}
.tsStyle {
  margin-top: 8px;
  margin-bottom: 20px;
}
.bgOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 40%);
  top: 78px;
  left: 0;
  z-index: 10;
}

/*container  */
.searchMobContainer {
  position: absolute;
  width: 100%;
  /* min-height: 340px; */
  /* height: 100%; */
  background: #e8e8e8
}
.containerBg {
  /* background: #faf9f5; */
  background: transparent;
  /* height: 100%; */
}
.inputInnerContainer {
   /* position: absolute; */
   width: calc(100% - 40px);
   left: 0;
   right: 0;
   margin-left: auto;
   margin-right: auto;
   margin-bottom: 20px;
   margin-top: 20px;
   /* top: 20px; */
}

/*  */
.suggestHeaderBox ul {
  padding: 0;
  margin: 0;
  border-radius: 8px;
  outline: 1px solid var(--Blue, #373ae5);
}
.suggestHeaderBox ul li {
  list-style-type: none;
  padding: 13px 24px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

/* Also in GLobalSearch input */
.clearIcon {
  background-image: url("../../assets/search/icon_cross.png");
  background-repeat: no-repeat;
  width: 21px;
  height: 21px;
  position: absolute;
  top: 37px;
  right: 67px;
  cursor: pointer;
  display: none;
}

.inputOuter {
  width: 100%;
  height: 57px;
  border: 1px solid var(--Blue-500, #373ae5);
  border-radius: 8px;
  padding: 0 18px 0 19px;
  background-color: #fff;

  display: flex;
}
.input {
  border: 0;
  width: calc(100% - 40px);
  padding-right: 50px;
}
.searchIconInside {
  background: url("../../assets/search/icon-magnifying-glass.svg") no-repeat
    #fff 95%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  align-self: center;
}

.trendingSearchContainer {
  padding: 27px 24px;
  color: #00012b;
  height: 235px;
}
/* //verif once */
.trendingSearchContainer h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.iconmGlass {
  background-image: url("../../assets/images/magnifying-glass_ofwhite.svg");
  background-repeat: no-repeat;
  margin: 13px 0;
  padding-left: 33px;
  font-size: 16px;
  cursor: pointer;
  width: 50%;
  word-wrap: break-word;
}
.itemHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: auto;
}
@media (max-width:768px)
{
  .iconmGlass
  {
    width:100%;
  }
  .trendingSearchContainer
  {
    height: auto;
  }
}
/* @media (min-width: 768px) {
  .snackbarFav > div:nth-child(1) {
    position: absolute;
    width: fit-content;
  }
} */
/* @media (max-width: 768px) {
  .iconmGlass {
    width: 100%;
  }
} */
