.sscontainer {
  background-color: var(--white);
  border: 1px solid var(--gray-100);
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  margin-top: 40px;
}
.ssheadersection {
  display: flex;
  justify-content: space-between;
}
.ssheader {
  color: var(--blue-900);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.ssviewall {
  color: var(--blue-900);
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
}
.sssubheader {
  color: var(--gray-700, #464646);
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  margin-top: 8px;
}
.ssentry {
  display: grid !important;
  grid-template-columns: 90% 5%;
  text-decoration: none;
  width: 100%;
  border: 1px solid transparent !important;
  padding: 1rem 0 !important;
  color: var(--blue-900) !important;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 135%;
  align-items: center;
  text-decoration: none !important;

  &:hover, &:focus-visible, &:active {
    outline: 0 !important;
    background: #ffffff !important;
    color: var(--blue-500) !important;
  }
}
.service_border {
  border-top: 1px solid var(--gold-500);
}

.sssection {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.ssDetailsSection {
  margin-top: 16px;
}

.ssdate {
  color: var(--blue-900);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.ssdate {
  margin-right: 24px;
}
.ssarrow {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

@media (width>992px) {
  .sscontainer {
    padding: 40px;
    margin-top: 0px;
  }
  .ssDetailsSection {
    margin-top: 35px;
  }
}
