.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.25);
}
.modal {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 30;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  
}
.modal > div {
  display: flex;
  flex-direction: column;
  pointer-events: all;
  /* width: 90%; */
  width: 100%;
  max-height: 80%;
  /* background-color: var(--gold-25); */
  background: var(--gray-25) !important;
  /* padding: 24px; */
  padding: 16px;
  border-radius: 14px;
}
.modal .modalHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 24px;
}
.modal .closeButton {
  /* flex: 0 0 32px; */
  height: 40px;
    width: 40px;
    border-radius: 50%;
 
}
.modal .closeButton:hover{
  height: 40px;
    width: 40px;
    border-radius: 50%;
  background: rgba(0, 1, 43, 0.1);
}
.modal .closeButton>img{
  width:24px;
  height:24px;
}
.modalHeader h3 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
}
.modal .content {
  overflow-y: auto;
}
.modal .content iframe {
  width: 100%;
}
.modal .content h4 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
}
.modal .content li,
.modal .content p,
.modal .content a {
  color: var(--blue-900);
  font-family: Calibre-R;
  /* font-size: 18px; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 24.3px */
}
.buttonHolder {
  margin-top: 16px;
}
.buttonHolder button {
  margin-left: 80%;
  background: var(--blue-900);
}
.buttonHolder button:hover {
  /* margin-left:90%; */
  background: var(--blue-800);
}
.btnGroup {
  float: right;
  margin-bottom: 5px;
}
.btnGroup button {
  display: inline-block;
  margin-right: 10px;
  margin-top: 15px;
}
.modalPrimaryBtn {
  padding: 0px 16px 0px 16px;
  background: #00012b !important;
}
.modalPrimaryBtn:hover{
  outline: 1px solid #00012b;
}
@media (max-width: 950px) {
  .modal {
    z-index: 101;
  }
  .buttonHolder button {
    margin-left: 70%;
  }
  /* Fixed: Modal was not functioning proper on Language dropdown change */
  /* .modal > div{
    pointer-events: none;
  } */
}
@media (min-width: 1024px) {
  .modal > div {
    width: 60%;
    padding: 24px;
  }
}
