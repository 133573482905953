
.dropDownRowContainer {
  margin-top: 1rem;
}

.dropdown {
  padding: 0.8rem 1rem !important;
  font-weight: normal;
  font-size: 14px !important;
}

.dropdownContainer {
  display: flex;
  align-items: center;
  padding-top: 8px;
   /*label for 1.5 
  padding-top: 8px;
  */
}

.dropdownContainer > div {
  height: 45px;
}
.dropDownRowContainerOld {
  display: none;
}

.viewAllTimecardsLink {
   /*label for 1.5 
  padding: 1rem 0 0 0 !important;
  
  padding: 1rem 0 0 0 !important;*/
  color: var(--Blue-500, #373ae5) !important;
  font-size: 14px !important;
  display: flex;
  align-items: center !important;
  border: transparent solid 1.5px !important;
  font-family: Calibre-R;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline !important;
  &:hover {
    font-weight: 400 !important;
  }
}

.payslipViewAll {
  padding: 10px 0px 20px 0px;
}

.payslipText {
  padding-left: 0.2rem;
  color: var(--Blue-500, #373ae5);
  /*label for 1.5 
  padding-top: 16px;
  */
}
/*label for 1.5 
.payslipImage {
  padding-top: 16px;
}*/

.dropDownRowContainer a {
  text-decoration-color: var(--Blue-500, #373ae5) !important;
  color: var(--Blue-500, #373ae5) !important;
  margin-top:-4px;
}

.viewAllTimecardsLink, .viewAllTimecardsLink a {
  text-decoration-color: var(--Blue-500, #373ae5) !important;
  color: var(--Blue-500, #373ae5) !important;
}

.viewAllTimecardsLink svg {
  margin-left: unset !important;
}

.viewAllTimecardsLink svg path {
  fill: var(--Blue-500, #373ae5) !important;
}
@media (max-width: 755px) {
  .dropDownRowContainer a {
    
    margin-top: 8px;
  }
}
@media (min-width: 768px) {
  .dropDownRowContainer {
    display: flex;
    justify-content: space-between;
    align-items: self-start;
    margin-top: 0;
  }
  
  .viewAllTimecardsLink {
    /*padding: 0 0 20px 0 !important;*/
    font-size: 16px !important;
  }
}
