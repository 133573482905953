.filterContainer {
  /*background-color: var(--white);
    box-shadow: 0px 2px 16px 0px rgba(177, 150, 97, 0.2);*/
  width: var(--asFilterWidth);
  /*position: sticky;
    top:0;
    */
  z-index: 1;
  /*float: left;*/
  padding: 32px 16px;
  /*height: calc(100vh - 112px);
    overflow-y: scroll;*/
  padding-left: 41px;
  min-height: calc(100vh - 112px);
  /*min-height: 100vh;*/
}

.checkHolder {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
}
.uncheckedHolder {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
}
.uncheckedHolder > div > div:focus-within {
  outline: 2px var(--blue-900);
}
.checkHolder > div > div {
  background: var(--blue-900, #00012b);
  outline: 2px solid #00012b;
}

.filterTitle {
  color: var(--Black);

  /* Additional styles/med/text-2xl-med */
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  margin-bottom: 16px;
}
.filterSubTitle {
  color: var(--Black);

  /* Additional styles/med/text-lg-med */
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
}
.inputH {
  margin-top: 12px;
  margin-bottom: 12px;
  padding-left: 40px;
}
.filterResultContent {
  padding: 16px 0px 16px 0px;
  color: var(--Blue-900, #00012b);
  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}

.cBox {
  width: 22px;
  height: 22px;
  border-radius: 3px;
}

.cLable {
  color: var(--blue-900);

  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */

  padding-left: 16px;
  padding-top: 3px;
  cursor: pointer;
}
.fLowerBlock {
  margin-top: 30px;
}
.filterControlH {
  display: flex;
  /*justify-content: space-around;
    position: absolute;
    bottom: 40px;*/
}
.filterApply {
  color: var(--blue-900, #00012b);
  text-align: center;

  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  margin-right: 24px;
}
.filterApply button {
  background: var(--blue-900);
}
.filterApply button:not([disabled]):hover {
  background: var(--blue-800);
}
.filterClear {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.mholder {
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 15px;
}
