.heroSubHeading {
  margin-bottom: 32px;
  margin-top: 16px; /* added for double padding on top to separate sections per figma */
}
.heroSubHeading .titleSection {
  border-bottom: 1px solid var(--gold-500);
}
.heroSubHeading h3 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 40.5px */
  margin-bottom: 16px;
  margin-top: 16px;
}
.heroSubHeading ul,
.heroSubHeading span,
.heroSubHeading p {
  margin-bottom: 16px;
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.heroSubHeading li {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.heroSubHeading .innerHtml {
  margin-bottom: 24px;
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.heroSubHeading strong {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .heroSubHeading {
    border-bottom: 1px solid var(--gold-500);
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .heroSubHeading .titleSection {
    border-bottom: none;
  }
}
