.helpLinks {
  margin-bottom: 40px;
}
.cardContainerInner {
  padding: 24px;
}
.cardContainerInner a {
  /* margin-bottom: 24px;
  padding-left: 0;
  display: block; */
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  line-height: 1.5;
  padding-left: 0;

  /* display: block; */

}
.cardContainerInner a:hover {
  line-height: 1.5;
}
.cardContainerInner a svg {
display: flex;
flex-shrink: 0;

}
.cardContainerInner a:last-child {
  margin-bottom: 0;
}
.cardContainerInner h3 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .cardContainerInner a {
    width:100%
    /* display: block; */
  }
  .cardContainerInner a>svg{
    margin-left:auto;
  }
}
@media (min-width: 1024px) {
  .helpLinks {
    margin-bottom: 32px;
  }
  .cardContainerInner {
    padding: 40px 32px 40px 40px;
  }
}
/* //global check 203 */
.cardContainerInner a {
  padding-right: 0;
}
@media(max-width:768px){
  .cardContainerInner a {
    width:100%
    /* display: block; */
  }
  .cardContainerInner a>svg{
    margin-left:auto;
  }
}
