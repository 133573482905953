.crisisNotification,
.crisisNotification .showMoreHolder {
  background: var(--alerts-red-700);
}
.crisisNotification.orange,
.crisisNotification.orange .showMoreHolder {
  background: #fcb64f;
}
.crisisNotification.heightRestricted {
  max-height: auto;
  overflow: hidden;
  position: relative;
}
.crisisNotificationInner {
  padding: 16px 24px;
  margin: 0 auto;
  max-width: 1440px;
}
.crisisNotificationContent {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}
.crisisNotificationContent.center {
  align-items: center;
}
.crisisNotification.heightRestricted .crisisNotificationContent .buttonHolder {
  margin-top: 30px;
}
.crisisNotification .heading {
  display: flex;
  gap: 8px;
  margin-bottom: 4px;
}
.crisisNotification .heading img {
  width: 24px;
  height: 24px;
}
.crisisNotification h3 {
  color: var(--white);
  font-family: Calibre-R;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}
.crisisNotification.orange h3 {
  color: var(--Text, #00012b);
}
.crisisNotification .innerHtml,
.crisisNotification .innerHtml p,
.crisisNotification .innerHtml a,
.crisisNotification .innerHtml span {
  color: var(--white);
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.crisisNotification.orange .innerHtml,
.crisisNotification.orange .innerHtml p,
.crisisNotification.orange .innerHtml a,
.crisisNotification.orange .innerHtml span {
  color: var(--Text, #00012b);
}
.crisisNotification .showMoreHolder {
  position: static;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px 16px 10px 0;
  margin: 0 auto;
  max-width: 1440px;
}
.crisisNotification .showMoreHolder .shade {
  background: linear-gradient(#ffffff00, var(--alerts-red-700));
  height: 30px;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: -30px;
}
.crisisNotification .showMoreHolder a
{
  padding-left: 0;
  border-left: 0;
}
.crisisNotification .showMoreHolder a:hover
{
  color:var(--blue-800);
}
.crisisNotification.orange .showMoreHolder .shade {
  background: linear-gradient(#ffffff00, #fcb64f);
}

@media (min-width: 1024px) {
}
