.schedule {
  display: flex;
  flex: 50%;
  padding: 24px 24px;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid var(--gray-100);
  margin-bottom: 20px;
}

.offerHolderSub {
  width: 100%;
}
.offerHolder::-webkit-scrollbar {
  display: none;
}

.viewEDRMenu {
  /* display: grid;
  grid-auto-flow: column;
  padding: 1rem 0; */
  /* border-bottom: 1px solid #d8c5a5; */
  display: flex;
  /* height: 66px; */
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--Gold-500, #D8C5A5);
}

.viewEDRMenu:hover {
  background-color: var(--gray-50);
}

.viewEDRMenu .loc {
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  color: #00012B;
  margin-bottom: 4px;
}

.viewEDRIcon {
  align-self: center;
}

.viewEDRIcon svg {
  cursor: pointer;
}

.viewEDRMenu .dateRange {
  font-family: Calibre-R;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.2px;
  text-align: left;
  color: #5D5D5D;
}

.viewEDRMenu .noDateRange {
  color: #663D00;
}

.propertyName {
  font-family: Calibre-R;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  text-align: left;
  color: #00012b;
}

.propertyLabel {
  font-family: Calibre-R;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.2px;
  text-align: left;
  color: #5d5d5d;
}

.toptextholder {
  /* display: flex; */
  justify-content: center;
  /* margin-top: 32px; */
  margin-bottom: 16px;
  /* padding: 0 16px; */
}
.subTextHolder {
  display: flex;
  width: 100%;
  position: relative;
}
.offerTitle {
  color: var(--blue-900, #00012b);

  /* Additional styles/med/text-2xl-med */
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
}
.scheduleLink {
  position: absolute;
  /*left:var(--viewAll-left-val);*/
  color: var(--blue-900, #00012b);
  text-align: right;
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  right: 0;
  text-transform: capitalize;
}
.scheduleLink a {
  color: var(--blue-900, #00012b);
  font-weight: 400;
}
.offerLink a:hover {
  color: var(--blue-500);
  font-weight: 500;
}
.allCardHolder {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.imageHolder {
  display: flex;
  /*padding: 0px 16px;*/
  /* align-items: flex-start; */
  justify-content: left;
  gap: 24px;
  border: 1px solid var(--gray-100);
  border-radius: 8px;
  position: relative;
}
.offerCard {
  display: flex;
  /*width: 324px;*/
  width: 240px;
  /* height: 330px; */
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--white, #fff);
  padding-bottom: 24px;
}
.offerCard.salaried {
  width: 100%;
}
.offerHolderSub.salaried {
  width: 100%;
}
.cardimg {
  /* width:249px; */
  /* width:240px; */
  height: 165px;
  height: auto;
  border-radius: 8px 8px 0px 0px;
  padding: 24px;
}
.cardimg img {
  width: 100%;
  height: auto;
}
.cardSubHolder {
  padding: 16px;
}
.cardDate {
  color: var(--gray-600, #5d5d5d);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  margin-bottom: 10px;
}
.cardTitle {
  color: var(--blue-900, #00012b);
  /* Additional styles/med/text-xl-med */
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 27px */
}
.cardBtn {
  display: flex;
  gap: 8px;
  padding-top: 8px;
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Mgmnewsmain {
  display: flex;
  gap: 40px;
}
.cards {
  padding: 24px 24px;
  border-radius: 8px;
  margin: 0;
  /* padding:0; */
}
/* .cardContainerInner {
  padding: 24px;
} */
.date {
  color: var(--gray-600, #5d5d5d);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  margin-top: 10px;
}
.title {
  color: var(--blue-900, #00012b);
  /* Additional styles/med/text-xl-med */
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 27px */
}
.description {
  color: var(--blue-900, #00012b);

  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}

.cardalignment {
  margin-bottom: 40px;
}
.learnmorepara {
  color: var(--blue-900);
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  position: absolute;
  top: calc(100% - 38px);
  font-size: 1rem;
  margin-top: 16px;
}

.learnmorebtn {
  align-items: center;
  background-color: initial;
  border: 1px solid var(--blue-900);
  border-radius: 6px;
  display: flex;
  font-size: 0.9rem;
  gap: 8px;
  height: 40px;
  justify-content: center;
  /* margin-top: 24px; */
  padding: 10px 16px;
  text-align: center;
  color: var(--blue-900);
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  position: absolute;
  top: calc(100% - 48px);
  width: max-content;
}
.learnmorebtn:hover {
  outline: #00012b 1px solid;
}

.scheduleNotes {
  position: relative;
  display: block;
  width: 100%;
  font-family: Calibre-R;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.9px;
  text-align: left;
  color: #00012b;
}

.accordionPanel {
  font-family: Calibre-R;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.2px;
  text-align: left;
  color: #5d5d5d;
  margin-top: 12px;
}

.profileContainer {
  /* display: flex;
  gap: 35px; */
  display: inline-grid;
  gap: 35px;
  grid-auto-flow: column;
}

.scheduleNotesSection {
  border-top: 1px solid var(--Gray-50, #e8e8e8);
  padding: 10px 24px;
}

.myScheduleBlock {
  width: 100%;
}

.noOfShifts {
  font-family: Calibre-R;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.2px;
  text-align: left;
  color: #292cb7;
  padding-top: 6px;
}

.shiftCount {
  width: 20px;
  height: 20px;
  border: 1px solid #292cb7;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.overnight {
  font-family: Calibre-R;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.2px;
  text-align: left;
  color: #373ae5;
}
.pipe {
  color: #d8c5a5;
  padding: 0 5px;
}

.payslipHolder {
  padding: 0 16px;
}
.cardContainer {
  border-radius: 8px !important;
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 20px !important;
}

.DateAndToggle {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}
.Clock {
  position: absolute;
  right: 32%;
  display: flex;
  align-items: center;
  gap: 16px;
}
.pageContent {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
}
.profileIcon,
.iconContainer a.profileIcon {
  background-color: var(--gold-900);
  width: 41px;
  min-width: 41px;
  height: 41px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  color: var(--black, #000);
}

.hideMyPay {
  margin-top: 16px;
}
.hideMyPay > div {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}
.hideMyPay > div > span {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}
.linkSection {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  gap: 24px;
}
.linkSection a {
  padding-left: 0;
}
.Today {
  color: var(--Blue-900, #00012b);

  /* Additional styles/sb/text-2xl-sb */
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
}
.Time {
  color: var(--Blue-900, #00012b);

  /* Additional styles/med/text-base-med */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
}
.Date {
  color: var(--Gray-600, #5d5d5d);

  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  margin-bottom: 7px;
}
.Loc {
  color: var(--Blue-900, #00012b);

  /* Additional styles/med/text-base-med */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
}
.floor {
  color: var(--Gray-600, #5d5d5d);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  margin-top: 2px;
}

.noMenuFoundContainer {
  text-align: center;
  padding: 15px;
  /* color: #00012B; */
}

.noMenuFoundContainer .imgWrapper {
  margin-bottom: 1.5rem;
}

.noMenuFound {
  width: 290px;
  margin: 0 auto;
  color: var(--Blue-900, #00012B);
  text-align: center;
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1100px) {
  .payslipHolder {
    padding: 0 16px;
  }

  .ScheduleDay {
    color: var(--Gray-900, #171717);

    /* Additional styles/sb/text-xs-sb */
    font-family: Calibre-R;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 16.2px */
  }
  .pageContent {
    margin-top: 48px;
    flex-direction: row;
    gap: 4%;
  }
  .pageContent .leftContent {
    flex: 0 0 58%;
  }
  .pageContent .rightContent {
    flex: 0 0 38%;
  }
}
@media (min-width: 1300px) {
  .pageContent .leftContent {
    flex: 0 0 66%;
  }
  .pageContent .rightContent {
    flex: 0 0 30%;
  }
  .linkSection {
    flex-direction: row;
  }
  .linkSection a:first-child {
    flex: 0 0 30%;
  }
}

@media (min-width: 768px) {
  .schedule {
    margin-bottom: 0;
  }

  .mySCheduleBoardContainer {
    /* display: grid; */
    /* grid-auto-flow: column;
    grid-template-columns: 48% 48%;
    grid-gap: 4%; */
    display: flex;
    gap: 4%;
  }
}

@media (max-width: 768px) {
  .imageHolder {
    margin-right: 5px;
    width: 100%;
    justify-content: center;
  }
  .Mgmnewsmain {
    justify-content: left;
  }
  .cardBtn {
    display: none;
  }
  .allCardScrollingContainer::-webkit-scrollbar {
    display: none;
  }
  .allCardScrollingContainer {
    overflow-x: auto;
    margin-bottom: 0.5rem;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .toptextholder {
    margin: 0 0 16px 0;
  }
}
