.accordionBlock {
  border-bottom: 1px solid var(--gray-50);
}
.accordionBlock > div > button > div {
  padding-left: 0;
  padding-right: 0;
}
.accordionBlock > div > button > div:first-child {
  padding-left: 40px;
  background-image: url("../../../assets/images/compare-chart.svg");
  background-repeat: no-repeat;
  background-position: left center;
}
.accordionBlock > div > section {
  padding: 0 0 8px 0;
  width: 80%;
}
.accordionBlock.fixedHeight {
  height: 58px;
  overflow: hidden;
}
.abbreviations {
  margin-top: 16px;
}
.abbreviations > div {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  margin-right: 40px;
}
.abbreviations p {
  margin: 0;
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  display: inline-block;
}
.abbreviations span {
  display: inline-block;
  height: 8px;
  width: 24px;
  border-radius: 16px;
}
.abbreviations > div:nth-child(1) span {
  background-color: var(--blue-500);
}
.abbreviations > div:nth-child(2) span {
  background-color: var(--blue-900);
}
