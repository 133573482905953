.callout {
  margin-bottom: 32px;
  margin-top: 16px;
  /* margin-top: 32px; */
}
.callout.center {
  text-align: center;
}
.callout .iconHolder {
  max-width: 40px;
  max-height: 40px;
  margin-bottom: 10px;
}
.callout .iconHolder * {
  max-width: 100%;
}
.callout .iconHolder img {
  width: 48px;
  height: 48px;
}
.callout .iconHolder.center {
  margin: 0 auto;
}
.callout h2 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  margin-bottom: 16px;
}
.callout p {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  margin-bottom: 24px;
}
.callout .buttonHolder {
  display: inline-flex;
  gap: 16px;
}
.big {
flex-direction: row;
}
.small {
flex-direction: column;
}
.callout .buttonHolder Button {
  background: var(--blue-900);
  color: var(--white);
  padding-top: 8px;
  padding-bottom: 8px;
  height: auto;
  line-height: 1.2em;
}
.callout .buttonHolder Button:hover {
  background: var(--blue-800);
  color: var(--white);
}
.innerHtml{
  margin-bottom:24px;
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .callout {
    margin-bottom: 40px;
  }
}

@media (max-width: 1023px) {
  .callout .buttonHolder {
    display: inline-flex;
    gap: 16px;
    width:100%;
    /* margin-top: 16px; */
    
  }
  .callout .buttonHolder Button{
    justify-content: center;
  }

}
@media (max-width: 768px) {
  .callout .buttonHolder Button {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .big {
    flex-direction: column;
    }
}
