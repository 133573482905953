.mgmNewsMessageHolder {
  display: flex;
  gap: 48px;
  flex-direction: column;
  /* margin: auto 16px; */
}
.videos {
  width: 100%;
  display: flex;
  justify-content: center;
}
.newsSummary {
  margin-bottom: 34px;
}
.mgmNewsMessageHolder .message {
  width: 100%;
}
.mgmNewsMessageHolder .message h2 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  margin-bottom: 16px;
}
.mgmNewsMessageHolder .message img {
  width: 100%;
}
.mgmNewsMessageHolder .message .imageHolder {
  position: relative;
  margin-bottom: 32px;
  margin-left: -16px;
  margin-right: -16px;
  border-radius: 8px;
  max-height: 500px;
  overflow: hidden;
}
.mgmNewsMessageHolder .message .imageHolder img {
  border-radius: 8px;
}
.mgmNewsMessageHolder .message .videoHolder{
  position: relative;
}
.mgmNewsMessageHolder .message .leftArrow,
.mgmNewsMessageHolder .message .rightArrow {
  cursor: pointer;
  position: absolute;
  top: calc(50% - 20px);
  width: 40px;
  height: 40px;
  background-color: rgba(250, 249, 245, 0.6);
  border-radius: 20px;
  border: solid 1px var(--gray-100);
  display: flex;
  justify-content: center;
  align-items: center;
}
.mgmNewsMessageHolder .message .leftArrow {
  left: 16px;
}
.mgmNewsMessageHolder .message .rightArrow {
  right: 16px;
}
.mgmNewsMessageHolder .innerHtml {
  margin-bottom: 40px;
}

/*dynamic content styling*/
.mgmNewsMessageHolder .innerHtml p {
  margin-bottom: 16px;
}
.mgmNewsMessageHolder .innerHtml span[class^="fr-img-wrap"] {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mgmNewsMessageHolder .innerHtml ul {
  margin-bottom: 30px;
}
.mgmNewsMessageHolder .innerHtml li {
  margin-bottom: 5px;
}
.mgmNewsMessageHolder .innerHtml span[class^="fr-img-wrap"] {
  margin: 12px 0;
}
/*dynamic content styling*/

.mgmNewsMessageHolder .buttonHolder {
  display: flex;
  justify-content: space-between;
  margin: 40px 0 50px 0;
}
.mgmNewsMessageHolder .relatedNews h3 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.mgmNewsMessageHolder .relatedNews hr {
  margin: 4px 0 24px 0;
}
.mgmNewsMessageHolder .relatedNews .news {
  margin-bottom: 32px;
}
.mgmNewsMessageHolder .relatedNews .news .imageContainer {
  width: 100%;
  height: 238px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 10px; /* //16px */
  background-color: #e4e4e4;
  cursor: pointer;
}
.mgmNewsMessageHolder .relatedNews .news h4 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.mgmNewsMessageHolder .relatedNews .news h5 {
  color: #00012b;
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  margin-right: 8px;
  cursor: pointer;
}
.mgmNewsMessageHolder .relatedNews .news h5:hover {
  text-decoration: underline;
}
.relatedNews {
  width: 100%;
}
.image {
  width: 100%;
}
.mainContainer {
  display: flex;
  flex-direction: column;
}
.date {
  font-size: 14px;
  color: #5d5d5d;
  margin-bottom: 6px; /*8px*/
  margin-right: 8px;
}
.readMoreButton {
  margin-top: 16px;
}
.linkimage{
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.readMoreButton:hover {
  outline: #00012b 1px solid;
}
@media (min-width: 1024px) {
  .mgmNewsMessageHolder {
    flex-direction: row;
    margin: 0;
  }
  .mgmNewsMessageHolder .message h2 {
    font-size: 36px;
    margin-bottom: 8px;
  }
  .mgmNewsMessageHolder .message .imageHolder {
    margin-left: 0;
    margin-right: 0;
  }
  .relatedNews {
    width: 250px;
  }
  .newsSummary {
    margin-bottom: 40px;
  }
  .mainContainer {
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
  }
  .mgmNewsMessageHolder .relatedNews .news .imageContainer {
    width: 230px;
    height: 153px;
  }
}
.mgmNewsMessageHolder .relatedNews .news {
  margin-bottom: 40px;
}
