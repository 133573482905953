.casesWrapper {
  margin-top: 32px;
}
.casesWrapper > div {
  background-color: #ffff;
}
.casesInner {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray-100);
  border-radius: 8px;
  background-color: var(--White);
}
.anchors {
  margin: 8px 16px 0;
  border-bottom: 1px solid var(--gray-100);
}
.anchors .tabItem {
  display: inline-block;
  color: var(--blue-900);
  line-height: 135%;
  text-decoration: none;
  padding: 16px 2px !important;
  margin: 0 15px;
  border-radius: 0;
}
.anchors .tabItem.active {
  color: var(--blue-900);
  font-weight: 500;
  border-bottom: 1px solid var(--gold-900);
}

.apiError > div {
  margin: 0;
}

.eholder {
  padding: 16px 24px;
  width: 100%;
  background: var(--White);
  border-radius: 8px;
}

.APIErrContainer {
  display: grid;
  align-items: center;
  grid-gap: 1rem;
  justify-content: center;
  text-align: center;
}

.APIErrRowContainer {
  display: grid;
  align-items: center;
  grid-gap: 1rem;
  justify-content: center;
  text-align: center;
}

.txt1 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

.txt2 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

.casesWrapper [data-testid="card-container"] {
  border-radius: 8px;
  margin: 0;
  padding: 0;
  border: none;
  overflow: visible;
  background-color: var(--gold-f2);
}

@media screen and (max-width: 576px) {
  .iconholder {
    margin: 0 auto;
  }
  .txtH {
    padding: 0;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) {
  .errorBlock {
    width: 50%;
  }
  .errorBlock > div {
    margin: 0 !important;
  }
  .APIErrContainer {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: 40px;
    justify-content: left;
    text-align: left;
  }

  .APIErrRowContainer {
    justify-content: left;
    text-align: left;
  }

  .eholder {
    padding: 16px 24px;
    width: 100%;
    /*border-right: 1px solid var(--gray-100);*/
    background: var(--White);
  }
}

@media (min-width: 768px) {
  .casesInner {
    display: flex;
    flex-direction: row;
    gap: 16px;
    border: none;
    background-color: var(--gold-f2);

    /* display:grid; */
    /* grid-auto-flow: column;
    grid-template-columns: 50% 50%; */
  }
  .casesWrapper > div {
    background-color: var(--gold-f2);
  }
}
