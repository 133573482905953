.scheduleNotes {
  position: relative;
  display: block;
  width: 100%;
  font-family: Calibre-R;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.9px;
  text-align: left;
  color: #00012b;
}

.scheduleNotes[aria-expanded="false"] {
  &:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url("../../../assets/images/chevron-down-blue.svg") no-repeat;
    right: 0;
  }
}

.scheduleNotes[aria-expanded="true"] {
  &:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url("../../../assets/images/chevron-up-blue.svg") no-repeat;
    right: 0;
  }
}

.accordionPanel {
  font-family: Calibre-R;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.2px;
  text-align: left;
  color: #5d5d5d;
  margin-top: 12px;
}

.profileContainer {
  /* display: flex;
  gap: 35px; */
  padding: 24px;
  display: inline-grid;
  gap: 35px;
  grid-auto-flow: column;
}

.scheduleNotesSection {
  border-top: 1px solid var(--Gray-50, #e8e8e8);
  padding: 10px 24px;
}

.myScheduleBlock {
  width: 100%;
}

.waitConfirm {
  font-family: Calibre-R;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.2px;
  text-align: left;
  color: #292cb7;
  text-transform: capitalize;
  padding-top: 5px;
  position: relative;
  padding-left: 20px;

  &:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url("../../../assets/images/timestopwatch-blue.svg") no-repeat;
    left: 0;
  }
}

.myScheduleBlock > div:nth-child(2) {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #d8c5a5;
}

.payslipHolder {
  padding: 0 16px;
}
.cardContainer {
  border-radius: 8px !important;
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 20px !important;
  height: unset !important;
}
.overnight {
  font-family: Calibre-R;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.2px;
  text-align: left;
  color: #373ae5;
}
.pipe {
  color: #d8c5a5;
  padding: 0 5px;
}
.locationWrapper {
  padding-top: 15px;
}

.DateAndToggle {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}
.Clock {
  position: absolute;
  right: 32%;
  display: flex;
  align-items: center;
  gap: 16px;
}
.pageContent {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
}
.profileIcon,
.iconContainer a.profileIcon {
  background-color: var(--gold-900);
  width: 41px;
  min-width: 41px;
  height: 41px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  color: var(--black, #000);
}

.hideMyPay {
  margin-top: 16px;
}
.hideMyPay > div {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}
.hideMyPay > div > span {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}
.linkSection {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  gap: 24px;
}
.linkSection a {
  padding-left: 0;
}
.Today {
  color: var(--Blue-900, #00012b);

  /* Additional styles/sb/text-2xl-sb */
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
}
.Time {
  color: var(--Blue-900, #00012b);

  /* Additional styles/med/text-base-med */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
}
.Date {
  color: var(--Gray-600, #5d5d5d);

  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.Loc {
  color: var(--Blue-900, #00012b);

  /* Additional styles/med/text-base-med */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
}
.Floor {
  color: var(--Gray-600, #5d5d5d);

  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}

@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1100px) {
  .payslipHolder {
    padding: 0 16px;
  }

  .ScheduleDay {
    color: var(--Gray-900, #171717);

    /* Additional styles/sb/text-xs-sb */
    font-family: Calibre-R;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 16.2px */
  }
  .pageContent {
    margin-top: 48px;
    flex-direction: row;
    gap: 4%;
  }
  .pageContent .leftContent {
    flex: 0 0 58%;
  }
  .pageContent .rightContent {
    flex: 0 0 38%;
  }
}
@media (min-width: 1300px) {
  .pageContent .leftContent {
    flex: 0 0 66%;
  }
  .pageContent .rightContent {
    flex: 0 0 30%;
  }
  .linkSection {
    flex-direction: row;
  }
  .linkSection a:first-child {
    flex: 0 0 30%;
  }
}
