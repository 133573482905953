.accordionArrow {
  border-radius: 8px 0 0;
  background: var(--Blue-900, #00012b);
  list-style: none;
  padding: 16px;
}

.globalFlyoutSection {
  min-width: 200px;
  max-width: 300px;
  height: 100%;
  background: var(--White, #fff);
  width: auto;
  display: flex;
  align-items: center;
  /* visibility:hidden;
  overflow:hidden; */
  transition: width 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), visibility 0.5s;
  /* background-color: cadetblue; */
  list-style: none;
  &:hover,
  &:focus,
  &:focus-visible {
    background-color: var(--gray-50, #e8e8e8);
  }
}

.menuIcons {
  transform: rotateZ(-180deg);
  transition: all 0.4s ease;
}

.open {
  transform: rotateZ(0deg);
  /* transition: transform 1s linear; */
}

.globalFlyoutSection a,
.globalFlyoutSection a:hover,
.globalFlyoutSection a:focus,
.globalFlyoutSection a:focus-visible,
.globalFlyoutSection a:active {
  text-decoration: none;
  color: #00012b !important;
  display: flex;
  align-items: center;
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  padding: 0;
  display: inline-grid;
  grid-auto-flow: column;
  padding: 16px; 
}

.globalFlyoutSection a img,
.globalFlyoutSection a svg,
.globalFlyoutSection a svg path {
  width: 20px;
  height: 20px;
  margin: 0;
  color: var(--blue-900, #00012b) !important;
  fill: var(--blue-900) !important;
  margin-right: 16px;
  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    color: var(--blue-900, #00012b) !important;
    fill: var(--blue-900) !important;
  }
}

.globalFlyoutSection a span {
  /* margin-left: 16px; */
  color: var(--blue-900, "#00012B");
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
}

.globalIconContainer {
  border-radius: 8px 0px 0px 8px;
  background: var(--white, #FFFFFF);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
}

.globalFlyoutContainer {
  padding-left: 0;
  background: var(--white, #FFFFFF);
  border-radius: 8px;
}

.globalFlyoutContainer li {
  /* padding: 16px; */
}

.globalFlyoutContainer li:last-child {
  border-radius: 0 0 0 8px;
}

.globalIconSection {
  /* max-width: 300px; */
  /* width: 50px;
  visibility:hidden;
  overflow:hidden;
  background-color: cadetblue; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* padding: 20px 0; */
  justify-content: flex-end;
  min-width: 50px;
  min-height: 24px;
  align-items: center;
  width: 52px;
  height: 100%;
  transition: width 3s cubic-bezier(0.77, 0.2, 0.05, 1), visibility 3s;
}

.feedbackBtn {
  color: #ffffff !important;
  padding: 8px 15px 4px !important;
  border-radius: 0 0 6px 6px !important;
  background: var(--Alerts-Green-800, #006732) !important;
  border: 1px solid transparent !important;
  /* rotate: 270deg */
  /* transform: rotate(-270deg);
  margin-right: -48px; */
}

.feedbackFormContainer {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
}
