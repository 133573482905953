.eholder {
    padding: 40px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--gray-100);
    background: var(--White);
    margin-top: 16px;
    margin-bottom: 16px;
    height: 100%;
  }

  
.APIErrContainer {
    display: grid;
    align-items: center;
    grid-gap: 1rem;
    justify-content: center;
    text-align: center;
  }
  .iconholder {
    /*width: 110px;
    height: 132px;*/
    
  }
  .txt1 {
    color: var(--blue-900);
    font-family: Calibre-R;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
  }
  
  .txt2 {
    color: var(--blue-900);
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
  }
  
  