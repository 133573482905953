.reporteeCardContainer {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--Gray-100, #D1D1D1);
  background: var(--White, #FFF);
  padding: 24px;
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  width: 49%;
  min-height: 260px;
}

.personalInfoCard > div {
  margin-top: 0;
}
.reporteeImg{
  width:24px;
  height:24px;
}
.profileIcon {
  background-color: var(--gold-900);
  width: 56px;
  min-width: 56px;
  height: 56px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  color: var(--Blue-900, #00012B);
  width: 80px;
  height: 80px;
  border-radius: 40px;
  font-size: 30px;
  min-width: 80px;
}

.infoname {
  color: var(--Blue-900, #00012B);
  /* Additional styles/med/text-3xl-med */
  font-family: Calibre-R;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

.info {
  padding-left: 24px;
}

.profileDetails {
  display: flex;
  align-items: inherit;
  margin-bottom: 16px;
}

.personalsubtitle {
  color: var(--Gray-900, #171717);
  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  padding-top: 8px;
  /* 18.9px */
}

.personalResortName {
  color: var(--Gray-700, #464646);
  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  padding-top: 4px;
}

.borderReportee {
  border-bottom: 1px solid var(--gold-500);
}

.recogSomeoneButton {
  background: var(--Blue-900, #00012B) !important;
}

.recogSomeoneButton:hover {
  background: var(--blue-800) !important;
}

.ReporteeDetailsFlex {
  display: flex;
  align-items: center;
  position: relative;
  gap: 16px;
  margin-top: 16px;
  padding-bottom: 16px;

}

.ReporteeDetailsFlexDuo {
  display: flex;
  align-items: center;
  position: relative;
  gap: 16px;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--gray-50);
}

.ReporteeDetailsFlex:not(:last-child)::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  content: '';
  border-bottom: 1px solid var(--gray-50);
}

.ReporteeDetailsFlexChild {
  display: flex;
  align-items: center;
  width: 50%;
  gap: 16px;

}
.ReporteeDetailsFlexChild:first-child {
  border-right: 1px solid var(--gold-500);
}

.ReporteeEmailFlex {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.ReporteeGiveFeedbackFlex {
  display: flex;;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.detailsSubTitle {
  color: var(--Gray-900, #171717);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

.detailsValue {
  color: var(--Blue-900, #00012B);
  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

@media (max-width: 1024px) {
  .reporteeCardContainer {
    width: 100%;
  }
  .ReporteeGiveFeedbackFlex {
    /* display: flex;
    justify-content: space-between; */
    display:flex;
    flex-direction: column;
    gap:4px;
    align-items: flex-start;
    width: 100%;
  }
 
}
@media (max-width: 1400px) {
  .ReporteeDetailsFlexDuo {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    gap: unset;
    margin-top: unset;
    padding-bottom: unset;
    border-bottom: unset;
  }
  .ReporteeDetailsFlexChild:first-child {
    border-right: none;
    /* border-bottom: 1px solid var(--gray-50); */
  }
  .ReporteeDetailsFlexChild {
    display: flex;
    align-items: center;
    position: relative;
    gap: 16px;
    margin-top: 16px;
    padding-bottom: 16px;
    width: 100%;
    border-bottom: 1px solid var(--gray-50);
  }
}