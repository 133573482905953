.container {
  padding: 10px;
  min-width: 300px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  background: #fff;
  position: absolute;
  z-index: 1;
}
.edrDatePickerContainer
  [data-testid="custom-date-range-picker-input-start-input"],
.edrDatePickerContainer
  [data-testid="custom-date-range-picker-input-end-input"] {
  position: relative;
}
.edrDatePickerContainer [data-testid="custom-date-range-picker-input"] {
  min-width: 100%;
}
.btnGroup {
  /* margin-bottom: 5px; */
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  justify-items: right;
  float: right;
}
.scrollContainer {
  /* height: 95%; */
  overflow-y: auto;
  padding-right: 5px;
  /* margin-top: 1rem; */
  /* max-height: 60vh; */
  max-height: 380px;
}

.fileUploadContainer h6 {
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  color: #00012b;
}

.uploadEDRModal button:focus-visible {
  outline: 1px solid #292cb7 !important;
  border-color: #292cb7 !important;
  border-radius: 4px;
}

.fileUploadContainer .note {
  font-family: Calibre-R;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.2px;
  text-align: left;
  color: #00012b;
  margin-top: 0.2rem;
  /* margin-bottom: 1.5rem; */
}

.btnGroup button {
  display: inline-block;
  /* margin-right: 10px; */
  margin-top: 15px;
}
.modalPrimaryBtn {
  padding: 0px 16px 0px 16px;
  background: #00012b;
}
.required:after {
  content: " *";
  color: red;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 101;
  background: rgba(0, 0, 0, 0.4);
}

.innerHolder {
  width: 95vw;
  height: auto;
  padding: 64px 0;
}

.noBenefitInnerHolder {
  min-height: 200px;
  /* width: 95vw; */
  height: auto;
  /* padding: 64px 0; */
}

.cross {
  position: absolute;
  top: 10px;
  right: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  /* background: url("../../../assets/sitemaintanance/cross.svg"); */
  background: url("../../assets/sitemaintanance/cross.svg");
}
.hide {
  display: none;
}
.coreHolder {
  /* width: 492px; */
  position: relative;
  margin-bottom: 1rem;
  /* height: auto;
  max-height: 90vh;
  overflow: hidden; */
}

.viewCurrentBenefitTable {
  /* max-height: 450px; */
  /* height: 90%; */
  /* max-height: 720px; */
  height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
}

.scrollContainer::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scrollContainer::-webkit-scrollbar {
  margin-top: 10px;
  width: 8px;
  background-color: #f5f5f5;
}
.scrollContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--gray-100);
}

.scrollContainer {
  height: 100%;
}

.viewCurrentBenefitTable th {
  padding: 20px 0 7px;
  position: sticky;
  top: 0;
  background: #faf9f5;
}

.viewCurrentBenefitTable td,
.viewCurrentBenefitTable th {
  border-bottom: 1px solid var(--gold-500);
}

.mobileHeader {
  border-top: 1px solid var(--gold-500);
}

.viewCurrentBenefitTableMob td,
.viewCurrentBenefitTableMob th,
.mobileHeader {
  padding: 10px 10px 10px 0;
  text-align: left;
}

.benefitsRow {
  /* margin-top: 20px; */
  width: 100%;
  border-top: 1px solid var(--gold-500);
}

.benefitsRow div {
  padding: 7px 0;
}

.benefitsPlanHeader {
  margin: 20px 0 10px 0;
  width: 95%;
}

.viewCurrentBenefitTableMob {
  height: 100%;
  /* overflow-y: scroll;
  max-height: 800px; */
}

.viewCurrentBenefitTable th,
.viewCurrentBenefitTableMob th,
.benefitsHeader {
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  color: var(--blue-900);
}

.viewCurrentBenefitTableMob td {
  border-top: 1px solid var(--gold-500);
}

.viewCurrentBenefitTableMob th,
.benefitsHeader {
  font-weight: 600;
}

.viewCurrentBenefitTableMob tr {
  margin-bottom: 30px;
  border-spacing: 0 30px;
  border-collapse: separate;
}

.benefitsRow {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  grid-template-columns: 56% 40%;
}

.innerHtml {
  text-align: left;
  margin-top: 20px;
}

.viewCurrentBenefitTable td {
  font-family: Calibre-R;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  text-align: left;
  color: var(--blue-900);
}

.benefitLabel {
  font-weight: 500 !important;
  text-align: left;
  font-family: Calibre-R;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  color: var(--blue-900);
}

.benefitValue {
  text-align: right;
  font-family: Calibre-R;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  color: var(--blue-900);
}

.noRecordFound {
  margin: 2rem auto;
  width: 100%;
  display: grid;
  text-align: center;
}

.loadBenefits {
  margin-top: 2rem;
  width: 100%;
  text-align: center !important;
  border: 1px solid transparent !important;
}

.benefitsTable {
  overflow-y: scroll;
  min-height: 300px;
  max-height: 80vh;
  margin-bottom: 10px;
  padding-right: 10px;
}

/* thead,
tbody {
  display: block;
}

tbody {
  height: 500px;
  overflow: auto;
} */

/* .viewCurrentBenefitTable table thead tr {
  width: 100%;
  display: block;
} */

/* .viewCurrentBenefitTable table tbody {
  width: 100%;
  height: 300px;
  overflow-y: auto;
  display: block;
} */

.head {
  width: 90%;
  color: var(--gray-900);
  text-align: left;
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  /* margin-top: 10px; */
}

.body {
  color: var(--gray-800);
  text-align: center;
  width: 415px;
  margin: 0 auto;
  /* Additional styles/reg/text-lg-reg */
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  /* 24.3px */
  margin-top: 10px;
}

.contBtn {
  display: flex;
  height: 56px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--blue-900);
  cursor: pointer;
  margin-top: 24px;
  color: var(--white);
  text-align: center;

  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  /* 21.6px */
  margin-left: auto;
  margin-right: auto;
}

.contBtn:hover {
  background: var(--blue-800);
}
@media (max-width: 767px) {
  .coreHolder {
    /* width: 90vw; */
    height: auto;
  }
  .body {
    width: 95%;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.container > h2 {
  font-size: 14px;
}
.container > p {
  font-size: 11px;
  margin: 7px 0;
}
.container > hr {
  border: 1px solid #99845d;
}
/* tab */
.container > .button {
  padding: 3px 0;
  width: 100%;
  display: flex;
}

.btnContainer {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-auto-flow: column;
  grid-gap: 1rem;
}
.btnContainer > button:nth-child(2) {
  background: var(--blue-900);
}

.btnContainer > button:nth-child(2):hover {
  background: var(--blue-800);
}
/* 
.container > .button {
  background-color: #0f0;
  font-size: 13px;
  margin: 20px 0;
  width: 100%;
  display: flex;
} */
.container > button:last-child {
  margin: 10px 0;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.25);
}
.modal {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 101;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  /* text-align: center; */
}
.uploadSelectDropdown ul {
  top: 64px !important;
}
.location {
  color: #464646;
  font-family: Calibre-R;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  text-align: left;
}
.modal > div {
  display: flex;
  flex-direction: column;
  pointer-events: all;
  background-color: var(--gray-25);
  padding: 16px;
  border-radius: 14px;
  color: #00012b;

  /* align-items: center; */
  /* justify-content: center; */
}
.modal .modalHeader {
  gap: 24px;
}
.modal .closeButton {
  flex: 0 0 32px;
}
.modalHeader h3 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  color: var(--blue-900, #00012b);
  text-align: center;
}
.modal .content {
  overflow-y: auto;
}
.modal .content h4 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
}
.modal .content li,
.modal .content p {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 24.3px */
}

.imgHolder {
  text-align: center;
}
.imgHolder img {
  height: 46px;
  width: 46px;
}
@media (min-width: 768px) {
  .edrDatePickerContainer [data-testid="custom-date-range-picker-input"] {
    min-width: 336px;
  }
  .cross {
    position: absolute;
    top: 3px;
    right: 0;
  }
  .head {
    width: 100%;
  }
  .innerHolder {
    min-height: 457px;
    border-radius: 16px;
    background: var(--gray-25);
    padding: 64px 102px;
    position: relative;
  }
  .noBenefitInnerHolder {
    border-radius: 16px;
    background: var(--gray-25);
    padding: 64px 102px;
    position: relative;
  }
}
@media (min-width: 1024px) {
  .modal {
    z-index: 30;
  }
  .modal > div {
    padding: 24px;
  }
  .innerHolder {
    width: 696px;
    max-height: 625px;
    height: auto;
    /* height: auto; */
    max-height: 650px;
    overflow: hidden;
  }
  .noBenefitInnerHolder {
    width: 60%;
  }
}
