.quickAction {
  display: flex;
  /* padding: 20px 15px; */
  padding: 16px;
  align-items: center;
  cursor: pointer;
  width: 100%;
  background-color: var(--white);
  border-radius: 8px;
  gap: 10px;
  box-shadow: 0px 0px 4px 0px #00000026;

}
.quickAction.large {
  padding: 24px;
}
.quickAction:hover{
  /* box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 30%); 
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.30);*/
  box-shadow: 0px 0px 4px 0px #0000004D;

}
.quickAction:hover h4,
.quickAction:hover p {
  /* color: var(--blue-500); */
}
.quickAction .leadingIcon {
  margin-right: 16px;
}
.quickAction .leadingIcon svg {
  max-width: 40px;
  max-height: 40px;
  
}
.leadingIcon img {
  width: 40px;
  height: 40px;
}
.quickAction .leadingIcon svg path {
  fill:var(--gold-800);
}
.quickAction h4 {
  color: var(--blue-title);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
  /* margin-right: 16px; */
  word-break: break-word;
}
.quickAction.large h4 {
  font-size: 18px;
}
.quickAction p {
  color: var(--blue-sub-title);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.quickAction .trailingIcon {
  margin-left: auto; 
}
.quickAction .trailingIcon svg {
  max-width: 40px;
  max-height: 40px;
}
.trailingIcon img {
  width: 40px;
  height: 40px;
}

@media (min-width: 768px) {
  .quickAction {  
    /* padding: 20px 24px; */
    padding: 16px;
  }
}
