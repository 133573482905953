

.slideCardContainer:first-of-type {
  /* padding-top: 10px; */
}

.slideCardContainer {
  display: flex;
  padding-top: 16px;
  /* max-width: 340px;
  min-width: 340px; */
  max-width: 324px;
  min-width: 324px;
}
/* @media (max-width: 1224px) { */
@media (max-width: 1400px) {

  .slideCardContainer {
    display: flex;
    padding-bottom: 8px;
    min-width: 340px;
  }
}

.slideImage {
  max-width: 120px;
  max-height: 120px;
  width: 100%;
  display: flex;
  object-fit: cover;
  border-radius: 8px;
}

.slideDescriptionContainer {
  
  padding-left: 16px;
  width:100%;
  box-sizing: border-box;
  color: #5d5d5d;
  font-size: 14px;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  display: flex;
  flex-direction: column;
}
@media (max-width: 1224px) {
  .slideCardContainer {
    padding-bottom: 16px;
    margin-right: 8px;
  }
  .slideDescriptionContainer {
    max-width: 200px;
    max-height: 120px;
  }
}

.slideDescriptionContainer > div {
  max-height: 60px;
  min-height: 40px;
  padding-top: 5px;
  display: inline;
  overflow: hidden;
  /*text-align: justify;*/
}

.slideDescriptionContainer > a {
  padding-left: unset;
  margin-top: auto;
}

.slideHeader {
  color: var(--Blue-900, #00012b);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
