.sscontainer {
  background-color: var(--white);
  border: 1px solid var(--gray-100);
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  /* margin-top: 40px; */
}
.sscontainerborder {
  display: inline-flex;
  height: 100%;
  width: 100%;
  padding-left: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
}
.ssheadersection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 10px; */
}
.ssheadersection .ssheader {
  color: var(--blue-title);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.ssviewall {
  color: var(--blue-900);
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
  white-space: nowrap;
}
.sssubheader,
.sssubheader p {
  color: var(--blue-sub-title);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  margin-top: 8px;
}

.ssentry {
  display: grid !important;
  grid-template-columns: 90% 5%;
  text-decoration: none;
  width: 100%;
  border: 1px solid transparent !important;
  padding: 1rem 0 !important;
  color: var(--blue-900) !important;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 135%;
  align-items: center;
  text-decoration: none !important;

  &:hover,
  &:focus-visible,
  &:active {
    outline: 0 !important;
    background: #ffffff !important;
    color: var(--blue-700) !important;
  }
}
.ssentry svg, .ssentry:hover svg path {
  color: var(--blue-700) !important;
  fill:  var(--blue-700) !important;
}
.service_border {
  border-top: 1px solid var(--gold-500);
  &:last-child {
    border-bottom: 1px solid var(--gold-500);
  }
}

.service_border_twocol {
  display: flex;
  /* width: 307px; */
  height: 45.75px;
  padding: 10px 0px;
  align-items: center;
  gap: 14px;
}
.service_border_twocol:not(:first-child) {
  border-top: 1px solid var(--gold-500);
}

.sssection {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.ssDetailsSection {
  margin-top: 16px;
}
.ssDetailsSectionborder {
  margin-top: 0px;
  width: 100%;
}

.ssdate {
  color: var(--blue-900);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.ssdate {
  margin-right: 24px;
}
.ssarrow {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

@media (width>992px) {
  .sscontainer {
    padding: 40px;
    margin-top: 0px;
    overflow-y: auto;
  }
  .ssDetailsSection {
    margin-top: 16px;
  }
}

/* #CSS: Dynamic CSS added */
/* .sscontainer401k {
  background-color: unset;
  border: none;
  border-radius: unset;
} */

/* borderless */
.borderless {
  &:hover,
  &:focus-visible,
  &:active {
    outline: 0 !important;
    background: unset !important;
    color: unset !important;
  }
}
