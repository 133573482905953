.pholder
{
    width:100%;
    height:80px;
    background: var(--blue-900);
    position:sticky;
    top:32px;
    z-index:2;
}
.lnav
{
    width:184px;
    display: flex;
    align-items: center;
    justify-content: right;
    height: 100%;
    float: left;
    padding-right: 24px;
}
.lnavclick
{
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    padding-left: 40px;
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    color: var(--gold-25);
    background-image: url("../../assets/images/chevron-left-white.svg");
    background-repeat: no-repeat;
    background-position: left center;
}
.psection
{
    width:calc(100% - 184px);
    display: flex;
    align-items: center;
    height: 100%;
}
.divider
{
    width:2px;
    height:48px;
    opacity: 0.4;
    background: var(--White);
    margin-right: 24px;
}
.profileIcon
{
    background-color: var(--gold-900);
    width: 48px;
    height: 48px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Calibre-R;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    color: var(--black, #000);
}
.pnameh
{
    margin-left: 16px;
    margin-top: 5px;
}

.p1
{
    margin-bottom: auto;
    color: var(--gold-25);

    /* Additional styles/med/text-lg-med */
    font-family: Calibre-R;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 24.3px */
}
.p2
{
    color: var(--gold-25);
    margin-bottom: auto;
    /* Additional styles/reg/text-sm-reg */
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 18.9px */
}