.filterMainC
{
    /*margin-top: -72px;*/
    margin-top: -16px;
    height: calc(100% + 32px);
}
.resultH
{
    display: flex;
    background: var(--gold-25);
    z-index: 1;
    position: relative;
    padding: 0 48px;
}
.filterContainer
{
    background-color: var(--white);
    box-shadow: 0px 2px 16px 0px rgba(177, 150, 97, 0.2);
    width: 324px;
    position: sticky;
    z-index: 1;
    float: left;
    padding: 32px 20px;
    
}
.resultsContainer
{
    width: 100%;
    
}
.searchH
{
    height:54px;
    padding: 48px 0;
}
.titleH
{
    display:flex;
    border-bottom: 1px solid #D8C5A5;
    padding: 16px 0;
    align-items: baseline;
}
.mTitle
{
    color: var(--blue-900);
    font-family: Calibre-R;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 32.4px */
    margin-right: 16px;
    width:auto;
    margin-bottom: 25px;
}
.sTitle
{
    color: var(--blue-900);

    /* Desktop base kit/Body & Label */
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    width:auto;
    margin-bottom: 25px;
}
.topResults
{
   
    height: auto;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--gray-100);
    background: var(--White);
    margin: 40px 0;
    padding: 32px 24px;
}
.iconBlock, .block
{
    
    height: auto;
    margin: 0 0 40px 0;
}
.bTitle
{
    color: var(--blue-900);

    /* Additional styles/sb/text-lg-sb */
    font-family: Calibre-R;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 24.3px */
}
.fTitle
{
    color: var(--gray-600);

    /* Additional styles/reg/text-sm-reg */
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 18.9px */
}
.btext
{
    color: var(--blue-900);
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 21.6px */
    margin:8px 0;
}
.aText
{
    color: var(--blue-500);

    /* Additional styles/reg/text-xs-reg */
    font-family: Calibre-R;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 16.2px */
    cursor: pointer;
}
.iconHolder
{
    display: flex;
}
.icon{
    width:32px;
    height: 32px;
    margin-right: 8px;
}
.ddHolder
{
    margin-left: auto;
}
.filterTitle
{
    color: var(--Black);

    /* Additional styles/med/text-2xl-med */
    font-family: Calibre-R;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 32.4px */
    margin-bottom: 16px;
}
.filterSubTitle
{
    color: var(--Black);

    /* Additional styles/med/text-lg-med */
    font-family: Calibre-R;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 24.3px */

}
.cLable
{
    color: var(--blue-900);

    /* Additional styles/reg/text-base-reg */
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 21.6px */
    float:left;
    margin-left: 16px;
    
}
.cBox
{
    
}
.fLowerBlock
{
    margin-top: 30px;
}
.clear
{
    clear:both;
}
.inputH
{
    margin-top: 24px;
    margin-bottom: 24px;
    display:flex;
}
.filterControlH
{
    display:flex;
    justify-content: space-around;
    margin-top: 40px;
}
.filterApply
{
    color: var(--blue-900, #00012B);
    text-align: center;

    /* Additional styles/reg/text-sm-reg */
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 18.9px */
}
.filterClear
{
    color: var(--blue-900, #00012B);
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-decoration-line: underline;
}
.row
{
    margin-left: 0;
    margin-right: 0;
}
.mobClip
{
    width:169px;
    height:42px;
    padding: 14px 16px;
    align-items: center;
    gap: 2px;
    flex-shrink: 0;
    display: inline-flex;
    border-radius: 100px;
    border: 1px solid var(--blue-900);
}
.mobClipTitle
{
    color: var(--blue-900);
    text-align: center;

    /* reg/text-sm-reg */
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 18.9px */
}
@media (max-width: 768px) {
    .resultH
    {
        padding: 0 16px;
    }
    .mfeDDHolder
    {
        margin-left: auto;
        
    }
    .ddHolder
    {
        margin-left: auto;
        display: flex;
        position: relative;
    }
    .dropdownContainer
    {
        position: absolute;
        top: 212px;
    }
    
}
