.tile {
  background-color: var(--white);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
  position: relative;
}
.tile.clickable {
  cursor: pointer;
}
.tile:hover {
  box-shadow: 0px 2px 16px 0px rgba(177, 150, 97, 0.2);
}
.tile .image {
  width: 100%;
  height: 218px;
  text-align: center;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}
.tile .image img {
  max-width: 100%;
  height: 100%;
}
.tile .content {
  padding: 16px;
}
.tile .content.withCta {
  padding-bottom: 72px;
}
.tile .content h3 {
  margin-bottom: 4px;
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
}
.tile .content p,
.tile .content span {
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  /*
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  */
}
.tile .content .buttonHolder {
  position: absolute;
  bottom: 24px;
  z-index: 1;
}
/*
.tile.clickable .content .buttonHolder {
  pointer-events: none;
}
*/
.buttonHolder > a {
  padding-left: 0 !important;
}
