.myAppHolder {
  border-bottom: 1px solid var(--gold-500, #d8c5a5);
}
.myAppHolder:first-child {
  /* #1.5changes */
  /* padding-bottom: 8px !important; */
  padding-bottom: 12px !important;

  /*label for 1.5 
  padding-bottom: 0px !important;
  */
}
/* .myAppHolder:nth-child(2) {
  margin-bottom: -24px;
} */
.myAppHolder .anchor {
  text-decoration: none;
  cursor: pointer;
  display: block;
}
.myAppHolder .anchor:hover {
  background-color: var(--gray-50);
}
.myAppHolder .myApp {
  display: flex;
  height: 100%;
  gap: 16px;
  align-items: center;
  /* border-bottom: 1px solid var(--gold-500, #d8c5a5); */
  padding: 24px 0;
  min-height: 100px;
}
.myAppHolder .myApp h3 {
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
  /*label for 1.5 
  line-height: 32px;
  */
}
.myAppHolder .myApp .description {
  color: var(--Gray-600, #5d5d5d);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.iconWrapper
{
  width:72px;
  height:72px;
  padding: 8px;
  background-color: var(--white);
  border:1px solid var(--gray-100);
  display: flex;
  align-items: center;
  border-radius: 8px;
}
.logoHolder
{
  width:64px;
  height:64px;
  
  border-radius: 8px;
  background-color: var(--white);
  
  background-size: contain;
}
.myApp img {
  width: 72px;
  height:72px;
}
  
.appListContainer {
  display: grid;
}
.myAppComp > [class^="HeroSubHeading_heroSubHeading__"] {
  /* margin-bottom: 8px; */
  /*label for 1.5 
  margin-bottom: 0px;
  */
}

@media (min-width: 768px) {
  /* might needs to comment /*label for 1.5  */
  /* .myAppComp > [class^="HeroSubHeading_heroSubHeading__"] {
    margin-bottom: 32px;
  }
  .myAppHolder:first-child {
    padding-bottom: 12px !important;
  } */

  .myAppHolder .myApp {
    /*label for 1.5 
    padding: 0px 20px 0px 20px;
    */
    min-height: 100px;
    padding: 24px 20px 24px 20px;
  }
  .appListContainer {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3rem;
  }
}
@media (min-width: 1024px) {
  /* .myAppHolder:first-child {
    margin-bottom: -32px !important;
  }
  .myAppHolder:nth-child(2) {
    margin-bottom: -32px !important;
  } */
}
