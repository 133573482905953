.timeOffBalanceLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #e8e8e8;
  color: var(--Gray-600, #5d5d5d);
  font-size: 14px;
}

.timeOffBalanceName {
  color: var(--Gray-600, #5d5d5d);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  padding-right: 18px;
}

.timeOffBalanceArea {
  display: flex;
  align-items: baseline;
  flex-wrap: nowrap;
}

.timeOffBalanceArea span:nth-child(1) {
  color: var(--Blue-900, #00012b);
  padding-right: 6px;
  /* Additional styles/med/text-xl-med */
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 27px */
}

.timeOffHeader {
  color: var(--Gray-600, #5d5d5d);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  padding-top: 20px;
  /* border-top: 2px solid var(--gray-50); */
  padding-bottom: 20px;
  border-bottom: 1px solid var(--gray-50);
  display: flex;
  align-items: center;
}
.timeOffHeader > div:nth-child(1) {
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 27px */
  min-width: 55px;
}

@media (max-width: 700px) {
  .timeOffBalanceArea {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: flex-end;
    max-width: 80px;
  }
}
