.oneColumn h3 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  margin-bottom: 16px;
}
.oneColumn p,
.oneColumn li,
.oneColumn span {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  margin: 0;
}
.oneColumn .buttonHolder {
  margin-top: 24px;
}
.oneColumn img {
  width: 100%;
}

@media(max-width: 768px) {
  .oneColumn .buttonHolder  {
    display: flex;
    flex-direction: column;
  }
}