.multiselectContainer {
    /*position:absolute;*/
    width: 100%;
}

.resultHolder {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-height: 190px;
    overflow: auto;
    scrollbar-width: thin;
    padding: 8px 0px 8px 0px;
    gap: 8px;
    border-radius: 0px 0px 8px 8px;
    /*opacity: 0px;*/
    background-color: var(--white);
    position: relative;
}

.selectionHolder {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
}

.searchInputHolder {
    width: 100%;
    display: inline-flex;
    border-radius: 8px 8px 0px 0px;
    /* border: 1px solid var(--gray-400); */
    background-color: var(--white);
    align-items: center;
    grid-gap: 3px;
    /* padding: 24px; */
}

.searchInput {
    color: var(--Gray-300, #A2A2A2);
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    width: 90%;
    height: 56px;
    background-color: var(--white);
    text-align: left;
    border: none;
    margin-left: 24px;
}

.optionItem {
    width: 100%;
    
    height:auto;
    padding: 5px 12px 5px 12px;
    gap: 8px;
    /*opacity: 0px;*/
    cursor: pointer;
    display: flex;
    align-items: center;
}

.optionItem:hover {
    background-color: var(--blue-25);
}

.check {
    width: 24px;
    height: 24px;
}

.chip {
    width: auto;
    height: 32px;
    border-radius: 20px;
    /*opacity: 0px;*/
    display: flex;
    align-items: center;
    background-color: var(--white);
    font-family: Calibre-R;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: left;
    color: var(--gray-900);
    padding: 8px;
    cursor: pointer;
    gap: 8px;
    margin-bottom: 8px;
}

.chipCross svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.empName {

    font-family: Calibre-R;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: left;
    color: var(--Blue-900)
}

.otherDetails {

    font-family: Calibre-R;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: left;
    color:var(--gray-400)
}