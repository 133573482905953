.global_nopadding
{
    padding:0;
}
.global_justifyspacebetween
{
    display: flex;
    gap: 8px;
    justify-content: space-between;
    margin-bottom: 16px;
}
.global_justifyspacebetween_new
{
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
}
.global_headercontainer
{
    border-bottom:1px solid var(--gold-500);
    padding:16px 0px 25px;
   
    margin:0;
    padding:0;
    margin-left:16px;
    width:100vw;
}
.global_headercontainer_att
{
    border-bottom:1px solid var(--gold-500);
    padding:16px 0px 25px;
    width:100vw;
    margin:0;
    padding:0;
    margin-left:16px;
}
.global_headercontainer_tf
{
    border-bottom:1px solid var(--gold-500);
    padding:0px 0px 16px;
    margin:0;
}
.padding-left-zero
{
    padding-left: 0;
}
.global_buttonsection
{
    display: flex;
    justify-content: flex-end;
    padding:0;
    margin-bottom: 24px;
}
.global_headertext
{
    color: var(--blue-900);
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 40.5px */
    margin-bottom:16px;
}
.global_subheadertext,.global_subheadertext-loa{
    color: var(--blue-900);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 21.6px */
    padding-bottom:24px;
}

.global_button
{
    display: inline-flex;
    align-self: flex-end;
    align-items: center;
    padding: 16px;
    height:56px;
    background-image:linear-gradient(225deg, #373AE5 0%, #292CB7 50%);
    color:#fff !important;
}
.global_button:active, .global_button:hover
{
    color: white;
}
main>section>section:nth-child(2)>div>section:nth-child(2)>div:nth-child(2){
    
    width: 38%;
    padding: 40px;
    border-radius: 8px;
border: 1px solid var(--gray-100, #D1D1D1);
background: var(--White, #FFF);
}
.attendance-history{
    min-width: 39.25% ;
    padding-right: 48px;
    margin-left: 31px;
    padding: 40px;
    border-radius: 8px;
border: 1px solid var(--gray-100, #D1D1D1);
background: var(--White, #FFF);
height:fit-content;
    height: fit-content;
}
.loa-request{
    width: fit-content;
    height:fit-content;
    padding: 40px;
    margin-left:40px;
    border-radius: 8px;
    border: 1px solid var(--gray-100, #D1D1D1);
    background: var(--White, #FFF);
    padding-right: 48px;
}
.api-error{
    margin-bottom:40px;
}
.api-error>div{
margin-left:0;
}
.api-error>div>div:nth-child(2){
    width:100%;
    gap:16px;
    margin-top:24px;
    padding-left:0;
    padding-right:0;
  }
.timeoff_section
{
    /* background:white;
    border:1px solid var(--gray-100);
    border-radius:10px; */
    margin:0;
    margin-top:48px;
    /* padding:40px 40px 24px; */
}
.right-rail-heading{
    color: var(--blue-900, #00012B);
/* Additional styles/med/text-2xl-med */
font-family: Calibre-R;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 135%; 
margin-left:40px;
}
.timeoff_status
{
    border-top:1px solid var(--gold-500);
    
}
.need-help{
    /* margin-left:1px; */
    margin-top: 40px;
    margin-left: 40px;
}

.timeoff_header
{
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    margin-bottom:24px;
    color: var(--blue-900);
}
.timeoff_header_loa{
    font-style: normal;
    font-size: 24px;
    font-weight: 500;
    line-height: 135%;
    /* margin-bottom:24px; */
    color: var(--blue-900);
}
.attendance_nopending{
    color: var(--blue-900, #00012B);
/* Additional styles/med/text-base-med */
font-family: Calibre-R;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 135%;
margin-bottom:24px;
}
.timeoff_header_attendance{
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    margin-bottom:2px;
    color: var(--blue-900);
}
.history_header
{
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    margin-bottom:2px;
    /* margin-bottom:24px; */
    color: var(--blue-900);
}
.timeoff_header_new{
    color: var(--blue-900, #00012B);

/* Additional styles/med/text-2xl-med */
font-family: Calibre-R;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 0.5; /* 32.4px */
}
.timeoff-balance{
    color: var(--gray-700, #464646);

/* Additional styles/reg/text-sm-reg */
font-family: Calibre-R;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 0; /* 18.9px */
margin-bottom: 30px;
}
.timeoff_section .timeoff_header:nth-of-type(2)
{
    margin-bottom:16px;
}
.timeoff_leftsection_attendance
{
    border-radius: 8px;
border: 1px solid var(--gray-100, #D1D1D1);
background: var(--White, #FFF);
    
    padding: 40px;
    margin-right: 20px;
    margin-left:16px;
}
.timeoff_leftsection_loa
{
    border-radius: 8px;
border: 1px solid var(--gray-100, #D1D1D1);
background: var(--White, #FFF);
    
    padding: 40px;
    margin-right: 20px;
    margin-left:16px;
}
.timeoff_leftsection
{
  
    background: white;
    border: 1px solid;
    border-radius: 10px;
    padding: 40px;
    margin-right: 20px;
}
.timeoff_line{
    width: auto;
    height: 1px;
    background: var(--gold-500, #D8C5A5);
}
.timeoff_statusheader{
    color: var(--blue-900);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; 
    word-break: break-word;
}
.timeoff_statussubheader
{
    color: var(--gray-600);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
}
.timeoff_statussubheader_tf
{
    color: var(--blue-900, #00012B);
/* Additional styles/med/text-base-med */
font-family: Calibre-R;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 135%;
margin-top:24px;
padding-left:0px !important;
}
.allotment_header{
    color: var(--gray-700, #464646);
/* Additional styles/reg/text-sm-reg */
font-family: Calibre-R;
font-size: 14px;
font-style: normal;
font-weight: 400;

line-height: 135%; 
}
.overlimit_heading{
    align-self: stretch;
    color: var(--blue-900, #00012B);
/* Additional styles/med/text-base-med */
font-family: Calibre-R;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 135%;
}
.overlimit_content{
    align-self: stretch;
    color: var(--blue-900, #00012B);
/* Additional styles/reg/text-base-reg */
font-family: Calibre-R;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 135%;
}
.overtime_attendance{

padding: 16px;
align-items: flex-start;
gap: 8px;
border-radius: 8px;
margin-bottom: 5px;
border: 1px solid var(--alerts-red-600, #D20B02);
background: var(--alerts-red-25, #FFF3F3);
margin-top:16px;
}
.timeoff_statussent{
    color: var(--gray-600);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; 
}
.timeoff_statusheader_new{
    color: var(--blue-900, #00012B);

/* Additional styles/med/text-base-med */
font-family: Calibre-R;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 135%; /* 21.6px */
}
.timeoff_statussubheader_new{
    color: var(--gray-600, #5D5D5D);

/* Additional styles/reg/text-sm-reg */
font-family: Calibre-R;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 18.9px */
}
.timeoff-available{
   
    color: var(--blue-900, #00012B);

    /* Additional styles/med/text-base-med */
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 21.6px */
}
.timeoff-assured{
    color: var(--gray-600, #5D5D5D);

    /* Additional styles/reg/text-sm-reg */
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 18.9px */
}
.global_learnmoreheader{
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; 
    margin-top:32px;
}
.global_learnmorepara
{
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    margin-top:16px;
    color: var(--blue-900);
}
.global_learnmorebtn
{
    display: flex;
    height: 40px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border:1px solid var(--blue-900);
    color: var(--blue-900);
    text-align: center;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 18.9px */
    background-color: transparent;
    margin-top:24px;
}
.global_listpadding
{
    padding-left:1rem;
}
.global_listpadding li
{
    color: var(--blue-900);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; 
}
.timeoff_section .progress
{
    height:12px;
    border-radius:24px;
    background-color: var(--gray-100);
    margin-bottom:16px;
}
.timeoff_section .progress-bar
{
    background-color: var(--secondary-teal-400);
}
.timeoff_minmax {
   color: var(--blue-900);
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500; 
    
    line-height: 135%; /* 27px */
}
.timeoff_attendance{
    display: inline-flex;
    align-items: baseline;
    gap: 8px;
    margin-top:32px;
    margin-bottom:24px;
}
.attendance_history{
    display: inline-flex;
    gap: 4px;
}
.history_subheader{
    color: var(--gray-700, #464646);
    /* Additional styles/reg/text-sm-reg */
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; 
    margin-bottom: 16px;
}
.timeoff_count{
    color: var(--blue-900);
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
}
.timeoff_days 
{
    color: var(--gray-600);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    /* margin-bottom:24px; */
}
.timeoff_history_qty{
    color: var(--blue-900, #00012B);
text-align: right;
/* Additional styles/med/text-sm-med */
font-family: Calibre-R;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 135%; /* 18.9px */
}
.timeoff_history_unit{
    color: var(--gray-600, #5D5D5D);
/* Additional styles/reg/text-sm-reg */
font-family: Calibre-R;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 135%;
}
.attendance-border{
    border-top: 1px solid var(--gold-500);
    margin-bottom: 16px;
    
}
.attendance-border-bottom{
    border-bottom: 1px solid var(--gold-500);
    margin-bottom: 16px;
    
}
.timeoff_disclaimer
{
    color: var(--gray-600);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    margin-bottom:5px;
    margin-top: 20px;
}
.timeoff_ptolink
{
    color: var(--gray-600);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    text-decoration: underline;
    cursor: pointer;
    margin-top:16px;
}
.timeoff_ptolink_loa{
    color: var(--gray-600);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    text-decoration: underline;
    cursor: pointer;
    margin-top:16px;
    padding-left:0px !important;

}
.links_container
{
    background-color: white;
    border:1px solid var(--gray-100);
    border-radius: 8px;
    padding:40px;
    margin-left:40px;
}
.links_text
{
    color: var(--blue-900);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
}
.timeoff_linkssection
{
    /* margin-top: 40px; */
}
.timeoff_statusindicator img
{
    top: -2px;
    position: relative;
    right: 3px;
}
.timeoff_pendingstatus-pending
{
    color: var(--alerts-orange-800);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    white-space: nowrap;
}
.timeoff_pendingstatus-approved
{
    color: var(--alerts-green-700);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    white-space: nowrap;
}
.timeoff_nopending
{
    justify-content: center;
    display: flex;
    padding: 40px;
    border-top: 1px solid var(--gold-500);
    color: var(--blue-900, #00012B);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; 
}
.no_timeoff
{
    justify-content: left;
    display: flex;
    padding: 40px;
    margin:0;
    padding:0;
    margin-top:32px;
    margin-bottom:32px;
    color: var(--blue-900, #00012B);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; 
}

.global_learnmorecontainer
{
    margin-bottom:40px;
    margin-left:16px;
    margin-top:0;
}
.global_learnmorecontainer>div>p{
    margin-bottom:10px;
}
.global_resourcesheader{
    color: var(--blue-900);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: bold;
    font-weight: 500;
    line-height: 135%;
    margin-top:8px;
}
.links_header{
    color: var(--blue-900);
    font-family: Calibre-R;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    margin-bottom:16px;
}
.links_link
{
    display: flex;
    flex-direction: column;
    gap:24px;
}
.links_link a
{
    color:var(--blue-link);
}
.links_link img
{
    margin-left:8px;
}
.timeoff_main
{
    padding:10px 16px;
}
@media (min-width: 1024px) {
    /* .timeoff_main
    {
        padding:0 40px;
    } */
    
}
@media (max-width: 968px) {
    .attendance-history{
        /* width: fit-content; */
        min-width: 0%;
        margin-top: 32px;
        padding: 40px;
        border-radius: 8px;
    border: 1px solid var(--gray-100, #D1D1D1);
    background: var(--White, #FFF);
    margin-left:0px;
    margin-right:0px;
    height:fit-content;
    
    
    }
    .loa-request{
        width: 100%;
        min-width: 0%;
        margin-top: 32px;
        padding: 40px;
        border-radius: 8px;
    border: 1px solid var(--gray-100, #D1D1D1);
    background: var(--White, #FFF);
    margin-left:0;
    margin-right:16px;
    height:fit-content;
    }
    .timeoff_leftsection_attendance{
        margin-right:0px;
        margin-left:0px;
    }
    .timeoff_leftsection_loa{
        margin:0;
        width:100%;
        margin-top:24px;
    }
    .timeoff_header_loa{
        margin-top:20px;
    }
    main{
        flex-direction: column;
    }
    .global_headertext,.global_subheadertext{
        margin-left:16px;
        margin-right:16px;
    }
    .global_subheadertext-loa{
        padding-bottom:0;
        margin-left:16px;
        margin-right:16px;
    }
    .global_headersection_att
    {
        padding: 0;
        margin-bottom:24px;
        padding-bottom: 24px;
        border-bottom:1px solid var(--gold-500);
    }
    .global_headersection
    {
        padding: 0;
        /* margin-bottom:24px; */
        padding-bottom: 24px;
        border-bottom:none;
        /* border-bottom:1px solid var(--gold-500); */
    }
    .global_button
    {
        justify-content: center;
        margin-top:0;
        width: 100%;
        height:40px;
        border-bottom:1px solid var(--gold-500);
    }
    .global-border-bottom{
        border-bottom:1px solid var(--gold-500);
    }
    .global_headercontainer,.global_headercontainer_att
    {
        border-bottom: none;
        width:100%;
       
    }
    .timeoff_section_sup
    {
        margin-top: 32px;
    }
    .timeoff_section
    {
        margin-top: 32px;
    }
    .global_headercontainer,.global_headercontainer_att
    {
        padding-bottom:0px;
        margin-left:0;
    }
    .links_container
    {
        padding:24px;
    }
    .timeoff_section
    {
        padding:24px 16px 0px;
    }
    .timeoff_leftsection {
        margin-top: 20px;
    border: 1px solid;
    border-radius: 10px;
    padding: 20px;
    background: white;
    }
    .timeoff_rightsection
    {
        margin-top: 20px;
        border: 1px solid;
        border-radius: 10px;
        padding: 20px;
        background: white;
    }
    .timeoff_section .progress
    {
        height:16px;
        border-radius: 4px;
    }
    .global_learnmorecontainer{
        margin-left:16px;
        margin-right:16px;
        margin-top:0 !important;
        
    }
    .timeoff_linkssection{
        margin-bottom:0px;
    }
}

/* suprio  */
.f-left
{
    float:left;
}
.f-right
{
    float:right;
}

.c-both
{
    clear:both;
}
.m-b-20
{
    margin-bottom: 20px;
}
.circle
{
    border-radius: 50%;
    width: 34px;
    height: 34px;
    padding: 5px;
    background: #fff;
    border: 1px solid grey;
    color: #000;
    text-align: center;
    display: block;
}
.spreadOut
{
    padding: 2px 0 10px 22px;
}
.bulletHolder
{
    padding: 10px 0 10px 0;
}
.p-t-10
{
    padding-top: 10px;
}
.p-t-40
{
    padding-top: 40px;
}
.p-t-15
{
    padding-top: 15px;
    color: var(--blue-900);
}
.p-r-20
{
    padding-right: 20px;
}
.p-l-20
{
    padding-left: 20px;
}
.m-b-40
{
    margin-bottom: 40px;
}
.open-case-btn
{
    width: auto;
    justify-content: center;
    margin-top:24px;
    height:40px;
    display: inline-flex;
    align-self: flex-end;
    align-items: center;
    padding: 16px;
    height:56px;
    background-image:linear-gradient(225deg, #373AE5 0%, #292CB7 50%);
    color:#fff ;
}
.open-case-btn:active, .open-case-btn:hover
{
    color: white;
}
.howto-rightrail{
    /* margin-left:30px; */
}
.question-mark-img{
    border-style:none !important;
    margin-left:10px;
    margin-left:30px;
    margin-top:40px;
}
.question-mark-img>svg{
    width:40px !important;
    height:40px !important;
}
.left-arrow{
    border-style:none !important;
    /* color:#373AE5 !important; */
}
.no-left-pad
{
  padding-left: 0;
}
.no-right-pad
{
  padding-right: 0;
}
.global-top-btn-holder
{
  display: flex;
  justify-content: right;
  height: 100%;
}
.timeoff_section_sup
{
    background:white;
    border:1px solid var(--gray-100);
    border-radius:10px;
    margin:0;
    margin-top:48px;
    padding:40px 40px 24px;
}
@media (max-width: 768px)
{
    .space-top
    {
        padding-top: 20px;
    }
    .bHolderContainer
    {
        border-top: none;
    }
   .bottomBorder
   {
       border-bottom: 1px solid var(--gold-500);
       margin-bottom: 0;
       padding-bottom: 20px;
   }
   @media (width<=992px){
    .spreadOut{
        padding: 2px 0 10px 5px;

    }
    .global_learnmoreheader{
        margin-top:32px;
    }
    .question-mark-img{
        padding-left:0 !important;
        margin-left:0;
        margin-top:0;
    }
    .question-mark-img>svg{
        width:40px !important;
        height:40px !important;
    }
    .global_justifyspacebetween_new{
        display: block;
    }
    .timeoff-available{
        display:flex !important;
        gap:40px;
    }
    .howto-rightrail{
        margin-left:0;
        margin-bottom:16px;
    }
    .bottomBorder
   {
       border-bottom: 1px solid var(--gold-500);
       margin-bottom: 0;
       padding-bottom: 20px;
   }
   .attendance_nopending{
    margin-bottom:24px;
   }
   .space-top
   {
       padding-top: 20px;
   }
   .bHolderContainer
   {
       border-top: none;
   }
  .bottomBorder
  {
      border-bottom: 1px solid var(--gold-500);
      margin-bottom: 0;
      padding-bottom: 20px;
  }
  
   }
    
}

