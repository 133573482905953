.cHolder
{
    width:100%
}
.header
{
    
    font-family: Calibre-R;
    font-size: 24px;
    font-weight: 500;
    line-height: 32.4px;
    text-align: left;
    color:var(--blue-900)
}
.bHolder
{
    margin-top:16px;
    font-family: Calibre-R;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.3px;
    text-align: left;
    color:var(--blue-900);
    margin-bottom: 16px;

}
.dummyImg
{
    max-width: 100%;
    height:auto;
    visibility:hidden;
}
.tileImg
{
    
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: contain !important;
    background-position: 0px 0px !important;
    margin-bottom: 20px;
}