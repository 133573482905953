.contentCardFrame {
  margin-bottom: 32px;
}
.cardContainerInner {
  padding: 24px;
}
.cardContainerInner h3 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
  margin-bottom: 16px;
}
.cardContainerInner p {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  margin-bottom: 24px;
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .cardContainerInner {
    padding: 40px;
  }
  .contentCardFrameTypeOne {
    margin-bottom: 40px;
    margin-top:40px;
  }
}
.contentCardFrame 
.buttonHolder {
margin-top: 21px;
}
