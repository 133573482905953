.layoutOneHolder {
  padding: 0 16px;
}
.layoutOne > section {
  margin: 32px 0;
}
.layoutOne > section:first-child {
  margin-top: 0;
}
@media (min-width: 1024px) {
  .layoutOneHolder {
    padding: 0 48px;
  }
  .layoutOne > section {
    margin: 48px 0;
  }
}
