.quickActionsHolder h3 {
  color: var(--blue-900);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  padding: 0;
  margin-bottom: 16px;
}
.actionSection {
  display: flex;
  /* border: 1px solid var(--gray-100); */
  border-radius: 8px;
  /* box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15); */
  flex-wrap: wrap;
  overflow: hidden;
  /* gap:10px; */
  gap:10px;
    padding-bottom: 16px;
    padding-left:2px;
    padding-top:8px;
    padding-right:2px;
}

.actionTab.twoCol:not(:nth-last-child(1)),
.actionTab.threeCol:not(:nth-last-child(1)) {
  /* border-bottom: var(--gray-100) solid 1px; */
}

.actionTab.fourCol {
  flex: 1 1 45%;
}
.actionTab.fourCol:not(:nth-child(2n)) {
  /* border-right: var(--gray-100) solid 1px; */
}
.actionTab.fourCol:nth-last-child(n + 3) {
  /* border-bottom: var(--gray-100) solid 1px; */
}
@media (max-width: 426px) {
  .actionTab.fourCol,.actionTab.twoCol,.actionTab.threeCol {
    flex: 1 1 98%;
    margin-bottom: 2px;
  }
}
@media (min-width:1200px)
{
  .actionSection{
    gap:10px;
    padding-bottom: 16px;
    padding-left:2px;
    padding-top:8px;
    padding-right:2px;
}
.actionTab.twoCol:not(:nth-last-child(1)),
  .actionTab.threeCol:not(:nth-last-child(1)),
  .actionTab.fourCol:not(:nth-child(2n)),
  .actionTab.fourCol:nth-last-child(n + 3) {
    border-right: none;
    border-bottom: none;
  }
  .actionTab.twoCol {
    flex: 1 1 42%;
  }
  .actionTab.twoCol:not(:nth-child(2n)) {
    border-right: var(--gray-100) solid 1px;
  }
  .actionTab.twoCol:nth-last-child(n + 3) {
    border-bottom: var(--gray-100) solid 1px;
  }
  
  .actionTab.threeCol {
    flex: 1 1 30%;
  }
  .actionTab.threeCol:not(:nth-child(3n)) {
    border-right: var(--gray-100) solid 1px;
  }
  .actionTab.threeCol:nth-last-child(n + 4) {
    border-bottom: var(--gray-100) solid 1px;
  }
  .actionTab.fourCol {
    flex: 1 1 42%;
  }
  .actionTab.fourCol:not(:nth-child(4n)) {
    border-right: var(--gray-100) solid 1px;
  }
  .actionTab.fourCol:nth-last-child(n + 5) {
    border-bottom: var(--gray-100) solid 1px;
  }
}
@media (min-width: 1440px) {

  .actionSection{
      gap:10px;
      padding-bottom: 16px;
      padding-left:2px;
      padding-top:8px;
      padding-right:2px;
  }
 
  .actionTab.twoCol:not(:nth-last-child(1)),
  .actionTab.threeCol:not(:nth-last-child(1)),
  .actionTab.fourCol:not(:nth-child(2n)),
  .actionTab.fourCol:nth-last-child(n + 3) {
    border-right: none;
    border-bottom: none;
  }
  .actionTab.twoCol {
    flex: 1 1 42%;
  }
  .actionTab.twoCol:not(:nth-child(2n)) {
    border-right: var(--gray-100) solid 1px;
  }
  .actionTab.twoCol:nth-last-child(n + 3) {
    border-bottom: var(--gray-100) solid 1px;
  }
  
  .actionTab.threeCol {
    flex: 1 1 30%;
  }
  .actionTab.threeCol:not(:nth-child(3n)) {
    border-right: var(--gray-100) solid 1px;
  }
  .actionTab.threeCol:nth-last-child(n + 4) {
    border-bottom: var(--gray-100) solid 1px;
  }
  .actionTab.fourCol {
    flex: 1 1 20%;
  }
  .actionTab.fourCol:not(:nth-child(4n)) {
    border-right: var(--gray-100) solid 1px;
  }
  .actionTab.fourCol:nth-last-child(n + 5) {
    border-bottom: var(--gray-100) solid 1px;
  }
}
