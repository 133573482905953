.offerHolderSub {
  width: 100%;
}
.offerHolder::-webkit-scrollbar {
  display: none;
}

.toptextholder {
  /* display: flex; */
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 16px;
  /* padding: 0 16px; */
}
.subTextHolder {
  display: flex;
  width: 100%;
  /* position: relative; */
}
.offerTitle {
  color: var(--blue-900, #00012b);

  /* Additional styles/med/text-2xl-med */
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  flex:1 1 auto;
}
.offerLink {
  /* position: absolute; */
  /*left:var(--viewAll-left-val);*/
  color: var(--blue-900, #00012b);
  text-align: right;
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  white-space: nowrap;
  /* width: 65px; */
  left: calc(100% - 65px);
}
.offerLink a {
  color: var(--blue-900, #00012b);
  font-weight: 400;
}
.offerLink a:hover {
  color: var(--blue-900, #00012b);
  font-weight: 500;
}
.allCardHolder {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.imageHolder {
  display: flex;
  /*padding: 0px 16px;*/
  /* align-items: flex-start; */
  justify-content: left;
  gap: 24px;
  border: 1px solid var(--gray-100);
  border-radius: 8px;
  position: relative;
}
.offerCard {
  display: flex;
  /*width: 324px;*/
  /* width: 240px; */
  /* height: 330px; */
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--white, #fff);
  padding-bottom: 24px;
  width: 100%;
}
.offerCard.salaried {
  width: 100%;
}
.offerHolderSub.salaried {
  width: 100%;
}
.cardimg {
  /* width:249px; */
  /* width:240px; */
  height: 165px;
  height: auto;
  border-radius: 8px 8px 0px 0px;
  padding: 24px;
}
.cardimg img {
  width: 100%;
  height: auto;
}
.cardSubHolder {
  padding: 16px;
}
.cardDate {
  color: var(--gray-600, #5d5d5d);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  margin-bottom: 10px;
}
.cardTitle {
  color: var(--blue-900, #00012b);
  /* Additional styles/med/text-xl-med */
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 27px */
}
.cardBtn {
  display: flex;
  gap: 8px;
  padding-top: 8px;
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Mgmnewsmain {
  display: flex;
  gap: 40px;
}
.cards {
  padding: 24px 24px;
  border-radius: 8px;
  margin: 0;
  /* padding:0; */
}
.cardContainerInner {
  padding: 24px;
}
.date {
  color: var(--gray-600, #5d5d5d);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  margin-top: 10px;
}
.title {
  color: var(--blue-900, #00012b);
  /* Additional styles/med/text-xl-med */
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 27px */
}
.description {
  color: var(--blue-900, #00012b);

  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}

.cardalignment {
  
  margin-bottom: 40px;
}
.learnmorepara {
  color: var(--blue-900);
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  position: absolute;
  top: calc(100% - 38px);
  font-size: 1rem;
  margin-top: 16px;
}

.learnmorebtn {
  align-items: center;
  background-color: initial;
  border: 1px solid var(--blue-900);
  border-radius: 6px;
  display: flex;
  font-size: 0.9rem;
  gap: 8px;
  height: 40px;
  justify-content: center;
  /* margin-top: 24px; */
  padding: 10px 16px;
  text-align: center;
  color: var(--blue-900);
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  position: absolute;
  top: calc(100% - 48px);
  width: max-content;
}
.learnmorebtn:hover {
  outline: #00012b 1px solid;
}

@media (min-width: 769px) {
  .allCardHolder {
    width: 100%;
    gap: 40px;
  }
  .imageHolder {
    width: 100%;
  }
  .cardHolder {
    width: 30%;
  }
  .offerCard {
    width: 100%;
  }
  .cardimg {
    width: 100%;
    padding: 24px;
    
  }
  .cardimg img{
    border-radius: 8px;
  }
  .Mgmnewsmain {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .imageHolder {
    margin-right: 5px;
    width: 100%;
    justify-content: center;
  }
  .cardSubHolder {
    padding: 24px;
  }
  .cardimg img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  .Mgmnewsmain {
    justify-content: left;
  }
  .cardBtn {
    display: none;
  }
  .allCardScrollingContainer::-webkit-scrollbar {
    display: none;
  }
  .allCardScrollingContainer {
    overflow-x: auto;
    margin-bottom: 0.5rem;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .toptextholder {
    margin: 32px 0 16px 0;
  }
}
