.helpInfoContainer {
  margin-bottom: 40px;
}
.helpCenterContainer {
  border-bottom: 1px solid var(--gold-500);
  padding: 16px 0px 25px;
  margin: 0;
  overflow: hidden;
  border: none;
  margin-top: 16px;
}
.helpCenterHeading {
  color: var(--blue-900);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 40.5px */
  margin-bottom: 16px;
  text-align: center;
  font-family: "Calibre-R";
  /*white-space: nowrap;*/
  margin: 0;
}
.helpCenterSubtext {
  color: var(--blue-900);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-family: "Calibre-R";
  text-align: center;
  margin: 0;
  line-height: 135%; /* 21.6px */
}
.helpCenterButton {
  display: inline-flex;
  background-image: linear-gradient(225deg, #373ae5 0%, #292cb7 50%);
  color: #fff !important;
  border-radius: 0.5rem;
  padding: 10px 20px;
}
.helpCenterBtnHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 25px;
}
.bufferSide {
  padding: 0 16px;
}
@media (min-width: 1024px) {
  .bufferSide {
    padding: 0 48px;
  }
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .helpCenterContainer {
    display: inline-block;
  }

  .helpInfoContainer {
    display: flex;
    margin-top: 0px;
  }
  .helpCenterContainer {
    flex: 1;

    text-align: center;
    border: none;
    margin-top: 24px;
  }
  .helpCenterBtnHolder {
    height: 40px;
    margin-top: 20px;
    overflow: visible;
  }
  .helpCenterHeading {
    text-align: center;
  }
}
