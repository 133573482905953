.mainBox {
  max-width: none;
  display: block;
}
.loginForm {
  width: 90%;
  max-width: 400px;
  margin-top: 56px;
  margin-bottom: 56px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 56px;
}
.loginForm h2 {
  color: var(--blue-900);
  text-align: center;
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 48.6px */
  margin-bottom: 24px;
}
.loginForm > div {
  margin-bottom: 32px;
}
.loginForm .submitBtn {
  margin-top: 56px;
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .mainBox {
    display: flex;
  }
  .loginForm {
    width: auto;
    max-width: 500px;
    margin-top: 63px;
  }
  .loginForm h2 {
    margin-bottom: 56px;
    font-size: 36px;
  }
}
