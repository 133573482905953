.sessionTimeoutHolder {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(116, 116, 116, .8);
    display: flex;
    z-index: 101;
    justify-content: center;
    align-items: center;
  }
  
  .wBlock {
    display: flex;
    width: 584px;
    height: 385px;
    padding: 80px 40px 40px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--Gray-100, #D1D1D1);
    background: var(--White, #FFF);
  }
  
 .sBtn {
    display: inline-flex;
    height: 40px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 6px;
    background: linear-gradient(225deg, #373AE5 14.64%, #292CB7 50%);
    color: var(--dark-base-base-high-100-white);
    cursor: pointer;
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    /* 18.9px */
  }
  
  .timer {
    color: #9F0000;
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  }
   .iconH
  {
    width:135px;
    height: 135px;
  }
   .iconH img
  {
    width:100%;
    height: 100%;
  }
  .heading
  {
    color: var(--Blue-900, #00012B);
    text-align: center;
  
    /* Additional styles/med/text-lg-med */
    font-family: Calibre-R;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 24.3px */
  }
  .subheading
  {
    color: var(--Blue-900, #00012B);
  text-align: center;
  
  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  }
  @media (max-width: 768px) {
    .wBlock {
      width: 80%;
      height: auto;
    }
  
  }
  