.leadershipMessage {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.leadershipMessage > div {
  flex: 0 0 48%;
}
.leadershipMessage > div:nth-child(2) {
  padding: 0 16px 16px;
}
.leadershipMessage img {
  width: 100%;
  height: 298px;
}
.leadershipMessage h3 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  margin-bottom: 16px;
}
.leadershipMessage h4 {
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  margin-bottom: 8px;
}
.leadershipMessage .innerHtml {
  height: 45px;
  overflow: hidden;
}
.leadershipMessage p {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  margin: 0;
  height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.leadershipMessage .buttonHolder {
  margin-top: 24px;
}
.leadershipMessage .buttonHolder button {
  margin: 1px;
}
.imgHolder {
  height: 298px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .leadershipMessage {
    flex-direction: row;
    gap: 32px;
  }
  .leadershipMessage > div:nth-child(2) {
    padding: 32px 48px 32px 0;
  }
  .leadershipMessage h3 {
    font-size: 24px;
  }
  .leadershipMessage h4 {
    font-size: 16px;
  }
}
