.caseItem {
  display: flex;
  flex: 50%;
  padding: 16px 24px;
  justify-content: space-between;
  align-items:flex-start;
  /* border: 1px solid var(--gray-100); */
    border-radius: 8px;
    background-color: #ffffff;
}
.caseItem .information {
  display: flex;
  gap: 16px;
}
.caseItem .alert {
  border-radius: 4px;
  padding: 8px 16px;
}
.caseItem .alert p {
  color: inherit;
  display: inline;
  font-size: 14px;
}
.caseItem .alert p.count {
  font-size: 16px;
  font-weight: 600;
  padding-right: 0.5rem;
}
.caseItem .alert.alertGreen {
  background: var(--alerts-green-25);
  color: var(--alerts-green-800, #006732);
}
.caseItem .alert.alertRed {
  background: var(--alerts-red-25);
  color: var(--alerts-red-800, #891207);
}
.buttonHolder a {
  color: var(--blue-900);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.caseItem .title h3 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
}
.caseItem .title p {
  margin: 0;
  color: var(--gray-600);
  font-size: 12px;
}

a.buttonViewAll {
  padding-left: 2px;
}
a.buttonViewAll:hover {
  padding-left: 0;
  color: var(--blue-500);
  font-weight: 500;
}

@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .caseItem {
    justify-content: space-between;
  }
  .caseItem:first-child {
    /* border-right: 1px solid var(--gray-100); */
  }
}
