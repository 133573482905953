
.tileSpace {
  margin: 0 10px 0 0;
}

.tileSpaceL {
  margin: 0 0 0 10px;
}

.tileSpaceM {
  margin: 0 5px 0 5px;
}
.plainTiles {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
}
.plainTilesMob {
  display: block;
}

.noEvents {
  padding: 1rem 0;
}

