.contactDetails {
  width: 100%;
  margin: 8px;
}

.contactDetails h2 {
  margin: 16px auto;
  color: var(--Blue-900, #00012b);
  font-size: 24px;
}

.contactDetails p {
  font-size: 20px;
}
.contactDetails p:last-child {
  margin-top: 24px;
}
.contactDetails img {
  width: 48px;
  height: 48px;
}

.phoneIcon {
  border: 2px solid #000;
  border-radius: 100px;
  padding: 3px;
}

@media (min-width: 500px) {
  .contactDetails {
    width: 100%;
    margin-bottom: 40px;
  }
  .phoneIcon {
    margin-top: 8px;
  }
}

@media (min-width: 1024px) {
  .contactDetails {
    max-width: 280px;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .contactDetails {
    max-width: 420px;
  }
}