/* .aucontainer {
  padding: 16px;
} */
.button {
  border-radius: 0px 8px 8px 0px;
  display: inline-flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--White, #fff);
  text-align: center;
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  flex-shrink: 0;
  background: var(
    --gradients-digital-blue,
    linear-gradient(225deg, #373ae5 14.64%, #292cb7 50%)
  );
}
.input {
  display: flex;
  height: 41px;
  width: 640px;
  padding: 0px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--blue-500, #373ae5) !important;
  background: var(--White, #fff);
}
.drop {
  display: flex;
  width: 640px;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0px;
  /* border: 1px solid var(--Blue, #373AE5); */
  background: var(--White, #fff);
  /* box-shadow: 2px 2px 8px 0px rgba(55, 58, 229, 0.10); */
}
.tabHolder {
  margin: 0;
  border-bottom: 1px solid var(--gray-100);
}
.tabHolder .tabItem {
  display: inline-block;
  color: var(--blue-900);
  line-height: 135%;
  text-decoration: none;
  padding: 16px 2px;
  margin: 0 15px;
  border-radius: 0;
}
.tabHolder .tabItem.active {
  color: var(--blue-500);
  font-weight: 500;
  border-bottom: 1px solid var(--blue-500);
}

.sc-bcXHqe.bncmtB.react-search-box-dropdown {
  width: 132% !important;
}
.react-search-box-dropdown-list-item {
  width: 132% !important;
}
.sc-eDvSVe .jifbFL {
  width: 590px !important;
}
.search .wrapper:first-child svg {
  border: 4px solid blue !important;
}

.sections {
  margin-bottom: 24px;
}
.sections2 {
  margin-bottom: 0;
}
.sections1 {
  margin-top: 42px;
}
.reportTo {
  color: var(--gray-600, #5d5d5d);

  /* Additional styles/sb/text-sm-sb */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
}
.name {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; 
}
.empid {
  color: var(--gray-500, #747474);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.infoname {
  color: var(--blue-900, #00012b);

  font-family: Calibre-R;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.personalinfo {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.personalsubtitle {
  color: var(--gray-700, #464646);
  
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.profileDetails {
  /* display: grid;
  gap: 16px;
  align-items: inherit;
  grid-auto-flow: column; */
  display: flex;
  /* gap: 16px; */
  align-items: inherit;
}
.info {
  padding-left: 16px;
}
.hideShowbutton {
  color: var(--blue-700, #1b1d88);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
}

.mobilenav select {
  width: 100%;
  padding: 15px;
  color: var(--blue-900);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  margin-bottom: 0;
  border-radius: 8px;
  border: 1px solid var(--gray-100);
  background: var(--white);
  box-shadow: 2px 2px 8px 0px rgba(55, 58, 229, 0.1);
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../../assets/images/chevron-down.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
}
.reports {
  padding-top: 16px;
}
.reportsInner {
  border-top: 1px solid var(--gray-100, #d1d1d1);
  padding-top: 15px;
  width: 100%;
}
.hideShowHolder {
  display: flex;
  margin-top: 10px;
  justify-content: center;
}
.infocontainer {
  height: 311px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--gray-100, #d1d1d1);
  background: var(--White, #fff);
}
.field1 {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.field2 {
  color: var(--blue-900, #00012b);

  /* Additional styles/reg/text-lg-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 24.3px */
}
.field3 {
  /* color: var(--gray-700, #464646); */

  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}
.language {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.parking {
  color: var(--gray-900, #171717);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.edit {
  right: 9%;
  position: absolute;
  color: var(--blue-700, #1b1d88);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
}
.profileBar {
  /* display: flex; */
  border-radius: 8px;
  margin-bottom: 16px;
  border: 1px solid var(--gray-100, #d1d1d1);
  background: var(--White, #fff);
  /* border-top: var(--gold-900) solid 1px; */
  padding: 16px;
  align-items: center;
  background-image: url("../../assets/images/chevron-right-white.svg");
  background-repeat: no-repeat;
  background-position: right 24px center;
}
.profileBar p {
  margin: 0 0 0 16px;
  color: var(--white);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 21.6px */
}
.profileBar p:nth-child(2) {
  font-size: 14px;
  font-weight: 300;
}
.profileBar .profileIcon,
.iconContainer a.profileIcon {
  background-color: var(--gold-900);
  width: 56px;
  min-width: 56px;
  height: 56px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  color: var(--black, #000);
}
.line {
  border-bottom: 1px solid var(--gray-100, #d1d1d1);
  height: 1px;
}
.radiobutton {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin-top: 10px;
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

@media (width<=992px) {
  .tabHolder {
    display: none;
  }
  .infoname {
    color: var(--blue-900, #00012b);

    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
  }
}
@media (width>992px) {
  .main {
    margin-top: 40px;
  }
  /* .aucontainer {
    padding: 0px 48px 64px 48px;
  } */
  .mobilenav {
    display: none;
  }
  .parkingsection {
    width: 57%;
    margin-bottom: 24px;
    color: var(--gray-700, #464646);
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
  }

  .sections {
    display: flex;
  }
  .sections2 {
    display: flex;
    justify-content: space-between;
  }
  .sections2 > span {
    min-width: 80px;
    display: inline-block;
    text-align: right;
  }
  .sections > span {
    min-width: 80px;
    display: inline-block;
    text-align: right;
  }

  .field1 {
    width: 32%;
    float: left;
  }
  .field2 {
    width: 100%;
  }
  .edit {
    right: 55%;
  }
  .radiobutton {
    gap: 40px;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }
  /* .profileDetails {
    gap: 24px;
  } */
  .info {
    padding-left: 24px;
  }
  .profileBar .profileIcon,
  .iconContainer a.profileIcon {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    font-size: 30px;
    min-width: 80px;
  }
  .line {
    border-left: 1px solid var(--gray-100, #d1d1d1);
    width: 1px;
    height: 110px;
    flex-shrink: 0;
    position: absolute;
    right: 39%;
  }
}
@media (width>1200px) {
  .reports {
    padding: 24px 24px 24px 0;
  }
  .reportsInner {
    border-top: none;
    border-left: 1px solid var(--gray-100, #d1d1d1);
    padding-top: 0;
    padding-left: 15px;
  }
  .profileBar {
    display: flex;
    min-height: 187px;
    justify-content: space-between;
    width: 100%;
    padding: 0 40px;
  }
  .mobileShow {
    display: none;
  }
}
