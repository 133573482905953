.instructionContainer [data-testid="card-container"] {
  padding: 32px;
  border-radius: 8px;
  margin: 0;
  margin-bottom: 5rem;
}

.EDRLandingPageContainer {
  padding: 0 16px;
}

.instructionContainer h6 {
  font-family: Calibre-R;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: #00012b;
}

.modalFooterContainer {
  float: right;
}

.successEDRContainer [data-testid="modal-content"] {
  height: 40%;
}

.successDescription {
  color: var(--Blue-900, #00012B);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}

.successEDRContainer [data-testid="modal-content"] {
  height: 40%;
  width: 100%;
}

.textRight { 
  text-align: right;
}

.instructionPoints a {
  font-weight: 500;
}

.instructionPoints {
  padding-left: 1rem;
}
.instructionPoints li {
  margin-bottom: 20px;
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  color: #00012b;
  /* list-style-position: outside; */
}
.notes {
  font-family: Calibre-R;
  font-size: 14px;
  line-height: 18.9px;
  text-align: left;
}
.notes span {
  color: #464646;
  font-weight: 700;
}

.heroComponent {
  position: relative;
}

.uploadDesktopWrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.EDRLandingPageContainer .heroComponent section {
  margin-bottom: 0px !important;
}

.uploadMobWrapper button {
  margin-bottom: 32px;
} 

.uploadDesktopWrapper button, .uploadMobWrapper button {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  height: 56px;
  width: auto;
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-family: Calibre-R;
  font-weight: 400;
  line-height: 100%;
  font-size: 16px;
  -webkit-text-decoration: none;
  text-decoration: none;
  background: var(--blue-900);
  color: #ffffff;
  border-color: #00000000;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.uploadMenuBtn {
  background-color: var(--blue-900);
}

.notesContainer {
  margin-left: -16px;
  margin-right: -16px;
}

@media (width>992px) {
  .updatesEventcontainer {
    padding: 0 48px;
  }

  .principles {
    margin-top: 48px;
    gap: 40px;
    flex-direction: row;
  }
}

p.quoteDescription em {
  font-size: 21px;
  font-style: italic;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
}

.author {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: var(--gray-600);
}

@media (min-width: 1024px) {
  .EDRLandingPageContainer {
    padding: 0 48px;
  }
  .EDRLandingPageContainer .heroComponent section {
    margin-bottom: 32px !important;
  }
  .successEDRContainer [data-testid="modal-content"] {
    height: 40%;
    width: 530px;
  }
}

@media (min-width: 1600px) {
  .successEDRContainer [data-testid="modal-content"] {
    height: 30%;
  }
}
