.container {
  padding: 10px;
  min-width: 300px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  background: #fff;
  position: absolute;
  z-index: 1;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 101;
  background: rgba(0, 0, 0, 0.4);
}

.innerHolder {
  width: 696px;
  min-height: 457px;
  margin: 130px auto;
  border-radius: 16px;
  background: var(--gray-25);
  padding: 64px 102px;
  position: relative;
}
.cross {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  /* background: url("../../../assets/sitemaintanance/cross.svg"); */
  background: url("../../assets/sitemaintanance/cross.svg");
}
.hide {
  display: none;
}
.coreHolder {
  width: 492px;
  height: auto;
}

.errorIcon {
  width: 110px;
  height: 132px;
  margin: 0 auto;
  background-size: contain !important;
}

.head {
  width: 100%;
  color: var(--gray-900);
  text-align: center;
  padding: 4px 32px;
  /* Additional styles/sb/text-2xl-sb */
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  /* 32.4px */
  margin-top: 10px;
}

.body {
  color: var(--gray-800);
  text-align: center;
  width: 415px;
  margin: 0 auto;
  /* Additional styles/reg/text-lg-reg */
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  /* 24.3px */
  margin-top: 10px;
}

.contBtn {
  display: flex;
  height: 56px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--blue-900);
  cursor: pointer;
  margin-top: 24px;
  color: var(--white);
  text-align: center;

  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  /* 21.6px */
  margin-left: auto;
  margin-right: auto;
}

.contBtn:hover {
  background: var(--blue-800);
}
@media (max-width: 767px) {
  .innerHolder {
    width: 95vw;
    min-width: 90vw;
    height: auto;
    padding: 64px 0;
  }
  .coreHolder {
    width: 90vw;
    height: auto;
  }
  .body {
    width: 95%;
    padding-left: 12px;
    padding-right: 12px;
  }
}


.container > h2 {
  font-size: 14px;
}
.container > p {
  font-size: 11px;
  margin: 7px 0;
}
.container > hr {
  border: 1px solid #99845d;
}
/* tab */
.container > .button {
  padding: 3px 0;
  width: 100%;
  display: flex;
}

.btnContainer {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-auto-flow: column;
  grid-gap: 1rem;
}
.btnContainer>button:nth-child(2){
  background: var(--blue-900);
}

.btnContainer>button:nth-child(2):hover{
  background: var(--blue-800);
}
/* 
.container > .button {
  background-color: #0f0;
  font-size: 13px;
  margin: 20px 0;
  width: 100%;
  display: flex;
} */
.container > button:last-child {
  margin: 10px 0;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.25);
}
.modal {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 101;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  text-align: center;
}
.modal > div {
  display: flex;
  flex-direction: column;
  pointer-events: all;
  background-color: var(--gold-25);
  padding: 24px;
  border-radius: 14px;
  align-items: center;
  justify-content: center;
}
.modal .modalHeader {
  gap: 24px;
}
.modal .closeButton {
  flex: 0 0 32px;
}
.modalHeader h3 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  color: var(--blue-900, #00012b);
  text-align: center;
}
.modal .content {
  overflow-y: auto;
}
.modal .content h4 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
}
.modal .content li,
.modal .content p {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 24.3px */
}

.imgHolder {
  text-align: center;
}
.imgHolder img {
  height: 46px;
  width: 46px;
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .modal {
    z-index: 30;
  }
}

