.quickAction {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  background-color: var(--white);
  text-decoration: none;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  /* padding: 1rem 0; */
  color: var(--blue-900);
  /* font-size: 1rem; */
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  align-items: center;
  background: transparent;
  /* padding: 24px 20px 24px 0; */
  padding: 24px 0;
  gap: 18px;

  &:hover,
  &:focus-visible,
  &:active,
  &:hover div,
  &:focus-visible div,
  &:active div,
  &:hover svg path,
  &:focus-visible svg path,
  &:active svg path {
    outline: 0 !important;
    color: var(--blue-900) !important;
    fill: var(--blue-900) !important;
    background-color: var(--gray-50, "#E8E8E8");
  }
}
.quickAction.large {
  padding: 24px;
}
.quickAction:hover h4,
.quickAction:hover p {
  color: var(--blue-900);
}
.quickAction .leadingIcon {
  margin-right: 16px;
}
.quickAction .leadingIcon svg {
  max-width: 40px;
  max-height: 40px;
}
.quickAction h4 {
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
  margin-right: 16px;
  word-break: break-word;
}
.quickAction.large h4 {
  font-size: 18px;
}
.quickAction p {
  color: var(--Gray-700);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.quickAction .trailingIcon {
  margin-left: auto;
}
.quickAction .trailingIcon svg {
  max-width: 32px;
  max-height: 32px;
}

@media (min-width: 992px) {
  .quickAction {
    padding: 24px 20px 24px 20px;
    gap:5px;
  }
}
