.cHolder {
    padding: 0 16px;
}

.pageContent {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
}
@media (min-width: 1100px) {
    .cHolder {
      padding: 0 48px;
    }
    .pageContent {
      margin-top: 48px;
      flex-direction: row;
      gap: 4%;
    }
    .pageContent .leftContent {
      flex: 0 0 58%;
    }
    .pageContent .rightContent {
      flex: 0 0 38%;
    }
  }
  @media (min-width: 1300px) {
    .pageContent .leftContent {
      flex: 0 0 66%;
    }
    .pageContent .rightContent {
      flex: 0 0 30%;
    }
    
  }
  