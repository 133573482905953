.breadcrumb a,
.breadcrumb .link {
  margin-right: 6px;
  /* color: var(--Blue-900, #00012B); */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  /* font-weight: 600; */
  text-decoration-line: none;
  line-height: 135%; /* 18.9px */
  
  cursor: pointer;
}
.breadcrumb a:hover{
  color: var(--blue-900);
  text-decoration-line: underline;
  /* font-weight: 600; */
}
.breadcrumb span {
  margin-right: 6px;
  color: var(--blue-900);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.breadcrumb .mobileLink {
  display: flex;
  justify-content: center;
  align-items: center;
}
