.layoutFullWidthHolder {
  padding: 0 16px;
}
.layoutFullWidth > section {
  margin-bottom: 32px;
}
.fiftyFifty {
  display: flex;
  flex-direction: column;
  gap: 4%;
}
.fiftyFifty > div:first-child {
  margin-bottom: 32px;
}
.layoutFullWidth > section:first-child {
  margin-top: 0;
}
@media (min-width: 768px) {
  .tiles {
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
  }
  .tiles > * {
    flex: 0 0 48.5%;
  }
}
@media (min-width: 1024px) {
  .layoutFullWidthHolder {
    padding: 0 48px;
  }
  .layoutFullWidth > section {
    /* margin-bottom: 48px; */
  }
  .fiftyFifty {
    display: flex;
    flex-direction: row;
    gap: 32px;
  }
  .fiftyFifty > * {
    flex: 0 0 48.5%;
  }
  .fiftyFifty > div:first-child {
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .tiles {
    gap: 2%;
  }
  .tiles > * {
    flex: 0 0 32%;
  }
}
