.matrixHolder {
  /* display: flex;
  align-items: flex-start; */
  background-color: var(--white);
  display: grid;
  /*justify-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr;*/
  grid-template-columns: 48% 48%;
  justify-content: space-between;
}
.card {
  /* width: 32%; */
  height: 100%;
  padding: 24px 16px 24px 16px;
  border-radius: 8px 0px 0px 0px;
  background-color: var(--white);
  margin-right: auto;
  display: grid;
  align-items: flex-start;
  justify-content: center;
  grid-template-rows: 48px 48px auto;
  /* flex-direction: column; */
  &:last-child {
    margin-right: 0;
  }
}

.iconH {
  margin: auto;
}
.cardDes {
  margin: auto;
  color: var(--gray-500);
  font-family: Calibre-R;
  font-weight: 400;
  line-height: 24.3px;
  text-align: center;
  margin-top: 8px;
  font-size: 12px;
  align-self: flex-start;
}
.cardData {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-weight: 500;
  line-height: 32.4px;
  text-align: center;
  margin-top: 16px;
  font-size: 18px;
}

.hide {
  display: none;
}
@media (max-width: 768px) {
  .card {
    margin-right: 0;
    /* width: 33.3%; */
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    position: relative;

    &:not(:last-child):after {
      content: "";
      right: 0;
      position: absolute;
      display: block;
      border-right: 1px solid var(--gold-500);
      height: 44px;
      top: 40%;
    }
  }
}

@media (min-width: 768px) {
  .card {
    padding: 32px 16px;
    border-radius: 8px;
    width:100%
  }
  .matrixHolder {
    min-height: 216px;
    background-color: transparent;
    grid-gap: 8px;
  }
  .cardData {
    font-size: 24px;
  }
  .cardDes {
    font-size: 18px;
  }
}
