.timesheetHolder {
  padding: 0 16px;
}
.pageContent {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
}

.pageContent .rightContentM {
  margin-top: 25px;
}
@media (min-width: 1024px) {
  .timesheetHolder {
    padding: 0 48px;
  }
}

@media (min-width: 1100px) {
  .pageContent {
    margin-top: 48px;
    flex-direction: row;
    gap: 4%;
  }
  .pageContent .leftContent {
    flex: 0 0 58%;
    height:fit-content;
  }
  .pageContent .rightContent {
    flex: 0 0 38%;
  }
}
@media (min-width: 1300px) {
  .pageContent .leftContent {
    flex: 0 0 66%;
  }
  .pageContent .rightContent {
    flex: 0 0 30%;
  }
  .linkSection {
    flex-direction: row;
  }
  .linkSection a:first-child {
    flex: 0 0 30%;
  }
}
