.accordionCard {
  margin-bottom: 24px;
}
.cardContainerInner,.cardContainerIconInner{
  padding: 24px 24px 24px 24px;
}
.cardContainerInner.setFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.cardContainerIconInner.setFlex {
  display: flex;
  flex-direction: row;
  gap:24px;
  
}
.accordionCard .accordionHeader {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.arrowdown{
  display: flex;
  flex-direction: row;
  gap:4px;
}
.arrowdown >img{
  margin-bottom: 12px;
  cursor: pointer;
}
.readMore{
  color: var(--Blue-900, #00012B);
/* Additional styles/reg/text-base-reg */
font-family: Calibre-R;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 135%;
cursor:pointer;
text-decoration: underline;
}
.accordionCursor{
  cursor:pointer;
}
.accordionCard .accordionBody {
  height: 62px;
  overflow: hidden;
  margin-bottom: 16px;
  position: relative;
  /* background: rgb(255,255,255); */

&::after {
  content: " ";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 43px;
  background: linear-gradient(to top, white 0%, rgba(168, 168, 74, 0) 79%);
}
}
.accordionCard .accordionBodyExpand {
  
  overflow: hidden;
  margin-bottom: 16px;
  position: relative;
}
.accordionCard .accordionBody>div>p:first-child{
  /* background: rgb(255,255,255); */
  /* background: linear-gradient(180deg, rgba(255,255,255,0) 20%,rgba(128, 128, 128, 0.179) 40%,rgba(255,255,255,0) 40%); */
  /* background: linear-gradient(180deg, rgba(255,255,255,0) 20%,transparent 40%, rgba(255,255,255,0) 40%); */
  /* background: linear-gradient(180deg  rgba(255,255,255,1) 2%,rgba(255,255,255,1) 14%,rgba(255,255,255,1) 23%,rgba(255,255,255,0.78) 40%,rgba(255,255,255,0) 100%);  */
  /* background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(241,241,241,1) 50%,rgba(225,225,225,1) 51%,rgba(246,246,246,1) 100%); */
  /* background: linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 34, 0.8) 100%); */

}
.accordionCard .accordionBody.open {
  height: auto;
  overflow: visible;
  position: relative;
  
  &::after {
    content: " ";
    position: absolute;
    /* bottom:0; */
    /* width: 100%;
    height: 100%; */
    background: none;
    
  }
  
}
.accordionCard .accordionBody.open>div>p:first-child{
  
  /* background: linear-gradient(180deg, rgba(26, 255, 255, 0.00) 32.95%, #FFF 86.05%); */
}
.cardContainerInner .imageHolder img{
  margin-bottom: 24px;
  /* width: 150px;
    height: 100%; */
}
.cardContainerIconInner .imageIconHolder svg{
  margin-bottom: 24px;
  
}
.accordionCard h3 {
  margin-bottom: 16px;
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
}
.accordionCard ul,
.accordionCard p {
  /* margin-bottom: 16px; */
}
.accordionCard > *,
.accordionCard ul,
.accordionCard li .accordionCard p,
.accordionCard td {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}
.accordionCard th,
.accordionCard strong {
  font-weight: 500;
}
.accordionCard th,
.accordionCard td {
  padding-right: 24px;
}
.accordionCard .links {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
}
.accordionCard .links a {
  display: block;
  padding-left: 0;
  margin-bottom: 16px;
}
.accordionCard .links a:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .cardContainerInner,.cardContainerIconInner {
    padding: 40px 40px 40px 40px;
  }
  .cardContainerInner.setFlex,.cardContainerIconInner.setFlex {
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;
  }
  .cardContainerInner.setFlex > div:nth-child(2),.cardContainerIconInner.setFlex > div:nth-child(2) {
    width: 100%;
  }
  .cardContainerInner .imageHolder img,.cardContainerIconInner .imageHolder img{
    max-width: 221px;
    margin-bottom: 40px;
  }
  
  
  .accordionCard .links {
    gap: 48px;
    flex-direction: row;
    margin-bottom: 40px;
  }
  .accordionCard .links > div {
    flex: 1 0 0;
  }
}
