.leadershipMessagesListHolder .message {
  margin: 32px 0;
}
.leadershipMessagesListHolder .message * {
  overflow: hidden;
}
.leadershipMessagesListHolder {
  margin-bottom: 58px;
}
@media (min-width: 1024px) {
  .leadershipMessagesListHolder .message {
    margin: 40px 0;
    height: 298px;
  }
}
