/* common */
.recogBlock1 {
  color: #00012b;
}
a:hover {
  color: var(--blue-900, #00012b);
  /* font-weight: 400; */
}
.recogBlock1 button {
  background: var(--blue-900);
}
.recogBlock1 button:hover {
  background: var(--blue-800);
}
.recogBlock1 h2 {
  font-size: 24px;
  font-weight: 500;
}
.recogBlock1 h3 {
  font-size: 18px;
  font-weight: 500;
}
h5 {
  /* .recogBlock1 h5 { */
  font-size: 16px;
  color: var(--Blue-900, #00012b);
}
.recogBlock1 p {
  font-size: 14px;
}
.textUnderline {
  text-decoration: underline;
}
.boxStyling {
  border: 1px solid var(--Gray-100, #d1d1d1);
  background-color: #fff;
  border-radius: 8px;
}
.rTilesBig .subHeading {
  color: var(--Gray-600, #5d5d5d);
  font-size: 14px;
}

.rTilesBig button {
  /* make it generic */
  color: var(--Blue-900, #00012b);
}

/* recogReceived */
.recogReceived {
  margin-top: 43px;
}

/* recogReceivedList */
.recogReceivedList {
  display: flex;
  gap: 40px;
}

.rContent1 h5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.rTilesBig p.content {
  font-size: 14px;
  line-height: 19px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  /* min-height: 95px; */
  min-height: 76px;
}

/* rTilesBig */
.rTilesBig {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 16px;
  /* todo */
  /* height: 255px; */
  min-height: 235px;
  overflow: hidden;
}
.rTilesBig .rTileIcon {
  margin-right: 16px;
}

/* div[class^="RecognitionReGi_rContent1"] */
/* .div[class^="RecognitionReGi_rTileIcon__"] > .initials-avatar {
  background-color: red !important;
} */

.tag {
  position: absolute;
  right: 0;
  top: 22px;
  border-radius: 4px 0px 0px 4px;
  background: var(--Blue-900, #00012b);
  height: 24px;
  padding: 8px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.tag p {
  color: var(--White, #fff);
  font-size: 14px;
}

.rTilesBigFooter {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-gap: 8px;
  margin-top: 25px;
  grid-auto-flow: column;
}
.rTilesBig .rTilesBigFooter img {
  width: 28px;
  height: 28px;
  margin-right: 6px;
}
.footerRight {
  display: flex;
  align-items: center;
  height: 40px;
}
@media (min-width: 1024px) {
  /* for 1024px and 1200px - it will create badge text of 3 words into 3 line - otherwise badge text is not aligning from right side */
  .footerRight {
    width: min-content;
  }
  .rTilesBigFooter {
    grid-gap: 16px;
  }
}

@media (min-width: 1400px) {
  .footerRight {
    width: unset;
  }
}
.downIcon {
  width: 13px;
  height: 13px;
  margin-left: 8px;
}
.footerRightText {
  font-size: 12px;
  color: var(--Gray-600, #5d5d5d);
}

.tileSpaceL {
  /* margin: 0 10px 0 0; */
  margin: 0 10px 0 10px;
}
.tileSpaceR {
  /* margin: 0 0 0 10px; */
  margin: 0 10px 0 10px;
}

/* aa */
/* 
.tileSpace {
  margin: 0 10px 0 0;
}

.tileSpaceL {
  margin: 0 0 0 10px;
}

.tileSpaceM {
  margin: 0 5px 0 5px;
} */

.carousel {
  display: flex;
  flex-direction: column;
}
.carouselNav {
  display: flex;
  align-self: flex-end;
  align-items: center;
  margin-bottom: 16px;
}
.carouselNavArrowP {
  border-radius: 8px 0px 0px 8px;
  border: 1px solid var(--Blue-900, #00012b);
  padding: 5px;
}
/* Trial */
/* button.carouselNavArrowP:disabled {
  border-left: 1px solid #31313161;
  border-top: 1px solid #31313161;
  border-bottom: 1px solid #31313161;
  cursor: default;
}
button.carouselNavArrowN:disabled {
  border-right: 1px solid #31313161 !important;
  border-top: 1px solid #31313161 !important;
  border-bottom: 1px solid #31313161 !important;
  cursor: default;
}
button.carouselNavArrowP:disabled > svg path,
button.carouselNavArrowN:disabled > svg path {
  fill: #31313161;
} */

.carouselNavArrowN {
  border-radius: 0px 8px 8px 0px;
  border: 1px solid var(--Blue-900, #00012b);
  border-left: 0;
  padding: 5px;
}

p.viewAllBtn {
  margin-right: 28px;
  white-space: nowrap;
}

p.viewAllBtn a {
  color: var(--blue-900, #00012b);
}
p.viewAllBtn a:hover {
  color: var(--blue-900, #00012b);
  /* font-weight: 400; */
}
/* common */
p.linkButton {
  font-size: 14px;
  text-decoration-line: underline;
  cursor: pointer;
}

.rContent1 {
  display: flex;
}
.readMore {
  font-size: 14px;
  /* white-space: nowrap; */
}
.rContent2 {
  width: -webkit-fill-available;
}

/*  not mobile start */
/* @media (min-width: 500px) { */
@media (min-width: 1200px) {
  .rContent2 {
    margin-left: calc(48px + 17px);
  }
  .rTilesBig {
    padding: 32px;
  }
}

@media (max-width: 1023px) {
  p.viewAllBtn {
    margin-right: 0;
    white-space: nowrap;
    text-align: center;
  }
  .recogReceivedList {
    margin-top: 10px;
  }
}

div[class^="RecognitionReGi_rTileIcon__"] [class^="initials-avatar"] {
  background-color: #b19661;
}
div[class^="RecognitionReGi_rTileIcon__"] [class^="initials-avatar"] div {
  color: #000;
  font-size: 20px;
}
.threePeople {
  background-color: #b19661;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  /* background-color: #000; */
  display: flex;
  box-sizing: border-box;
}
.threePeopleImg {
  width: 24px;
  height: 24px;
  display: flex;
  /* align-items: center;
    justify-content: center; */
  /* font-style: inherit;
    font-weight: inherit; */
  color: #000;
}
.carousalTopSpacing {
  margin-top: 27px;
}

ul[class^="alice-carousel_"] li[class^="alice-carousel__dots-item __active"] {
  background-color: #00012b !important;
}
ul[class^="alice-carousel_"] li[class^="alice-carousel__dots-item"] {
  border: 1px solid #00012b;
  background-color: rgba(0, 1, 43, 0);
  width: 10px;
  height: 10px;
}
ul[class^="alice-carousel__dots"] {
  margin-top: 24px !important;
  padding: 0;
  list-style: none;
  text-align: center;
}
ul[class^="alice-carousel_"] li[class^="alice-carousel__dots-item"]:last-child {
  margin-right: 0 !important;
}

/* //Tab from Vegas | Max - to verify*/
/* also add for disable one */
.tabHolder .tabItem {
  border-radius: 0;
  color: var(--blue-900);
  display: inline-block;
  line-height: 135%;
  margin: 0 15px;
  padding: 16px 2px;
  text-decoration: none;
}
.tabItem.active {
  font-weight: 500;
  color: #00012b;
  border-bottom: 1px solid var(--Rewards-Tier-Gold, #bda770);
  border-radius: 0;
}
.tabHolder .tabItem:hover {
  color: #00012b;
}
.tabHolder {
  /* long tab line */
  border-bottom: 1px solid var(--gray-100);
  width: 100%;
  margin-bottom: 20px;
}
