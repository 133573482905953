.loaderHolder {
  padding: 0 16px;
}
.loaderHolder.inline {
  padding: 0;
}
.loaderHolder > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
  gap: 20px;
}
.loaderHolder.inline > div {
  flex-direction: row;
  margin: 0;
  gap: 20px;
  justify-content: left;
}
.loaderHolder img {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media (min-width: 1024px) {
  .loaderHolder {
    padding: 0 48px;
  }
}
