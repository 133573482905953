.initSearchH
{
    width:100%;
    min-height: 168px;
    height: calc(100vh - 32px);
    background-color: var(--blue-900);
    /*margin: 0 auto;*/
    margin-top:32px;
}
.msg
{
    color: var(--gold-25);

    /* Additional styles/med/text-base-med */
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 21.6px */
    padding-top: 41px;
    margin: 0 auto;
    width: 259px;
}
.btnHolder
{
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-top: 25px;
}
.cancel
{
    display: inline-flex;
    height: 40px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid var(--gold-100);
    color:var(--gold-100);
    cursor: pointer;
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 18.9px */
}
.search
{
    display: inline-flex;
    height: 40px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 6px;
    background: linear-gradient(225deg, #373AE5 14.64%, #292CB7 50%);
    color: var(--dark-base-base-high-100-white);
    cursor: pointer;
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 18.9px */
    
}
.searhIcon
{
    background-image: url("../../assets/images/magnifying-glass-white.svg");
    background-position: left;
    background-repeat: no-repeat;
    width:24px;
    height:24px;
}