.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 8px; */
}

.headingJob {
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
}
.showMoreLabel {
  color: var(--Gray-600, #5d5d5d);
  text-align: center;
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  /* margin-left:47%; */
}
.loadMoreBtn {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.topPaginationNums {
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}

.filterChipLabels {
  margin-top: 20px;
}

.filterChipLabel {
  margin-right: 8px;
  margin-bottom: 10px;
}

.filterChipLabels>button:hover{
  border: 1px solid #373ae5;
  box-shadow: none !important;
}
.jobOpportuniesCardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
}

.modalFooterContainer {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* justify-content: flex-start; */
  justify-content: flex-end;
  align-items: center;
  padding-top: 16px;
  /* margin-top: 20px; */

}
.modalFooterContainer > p {
  margin-top: 20px;

}

.modalFooterBtnArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 20px; */
}


.leftBtn {
  text-wrap: nowrap;
  margin-right: 30px;
}
.leftBtn:hover {
  color: var(--Blue-900, #00012b);
}
/* 
.applyBtn {
  background: var(--Blue-900, #00012b);
}
.applyBtn:hover {
  background: var(--Blue-900, #00012b);
} */
.centerModal {
  width: 50%;
  max-width: 800px;
}

.noResultsContainer {
  padding: 81px 24px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.noResultsContainer > div {
  padding-top: 24px;
  text-align: center;
  color: var(--Blue-900, #00012b);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
}

.noResultsContainer > div:nth-child(3) {
  padding-top: 16px;
  font-size: 16px;
  font-weight: 400;
}


@media (max-width: 690px) {
  .jobOpportuniesCardsContainer {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    margin-top: 10px;
  }
  .modalFooterContainer {
    padding-top: unset;
  }
  .modalFooterContainer > p {
    margin-top: unset;
    margin-right: 10px;
  }
  .modalFooterBtnArea {
    margin-top: unset;
  }
}
