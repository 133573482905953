* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  --blue-900: #00012b;
  --blue-800: #0E0F59;
  --blue-700: #1b1d88;
  --blue-500: #373ae5;
  --blue-title: #282827;
  --blue-sub-title: #737270;
  --blue-25: #dedefb;
  --blue-btn: #292cb7;
  --blue-link: #373ae5;
  --gold-900: #b19661;
  --gold-800:#BAA173;
  --gold-500: #d8c5a5;
  --gold-200: #f3e5ce;
  --gold-100: #f6eddd;
  --gold-25: #faf9f5;
  --gold-f2: #f2f2f2;
  --gray-200:#B9B9B9;
  --gray-600: #5d5d5d;
  --gray-400: #8b8b8b;
  --gray-100: #d1d1d1;
  --gray-50: #e8e8e8;
  --gray-25: #F5F5F5;
  --gray-900: #171717;
  --gray-800 : #2E2E2E;
  --white: #ffffff;
  --secondary-teal-400: #289e88;
  --alerts-orange-800: #c3491a;
  --alerts-green-800: #006732;
  --alerts-green-700: #00803c;
  --alerts-green-25: #f3fcf7;
  --alerts-red-800: #891207;
  --alerts-red-500: #f70800;
  --alerts-red-25: #fff3f3;
  --viewAll-left-val: 0px;
  --Black: #000;
  --gray-500: #747474;
  --blue-900: #00012b;
  --gray-100: #d1d1d1;
  --gold-25: #faf9f5;
  --gold-100: #f6eddd;
  --dark-alt-alt-base-high-100-black: #000;
  --dark-base-base-high-100-white: #fff;
  --blue-500: #373ae5;
  --White: #fff;
  --alerts-red-600: #d20b02;
  --alerts-red-25: #fff3f3;
  --asFilterWidth: 324px;
  --alerts-red-700: #AE0F05;
}

body {
  font-family: Calibre-R;
  font-style: normal;
  font-weight: 400;
}

body main {
  width: 100%;
  max-width: 1440px;
  background-color: var(--gold-f2);
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
}

body header {
  background-color: var(--blue-900);
  width: 100%;
  position: sticky;
  top: 0;
}

body footer {
  background-color: var(--blue-900);
  width: 100%;
}

#root {
  background-color: var(--gold-f2);
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

main {
  flex: 1;
}

p {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.css-hdmgd3
{
  width:auto !important;
}
a {
  font-size: 16px;
  font-family: Calibre-R;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  color: var(--blue-900);
  
}
a[href^="mailto:"]{
  white-space: nowrap;
}

a:hover {
  color: var(--blue-900, #00012b);
  font-weight: 500;
}

.inner-html-parent a:hover {
  color: var(--blue-900);
  font-weight: 500;
}
.radioHolder  #radio-outter-container {
  display:flex !important;
  justify-content: space-between !important;
  width:100% !important;
  outline: none;
}
.radioHolder  #radio-outter-container :focus-within {
outline: none !important;
}
.radioOutline #radio-outter-container {
  outline: none;
}
.radioOutline  #radio-outter-container :focus-within {
  outline: none !important;
  }

.container {
  display: flex;
  flex: 1;
}

.nav-container {
  display: none;
}

.page-container {
  /*padding: 16px;*/
  padding-top: 16px 0;
  /* this css creates issues in agent serch app..need discussion with Navanee */
  overflow: hidden;
  width: 100%;
}

.page-container.leadershipmsg {
  overflow: visible;
}

.container {
  max-width: 1440px;
  padding: 0;
  /*overflow-x: hidden;*/
}

/* MFE Dropdown overwrite CSS start */
/* custom checkbox */
/* The container */
.Ccontainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.Ccontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.filterApplyBtn
{
    background: var(--blue-900) !important;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid var(--gray-500);
}

/* On mouse-over, add a grey background color */
.Ccontainer:hover input~.checkmark {
  background-color: #ffffff;
}

/* When the checkbox is checked, add a blue background */
.Ccontainer input:checked~.checkmark {
  background-color: #373ae5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.Ccontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.Ccontainer .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mfeDDHolder .css-1upa1rx,
.mfeDDHolder .css-8cmj99 {
  top: 0.8rem;
}

.mfeDDHolder .css-h8ofm1 {
  min-width: 106px;
  max-width: 106px;
  border-radius: 100px;
  border: 1px solid var(--blue-900);
  height: 40px;
  align-items: center;
}

.mfeDDHolder .css-h8ofm1:hover,
.mfeDDHolder .css-1rpmf7b:hover {
  border-radius: 100px;
  border: 1px solid var(--blue-900);
}

.mfeDDHolder .css-1rpmf7b {
  min-width: 106px;
  max-width: 106px;
  border-radius: 100px;
  border: 1px solid var(--blue-900);
  height: 40px;
}

.mfeDDHolder .css-11ezstd,
.mfeDDHolder .css-1qs922i {
  padding: 0 8px;
}

.mfeDDHolder .css-8cmj99,
.mfeDDHolder .css-1upa1rx {
  color: var(--blue-900);
  text-align: center;

  /* reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  /* 18.9px */
}

.previewBackBtnHolder {
  width: 184px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  float: left;
  padding-left: 40px;
  margin-top: 50px;
  cursor: pointer;
}

.previewBackBtnHolder span {
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  text-decoration-line: underline;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
}

.containerElectron {
  margin-top: 40px;
}

.blackOverlay {
  background: #0000005c;
  width: 100%;
  height: 100%;
  /* position: absolute; */
  position: fixed;
  z-index: 5;
}

@media (max-width: 768px) {
  .mfeDDHolder .css-1nrchwz {
    /*margin-top: 20px;*/
    margin-left: auto;
  }

  .mfeDDHolder .css-1nrchwz:first-child {
    margin-right: auto;
  }

  .mfeDDHolder .css-1nrchwz:first-child .css-h8ofm1 {
    min-width: 150px;
    max-width: 150px;
  }

  .mfeDDHolder .css-1nrchwz:first-child .css-1rpmf7b {
    min-width: 150px;
    max-width: 150px;
  }
}

/* MFE Dropdown overwrite CSS end */
/* agent search header */

/*.h-container
{
  width:100%;
  justify-content: space-between;
  background-color: var(--dark-alt-alt-base-high-100-black);
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  box-sizing: border-box;
  -webkit-user-select: none;
  -webkit-app-region: drag;
}
.eTitle
{
    color: var(--dark-base-base-high-100-white);
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; 
}*/
.stockDesktop
{
    display:flex;
    background: var(--gray-50);
    border-top: 1px solid var(--gray-200);
    width:100%;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
}
/*@media (max-width: 768px) {
  .stockMobile {
    display: flex;
    background: var(--gray-50);
  }

  .stockDesktop {
    display: none;
  }
}
@media (min-width:769px) {
  .stockMobile{
      display: none;
  }
  .stockDesktop
  {
      display:flex;
      background: var(--gray-50);
  }
   
}*/
@media (min-width: 1024px) {
  .nav-container {
    display: block;
    background-color: var(--white);
    box-shadow: 7px 0 16px -7px rgba(177, 150, 97, 0.2);
    flex-basis: 324px;
    flex-grow: 0;
    flex-shrink: 0;
    z-index: 1;
  }

  .page-container {
    /*padding: 16px 48px;*/
    padding: 16px 0;
  }

  body header {
    z-index: 10;
  }

  body footer {
    z-index: 1;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .page-container {
    /* this css creates issues in agent serch app..need discussion with Navanee */
    padding: 20px 48px;
  }
}

.askAssistant {
  position: fixed;
  z-index: 100;
  bottom: 10px;
  right: 10px;
}

.pilotFeedback {
  position: fixed;
  z-index: 100;
  top: calc(100vh - 55vh);
  right: 0;
  left: 100%;
  transform-origin: 0% 0%;
  transform: rotate(90deg) translateX(-50%);
}

.flyoutMenu {
  position: fixed;
  z-index: 98;
  top: 150px;
  right: 0;
  box-shadow: 0px 3px 8px 0px #0000001A;
  border-radius: 8px 0 0 8px;
  /* left: 100%;
  transform-origin: 0% 0%;
  transform: rotate(90deg) translateX(-50%); */
}

dialog {
  background-color: var(--gray-25) !important;
}

.flyoutMenuMobile {
  /* position: fixed; */
  z-index: 99;
  /* bottom: calc(100vh - 90vh); */
  /* right: 30px; */
  width: 100%;
}
.css-1l4bdbu:not([disabled]):hover {
  background: var(--blue-800) ;

  
}
/* button {
  &:focus, &:focus-visible, &:hover, &:active {
    background-color: var(--Blue-900, #00012B);
  }
} */

@media (min-width: 768px) {
  .pilotFeedback {
    top: calc(100vh - 40vh);
  }
}

@media (max-width: 768px) {
  dialog {
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    border-radius: 0 !important;
  }
}

