.heroBanner {
  position: relative;
}
.imgHolder {
  width: 100%;
  height: 100%;
}
.imgOverlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.heroBanner img {
  max-width: 100%;
  border-radius: 8px;
}
.heroBanner .heading {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.heroBanner h2 {
  color: var(--white);
  font-family: Calibre-R;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 64.8px */

  display: flex;
  height: 100%;
  align-items: center;
}
.heroBanner p {
  color: var(--White, #fff);
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 27px */
  margin: 0;
}
@media (min-width: 768px) {
}
@media (min-width: 360px) and (max-width: 480px) {
  .heroBanner .heading {
    top: 16px;
  }
}
@media (min-width: 1024px) {
  .heroBanner .heading {
    bottom: 40px;
    left: 40px;
  }
  .heroBanner h2 {
    font-size: 48px;
  }
  .heroBanner p {
    font-size: 30px;
    font-weight: 600;
    line-height: 135%; /* 40.5px */
  }
}
