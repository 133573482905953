@media (min-width: 1200px) {
  .launchContestWrapper {
    position: absolute;
    right: 0;
    top: 40px;
    min-width: 384px;
  }
}
.launchContestContainer {
  max-width: 384px;
  height: fit-content;
  background: #00012b;
  padding: 32px;
  font-family: Calibre-R;
  font-style: normal;
  line-height: 135%; /* 27px */
  border-radius: 5px;
}

/* .snackbarBoxLaunchContest > div { */
/* .snackbarBoxLaunchContest > div:nth-child(1) {
  border: 1px solid red;
  position: absolute;
  right: 0;
  top: 40px;
  width: 384px;
  height: 214px;
  background-color: #004724 !important;
} */

.launchContestContainer .headerWrapper {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}

.launchContestContainer h2 {
  color: var(--Gold-700, #c2ad86);
  /* Additional styles/med/text-xl-med */
  font-size: 20px;
  font-weight: 500;
}
.headerWrapper img {
  width: 24px;
  margin-right: 12px;
}
.innerHtml p {
  color: var(--White, #fff);
}

.launchContestContainer .description {
  color: var(--White, #fff);
  /* Additional styles/reg/text-base-reg */
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 16px;
}

.linkContainer {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.linkContainer .readRulesLink {
  color: var(--Gray-25, #f5f5f5);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  padding-left: unset;
  padding-bottom: unset;
  cursor: pointer;
}
.linkContainer .readRulesLink:hover {
  color: var(--Gray-25, #f5f5f5);
  line-height: 20px; /* 142.857% */
  font-weight: bold;
  font-size: 14px;
}

.linkContainer .link2 {
  color: var(--Blue-900, #00012b);
  text-align: center;
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  border-radius: 6px;
  background: var(--gold-f2);
}
.linkContainer .link2:hover {
  color: var(--Blue-900, #00012b);

  background: var(--White, #fff);
}

.linkContainer .link2a button {
  color: var(--Blue-900, #00012b);
  text-align: center;
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  border-radius: 6px;
  background: var(--White, #fff);
  height: 32px;
  width: 100px;
  border: 1px solid var(--Blue-800, #0e0f59);
}
.linkContainer .link2:hover button {
  border: 2px solid var(--Blue-800, #0e0f59);
}

@media (max-width: 1199px) {
  .launchContestWrapper {
    position: unset;
    width: 100%;
    height: 100%;
    top: 0;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  .launchContestContainer {
    display: block;
    width: 100%;
    height: fit-content;
  }
  /* .snackbarBoxLaunchContest > div:nth-child(1) {
    border: 1px solid red;
    position: absolute;
    right: 0;
    top: -80px;
    width: 384px;
    height: 214px;
    background-color: #004724 !important;
  } */
}