.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 101;
    background: rgba(0, 0, 0, .4);
}

.innerHolder {
    width: 696px;
    height: auto;
    margin: 130px auto;
    border-radius: 16px;
    background: var(--gray-25);
    padding: 64px 102px;
    position: relative;
}
.cross
{
    position: absolute;
    top:20px;
    right:20px;
    width:24px;
    height:24px;
    cursor: pointer;
    background: url('../../../assets/sitemaintanance/cross.svg');
}
.hide
{
    display:none;
}
.coreHolder {

    width: 492px;
    height: auto;
}

.errorIcon {
    width: 110px;
    height: 132px;
    margin: 0 auto;
    background-size: contain !important;
}

.head {
    width: 100%;
    color: var(--gray-900);
    text-align: center;
    padding: 4px 32px;
    /* Additional styles/sb/text-2xl-sb */
    font-family: Calibre-R;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    /* 32.4px */
    margin-top: 24px;
}

.body {
    color: var(--gray-800);
    text-align: center;
    width: 316px;
    margin: 0 auto;
    /* Additional styles/reg/text-lg-reg */
    font-family: Calibre-R;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    /* 24.3px */
    margin-top: 24px;
}

.contBtn {
    display: flex;
    height: 56px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--blue-900);
    cursor: pointer;
    margin-top: 24px;
    color: var(--white);
    text-align: center;

    /* Additional styles/reg/text-base-reg */
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    /* 21.6px */
    margin-left: auto;
    margin-right: auto;
}

.contBtn:hover {

    background: var(--blue-800);
}
@media (max-width: 767px) {
    .innerHolder
    {
        width:90vw;
        height:auto;
        padding: 64px 0;
    }
    .coreHolder
    {
        width:90vw;
        height:auto;
    }
    .body
    {
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
    }
}
