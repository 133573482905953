.jobCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15px;
  border-radius: 8px;
  background: var(--White, #fff);
  padding: 24px;
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  /* max-width: 502px;
  min-width: 502px;
  max-height: 260px;
  min-height: 260px; */
  width: 49%;
  min-height: 260px;
}

.jobCardContainer .title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 27px */
}

.secondLine {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  padding: 14px 0 10px 0;
}

.secondLine span {
  display: flex;
  align-items: center;
}
.secondLine span:nth-child(1) {
  margin-right: 14px;
}
.secondLine span:nth-child(2) svg {
  margin-right: 10px;
}

.thirdLine {
  color: var(--Gray-500, #747474);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}

.btnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.leftBtn {
  margin-right: 30px;
}
.leftBtn:hover {
  color: var(--Blue-900, #00012b);
}
.quickViewBtn:hover {
  color: var(--Blue-900, #00012b);
}
.applyBtn {
  background: var(--Blue-900, #00012b);
}
.applyBtn:hover {
  background: var(--Blue-900, #00012b);
}

.description {
  color: var(--Blue-900, #00012b);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}

.modalFooterContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

@media (max-width: 690px) {
  .jobCardContainer {
    width: 100%;
    min-height: 229px;
    padding: 16px;
  }
  .secondLine {
    display: flex;
    flex-direction: column;
    padding: 9px 0 10px 0;
  }
  .secondLine span:nth-child(2) {
    padding-top: 8px;
  }
  .modalFooterContainer {
    margin-top: unset;
  }
}
