.filterContainer {
  width: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 100;
  padding: 16px 16px 16px 16px;
  height: 100%;
}
.checkedHolder > div > div {
  background: var(--blue-900, #00012b);
}
.filterResultContent {
  padding: 16px 0px 24px 0px;
  color: var(--Blue-900, #00012b);
  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}

.checkHolder {
  display: flex;
  justify-content: space-between;
  padding: 12px 0px 12px 0px;
}
.filterTitle {
  color: var(--Black);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  margin-bottom: 16px;
}
.filterSubTitle {
  color: var(--Black);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
}
.inputH {
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 0px 40px 0px 40px;
}

.cBox {
  width: 22px;
  height: 22px;
  border-radius: 3px;
}

.cLable {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  padding-top: 3px;
  cursor: pointer;
}
.fLowerBlock {
  margin-top: 30px;
}
.filterControlH {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filterClear,
.filterApply {
  flex: 1;
}
.filterClear button,
.filterApply button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.filterApply {
  color: var(--blue-900, #00012b);
  text-align: center;
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  margin-left: 8px;
}
.filterClear button {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  display: flex;
  align-items: center;
  cursor: pointer;
}
.mholder {
  overflow-y: auto;
  max-height: 400px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 15px;
}

.filterBottom {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  padding: 16px;
}
.closeBtn {
  background: url("../../assets/images/close-button.svg") no-repeat;
  width: 28px;
  height: 28px;
}
.filterHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20px;
}
