.tscontainer
{
    background-color: var( --white);
    border: 1px solid var(--gray-100);
    border-radius:8px;
    padding:24px;
    width:100%;
}
.tsheadersection
{
    display: flex;
    justify-content: space-between;
}
.tsheader
{
    color: var(--blue-title);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
}
.tsviewall
{
    color: var(--blue-900);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
    align-items: center;
}
.tssubheader
{
    color: var(--blue-sub-title);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    margin-top:8px;
    border-bottom: 1px solid var(--gold-500);
    padding-bottom: 1rem;
}
.tsentry
{
    display: flex;
    justify-content: space-between;
}

.tsDetailsSection
{
    margin-top:16px;
}
.tsentry::not(:last-child)
{
    border-top: 1px solid var(--gold-500);
}
.tsdate,.tshoursmins
{
    color: var(--blue-900);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
}
.tsdate
{
    margin-right:24px;
}
.tshourtext
{
    margin-right:8px;
    color: var(--gray-600);
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; 
}
.tspto_statusindicator
{
    color: var(--secondary-teal-400);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
}
.tspto_statusindicator img
{
    top: -2px;
    position: relative;
    right: 3px;
}

@media (width>992px)
{
    .tscontainer
    {
        padding:40px;
    }
    .tsDetailsSection
    {
        margin-top:35px;
    }
}