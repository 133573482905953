.payslipHolder {
  padding: 0 16px;
}
.noCardContainer {
  align-items: center;
  border-radius: 8px !important;
  display: grid;
  justify-items: center;
  margin: 0 !important;
  padding: 20px !important;
  text-align: center;
  height: 93% !important;
}
.noCardContainer h6 {
  padding-bottom: 5px;
  font-family: Calibre-R;
  font-size: 18px;
  font-weight: 500;
  line-height: 24.3px;
  text-align: center;
  color: #00012b;
  margin-top: 10px;
}
.noCardContainer p {
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: center;
}
.pageContent {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
}
.hideMyPay {
  margin-top: 16px;
}
.hideMyPay > div {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}
.hideMyPay > div > span {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}
.linkSection {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  gap: 24px;
}
.linkSection a {
  padding-left: 0;
}

@media (max-width: 768px) {
  .noCardContainer {
    height: 284px !important;
    margin-bottom: 20px !important;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1100px) {
  .payslipHolder {
    padding: 0 48px;
  }
  .pageContent {
    margin-top: 48px;
    flex-direction: row;
    gap: 4%;
  }
  .pageContent .leftContent {
    flex: 0 0 58%;
  }
  .pageContent .rightContent {
    flex: 0 0 38%;
  }
}
@media (min-width: 1300px) {
  .pageContent .leftContent {
    flex: 0 0 66%;
  }
  .pageContent .rightContent {
    flex: 0 0 30%;
  }
  .linkSection {
    flex-direction: row;
  }
  .linkSection a:first-child {
    flex: 0 0 30%;
  }
}
