/* in desktop each block is 215px width */
.mainContainer {
  width: 100%;
  background: var(--gold-100);
  padding: 32px 0px 32px 48px;
}
.scrollHolder {
  height: auto;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding-top: 50px;
}
.scrollHolder::-webkit-scrollbar {
  display: none;
}
.topHeader {
  display: flex;
}
.headerL {
  width: 90%;
}
.title {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  margin-top: 4px;
  margin-bottom: 8px;
}
.subTitle {
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 24.3px */
}
.commonBlockH {
  /*display: flex;
    gap: 32px;*/
  width: auto;
}
.commonLineBlock {
  display: inline-block;
}
.commonBlock {
  width: 200px;
  display: inline-block;
  margin-right: 15px;
  vertical-align: top;
}
.bTitle {
  color: var(--blue-900);

  /* Additional styles/med/text-lg-med */
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
}
.sTitle {
  color: var(--gray-600);

  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  margin-top: 8px;
  margin-bottom: 8px;
}
.infoIcon {
  width: 40px;
  height: 40px;
}
.infoIcon svg {
  width: 100%;
  height: 100%;
}
.infoIcon img {
  width: 48px;
  height: 48px;
}
.navHolder {
  display: flex;
}
.rightnavP {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 0px 8px 8px 0px;
  border: 1px solid var(--blue-900);
  align-items: center;
  cursor: pointer;
}
.leftnavP {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px 0px 0px 8px;
  border: 1px solid var(--blue-900);
  align-items: center;
  cursor: pointer;
}
.leftnav {
  width: 22px;
  height: 22px;
  background: url("../../assets/center-of-ex-facilities/chevron-left.svg")
    no-repeat;
  cursor: pointer;
}
.rightnav {
  width: 22px;
  height: 22px;
  background: url("../../assets/center-of-ex-facilities/chevron-right.svg")
    no-repeat;
  cursor: pointer;
}
.mapHolder {
  padding-bottom: 50px;
}
.hLine {
  width: 215px;
  border-top: 1px solid var(--blue-500);
}
.flagHolder {
  position: relative;
}
.flag {
  display: inline-flex;
  padding: 2px 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 0px 4px 4px 0px;
  background: var(--blue-500);
  color: var(--white);
  text-align: center;

  /* Additional styles/med/text-base-med */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
  position: absolute;
  top: -40px;
}
.pole {
  border-left: 1px solid var(--blue-500);
  height: 40px;
  position: absolute;
  top: -40px;
}
.flagl {
  display: inline-flex;
  padding: 2px 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 0px 4px 4px 0px;
  background: var(--blue-500);
  color: var(--white);
  text-align: center;

  /* Additional styles/med/text-base-med */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
  position: absolute;
  top: -40px;
  right: -20px;
}
.polel {
  border-left: 1px solid var(--blue-500);
  height: 40px;
  position: absolute;
  top: -40px;
  right: 0;
}
.bPole {
  border-left: 1px solid var(--blue-500);
  height: 20px;
  position: absolute;
}
.anc {
  color: var(--blue-500, #373ae5);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
}
.arrowR {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 4px;
  background: url("../../assets/center-of-ex-facilities/arrow-right.svg")
    no-repeat;
  position: relative;
  top: 2px;
}
