.tabHolder {
  margin: 0;
  border-bottom: 1px solid var(--gray-100);
  margin-bottom: 16px;
  /* display: inline-grid;
  grid-gap: 16px;
  grid-auto-flow: column; */
}
.tabHolder .tabItem {
  display: inline-block;
  color: var(--blue-900);
  line-height: 135%;
  text-decoration: none;
  padding: 16px 2px;
  margin: 0 32px 0 0;
  border-radius: 0;
  &:hover {
    color: var(--Blue-900, #00012b);
  }
}
.tabHolder .tabItem.active {
  color: var(--Blue-900, #00012b);
  font-weight: 500;
  border-bottom: 1px solid var(--Rewards-Tier-Gold, #bda770);
}

.reportsContainer {
  display: grid;
  grid-gap: 16px;
}

.toptextholder {
  /* display: flex; */
  justify-content: center;
  /* margin-top: 32px; */
  margin-bottom: 16px;
  /* padding: 0 16px; */
}

.subTextHolder {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
}

.managerTitle {
  color: #282827;
 
  /* Additional styles/med/text-2xl-med */
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
}

.managerRightSection {
  /* position: absolute; */
  /*left:var(--viewAll-left-val);*/
  color: var(--blue-900, #00012b);
  text-align: right;
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  right: 0;
  text-transform: capitalize;
  display: flex;
  gap: 8px;
  align-items: center;
}

.managerRightSection a {
  color: var(--blue-900, #00012b);
  font-weight: 400;
}

.managerTitle a:hover {
  color: var(--blue-500);
  font-weight: 500;
}
.paginationContainer {
  margin-top: 16px;
}
.paginationContainer button {
  width:fit-content;
}

@media (min-width: 768px) {
  .reportsContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1280px) {
  .reportsContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}
