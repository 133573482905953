.inputcomph {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  /*position: sticky;
    top: 0;*/
  display: flex;
  /* min-height: calc(100vh - 112px); */
}
.inputcomph_woFilter {
  flex-direction: column;
  align-items: center;
}

.contentLoaderContainer button {
  background: var(--blue-900, #00012b);
}
.contentLoaderContainer button:hover{
  background: var(--blue-800) !important;
}

.filterH {
  width: var(--asFilterWidth);
  height: 100%;
  /*float: left;*/
  background-color: var(--white);
  box-shadow: 0px 2px 16px 0px rgba(177, 150, 97, 0.2);
  align-self: flex-start;
  /* position: sticky; */
  /* check for mobile */
  top: 112px;
}

.inputHMain {
  float: left;
  width: 100%;
  padding: 0 48px;
  max-width: 860px;
}

.inputHMainless {
  /*float: left;*/
  width: calc(100% - var(--asFilterWidth));
  padding: 0 48px;
}

.inputholderless {
  width: 100%;
  display: flex;
  height: 56px;
  padding: 0;
  justify-content: center;
  align-items: center;
  gap: 3px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--blue-500);
  background: var(--White);
  margin-top: 65px;
  /* margin-top: 40px; */
}

.inputholder {
  width: 100%;
  display: flex;
  height: 56px;
  padding: 0;
  justify-content: center;
  align-items: center;
  gap: 3px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--blue-500);
  background: var(--White);
  margin-top: 80px;
}
.buttonSearch:disabled {
  background: #808080;
}
.buttonSearch {
  padding: 0 16px;
  border-radius: 0px 8px 8px 0px;
  display: inline-flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--White);
  text-align: center;
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  flex-shrink: 0;
  background: var(--blue-900);
}
.buttonSearch:hover
{
  background: var(--blue-800);
}
.input {
  display: flex;
  height: 53px;
  border: none;
  width: 90%;
  color: var(--gray-500);
  text-overflow: ellipsis;
  white-space: nowrap;
  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  /* 21.6px */
  color: var(--blue-900, #00012b);
}

.icon {
  display: flex;
  height: 56px;
  /*padding: 0px 16px;*/
  align-items: center;
  /*gap: 16px;*/
  align-self: stretch;
  /*width: 5%;*/
  width: 33px;
  background-image: url("../../assets/images/magnifying-glass.svg");
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 16px;
}

.inputh {
  width: 85%;
  position: relative;
}

.drop {
  /*display: flex;*/
  width: 100%;
  padding: 8px 24px;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 0px;
  /* border: 1px solid var(--Blue, #373AE5); */
  background: var(--White, #fff);
  /* box-shadow: 2px 2px 8px 0px rgba(55, 58, 229, 0.10); */
  color: var(--blue-900, #00012b);

  /* reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  /* 21.6px */
  cursor: pointer;
}

.suggestionH {
  width: 100%;
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--Blue, #373ae5);
  background: var(--White, #fff);
  margin-top: 8px;
  /* Interactive */
  box-shadow: 2px 2px 8px 0px rgba(55, 58, 229, 0.1);
}
.resultHCBorder {
  border-bottom: 1px solid var(--gold-500);
  padding-bottom: 16px;
  margin-top: 40px;
}
.resultHC {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  /*margin-bottom: 24px;*/
}
.resultitem:last-child {
  margin-bottom: 50px;
}
.resultitem {
  /*padding: 24px 0;*/
  padding-top: 24px;
  margin-bottom: 8px;
}

.linkh {
  display: flex;
  margin-top: 8px;
}

.topResult {
  color: var(--blue-500, #373ae5);

  /* Additional styles/sb/text-base-sb */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  /* 21.6px */
  margin-bottom: 16px;
}

.cat {
  color: var(--gray-600, #5d5d5d);

  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  cursor: pointer;
  /* 18.9px */
}

.highlight {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  /* 21.6px */
  margin-top: 8px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.titleDoc {
  color: var(--blue-900, #00012b);

  /* Additional styles/sb/text-lg-sb */
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
}
.title {
  color: var(--blue-900, #00012b);

  /* Additional styles/sb/text-lg-sb */
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  cursor: pointer;

  /* 24.3px
    margin-top: 8px; */
}

.url, .resultitem a {
  color: var(--blue-500, #373ae5);

  /* Additional styles/reg/text-xs-reg */
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  /* 16.2px */
  margin-top: 8px;
  cursor: pointer;
}
.url:hover, .resultitem a:hover {
  font-weight: bold;
  color: var(--blue-500, #373ae5);
}
.con {
  padding: 24px;
  margin-top: 40px;
  margin-bottom: 16px;
}
.st {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  text-transform: capitalize;
}
.num {
  color: var(--blue-900, #00012b);

  /* Desktop base kit/Body & Label */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  width: 260px;
  margin-left: 5px;
}
.preview {
  margin-right: 16px;
  cursor: pointer;
}
.clink {
  cursor: pointer;
}
.ddHolder {
  margin-left: auto;
  width: 106px;
}
.sortddHolder {
  /*display: flex;
    justify-content: end;*/
  margin-left: auto;
}
.sortddHolder span {
  width: 100%;
}
.iconmGlass {
  background-image: url("../../assets/images/magnifying-glass_ofwhite.svg");
  background-repeat: no-repeat;
}
.maxWidthForSearchPage {
  max-width: 760px;
}
.clearIcon {
  background-image: url("../../assets/search/Icon_small_outlined_Symbol_x_circle_1.svg");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 15px;
  right: 0;
  cursor: pointer;
}
.iconmGlass {
  margin: 10px 0;
  padding-left: 33px;
  font-size: 16px;
  cursor: pointer;
  width: 50%;
  word-wrap: break-word;
}
.itemHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: auto;
}
.trendingSearchContainer {
  margin: 40px 0;
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  padding: 27px 24px;
  color: #00012b;
}

/* page loader */
.contentLoaderContainer {
  /*max-width: 760px;*/
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 64px;

  flex-direction: column;
  align-items: center;
  color: #00012b;
}
.titleDoc {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  cursor: pointer;
  margin-left: -6px;
  display: flex;
  align-items: center;
}
.contentLoader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #7d7c7c;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-top: 10px;
}

.resultTitle
{
  color: var(--blue-900);

  /* Additional styles/med/text-2xl-med */
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  margin: 24px 0;
}
.appResultTileOuter
{

}
.appResultTile
{
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 32px;
  border-bottom: 1px solid var(--gold-500);
  box-sizing: border-box;
  min-height: 120px;
  max-height: 120px;
}
.appTitle
{
  overflow: hidden;
  color: var(--blue-900);
  text-overflow: ellipsis;

  /* Additional styles/med/text-base-med */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
  
}
.iconHolder
{
  width: 72px;
  height: 72px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--gray-100);
  background: #FFF;
  display: flex;
}
.icon
{
  width: 56px;
  height: 30px;
  flex-shrink: 0;
  margin: auto;
}
.appResultTileHolder
{
  margin-bottom: 24px;
}
.appResultTileHolder a
{
  text-decoration: none;
}
.description
{
  color: var(--Gray-600, #5d5d5d);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* mobile */
.buttonGroup {
  display: flex;
  justify-content: space-between;
}
.buttonGroup .filterButton {
  border-radius: 20px;
  font-size: 14px;
  color: var(--White);
  padding: 14px 16px;
  background: var(--blue-900);
}
.buttonGroup .filterButton:not([disabled]):hover
{
  background: var(--blue-800);
}
.circleNumber {
  border: 1px solid #000;
  border-radius: 14px;
  padding: 2px 7px;
  font-size: 12px;
}

.innerHtml p {
  /* color: red; */
  font-size: 20px;
  /* font-weight: 500; */
  margin-bottom: 24px;
}
.innerHtml strong {
  font-weight: 500;
}
.innerHtml ul li {
  font-size: 18px;
}
.innerHtml ul {
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .inputHMain {
    padding: 0 16px;
  }
  .iconmGlass {
    width: 100%;
  }
  .itemHolder {
    flex-direction: column;
  }
  .num {
    margin-top: 8px;
  }
}

/* desktop */
@media (min-width: 1024px) {
  .highlight {
    -webkit-line-clamp: 2;
  }
  .resultHC {
    flex-direction: row;
  }
}
