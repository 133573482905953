.container {
  padding: 10px;
  min-width: 300px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  background: #fff;
  position: absolute;
  z-index: 1;
}
.stickyFooter,
.currentBenefitStickyFooter {
  position: absolute;
  bottom: 0;
  background-color: #f5f5f5;
  z-index: 10;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-top: 2px solid var(--gold-500);
}
.footerArea {
  text-align: right;
  /* display: flex; */
  gap: 16px;
  margin-bottom: 24px;
  justify-content: flex-end;
  width: 100%;
  align-items: flex-end;
  display: inline-grid;
  grid-auto-flow: column;
}

/* temp solution */
.currentBenefitFooterTotal {
  grid-template-columns: minmax(15%, 300px) 15% 15% 13.5% 15% !important;
}
.stickyFooterBottomArea {
  /* margin-top: 9px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
@media (max-width: 1023px) {
  .stickyFooterBottomArea {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* background-color: #ffffff; */
  }
}
.iconContainer {
  cursor: default;
  /* margin-top: 24px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 240px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
}
.iconContainer span {
  display: flex;
  align-items: center;
  color: var(--Gray-600, #5d5d5d);
  font-size: 12px;
}
.iconContainer svg {
  margin-right: 6px;
}

.stickyFooterBtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stickyFooterBtnContainer a strong {
  font-weight: 600 !important;
}
.bottomMargin {
  margin-bottom: 24px;
  display: flex;
  justify-content: FLEX-END;
}
.stickyFooterOutlineBtn {
  background: none !important;
  border: 1px solid var(--Blue-900, #00012b) !important;
  color: #00012b !important;
  margin-right: 16px;
}
.stickyFooterPrimaryBtn {
  background: var(--Blue-900, #00012b) !important;
  /* display: inline-flex; */
  width: fit-content !important;
}
.stickyFooterPrimaryBtn:hover {
  background: var(--blue-800) !important;
}
.closeButton {
  position: absolute;
  height: 40px;
  width: 40px;
  justify-content: center;
  top: 3px;
  right: 0;
  border-radius: 50%;
}
.closeButton:hover {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: rgba(0, 1, 43, 0.1);
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 101;
  background: rgba(0, 0, 0, 0.4);
}

.innerHolder {
  width: 95vw;
  height: auto;
  padding: 64px 0;
}

.noBenefitInnerHolder {
  min-height: 200px;
  height: auto;
}
.tabHolder {
  margin: 0;
  border-bottom: 1px solid var(--gray-100);
}
.tabHolder .tabItem {
  display: inline-block;
  color: var(--blue-900);
  line-height: 135%;
  text-decoration: none;
  padding: 16px 2px;
  margin: 0 15px;
  border-radius: 0;
}
.tabHolder .tabItem.active {
  color: var(--Blue-900, #00012b);
  font-weight: 500;
  border-bottom: 1px solid var(--Rewards-Tier-Gold, #bda770);
}

.cross {
  top: 10px;
  right: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: url("../../assets/sitemaintanance/cross.svg");
}
.hide {
  display: none;
}
.coreHolder {
  position: relative;
  height: auto;
  max-height: 90vh;
  overflow: hidden;
}

.viewCurrentBenefitTable {
  height: calc(90vh - 250px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 30px;
}

/* temp fix remove once get permanent solution */
.currentBenfitWelCreditTotal {
  grid-template-columns: minmax(15%, 300px) 15% 20% 17% 14% !important;
}

.currentBenefitsTable {
  height: calc(90vh - 190px);
}

.viewCurrentBenefitTable::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.viewCurrentBenefitTable::-webkit-scrollbar {
  margin-top: 10px;
  width: 8px;
  background-color: #f5f5f5;
}
.closeButton {
  /* flex: 0 0 32px; */
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.closeButton:hover {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: rgba(0, 1, 43, 0.1);
}
.viewCurrentBenefitTable::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--gray-100);
}

.viewCurrentBenefitTable table,
.viewCurrentBenefitTable tr,
.viewCurrentBenefitTable td,
.viewCurrentBenefitTable th,
.viewCurrentBenefitTableMob th,
.viewCurrentBenefitTableMob table {
  width: 100%;
  text-align: left;
}

.viewCurrentBenefitTable td {
  padding: 20px 20px 20px 0;
}
/* padding for last two column to be corrected */
.viewCurrentBenefitTable th {
  padding: 16px 20px 8px 0 !important;
  vertical-align: bottom;
}
.benefitLabel p {
  color: var(--Blue-900, #00012b);
  font-weight: 500;
}

.viewCurrentBenefitTable th {
  padding: 20px 0 7px;
  position: sticky;
  top: 0;
  background: #faf9f5;
}

/* TODO => find better solution, this is bad css, absolute position to enitre area
 - there were issues with trying to add border to thead, tr, th, <hr> didn't work as well*/
.coreHolder::after {
  content: "";
  position: absolute;
  width: 100%;
}

thead::after {
  content: "";
  position: absolute;
  border-bottom: 2px solid var(--gold-500);
  width: 100%;
}

.viewCurrentBenefitTable td {
  border-bottom: 1px solid var(--gray-100);
}

.mobileHeader {
  border-top: 1px solid var(--gold-500);
  border-top: 1px solid var(--gray-100);
}

.viewCurrentBenefitTableMob td,
.viewCurrentBenefitTableMob th,
.mobileHeader {
  padding: 10px 10px 10px 0;
  text-align: left;
}

.benefitsRow {
  width: 100%;
  border-bottom: 1px solid var(--gray-100);
}

.benefitsRow div {
  padding: 7px 0;
}

.benefitsPlanHeader {
  margin: 20px 0 10px 0;
  width: 95%;
}

.viewCurrentBenefitTableMob {
  height: 100vh;
}

.viewCurrentBenefitTable th,
.viewCurrentBenefitTableMob th,
.benefitsHeader {
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  color: var(--blue-900);
  background: var(--gray-25) !important;
}

.viewCurrentBenefitTableMob td {
  border-top: 1px solid var(--gold-500);
}

.viewCurrentBenefitTableMob th,
.benefitsHeader {
  font-weight: 600;
}

.viewCurrentBenefitTableMob tr {
  margin-bottom: 30px;
  border-spacing: 0 30px;
  border-collapse: separate;
}

.benefitsRow {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  grid-template-columns: 56% 40%;
}

.innerHtml {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 24px;
}
.innerHtml p {
  margin-top: 20px;
  /* color: #606060; */
  color: var(--blue-900, #00012b);
  /* color - check in figma for mobile */
}

.innerHtml li {
  color: var(--blue-900, #00012b);
}

.innerHtml p strong {
  font-weight: 600;
}

.viewCurrentBenefitTable td:not(:nth-child(1)) {
  font-family: Calibre-R;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  text-align: left;
  color: var(--gray-700);
}

.viewCurrentBenefitTable td:nth-child(1) {
  font-family: Calibre-R;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  text-align: left;
  color: var(--blue-900);
}
.benefitLabel {
  font-weight: 500 !important;
  text-align: left;
  font-family: Calibre-R;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  /*color: var(--gray-700);*/
  color: var(--Blue-900, #00012b);
}

.benefitValue {
  text-align: right;
  font-family: Calibre-R;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  /* color: var(--blue-900); */
  color: var(--gray-700);
  align-self: center;
}

.noRecordFound {
  margin: 2rem auto;
  width: 100%;
  display: grid;
  text-align: center;
}

.loadBenefits {
  margin-top: 2rem;
  width: 100%;
  text-align: center !important;
  border: 1px solid transparent !important;
}

.benefitsTable [data-testid="modal-children"],
.viewCurrentBenefitTable [data-testid="modal-children"] {
  overflow: hidden;
}

.viewCurrentBenefitsContainer
  [data-testid="modal-overlay"]
  dialog
  [data-testid="modal-children"] {
  overflow: hidden;
}

.benefitsTable {
  overflow-y: scroll;
  padding-bottom: 50px;
  max-height: calc(90vh - 220px);
}

.benefitsTableLongFooter {
  overflow-y: scroll;
  padding-bottom: 70px;
  max-height: calc(100vh - 450px);
}

.head {
  width: 90%;
  color: var(--blue-900);
  text-align: left;
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

.body {
  color: var(--gray-800);
  text-align: center;
  width: 415px;
  margin: 0 auto;
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  margin-top: 10px;
}

.contBtn {
  display: flex;
  height: 56px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--blue-900);
  cursor: pointer;
  margin-top: 24px;
  color: var(--white);
  text-align: center;
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  margin-left: auto;
  margin-right: auto;
}

.contBtn:hover {
  background: var(--blue-800);
}

/* check for desktop - might needs to reset */
.benefitsPlanHeader:first-child {
  margin-top: 24px;
}
.benefitsPlanHeader:not(:first-child) {
  margin-top: 32px;
}
@media (max-width: 1022px) {
  .stickyFooterBtnContainer {
    margin-top: 24px;
    min-width: 100%;
  }
  .stickyFooterPrimaryBtn {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .coreHolder {
    /* width: 90vw; */
    height: auto;
    /* max-height: unset; */
    max-height: 100vh;
  }
  .body {
    width: 95%;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.container > h2 {
  font-size: 14px;
}
.container > p {
  font-size: 11px;
  margin: 7px 0;
}
.container > hr {
  border: 1px solid #99845d;
}
/* tab */
.container > .button {
  padding: 3px 0;
  width: 100%;
  display: flex;
}

.btnContainer {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-auto-flow: column;
  grid-gap: 1rem;
}
.btnContainer > button:nth-child(2) {
  background: var(--blue-900);
}

.btnContainer > button:nth-child(2):hover {
  background: var(--blue-800);
}

.container > button:last-child {
  margin: 10px 0;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.25);
}
.modalError {
  position: fixed;
  display: flex;
  width: 100%;
  top: 50px;
  z-index: 101;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  text-align: center;
}
.mobilenav {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mobilenav select {
  width: 100%;
  padding: 15px;
  color: var(--blue-900);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  margin-bottom: 0;
  border-radius: 8px;
  border: 1px solid var(--gray-100);
  background: var(--white);
  box-shadow: 2px 2px 8px 0px rgba(55, 58, 229, 0.1);
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../../assets/images/chevron-down.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
}
.viewCurrentBenefitsContainer dialog {
  max-width: 1000px !important;
  background-color: var(--gray-25) !important;
  /* height: 90%;
  max-height: 1000px; */
}

.modal {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 101;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.modal > div {
  display: flex;
  flex-direction: column;
  pointer-events: all;
  background: var(--gray-25) !important;
  padding: 0px;
  border-radius: 14px;
}
.modalStyleColor {
  background-color: #f5f5f5 !important;
  max-width: fit-content !important;
}

@media (max-width: 1022px) {
  .modal > div {
    height: 100%;
    width: 100%;
    border-radius: 0;
  }
}
.modal .modalHeader {
  gap: 24px;
}
.modal .closeButton {
  flex: 0 0 32px;
}
.modalHeader h3 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  color: var(--blue-900, #00012b);
  text-align: center;
}
.modal .content {
  overflow-y: auto;
}
.modal .content h4 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
}
.modal .content li,
.modal .content p {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 24.3px */
}

.imgHolder {
  text-align: center;
}
.imgHolder img {
  height: 46px;
  width: 46px;
}

.footerTotal,
.currentBenefitFooterTotal {
  display: flex;
  justify-content: space-between;
  display: grid;
  grid-auto-flow: column;
  color: var(--Blue-900, #00012b);
  font-weight: 500;
  border-top: 2px solid #d8c5a5;
  padding: 16px 8px 16px 0;
  width: 100%;
  gap: 30px;
}

.footerTotal th,
.currentBenefitFooterTotal th {
  position: relative;
}
.stickyFooter th,
.stickyFooter td {
  width: 100% !important;
  padding: 0 50px 0 0;
}

.currentBenefitStickyFooter th,
.currentBenefitStickyFooter td {
  width: 100% !important;
  padding: 0 46px 0 0;
}

.coreHolder {
  /* height: 79vh; */
  height: 90vh;
}
@media (max-width: 768px) {
  .footerTotal,
  .currentBenefitFooterTotal {
    padding-top: 16px;
  }
}
@media (min-width: 768px) {
  .modal {
    padding-bottom: 24px;
  }
  .footerTotal,
  .currentBenefitFooterTotal {
    border-top: none;
  }
  .cross {
    position: absolute;
    top: 3px;
    right: 0;
  }
  .closeButton {
    /* flex: 0 0 32px; */
    position: absolute;
    height: 40px;
    width: 40px;
    justify-content: center;
    top: 3px;
    right: 0;
    border-radius: 50%;
  }
  .closeButton:hover {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: rgba(0, 1, 43, 0.1);
  }
  .head {
    width: 100%;
    padding-bottom: 16px;
    padding-top: 4px;
  }
  .innerHolder {
    min-height: 457px;
    height: 90%;
    border-radius: 16px;
    background: var(--gray-25);
    padding: 64px 102px;
    position: relative;
  }
  .noBenefitInnerHolder {
    width: 100% !important;
    border-radius: 16px;
    background: var(--gray-25);
    padding: 64px 102px;
    position: relative;
  }
}
@media (min-width: 1024px) {
  .footerTotal,
  .currentBenefitFooterTotal {
    grid-template-columns: minmax(15%, 300px) 12% 12% 12% 16% 15%;
    justify-content: left;
    gap: 0;
  }
  .stickyFooter,
  .currentBenefitStickyFooter {
    position: sticky;
  }
  .enableCredits {
    grid-template-columns: minmax(15%, 300px) 15% 15% 15% 14.5% 15%;
    justify-content: space-around;
  }
  .modal {
    z-index: 30;
  }
  .totalPayback {
    text-wrap: wrap;
    position: absolute;
    left: 0;
  }
  .modal > div {
    padding: 0px;
  }
  .coreHolder {
    height: 100%;
    overflow: hidden;
  }
  .innerHolder {
    width: 998px;
    /* max-height: 723px; */
    max-height: 90%;
  }
  .noBenefitInnerHolder {
    width: 60%;
  }
}
.benefitsRow p {
  font-size: 14px;
}

.openACaseButton {
  color: #464646 !important;
  font-weight: 600 !important;
  text-decoration: underline !important;
  font-size: 16px !important;
  padding: 8px !important;
}
.openACaseButton:hover {
  color: #464646 !important;
  background: none !important;
}
.openACaseButton:hover:not([disabled]) {
  color: #464646 !important;
  background: none !important;
}
.colStyle2 {
  text-align: left !important;
}

.tabItem:disabled {
  color: #b3b3b3 !important;
}
.tabItem {
  &:focus,
  &:focus-visible,
  &:hover {
    color: var(--blue-900) !important;
  }
}
.starIconContainer {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: 24px auto;
  grid-gap: 8px;
  /* max-width: 24px; */
}

.starFlexContainer {
  display: flex;
  align-items: center;
}

th,
td {
  width: 15% !important;
}
