.empIdH
{
    width:100%;
    height: 80px;
    box-sizing: border-box;
    padding-left: 40px;
    background: var(--blue-900);
    display: flex;
    align-items: center;
}
.emplab
{
    color: var(--gold-25);

    /* Additional styles/med/text-base-med */
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 21.6px */
}
.inputH
{
    display: flex;
    height: 48px;
    padding-left: 16px;
    align-items: center;
    gap: 16px;
    
    border-radius: 8px;
    border: 1px solid var(--blue-500);
    background: var(--White);
    width:220px;
    margin-left: 24px;
}
.inp
{
    overflow: hidden;
    color: var(--blue-900);
    text-overflow: ellipsis;
    border:none;

    /* Additional styles/reg/text-base-reg */
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 21.6px */
}
.btn
{
    display: inline-flex;
    height: 48px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 0px 6px 6px 0px;
    background: var(--blue-500, linear-gradient(225deg, #373AE5 14.64%, #292CB7 50%));
    border: none;
    color:var(--White);
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 18.9px */
}
.empBody
{
    width:100%;
    height: 100%;
}
.empMsg
{
 display: flex;
 justify-content: center;
 flex-direction: column;
}
.empMsg1
{
    color: var(--blue-900);
    text-align: center;

    /* Additional styles/med/text-lg-med */
    font-family: Calibre-R;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 24.3px */
    margin-top: 50px;
}

.empMsg2
{
    color: var(--blue-900, #00012B);
    text-align: center;

    /* Additional styles/reg/text-base-reg */
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 21.6px */
    width: 426px;
    margin: 14px auto;
}
.empMsg3
{
    color: var(--blue-900, #00012B);
    

    /* Additional styles/reg/text-base-reg */
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 21.6px */
    
}
.empMsg4
{
    color: var(--blue-900);
    text-align: center;

    /* Additional styles/med/text-lg-med */
    font-family: Calibre-R;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 24.3px */
  
}
.empErrorHolder
{
    width:100%;
    display: flex;
    justify-content: center;
}
.empError
{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 450px;
    padding: 16px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--alerts-red-600);
    background: var(--alerts-red-25);
}
.empResult
{
    display: flex;
    width: 280px;
    padding: 8px 24px;
    flex-direction: column;
    align-items:left;
    border-radius: 8px;
    border: 1px solid var(--blue-500);
    background: var(--White);
    height: 48px;
    padding: 0px 24px;  
    /* Interactive */
    box-shadow: 2px 2px 8px 0px rgba(55, 58, 229, 0.10);
    justify-content: center;
    position: relative;
    top: 55px;
    left: -219px;
    cursor: pointer;
    color: var(--blue-900);

    /* reg/text-base-reg */
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 21.6px */
}
.agentpagecontainer
{
    overflow: hidden;
    width: 100%;
    margin-top: 32px;
}