.bgOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
}
.helperBG {
  position: fixed;
  width: 100%;
  height: 100%;
}
@media (min-width: 1024px) {
  .mainHolder {
    margin: 0 auto;
    max-width: 1440px;
    padding: 16px 36px;
    position: relative;
  }
}

.wrapper {
  float: right;
  padding-right: 255px;
  padding-top: 5px;
}
.inputContainer {
  width: 584px;
}
.suggestHeaderBox ul {
  padding: 0;
  border-radius: 8px 8px 8px 8px;
  outline: 1px solid var(--Blue, #373ae5);
  background: var(--White, #fff);
  margin-bottom: 0;
}
.suggestHeaderBox ul li {
  list-style-type: none;
  padding: 13px 24px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.inputInnerContainer {
  position: relative;
  width: 93%;
  /* try with px .. */
}

/* Also in GLobalSearch input */
.clearIcon {
  background-image: url("../../assets/search/icon_cross.png");
  background-repeat: no-repeat;
  width: 21px;
  height: 21px;
  position: absolute;
  top: 9px;
  right: 0;
  cursor: pointer;
}

/* from header */

.inputOuter {
  width: 584px;
  height: 40px;
  border: 1px solid var(--Blue-500, #373ae5);
  border-radius: 8px;
  padding: 0 0 0 17px;
  background-color: #fff;

  display: flex;
}
.input {
  border: 0;
  width: calc(100% - 40px);
  padding-right: 50px;
}
.searchIconInside {
  background: url("../../assets/search/icon-magnifying-glass.svg") no-repeat
    #fff 98%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  align-self: center;
}

.searchPopUpBox {
  position: absolute;
  top: 65px;
  z-index: 6;
  width: 584px;
  border-radius: 8px;
  border: 1px solid var(--Light-gray-stroke, #d1d1d1);
  background: var(--White, #fff);
}
.trendingSearchContainer {
  padding: 27px 24px;
  color: #00012b;
  height: 235px;
}

.iconmGlass {
  background-image: url("../../assets/images/magnifying-glass_ofwhite.svg");
  background-repeat: no-repeat;
  margin: 10px 0;
  padding-left: 33px;
  font-size: 16px;
  cursor: pointer;
  width: 50%;
  word-wrap: break-word;
}
.itemHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: auto;
}

@media (min-width: 768px) {
  /* .snackbarFav > div:nth-child(1) {
    position: absolute;
    width: fit-content;
  } */
}

/* @media (max-width: 768px) {
  .iconmGlass {
    width: 100%;
  }
} */
