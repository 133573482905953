.tile {
  border-radius: 8px 8px 8px 8px;
  /* border: 1px solid var(--gray-50); */
  overflow: hidden;
  /* margin-bottom: 24px; */
  position: relative;
  cursor: pointer;
  gap: 24px;
  background: var(--white, #fff);
}

.tile:hover {
  box-shadow: 0px 2px 16px 0px rgba(177, 150, 97, 0.2);
}
.tile .image {
  width: 100%;
  height: 162px;
  text-align: center;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}
.tile .image img {
  max-width: 100%;
  height: 100%;
}
.tile .content {
  padding: 24px 16px;
  height: 225px;
  position: relative;
}
.tile .content.withCta {
  /* margin: 24px 16px 0 16px;
  padding: 0; */
}
.tile .content h3 {
  margin-bottom: 16px;
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.tile .content p,
.tile .content span {
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  /*
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  */
}
.tile .content .buttonHolder {
  position: absolute;
  bottom: 16px;
  z-index: 1;
}

.limitedTimeOffer {
  position: absolute;
  top: 16px;
  left: 0;
  z-index: 7;
  font-size: 14px;
  color: #fff;
  background: #373ae5;
  padding: 0px 8px;
}
.cardBtn {
  position: absolute;
  bottom: 24px;

  display: flex;
  height: 40px;
  /* padding-top:24px;
  padding-bottom:24px; */
  /* margin-bottom:24px; */
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--blue-900, #00012b);
  cursor: pointer;
  padding: 10px 16px;
  text-align: center;
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.description {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 135%;
}
