/* .edit_text {
  color: var(--blue-700, #1b1d88);
  font-family: Calibre-R;
  font-size: 14px !important ;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: underline;
  margin-left: 40px;
  justify-content: flex-end;
  white-space: nowrap;
  margin-left:auto;
  margin-right: 16px;
}
.personal_info_text {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  width:100%;
  gap:8px;
display: flex;
  margin-top: 40px;
}
.personal_text{
  white-space: nowrap;
} */
.option-block {
  /* display: inline-flex;
  gap: 16px; */
  grid-gap: 16px;
  display: inline-grid;
  grid-auto-flow: column;
}
.option-style {
  /* display: inline-flex;
  gap: 12px;
  margin-bottom: 24px; */
  grid-gap: 12px;
  display: inline-grid;
  grid-auto-flow: column;
  margin-bottom: 24px;
}
.option-style > label {
  color: var(--Blue-900, #00012b);
  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.radio-required {
  color: var(--Alerts-Red-600, #d20b02);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;

  margin-bottom: 26px;
  font-weight: 400;
}
.addLicenseLink
{
    color: var(--gray-600);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    text-decoration: underline;
    cursor: pointer;
}
.edit-popup>div{
  z-index: 20;
}
.question-error {
  color: var(--Alerts-Red-600, #d20b02);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  margin-bottom:16px;

  font-weight: 400;
}
.google-text-error{
  color: var(--Alerts-Red-600, #d20b02);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
}
.modal-text {
  color: var(--blue-900, #00012b);
  /* Additional styles/med/text-xl-med */
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.reportsto-text {
  color: var(--gray-600, #5d5d5d);
  /* Additional styles/sb/text-sm-sb */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  margin-bottom: 10px;
}
.supervisorname-text {
  color: var(--blue-900, #00012b);
  /* Additional styles/med/text-sm-med */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.supervisordetails-txt {
  color: var(--gray-700, #464646);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.supervisor-email,
.supervisor-email a {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  margin-top: 10px;
}

.supervisor-ph,
.supervisor-ph a {
  color: var(--gray-700, #464646);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  margin-top: 10px;
}

.modal {
  display: flex;
  justify-content: center;

  position: fixed;
  top: 10%;
  left: 30%;
  width: 35%;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 16px;
  background: var(--gold-25, #faf9f5);
}
.textfield-name {
  align-self: stretch;
  color: var(--gray-600, #5d5d5d);
  background-color: #f5f5f5;
  /* Additional styles/reg/text-xs-reg */
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.textfield-input {
  align-self: stretch;
  border-color: transparent;
  color: var(--blue-900, #00012b);
  background-color: #f5f5f5;
  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 113%;
  border-bottom: 0px solid var(--gray-400, #8b8b8b);
}
.textfield-name-enabled {
  align-self: stretch;
  color: var(--gray-600, #5d5d5d);

  /* Additional styles/reg/text-xs-reg */
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.textfield-input-enabled {
  align-self: stretch;
  border-color: transparent;
  color: var(--blue-900, #00012b);

  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 113%;
  background: var(--White, #fff);
  border-bottom: 0px solid var(--gray-400, #8b8b8b);
}

.modal-content {
  background-color: var(--gold-25);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}
.edit-subheading1 {
  color: var(--gray-600, #5d5d5d);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  margin-top: 8px;
  /* margin-bottom: 24px; */
}
.edit-subheading2 {
  color: var(--gray-600, #5d5d5d);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 135%;
}
.textbox-edit {
  /* display: flex;
  height: 56px;
  padding: 0px 16px;
  align-items: center;
  margin-top: 18px;
  gap: 16px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 8px 8px 0px 0px;
border-bottom: 0.5px solid var(--gray-400, #8B8B8B);
/* background: var(--White, #FFF); */
  /* background-color: #f5f5f5; */

  /* margin-left:10px; */
  margin-bottom: 24px;
}
.text-autocomplete {
  margin-bottom: 24px;
  padding: 16px;
}
.textbox-edit-enabled {
  display: flex;
  height: 56px;
  padding: 0px 16px;
  align-items: center;
  margin-top: 18px;
  gap: 16px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 0.5px solid var(--gray-400, #8b8b8b);
  background: var(--White, #fff);

  margin-left: 10px;
}
.parking {
  color: var(--Black, #000);

  /* Additional styles/med/text-base-med */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
}
.parkingDescription {
  color: var(--gray-700, #464646);

  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  margin-bottom: 34px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
/* .textbox-edit-new{
width: 20%;
} */
.css-1dgluof {
  height: 261px !important;
  overflow-y: scroll !important;
  overflow-x: scroll !important;
}
.css-h8ofm1 {
  min-width: 200px !important;
}
.css-1dgluof {
  min-width: 200px !important;
}
.css-1rpmf7b {
  min-width: 200px !important;
}
.saveButton{
  gap:16px;
  margin-top:16px;
  /* margin-bottom:16px; */
  float: right;
}
.profileSaveButton{
  background: var(--blue-900) !important;
}
.profileSaveButton:hover{
  background: var(--blue-800) !important;
}
/* .saveButton{
  justify-content: right;
}
.saveButton>button:nth-child(2){
  background: var(--blue-900);
} */
/* .saveButton {
  position: relative;
  left: 75%;
  padding: 10px;
  padding-bottom: 20px;
}
.saveButton{
  background: var(--blue-900) !important;

} */
/* .css-nzxxfo:hover{
  background-color: var(--blue-800) !important;

} */

.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
  margin-top: 15px;
  border-style: none !important;
}
.pencil_icon {
  display: inline-flex !important;
  align-items: center !important;
  padding-left: 0px !important;
  gap: 4px;
  margin-top: -9px;
  padding-top: 5px;
}
p {
  margin: 0;
  padding: 0;
}
.modal-mobile {
  border-radius: 16px;
  background: var(--gold-25, #faf9f5);
}
.handle-scroll > div:nth-child(2) > div:nth-child(2) {
  overflow-y: hidden;
}

/* .editProfileContainer input {
  width: 50%;
} */

.editProfileContainer .css-l8nord  {
  padding:0px 24px !important;
}  

.edit-scrollbar {
  /* height: 90%; */
  overflow-x: hidden;
  margin-bottom: 24px;
  /* overflow-y: scroll; */
}

.radio-lang {
  display: inline-flex;
  height: 40px;
  padding: 9px 0px;
  align-items: center;
  gap: 40px;
  flex-shrink: 0;
}
.text-radio {
  display: inline-flex;
  gap: 5px;
  margin-right: 40px;
  width: 16px;
  height: 16px;
}
.text-radio > div {
  width: 16px !important;
  height: 16px;
}
.text-radio-symbol > div:nth-child(1) {
  width: 16px !important;
  height: 16px;
  /* border: none; */
}
.text-radio-symbol {
  display: inline-flex;
  gap: 5px;
  margin-right: 40px;
  width: fit-content;
  height: 16px;
}
.radio-lang-nonsymbol {
  width: 16px !important;
  height: 16px;
  color: var(--blue-900, #00012b);
  /* Additional styles/med/text-base-med */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.radio-lang-nonsymbol-unchecked {
  width: 16px !important;
  height: 16px;
  color: var(--blue-900, #00012b);
  /* Additional styles/med/text-base-med */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.radio-lang > div > div > div {
  width: 16px !important;
  height: 16px;
}
.radio-lang > div > div > input {
  width: 16px;
  height: 16px;
}
.radio-lang-symbol {
  width: 75%;
  color: var(--blue-900, #00012b);
  /* Additional styles/med/text-base-med */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.radio-lang-symbol-unchecked {
  width: 75%;
  color: var(--blue-900, #00012b);
  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

 .deleteButton>svg:hover{
  background: url("../assets/images/trash-icon-bold.svg");
  /* color: var(--blue-500) !important;
  box-shadow: 0px 2px 16px 0px rgba(3, 217, 17, 0.2); */
}

.flexBox {
  display: grid;
  gap: 20px;
  grid-auto-flow: column;
  grid-template-columns: 52% 30% auto;
  /* align-items: center; */
  justify-content: center;
}
.css-1719qsp {
  height: auto !important;
  width: auto !important;
}
snackbar-box-container {
  position: relative;
}
.snackbar-box > div:nth-child(1) {
  background-color: #004724 !important;
  position:fixed;
  bottom:50px;
}
.snackbar-box-profile > div:nth-child(1) {
  background-color: #00012B !important;
  position: absolute;
  bottom: 0;
  /* left: 0; */
  right: 0;
  top:0;
  text-align: center;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-auto-flow: column;
  z-index: 99;
}
.snackbar-box-launch-contest > div:nth-child(1) {
  background-color: #004724 !important;
  position: absolute;
  top: 0;
  /* left: 0; */
  right: 0;
  top: 2rem;
  text-align: center;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-auto-flow: column;
}
.css-1s0o1pg{
  background: var(--blue-900) !important;
}
@media (max-width: 1199px) {
  .snackbar-box-launch-contest > div:nth-child(1) {
  position: relative;
  margin: auto;
  top: unset
}
/* .flexBox {
  display: flex;
  gap: 20px;
} */
}
.css-s6yt6b {
  width: 60% !important;
  background: var(--gray-25) !important;
}
.textbox-edit input{
  padding-bottom:0px;
  padding-top:0px;
}

.autocomplete {
  padding: 16px;
  margin-bottom: 24px;
  width: 100%;
  height: 56px;
  /* width: 100%; */
  background: #ffffff;
  border-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid transparent;
  border-bottom: 1px solid #5d5d5d;
}
.autocomplete-focus{
  padding: 16px;
  margin-bottom: 24px;
  width: 97%;
  height: 56px;
  /* width: 100%; */
  background: #ffffff;
  border-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid blue;
  /* border-bottom: 1px solid blue; */
  border: 1px solid var(--blue-900) !important;
}
.autocomplete-error{
  padding: 16px;
  margin-bottom: 0px;
  width: 97%;
  height: 56px;
    background: #FFFFFF;
    border-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid #d20b02;
    border-bottom: 1px solid #d20b02;
}
.google-label {
  font-size: 12px;
  font-weight: 400;
  font-style: Calibre-R;
  color: #5d5d5d;
  margin-bottom:16px;
  margin-top:6px;
}
.google-text {
  font-size: 12px;
  font-weight: 400;
  /* font-style: Calibre-R; */
  color: #5d5d5d;
  
}
.add-more-license{
  margin-top: 16px;
}

.licensePlateDropdown li {
  border-radius: 8px !important;
}

.deleteButton {
  position: relative;
  top: 8px;
  cursor: pointer;
  align-self: center;
}
/* .customInputbox1{
box-shadow: none;
} */

.css-43x9qw{
  box-shadow: 0px 1px 0px 0px var(--blue-900) !important;
  }
  .css-1orv65f{
    outline: solid 1.5px var(--blue-900) !important;
    box-shadow: none !important;
  }
  .licensePlate input {
    padding-top: 0 !important;
    /* justify-content: space-between; */
    /* align-items: baseline !important; */
  }
@media (width<=825px) {
  .saveButton {
    position: relative;
    /* left: 60%; */
    /* padding: 10px;
    padding-bottom: 20px; */
    float: none;
  }
  .css-1dgluof {
    height: 200px !important;
    overflow-y: scroll !important;
    overflow-x: scroll !important;
  }
  .css-h8ofm1 {
    min-width: 180px !important;
  }
  .deleteButton>svg:focus{
    background: url("../assets//images//Icon-small_filled_Trash-can.svg");
    /* color: var(--blue-500) !important;
    box-shadow: 0px 2px 16px 0px rgba(3, 217, 17, 0.2); */
  } 
}
@media(width<=285px){
  .saveButton {
    position: relative;
    left: 40%;
    padding: 10px;
    padding-bottom: 20px;
    float: none;
  }
}
@media(min-width:1023px){
  .textbox-edit-new{
   
    height:84px !important;
    /* margin-left:-12px !important;   */
  }
 .textbox-edit-new-platenumber label{
  /* height:24px !important; */
 }
}
@media(min-width:769px){
  .edit_text {
    color: var(--blue-700, #1b1d88);
    font-family: Calibre-R;
    font-size: 14px !important ;
    font-style: normal;
    font-weight: 400;
    /* line-height: 20px; */
    text-decoration: underline;
    margin-left: 16px;
    justify-content: flex-end;
    /* white-space: nowrap;
    margin-left:auto;
    margin-right: 16px; */
  }
  .personal_info_text {
    color: var(--blue-900, #00012b);
    font-family: Calibre-R;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    width:100%;
    /* gap:8px; */
  display: flex;
    margin-top: 40px;
  }
  .personal_text{
    /* white-space: nowrap; */
  }
  
}
@media (max-width: 999px) {
  .profileSaveButton{
    width:100% !important;
    justify-content: center !important;
  }
  .paddingBottom {
    /* padding-bottom: 60px; */
    /* display: flex;
    flex-direction: column; */
  }
  /* .saveButton{
    margin-top: 0px;
    margin-bottom:0px;
  } */
  .deleteButton {
    align-content: center;
    justify-self: flex-end;
    /* top: 35px; */
  }
  .textbox-edit-new-platenumber {
    /* padding-left: 15px; */
    /* justify-content: space-between; */
    /* width: 90%; */
  }
  .textbox-edit-new-platenumber >label,.textbox-edit-new-platenumber>div{
    padding-left:0;
  }
  .add-more-license{
    margin-top: 16px;
    margin-bottom: 8px;
    text-align:start;
  }
  /* .flexBox {
    display: flex;
    flex-direction: column;
    gap: 32px;
  } */
  .textbox-edit-new{
    width:100%;
    height:84px !important;
    /* margin-left:-12px !important;   */
  }
  .textbox-edit-new label{
    /* height:24px !important; */
  }
  .textbox-edit-new>div{
    padding-left:0;
    padding-right:0;
  }
  .platenum-trash-mobile{
    display: grid;
    grid-auto-flow: column;
    gap: 8px;
    width: 100%;
    grid-template-columns: 85% 10%;
    justify-content: space-between;
    /* margin-left:16px; */
    /* margin-left:-32px !important; */
  }
  .textbox-edit input{
    padding-bottom:0px;
    padding-top:0px;
  }
  .modal {
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 0px;
  }
   .css-1719qsp {
    height: auto !important;
    width: auto !important;
  }
  .snackbar-box > div:nth-child(1) {
    background-color: #004724 !important;
  }
  .modal-mobile {
    width: 100% !important;
    height: 100% !important;
    border-radius: none !important;
  }
  .radio-lang {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 124px;
    gap: 24px;
    margin-top: 24px;
  }
  /* .flexBox {
    display: flex;
    gap: 20px;
  } */
}
@media (max-width: 768px) {
  .edit_text {
    color: var(--blue-700, #1b1d88);
    font-family: Calibre-R;
    font-size: 14px !important ;
    font-style: normal;
    font-weight: 400;
    /* line-height: 20px; */
    text-decoration: underline;
    /* margin-left: 40px; */
    justify-content: flex-end;
    white-space: nowrap;
    margin-left:auto;
    margin-right: 16px;
  }
  .personal_info_text {
    color: var(--blue-900, #00012b);
    font-family: Calibre-R;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    width:100%;
    gap:8px;
  display: flex;
    margin-top: 40px;
  }
  .personal_text{
    white-space: nowrap;
  }
  /* .flexBox {
    display: flex;
    gap: 20px;
  } */
}