.calloutHorizontal {
  margin-bottom: 32px;
  text-align: center;
}
.calloutHorizontal .container {
  display: inline-flex;
  gap: 24px;
  align-items: center;
}
.calloutHorizontal .innerHtml {
  max-width: 348px;
  text-align: left;
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}
.calloutHorizontal p {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  margin-bottom: 0;
}
.calloutHorizontal .buttonHolder {
  display: inline-flex;
  gap: 26px;
}
.buttonHolder Button,
.buttonHolderMobile Button {
  background: var(--blue-900);
  color: var(--white);
}
.buttonHolderMobile {
  margin-top: 16px;
  max-width: 422px;
  display: inline-block;
  width: 100%;
}
.buttonHolder button:hover {
  background: var(--blue-800);
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .calloutHorizontal {
    margin-bottom: 40px;
  }
}
