.mainContainer {
  width: 100%;
  background: var(--gold-100);
  padding: 32px 16px 32px 16px;
}
.topHeader {
  display: flex;
}
.headerL {
  width: 90%;
}
.title {
  color: var(--blue-900);

  /* Additional styles/med/text-2xl-med */
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  margin-top: 4px;
  margin-bottom: 8px;
}
.subTitle {
  color: var(--gray-600);
  /* Additional styles/reg/text-lg-reg */
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 24.3px */
}
.infoIcon {
  width: 40px;
  height: 40px;
}
.infoIcon svg {
  width: 100%;
  height: 100%;
}
.commonBlock:first-child {
  margin-top: 61px;
  padding-top: 11px;
}
.commonBlock {
  width: auto;
  display: block;
  padding: 0px 0px 32px 25px;
  border-left: 1px solid var(--blue-500);
  position: relative;
}

.bTitle {
  color: var(--blue-900);

  /* Additional styles/med/text-lg-med */
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
}
.sTitle {
  color: var(--gray-600);

  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  margin-top: 8px;
  margin-bottom: 8px;
}
.anc {
  color: var(--blue-500, #373ae5);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
}
.arrowR {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 4px;
  background: url("../../assets/center-of-ex-facilities/arrow-right.svg")
    no-repeat;
  position: relative;
  top: 2px;
}

.bPole {
  border-top: 1px solid var(--blue-500);
  width: 20px;
  position: relative;
  top: 11px;
  left: -25px;
}

.flag {
  display: inline-flex;
  padding: 2px 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 0px 4px 4px 0px;
  background: var(--blue-500);
  color: var(--white);
  text-align: center;

  /* Additional styles/med/text-base-med */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
  position: relative;
  top: -16px;
  left: -26px;
}

.flagl {
  display: inline-flex;
  padding: 2px 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 0px 4px 4px 0px;
  background: var(--blue-500);
  color: var(--white);
  text-align: center;

  /* Additional styles/med/text-base-med */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
  position: absolute;
  bottom: -17px;
  left: -1px;
}
