.agentpagecontainer {
  /*overflow: hidden;*/
  width: 100%;
  margin-top: 32px;
}
.agentSearch .backBtn {
  cursor: pointer;
  font-size: 14px;
  color: #373ae5;
  width: 358px;
  position: absolute;
  margin-top: 26px;
  margin-bottom: 26px;
  margin-left: 41px;
}
.agentSearch .backBtn:hover {
  text-decoration: underline;
}
.agentSearch .searchInputContainer {
  display: flex;
  justify-content: center;
}
.agentSearch .searchInputContainer .searchInputWidth {
  /* width: 828px; */
  width: 100%;
}
.agentSearch
{
  width:100%;
}
@media (max-width: 768px) {

  .agentSearch .backBtn { 
    margin-left: 16px;
  }
}