.threeColumn {
  margin-left: -16px;
  margin-right: -16px;
  padding: 24px 16px;
  background: var(--gold-100);
}
.threeColumn .blocks {
  display: flex;
  flex-direction: column;
}
.threeColumn .blocks .block {
  border-bottom: solid 1px var(--gold-500);
  padding-top: 24px;
  padding-bottom: 24px;
}
.threeColumn .blocks .block .imgaeHolder {
  max-width: 40px;
  margin-bottom: 5px;
}
.threeColumn .blocks .block:first-child {
  padding-top: 0;
}
.threeColumn .blocks .block:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.threeColumn .blocks .block img {
  margin-bottom: 5px;
  width: 48px;
  height: 48px;
}
.threeColumn h3 {
  margin-bottom: 16px;
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 27px */
}
.threeColumn .block > ul {
  margin: 0;
}
.threeColumn .block > ul > li,
.threeColumn .block > p {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}
.threeColumn .block .subBlock {
  margin-top: 16px;
}
.threeColumn .block .subBlock h4 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
}
.threeColumn .block .subBlock p {
  margin: 0;
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}
.threeColumn .block .subBlock a {
  padding-left: 0;
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .threeColumn {
    margin-left: -48px;
    margin-right: -48px;
    padding: 32px 48px;
  }
  .threeColumn .blocks {
    flex-direction: row;
  }
  .threeColumn .blocks .block {
    flex-grow: 1;
    flex-basis: 0;
    border-right: solid 1px var(--gold-500);
    border-bottom: none;
    padding: 0 24px;
  }
  .threeColumn .blocks .block:first-child {
    padding-left: 0;
  }
  .threeColumn .blocks .block:last-child {
    border-right: none;
    padding-right: 0;
  }
}
