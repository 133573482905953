.carouselContainer {
  display: flex;
  gap: 8px;
}

.carouselNavArrowP {
  /* border-radius: 8px 0px 0px 8px; */
  border: 1px solid var(--Blue-900, #00012b);
  /* padding: 5px 5px 8px 5px; */
  border-radius: 50px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.carouselNavArrowN {
  /* border-radius: 0px 8px 8px 0px; */
  border: 1px solid var(--Blue-900, #00012b);
  /* padding: 5px 5px 8px 5px; */
  border-radius: 50px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.carouselNavArrowP.disable,
.carouselNavArrowN.disable {
  pointer-events: none;
  opacity: 0.3;
}

.carouselNavArrowP:hover,
.carouselNavArrowN:hover {
  color: #00012b;
  outline: 1px solid #00012b;
}
