.hcontainer
{
  width:100%;
  justify-content: space-between;
  background-color: var(--dark-alt-alt-base-high-100-black);
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  box-sizing: border-box;
  -webkit-user-select: none;
  -webkit-app-region: drag;
  position: fixed;
  z-index: 100;
}
.eTitle
{
    color: var(--dark-base-base-high-100-white);
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 116.667% */
}