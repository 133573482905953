.mgmNews {
  font-size: 20px;
  display: flex;
  flex-direction: column;
}
.mgmNews .mgmNewsLatest img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.imgHolder {
  width: 100%;
  height: 320px;
  border-radius: 5px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}
.volunteerOpportunitiesContainer {
  display: grid;
  /* grid-auto-flow: row; */
  grid-gap: 16px;
}
/* mgmNewsLatest */
.mgmNews .mgmNewsLatest {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.mgmNews .mgmNewsLatest .content {
  font-size: 16px;
  color: #00012b;
  padding: 24px 0;
}

/* mgmNewsDetail */
.mgmNews .mgmNewsDetail {
  /* display: flex;
  flex-direction: column; */
  border: 1px solid #d1d1d1;
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  /* margin-bottom: 24px; */
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 240px auto auto;
}
.mgmNews .mgmNewsDetail img {
  width: 100%;
  height: 100%;
  flex: none;
  margin-bottom: 24px;
  border-radius: 8px;
}
.imgHolderDetail {
  margin-bottom: 24px;
  border-radius: 8px;
  width: 100%;
  height: 216px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}
.mgmNews .mgmNewsDetail .content {
  font-size: 16px;
  color: #00012b;
}
/* common */
.mgmNews .content .date {
  color: #5d5d5d;
}
.mgmNews .content h2 {
  font-size: 24px;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

}
.mgmNews .content .desc {

  /* min-height: 65px; */
  /* height: 24px; */
}
.mgmNews .content .desc p {
  /* margin-top: 16px; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-top: 10px;

  /* height: 24px; */
}
.mgmNews p .sorting {
  margin-top: 40px;
  margin-bottom: 24px;
}
.mgmNews .readMoreButton {
  margin-top: 24px;
}
.mgmNews .readMoreButton:hover {
  outline: #00012b 1px solid;
}
.newsCardTitle{
min-height: 58px;
}
.newsCardButton{
  /* justify-self: flex-end; */
  align-self: flex-end;
  /* position: absolute;
  bottom: 24px; */
}
.leoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  font-size: 16px;
  max-width: 488px;
  align-self: center;
}
.leoContainer .leo {
  padding: 16px;
  background: #373ae5;
  width: 167px;
  font-size: 16px;
  border-radius: 5px;
  color: #fff;
  margin-left: 5px;
  text-align: center;
  margin-top: 32px;
}
.filterMgmNews {
  margin-left: auto;
  margin-top: 30px;
  margin-bottom: 24px;
}
.filterMgmNews .menuStyle {
  max-width: 176px;
}
.showMoreLabel {
  color: var(--Gray-600, #5d5d5d);
  text-align: center;
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  display: flex;
  justify-content: center;
  /* margin-left:47%; */
}
.showMorepadding{
  padding: 40px 16px 16px 16px;
}
.hideshowMorelabel{
  display: none
}
.linkimage{
  height: 20px;
  width: 20px;
  margin-right: 5px;
}
.linktext{
  text-decoration: none;
}
.dateandlink{
  display: flex;
  gap: 4px;
 
}
.dateandlink p{
  color:var(--Gray-700, #464646);
}

@media (min-width: 1024px) {
  .mgmNews .mgmNewsLatest img {
    width: 60%;
    max-width: 510px;
    flex: none;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .imgHolder {
    width: 60%;
    max-width: 510px;
    flex: none;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .mgmNews .mgmNewsLatest {
    margin-bottom: 32px;
    flex-direction: row;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    background-color: #fff;
    width: 100%;
  }
  .mgmNews .mgmNewsDetail {
    /* flex-direction: row; */
    padding: 24px;
    position: relative;
  }
  /* .mgmNews .mgmNewsDetail img {
    width: 324px;
    height: 216px;
  } */
  .linkimage{
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .imgHolderDetail {
    width: 100%;
  }
  .mgmNews .mgmNewsDetail .content {
    /* margin-left: 24px; */
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .mgmNews .mgmNewsLatest .content {
    padding: 40px 48px;
    width: 50%;
  }
  .leoContainer {
    flex-direction: row;
    margin-top: 30px;
  }
  .leoContainer .leo {
    margin-top: 0;
    cursor: pointer;
  }
}
@media (min-width: 768px) {


  .volunteerOpportunitiesContainer {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: minmax(49%, 50%) minmax(49%, 50%);
    grid-gap: 16px;
    overflow: hidden;
  }
}