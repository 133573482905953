.tile {
  background-color: var(--white);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
}

/* .tile:hover {
  box-shadow: 0px 2px 16px 0px rgba(177, 150, 97, 0.2);
} */

.tile .image {
  box-sizing: border-box;
  height: 162px;
}

.tile .image img {
  width: 100%;
  height: 100%;
}

.tile .content {
  padding: 16px;
}

.tile .content h3 {
  margin-bottom: 4px;
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 1.5rem;
}

.tile .content .description {
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 2.2rem;
  margin-bottom: 1rem;
}

.tile .content button {
  margin-top: 16px;
}
.tile .content button:hover {
  outline: #00012b 1px solid;
}

.dateFormat {
  color: var(--gray-600);
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
}

.learnMore {
  display: inline-flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--blue-900);
  color: var(--blue-900);
  text-decoration: none;
}

