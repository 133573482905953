/*@media (min-width: 769px) and (max-width: 1023px) {
    .page-container-agentsearch {
      
      padding: 0;
    }
  }*/
  .page-container-agentsearch {

    padding: 0;
  
    overflow: visible;
  
  }
  
  .container_agent_search {
    max-width: 100%;
  }
  
  
  
  
  .menu-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 106px;
  }
  
  .menu-trigger {
    /*background: #ffffff;
      border-radius: 90px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 6px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
      border: none;
      vertical-align: middle;
      transition: box-shadow 0.4s ease;
      margin-left: auto; */
    display: flex;
    height: 40px;
    padding: 14px 16px;
    align-items: center;
    gap: 2px;
    border-radius: 100px;
    border: 1px solid var(--blue-900);
  }
  
  .menu-trigger:hover {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  }
  
  .menu-trigger span {
    /*font-weight: 700;
    vertical-align: middle;
    font-size: 14px;
    margin: 0 10px;*/
    color: var(--blue-900);
    text-align: center;
  
    /* reg/text-sm-reg */
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    /* 18.9px */
  }
  
  
  .menu {
    background: #ffffff;
    /*border-radius: 8px;*/
    position: absolute;
    top: 42px;
    right: 0;
    width: 200px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    color: var(--blue-900);
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
  }
  
  .menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    z-index: 2;
  }
  
  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu li {
    border-bottom: 1px solid #dddddd;
    cursor: pointer;
    padding: 8px;
  }
  .menu li.selected
  {
    background: var(--gradients-digital-blue, linear-gradient(225deg, #373AE5 14.64%, #292CB7 50%));
    color: var(--White);
  }