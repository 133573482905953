.pageBanner .topBlock {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
}
@media (min-width: 1024px) {
  .pageBanner .topBlock {
    padding: 0 48px 16px 48px;
  }
}
