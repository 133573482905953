.container {
  border: 1px solid #dcdcdc;
  /* border-radius: 6px; */
  background: var(--white, #ffffff);
  position: absolute;
  z-index: 1;
  /* border-radius: 0; */
}

.notifyLink:hover,
.notifyLink:focus,
.notifyLink:focus-visible {
  color: var(--blue-900) !important;
}

.isReadItem {
  background: var(--gray-25, #f5f5f5);
  position: relative;
  padding: 16px 16px 16px 32px !important;

  &::before {
    content: "";
    position: absolute;
    top: 22px;
    left: 12px;
    width: 8px;
    height: 8px;
    background-color: #00012b;
    border-radius: 50px;
  }
}

.notifyLink:hover svg path {
  fill: var(--blue-900) !important;
}

.notifyLink a {
  display: inline-block;
}

.setDWidth {
  width: 460px;
  max-height: 636px;
}

.setMWidth {
  width: 100%;
  height: 100vh;
}

.scrollingContainer {
  overflow-y: auto;
  /* padding: 0 1rem 0 0; */
  max-height: 90vh;
  cursor: pointer;
}

.scrollingContainer::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scrollingContainer::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
.scrollingContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--gray-100);
}

.container > p {
  font-size: 11px;
  margin: 7px 0;
}
.container > hr {
  border: 1px solid #99845d;
}
/* tab */
.container > .button {
  padding: 3px 0;
  width: 100%;
  display: flex;
}

.container > button:last-child {
  margin: 10px 0;
}

.notificationHeader {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  border-bottom: 0.5rem;
  border-bottom: 1px solid var(--gold-500);
  padding: 1rem 1rem 0.5rem;
}

.notificationHeader a {
  text-align: right;
  color: var(--blue-900);
  text-decoration: underline;
  font-size: 14px;
  display: inline-block;
  padding-right: 0;
}

.notificationRow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 16px;
}

.notificationItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none !important;
}

.timeReceived {
  color: #5d5d5d;
  text-align: right;
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 16.2px */
}

.notificationRow:hover,
.notificationRow:focus,
.notificationRow:focus-visible {
  background-color: var(--gray-50, "#E8E8E8");
}

.notificationRow:not(:last-child) {
  border-bottom: 1px solid var(--gray-100, "#d1d1d1") !important;
}

.notificationItem h4 {
  color: var(--Blue-900, #00012b);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

.notificationItem span {
  color: var(--Gray-600, #5d5d5d);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

.notificationH3 {
  color: var(--Blue-900, #00012b);
  font-style: normal;
  line-height: 27px;
  font-family: Calibre-R;
  font-size: 20px;
  text-align: left;
  font-weight: 400;
}

.notifyDescription {
  font-family: Calibre-R;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.2px;
  text-align: left;
}

.noNotification {
  justify-content: left;
  display: flex;
  padding: 40px;
  margin: 0;
  padding: 0;
  margin-top: 32px;
  margin-bottom: 32px;
  color: var(--blue-900, #00012b);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  padding-left: 16px;
}

.taskdate_default {
  color: var(--Blue-900, #00012B);
  text-align: center;
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 16.2px */
  border-radius: 12px;
  border: 1px solid var(--Blue-900, #00012B);
  padding: 4px 12px;
  margin-top: 2px;
  /* text-transform: capitalize; */
  margin-top: 8px;
}

.taskdate_overdue {
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  list-style-type: none;
  color: var(--alerts-red-500);
  padding-left: 0;
  margin-bottom: 0;
  padding-top: 6px;
  list-style: none;
  font-size: 12px;
  border-radius: 12px;
  border: 1px solid var(--Alerts-Red-600, #C60600);
  padding: 4px 12px;
  margin-top: 8px;
}

.taskdate_overdue li:before {
  content: "";
  font-size: 170%;
  padding-right: 8px;
  top: 3px;
  top: 2px;
  position: relative;
}

@media (min-width: 1024px) {
  .container {
    /* padding: 1rem; */
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    background: var(--white, #ffffff);
    position: absolute;
    z-index: 1;
    -webkit-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 20%);
    -moz-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 20%);
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 20%);
    &:after {
      content: "";
      position: absolute;
      right: 58px;
      top: -15px;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 28px solid #ffffff;
      clear: both;
      z-index: 0;
    }
  }
  .scrollingContainer {
    max-height: 52vh;
  }
}
