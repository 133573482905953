.iconSize {
  max-height: 100%;
  margin: 0 4px;
}

.textLinkContainer {
  display: inline-flex;
  width: 100%;
  align-items: center;
  /* gap: 4px; */
}

.linkWithdescription {
  color: var(--blue-sub-title);
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  margin-top: 8px;
}
.ctaTextLinkDesContainer {
  width: 100%;
}

.ctaTextLinkDesContainer span {
  line-height: 130%;
}

.ctaTextLinkDesContainer a {
  align-items: center;
  gap: 8px;
}
