.retirementContainer {
  color: var(--Blue-900, #00012b);
}
.retirementContainer h2 {
  /* color: var(--Blue-900, #00012b); */
  font-size: 24px;
}
.retirementContainer h3 {
  /* color: var(--Blue-900, #00012b); */
  font-size: 24px;
  margin-bottom: 16px;
}
.retirementContainer .rtTilesContainer {
  /* display: flex;
  flex-direction: column;
  gap: 16px; */
  display: grid;
  grid-gap: 16px;
}

.error401Container > div {
  margin: 0;
  height: 212px;
}

/* RetirementTiles */
.rtTile {
  /* width will be dynamic */
  width: 100%;
  height: 212px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  text-align: center;
  padding: 24px;
}
.rtTile .customIconStyle {
  margin-bottom: 24px;
}
.viewDetail {
  line-height: 24px;
  text-decoration-line: underline;
}
.rtTile .rtTileHeading {
  font-weight: 500;
  margin-bottom: 10px;
}
.rtTile .rtTileHeading span {
  margin-left: 8px;
}
.balanceContainer {
  margin-bottom: 8px;
}
.rtTile .mainBalance {
  font-size: 24px;
  font-weight: 500;
  line-height: 135%;
}

.viewDetailBen {
  display: block;
  margin-top: 24px;
}
.viewDetail {
  display: block;
  margin-top: -5px;
}

/*Scenario: no balance appear from API due to fetching issue, then do redirect  */
.balanceContainerZero {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
.zeroBalance {
  color: red;
  font-size: 14px;
  line-height: 120%;
  cursor: pointer;
  padding-bottom: 5px;
}
.rtTile .mainBalance {
  line-height: 70%;
}

a {
  cursor: pointer;
}
a.textSpacing {
  margin-left: 8px;
}
.rtTile .ptext {
  color: var(--Gray-500, #747474);
  font-size: 14px;
}
.cTypeBox2 {
  display: contents;
}
.cTypeBox {
  width: 60px;
}
.rtTile p.cType {
  font-size: 12px;
  font-weight: 500;
}
.rtTile p.cPercent {
  font-size: 24px;
  font-weight: 500;
}

.myContributionType {
  display: flex;
  place-content: center;
  gap: 10%;
}
.verticalGoldenLine {
  border-left: 2px solid var(--Gold-500, #d8c5a5);
}

/* @media (min-width: 769px) {
  .rtTile {
    width: 329px;
  }

  .retirementContainer .rtTilesContainer {
    flex-direction: row;
  }
} */
@media (min-width: 768px) {
  .retirementContainer .rtTilesContainer {
    grid-gap: 16px;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1024px) {
  .error401Container {
    height: auto;
  }
  .rtTile {
    /* min-height: 212px;
    height: 100%; */
    /* ### */
    padding: 16px;
  }
}
@media (min-width: 1200px) {
  .rtTile {
    padding: 24px;
  }
}
