.container {
  margin-top: 2rem;
  }
  .scrollingContainer {
    overflow-y: auto;
    margin-top: 2rem;
  }
  
  .scrollingContainer::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  
  .scrollingContainer::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }
  .scrollingContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--gray-100);
  }
  .closeIcon svg>path{
    fill: #F6EDDD;
  }
  .snackbarSection {
    margin-bottom: 16px;
  }
  .snackbarSection button svg>path {
    fill: #F6EDDD !important
  }
  .snackbarSection [data-testid="snackbar-action"] {
    padding-top: 0;
  }
  .snackbarSection button {
    text-decoration: none;
  }
  .snackbarBox> div:nth-child(1) {
    background-color: #00012B !important;
    position: absolute;
    bottom: 0;
    /* left: 0; */
    right: 0;
    top: 0;
    text-align: center;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-auto-flow: column;
    z-index: 99;
  }
  
  .container > p {
    font-size: 11px;
    margin: 7px 0;
  }
  .container > hr {
    border: 1px solid #99845d;
  }
  /* tab */
  .container > .button {
    padding: 3px 0;
    width: 100%;
    display: flex;
  }
  
  .container > button:last-child {
    margin: 10px 0;
  }
  
  .bookmarkHeader {
    display: grid;
    align-items: center;
    border-bottom: 0.5rem;
    border-bottom: 1px solid var(--gold-500);
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .bookmarkHeader p {
    color: var(--Gray-700, #464646);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 18.9px */
  }
  
  .bookmarkItem {
    display: flex;
    padding: 8px 0 8px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    text-decoration: none;
  }
  
  .bookmarkItem h4 {
    color: var(--Blue-900, #00012b);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
  }
  
  .bookmarkItem span {
    color: var(--Gray-600, #5d5d5d);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
  }
  
  .h2 {
    color: var(--Blue-900, #00012b);
    font-family: Calibre-R;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
  }
  
  .deleteIcon {
    border: none;
    padding: 0;
  }
  .deleteIcon > svg:hover
  {
    background: url('../../assets/images/trash-icon-bold.svg');
  }
  .wrapper {
    display: grid;
    grid-template-columns: 90% 5%;
    align-items: center;
    min-height: 70px;
    /* background-color: var(--white); */
  }
  
  .wrapper:not(:last-child) {
    border-bottom: 1px solid var(--gold-500) !important;
  }
  .wrapper:hover {

    background: var(--gray-50, '#E8E8E8')

  }
  
  .noBookmark {
    justify-content: left;
    display: flex;
    padding: 40px;
    margin: 0;
    padding: 0;
    margin-top: 32px;
    margin-bottom: 32px;
    color: var(--blue-900, #00012b);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
  }
  
  .snackbarFav > div:nth-child(1) {
    background-color: #004724 !important;
    position: relative;
    bottom: 0;
    right: 0;
    top: -15px;
    text-align: center;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-auto-flow: column;
    width: 100%;
  }
  
  
  @media (min-width: 768px) {
    .snackbarFav > div:nth-child(1) {
      position: absolute;
      width: fit-content;
    }
  }
  
@media (width<=992px) {
  
  .snackbarSection {
    margin-bottom: 50px;
  }
  
}