.messageHolder {
  display: flex;
  gap: 48px;
  flex-direction: column;
}
.messageHolder .message {
  /*flex-basis: 750px;*/
  width: 75%;
  flex-grow: 0;
  flex-shrink: 0;
}
.relatedNews {
  width: calc(25% - 32px);
}
.heading {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  margin-bottom: 32px;
}
.messageHolder .message img {
  width: 100%;
}
.messageHolder .message .imageHolder {
  position: relative;
  margin-bottom: 32px;
  margin-left: -16px;
  margin-right: -16px;
}
.messageHolder .message .leftArrow,
.messageHolder .message .rightArrow {
  cursor: pointer;
  position: absolute;
  top: calc(50% - 20px);
  width: 40px;
  height: 40px;
  background-color: rgba(250, 249, 245, 0.6);
  border-radius: 20px;
  border: solid 1px var(--gray-100);
  display: flex;
  justify-content: center;
  align-items: center;
}
.messageHolder .message .leftArrow {
  left: 16px;
}
.messageHolder .message .rightArrow {
  right: 16px;
}
.messageHolder .innerHtml {
  margin-bottom: 32px;
}
.messageHolder .buttonHolder {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .newsHolder {
    position: sticky;
    top: 85px;
  }
}
@media (max-width: 768px) {
  .messageHolder .message {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .messageHolder {
    flex-direction: row;
    gap: 32px;
  }
  .heading {
    font-size: 36px;
  }
  .messageHolder .message .imageHolder {
    margin-left: 0;
    margin-right: 0;
  }
}
