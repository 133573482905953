.taskcontainer {
  background-color: var(--white);
  border: 1px solid var(--gray-100);
  border-radius: 8px;
  padding: 24px;
  position: relative;
  width: 100%;
}

.taskheader {
  color: var(--blue-900);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.tasksubheader {
  color: var(--gray-700, #464646);
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  margin-top: 8px;
}

.buttonWorkday {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  height: 40px;
  border-radius: 6px;
  padding-left: 16px;
  padding-right: 16px;
  font-family: Calibre-R;
  font-weight: 400;
  line-height: 100%;
  font-size: 14px;
  -webkit-text-decoration: none;
  text-decoration: none;
  background: #00000000;
  color: #00012b;
  border-color: #00012b;
}

@media (width>992px) {
  .taskcontainer {
    padding: 40px 30px 40px 40px;
  }
  .taskDetailsSection {
    max-height: 280px;
    overflow-y: auto;
    padding-right: 10px;
  }
  .readmoresection {
    display: none;
  }
}
