.offerCardList {
  /* display: flex;
  flex-flow: wrap;
  gap: 2%;
  color: #00012b; */
  display: grid;
  color: #00012b;
  grid-column-gap: 2%;
  grid-row-gap: 16px; 
}
.buttonContainer{
  display:flex;
  /*align-items:flex-end;*/
  margin:auto;
  width:50%;
  flex-direction: column; 
}
.showMoreLabel {
  color: var(--Gray-600, #5d5d5d);
  text-align: center;
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  /* margin-left:47%; */
}
.filterSession {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 27px;
  color: #00012b;
  gap: 8px;
}
.filterSessionRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.circleNumber {
  border: 1px solid #000;
  border-radius: 14px;
  padding: 2px 7px;
  font-size: 12px;
  margin-left: 8px;
}

/* new for mobile */
.limTimeOff {
  display: flex;
}

.offersEmpCardStyle {
  /* min-width: 324px;
  max-width: 500px; */
  width: 100%;
  background-color: var(--white);
  border-radius: 8px;
  border: 1px solid var(--gray-50);
}
.viewAllBtn
{
  width: fit-content;
  margin: 16px auto;
}

@media (min-width: 500px) {
  .offersEmpCardStyle {
    /* min-width: 324px; */
    /* min-width: 200px;
    max-width: 1000px; */
    width: 100%;
  }
}

@media (min-width: 1024px) {
  
  .offersEmpCardStyle {
    width: 100%;
  }
  .filterSession {
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .offerCardList {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1280px) {
  .offerCardList {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr 1fr;
    color: #00012b;
    grid-column-gap: 2%;
    grid-row-gap: 24px;
  }
}
