.loaHolder {
  padding: 0 16px;
}
.pageContent {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
}
.headerBlocks {
  margin-bottom: 32px;
}
.linkSection {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  gap: 24px;
}
.timeoff_leftsection_loa {
  background: #fff;
  background: var(--White, #fff);
  border: 1px solid #d1d1d1;
  border: 1px solid var(--gray-100, #d1d1d1);
  border-radius: 8px;
  padding: 40px;
}
.leaveRequest > div {
  margin: 24px 0;
}
.linkSection a {
  padding-left: 0;
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1100px) {
  .loaHolder {
    padding: 0 48px;
  }
  .pageContent {
    margin-top: 48px;
    flex-direction: row;
    gap: 4%;
  }
  .pageContent .leftContent {
    flex: 0 0 58%;
  }
  .pageContent .rightContent {
    flex: 0 0 38%;
  }
  .leaveRequest > div {
    margin: 0 0 40px 0;
  }
}
@media (min-width: 1300px) {
  .pageContent .leftContent {
    flex: 0 0 66%;
  }
  .pageContent .rightContent {
    flex: 0 0 30%;
  }
  .linkSection {
    flex-direction: row;
  }
  .linkSection a:first-child {
    flex: 0 0 30%;
  }
}
