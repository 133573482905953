.dragDrop {
  /* background: #fff; */
  border: 1px solid var(--border-color);
  border-radius: 8px;
}
.documentUploader {
  border: 2px dashed var(--gold-900);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  height: 245px;
  text-align: center;

  &.active {
    border-color: var(--gold-900);
  }
}

.uploadBox {
  background-color: #faf9f5;
  opacity: 1;
  cursor: pointer;
}
.uploadBoxDisabled {
  background-color: var(--Gray-50, #e8e8e8);
  opacity: 0.5;
  cursor: not-allowed;
  z-index: 0;
}
.uploadBoxDisabled label {
  cursor: not-allowed;
}
.fileUploadImage {
  rotate: 180deg;
}

.documentUploader .uploadInfo {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  svg {
    font-size: 36px;
    margin-right: 1rem;
  }
}

.documentUploader .uploadInfo div p {
  margin: 0;
  font-size: 16px;
  &:first-child {
    font-weight: bold;
  }
}

.fileList {
  display: inline-grid;
  grid-auto-flow: column;
  gap: 1rem;
  align-items: center;
  /* width: 100%; */
  /* height: 30vh; */
}

.successWrapper {
  margin-top: 10px;
}

.fileList .container {
  position: relative;
}

.fileItem {
  display: inline-grid;
  grid-auto-flow: column;
  gap: 30px;
  border-radius: 8px;
  border: 1px solid #b9b9b9;
  margin-top: 10px;
  padding: 3px 10px;
  align-items: center;
}

.fileItemLoader {
  background-color: var(--Gray-50, #e8e8e8);
  opacity: 0.5;
  cursor: not-allowed;
  z-index: 0;
}

.fileItem button {
  padding: 5px;
}

.documentUploader .fileItem .fileActions {
  cursor: pointer;

  svg {
    font-size: 18px;
    color: #888;
  }

  &:hover {
    svg {
      color: #d44;
    }
  }
}

.documentUploader .fileItem .fileInfo {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex: 1;
}

.documentUploader .fileItem .fileInfo p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.option {
  margin-top: 1rem;
}

.documentUploader .browseBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--blue-900, #00012b);
  border-radius: 6px;
  color: var(--blue-900, #00012b);
  cursor: pointer;
  padding: 10px 16px;
  position: relative;
  /* background-color: var(--primary-color); */
  /* &:hover {
    background-color: transparent;
  } */
}

.documentUploader.uploadBoxDisabled .browseBtn {
  cursor: not-allowed;
}

.documentUploader .success-file {
  display: flex;
  align-items: center;
  color: #6dc24b;
}

.documentUploader .success-file p {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.progressBar {
  appearance: none;
  height: 5px;
  border-radius: 100px;

  /* Chrome and Safari */
  &::-webkit-progress-bar {
    background: transparent;
    border-radius: 100px;
  }

  &::-webkit-progress-value {
    background-color: blue;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  /* Firefox */
  &::-moz-progress-bar {
    background-color: rgb(32, 22, 23);
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
}
.progressBar {
  &::-webkit-progress-value {
    transition: width 1s;
  }
}

.progressContainer {
  position: absolute;
  bottom: -5px;
  left: 2px;
}

.documentUploader input[type="file"] {
    /* opacity:0; */
    clip: rect(0px, 0px, 0px, 0px);
    clip-path: inset(50%);
    position: absolute;
    top: 0px;
    width: 1px;
    height: 1px;
    white-space: nowrap;
}

@media (min-width: 1024px) {
  .documentUploader {
    height: 245px;
  }
}
