.dividercontainer {
  display: flex;
}


.lineText {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1px;
  padding: 0 8px;
  color: var(--Blue-900, #00012b);
  /* Additional styles/reg/text-xs-reg */
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 16.2px */
}
