.scheduleMessageContainer {
  display: flex;
  flex: 50% 1;
  padding: 11px 10px;
  /* justify-content: space-between; */
  align-items: flex-start;
  border-radius: 8px;
  margin-bottom: 20px;
  gap: 15px;
}

.scheduleWarning {
  background-color: #fef7ec;
  border: 1px solid #fb9500;
}

.scheduleAckowledge {
  background-color: var(--alerts-red-25);
  border: 1px solid #d20b02;
}

.msgContent h3 {
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  color: #00012B;
}

.reviewHeader {
  display: flex;
  align-items: end;
  gap: 10px;
}

.msgContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.msgContent p {
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  color: #00012b;
}

.scheduleMessageContainer img {
  width: 24px;
  height: 24px;
}

.reviewNow {
  font-family: Calibre-R;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding-left: 0 !important;
  padding-top: 0 !important;
}