.container {
  padding: 10px;
  width: 180px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  background: #fff;
  position: absolute;
  z-index: 1;
}
.container > h2 {
  font-size: 14px;
}
.container > p {
  font-size: 11px;
  margin: 7px 0;
}
.container > hr {
  border: 1px solid #99845d;
}
/* tab */
.container > .button {
  padding: 3px 0;
  width: 100%;
  display: flex;
}
/* 
.container > .button {
  background-color: #0f0;
  font-size: 13px;
  margin: 20px 0;
  width: 100%;
  display: flex;
} */
.container > button:last-child {
  margin: 10px 0;
}

@media (min-width: 1024px) {
  .container {
    padding: 10px;
    width: 180px;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    background: #fff;
    position: absolute;
    z-index: 1;
    -webkit-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 20%);
    -moz-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 20%);
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 20%);
    &:after {
      content: "";
      position: absolute;
      right: 8px;
      top: -13px;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 28px solid #ffffff;
      clear: both;
      z-index: 0;
    }
  }
}
