.coutHolder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: rgb(0, 0, 0, .4);
    padding-top: 70px;
    padding-bottom: 140px;
    position: inherit;

}

.iconH {
    width: 116px;
    height: 111px;
    /* background: url(../../assets/images/Group2535.svg); */
}

.innerH {
    display: flex;
    max-width: 530px;
    width: 95%;
    padding: 40px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-radius: 16px;
    background: var(--gold-25);
    min-height: 300px;
}

.txt1 {
    color: var(--blue-900);
    text-align: center;

    /* Additional styles/med/text-lg-med */
    font-family: Calibre-R;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    /* 24.3px */
}

.txt2 {
    color: var(--blue-900);
    text-align: center;

    /* Additional styles/reg/text-base-reg */
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    /* 21.6px */
}