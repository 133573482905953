.contentAuthorNote {
  padding: 8px;
  border-radius: 8px;
  background-color: var(--bs-yellow);
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: left;
}
.contentAuthorNote h4 {
  color: var(--blue-900) !important;
  font-size: 12px !important;
  font-family: Calibre-R !important;
  font-style: normal;
  font-weight: 500;
  margin: 0 !important;
}
.contentAuthorNote p {
  color: var(--blue-900) !important;
  font-size: 12px !important;
  margin: 0 !important;
}
