header {
  z-index: 100;
}
.header {
  display: flex;
  justify-content: space-between;
  padding: 26px 16px;
  z-index: 1;
}
.headerContainer {
  background-color: var(--blue-900);
}
.header .logoContainer {
  display: flex;
  align-items: center;
}
.header .logoContainer a {
  display: inherit;
}
.header .logoContainer img {
  max-height: 30px;
}
.logoHolder
{
  height:100%;
  display: flex;
  align-items: center;
}
.header .logoContainer .propertyLogo {
  max-height: 30px;
}
.header .logoContainer .logoDividor {
  border-right: var(--gold-900) solid 1px;
  margin: 0 32px;
  height:38px;
}
.iconContainer {
  position: relative;
  display: flex;
  align-items: center;
  /* gap: 24px; */
  gap: 18px;
}
.iconContainer a {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inherit;
  text-decoration: none;
}
.iconContainer > a {
  width: 40px;
  justify-content: center;
}
.profileBar .profileIcon,
.iconContainer a.profileIcon {
  background-color: var(--gold-900);
  width: 48px;
  height: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 27px */
  color: var(--black, #000);
}
.mobileLeftContainer,
.mobileRightContainer {
  /* display: flex;
  gap: 24px; */
  grid-gap: 24px;
  display: grid;
  grid-auto-flow: column;
}
.hamburger {
  background-image: url("../../assets/images/stacked-lines-menu.svg");
  width: 24px;
  height: 24px;
  display: inline-block;
}
.hamburger.hamClose {
  background-image: url("../../assets/images/symbol-x.svg");
}
.profileBar {
  display: flex;
  background-color: var(--blue-900);
  border-top: var(--gold-900) solid 1px;
  padding: 12px 24px;
  align-items: center;
  background-image: url("../../assets/images/chevron-right-white.svg");
  background-repeat: no-repeat;
  background-position: right 24px center;
}
.profileBar p {
  margin: 0 0 0 16px;
  color: var(--white);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 21.6px */
}
.profileBar p:nth-child(2) {
  font-size: 14px;
  font-weight: 300;
}
.signout {
  background-color: var(--white);
  padding: 0 24px 32px 24px;
  /* height: 100%; */
  height: 120px;
}
.signout a {
  display: block;
  padding-top: 16px;
  border-top: var(--blue-900) solid 1px;
  text-decoration: none;
  color: var(--blue-900) !important;
  font-size: 16px;
}
.navDropdown {
  display: none;
}
.isOpen {
  display: block;
  position: absolute;
  /* top: 78px; */
  top: 76px;
  height: calc(100vh - 78px);
  /* position: relative; */
  overflow-x: auto;
  width: 100%;
  overflow-y: auto;
}
/* .helpIcon {
  position: absolute;
  right: 16px;
} */
.helpIcon,.searchIcon{
  border-radius: 50% !important;
}
.helpIcon,.searchIcon img {
  width: 24px;
  height: 24px;
}
.helpIcon:hover svg path,.searchIcon:hover svg path
{
  stroke: #ffffff;
  stroke-width: 1px;
}
.notification{
  border: 1px solid var(--blue-500) !important;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  background-color: var(--blue-500) !important;

  &::after {
    content: "";
    background: url("../../assets/images/bell-icon.svg") center 0 no-repeat;
    background-size: 20px 26px;
    text-align: right;
    padding: 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.selectedNotification {
  border: 1px solid var(--blue-500);
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* cursor: not-allowed !important; */
  /* opacity: .5; */

  &::after {
    content: "";
    background: url("../../assets/images/bell-icon.svg") center 0 no-repeat;
    background-size: 20px 26px;
    text-align: right;
    padding: 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  } 
}

.selectedHoverNotification:hover{
  background: url("../../assets/images/bell-icon.svg") center 0 no-repeat;
  
}
.bookmark {
  border: 1px solid var(--gold-900) !important;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  background-color: var(--gold-900) !important;

  /*&::after {
    content: "";

    background: url("../../assets/images/Heart-in-circle-white-28pix-icon.svg") center 0
      no-repeat;

    background-size: 30px 28px;
    text-align: right;
    padding: 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }*/
}
.bookmarkNormal
{
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bookmarkNormal img {
  width:24px;
  height:24px;
}

.notifiyActual {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
} 

.indicator {
  position: absolute;
  right: 10px;
  top: 12px;
  z-index: 2;
}

.indicatorMobile {
  position: absolute;
  right: 2px;
  top: 6px;
  z-index: 2;
}

.notifiyActual .iconFile {
  width:24px;
  height:24px;
}
.notificationBk {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--gold-900) !important;
  /* z-index: 0; */
  /* &::after {
    position: absolute;
    content: '';
    background-color: var(--gold-900);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
  } */
}

.notificationBk svg {
  position: absolute;
  z-index:1;
}
.notificationBk  .iconFile {
  width:24px;
  height: 24px;
}

.notificationBk svg path {
  fill: #000000;
}

.bookmarkNormal:hover svg path, .notifiyActual:hover svg path
{
  stroke: #ffffff;
  stroke-width: 1px;
}

.visibilityh {
  /* display: none; */
  /* changed display:none to visibility: hidden to keep the space of the hidden element */
  /* fixes the issue with the header moving when opening menu modals. */
  visibility: hidden; 
}

.visibilityb {
  /* visibility: visible; */
  display: block;
  position: relative;
}
.visibilityb img {
  width: 24px;
  height: 24px;
}
.visibilityb .indicatorMobile {
  width: auto;
  height: auto;
}
.selectedBookmark {
 border: 1px solid var(--blue-500);
  /*width: 40px;
  height: 40px;*/
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::after {
    content: "";

    background: url("../../assets/images/White-Heart.svg") center 0
      no-repeat;

    background-size: 20px 26px;
    text-align: right;
    padding: 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  } 
}
.selectedHoverBookmark:hover{
  background: url("../../assets/images/bookmarkSelected.svg") center 0 no-repeat;
  
}

@media(max-width: 1022px) {
  .helpIcon {
    position: absolute;
    right: 16px;
  }
}
@media (min-width: 768px) {
  .isOpen {
    position: relative;
    top: 0;
    height: calc(100vh - 170px);
  }
}
@media (min-width: 1024px) {
  header {
    position: sticky;
    top: 0;
  }
  .header {
    margin: 0 auto;
    max-width: 1440px;
    padding: 16px 36px;
    position: relative;
  }
  .hamburger {
    display: none;
  }
}