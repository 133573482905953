.bio {
 border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
}
.bio .image {
  overflow: hidden;
  border-radius: 8px;
  min-width: 324px;
  height: 324px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bio .image img {
  max-width: 100%;
  height: 100%;
}
.bio .content h3 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
  margin-top: 24px;
}
.bio .content h4 {
color: var(--blue-900);
font-family: Calibre-R;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 18.9px */
margin-top: 4px;
}
.bio .description {
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 16px;
  height: 56px;
}
.bio .content p,
.bio .content span {
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bio .content button {
  margin-top: 16px;
}
.bio .textLink {
  margin-top: 16px;
  padding-left: 0;
}
