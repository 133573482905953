.dateHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}
.dateHolder p {
  margin: 0;
  font-weight: 500;
}
.issuedDate p {
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.salary,
.timesheet {
  margin-top: 16px;
}
.payslipDetails label {
  color: var(--Blue-900, #00012B);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.salary p {
  margin: 0;
  color: var(--alerts-green-700);
  font-family: Calibre-R;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 64.8px */
}
.timesheetInner {
  display: flex;
  align-items: end;
  justify-content: space-between;
}
.timesheetInner > div > div {
  display: inline-block;
}
.timesheetInner > div > div > span {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  margin-right: 4px;
}
.timesheetInner > div > div > label {
  margin-right: 16px;
}
.timesheetInner a {
  padding-right: 0;
}
.accordion {
  margin-top: 18px;
}
.accordionBlock {
  border-bottom: 1px solid var(--gray-50);
}
.accordionBlock:first-child {
  border-top: 1px solid var(--gray-50);
}
.accordionBlock > div > button > div {
  padding-left: 0;
  padding-right: 0;
}
.accordionBlock > div > section {
  padding-top: 8px;
  margin-bottom: 12px;
  /* max-width: 450px; */
}
.accordionBlock.fixedHeight {
  height: 58px;
  overflow: hidden;
}
.accordionContent > div {
  display: flex;
  justify-content: space-between;
}
.accordionContent > div > p {
  max-width: 50%;
}
.accordionContent p,
.accordionContent span {
  margin-bottom: 4px;
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 16.2px */
}
.accordionContent span:first-child {
  color: var(--gray-600);
}
.accordionContent > div span {
  min-width: 60px;
  display: inline-block;
  text-align: right;
}
.hideMyPay {
  margin-top: 16px;
}
.hideMyPay > div {
  display: inline-flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}
.hideMyPay > div > span {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}
@media (min-width: 768px) {
}

@media (min-width: 1024px) {
  .accordionContent > div > p {
    max-width: 60%;
  }
  .accordionContent > div span {
    min-width: 80px;
  }
}
@media (min-width: 1100px) {
  
  .hideMyPay {
    margin-top: 24px;
  }
  
}
