.actionscontainer {
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  margin: 0;
  margin-top: 16px;
}
.actionheader {
  color: var(--blue-900);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  padding: 0;
}
.actionsection {
  border: 1px solid var(--gray-100);
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
  padding-bottom:8px;
  margin-top: 16px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.actiontab {
  text-align: left;
  background: var(--white, #fff);
  padding: 12px !important;
  flex: 1 1 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  text-decoration: none !important;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 135% !important;
  align-items: center !important;
  border: none !important;
  border-right: 1px solid var(--gray-100) !important;
  height: 75px;
  position: relative;
  
}
.actiontab::after
{
  content: "";
    display: block;
    background: var(--blue-500) url("../../assets/images/arrow-right-white.svg")
      center 0 no-repeat;
    background-size: 13px 33px;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    padding: 16px;
    text-align: center;
    /* display: flex;
    justify-content: center;
    align-items: center; 
    margin-left: 24px;*/
}

.actiontab:nth-child(1),
.actiontab:nth-child(2),
.actiontab:nth-child(3),
.actiontab:nth-child(4),
.actiontab:nth-child(5),
.actiontab:nth-child(6) {
  border-bottom: 1px solid var(--gray-100) !important;
}

.actiontab:nth-child(7),
.actiontab:nth-child(8) {
  border-bottom: none !important;
}

@media (min-width: 1024px) {
  .actionscontainer {
    margin-top: 24px;
  }
  .actiontab {
    flex: 1 1 25%;
    font-size: 1rem;
    /* min-width: 200px; */
    padding: 16px !important;
  }
  .actionsection {
    grid-template-columns: repeat(4, 1fr);
    background: var(--white, #fff);
  }
  .actiontab:nth-child(1),
  .actiontab:nth-child(2),
  .actiontab:nth-child(3),
  .actiontab:nth-child(4) {
    border-bottom: 1px solid var(--gray-100) !important;
  }

  .actiontab:nth-child(5),
  .actiontab:nth-child(6),
  .actiontab:nth-child(7),
  .actiontab:nth-child(8) {
    border-bottom: none !important;
  }
}
