.fallbackBg {
  width: 100%;
  height: auto;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid var(--Gray-100, #d1d1d1);
  background: var(--White, #fff);
}
.title {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  margin-bottom: 16px;
}
.subtitle, .subtitle p{
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  margin-bottom: 8px;
}
.subtitle li {
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 400;
  line-height: 135%;
}

.list {
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  margin-bottom: 16px;
}
.cta {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
}
.hide {
  display: none;
}
@media (max-width: 431px) {
}
