.filterContainer
{
    background-color: var(--white);
    box-shadow: 0px 2px 16px 0px rgba(177, 150, 97, 0.2);
    width: 324px;
    position: sticky;
    z-index: 1;
    float: left;
    padding: 32px 16px;
    height: 100%;
}
.checkHolder
{
    padding-top: 12px;
    padding-bottom: 12px;
}
.filterTitle
{
    color: var(--Black);

    /* Additional styles/med/text-2xl-med */
    font-family: Calibre-R;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 32.4px */
    margin-bottom: 16px;
}
.filterSubTitle
{
    color: var(--Black);

    /* Additional styles/med/text-lg-med */
    font-family: Calibre-R;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 24.3px */

}
.inputH
{
    margin-top: 24px;
    margin-bottom: 24px;
    
}
.cBox
{
    width:22px;
    height: 22px;
    border-radius: 3px;
}


  
.cLable
{
    color: var(--blue-900);

    /* Additional styles/reg/text-base-reg */
    font-family: Calibre-R;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 21.6px */
    
    
}
.fLowerBlock
{
    margin-top: 30px;
}
.filterControlH
{
    display:flex;
    justify-content: space-around;
    margin-top: 40px;
}
.filterApply
{
    color: var(--blue-900, #00012B);
    text-align: center;

    /* Additional styles/reg/text-sm-reg */
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 18.9px */
}
.filterClear
{
    color: var(--blue-900, #00012B);
    font-family: Calibre-R;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-decoration-line: underline;
}