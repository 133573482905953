.modal {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1000;
  justify-content: center;
  align-items: center;
}
.modal .container {
  display: flex;
  padding: 24px 16px 96px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  background: var(--white);
  width: fit-content;
  max-width: 95%;
}
.modal .body {
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  height: 400px;
  margin-top: 8px;
}
.closebtn {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.displaypic {
  max-width: auto;
}
.titlesection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 8px;
  padding-top: 24px;
}
.title {
  color: var(--blue-900);
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.designation {
  color: var(--gray-600);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.topsection {
  min-width: 0px;
  width: 106%;
}
.bottomsection {
  max-width: 100vw;
  color: var(--blue-900);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.bottomsection p {
  margin-top: 24px;
}
.right {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 20px;
}
.link {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--blue-900);
  border-radius: 50%;
  padding: 6px;
  cursor: pointer;
}

@media (min-width: 768px) and (max-width: 992px) {
  .topsection {
    width: 100%;
  }
}

@media (width>992px) {
  .topsection {
    min-width: 800px;
    width: 100%;
  }
  .bottomsection {
    max-width: 800px;
  }
  .displaypic {
    max-width: 332px;
  }
  .modal .container {
    max-width: 100%;
  }
  .titlesection {
    padding-left: 28px;
    padding-right: 8px;
    padding-top: 0px;
  }
}

@media (min-width: 768px) and (max-width: 992px) and (orientation: landscape) {
  .modal .body {
    height: 220px;
  }
}
