.cardContainerInner {
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
}
.locationDropdown {
  margin: 0 0 32px 0;
}
.locationDropdown select {
  width: 100%;
  padding: 15px;
  color: var(--blue-900);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  border-radius: 8px;
  border: 1px solid var(--gray-100);
  background: var(--white);
  box-shadow: 2px 2px 8px 0px rgba(55, 58, 229, 0.1);
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../../../assets/images/chevron-down.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
}
.tabHolder {
  margin: 0;
  border-bottom: 1px solid var(--gray-100);
}
.tabLocationHolder {
  margin: 0 0 32px 0;
  border-bottom: 1px solid var(--gray-100);
}
.tabLocationHolderAtt {
  margin: 0 0 32px 0;
  border-bottom: 1px solid var(--gray-100);
  margin-left: 16px;
  width: 100vw;
}

.linkSection {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  align-items: center;
  /*label for 1.5 
  align-items: center;
  padding: 24px 0px 0px 0px;
   
}
.linkSection a {
  padding-left: 0;
}
.tabLocationHolderAtt .tabLocationItem,
.tabHolder .tabItem {
  display: inline-block;
  color: var(--blue-900);
  line-height: 135%;
  text-decoration: none;
  padding: 16px 2px;
  margin: 0 15px;
  border-radius: 0;
}
.tabLocationHolderAtt .tabLocationItem.active,
.tabHolder .tabItem.active {
  color: var(--blue-500);
  font-weight: 500;
  border-bottom: 1px solid var(--blue-500);
}
.tabLocationHolder .tabLocationItem,
.tabHolder .tabItem {
  display: inline-block;
  color: var(--blue-900);
  line-height: 135%;
  text-decoration: none;
  padding: 16px 2px;
  margin: 0 15px;
  border-radius: 0;
}
.tabLocationHolder .tabLocationItem:hover,
.tabHolder .tabItem:hover {
  color: var(--blue-900);
}

.tabLocationHolder .tabLocationItem.active,
.tabHolder .tabItem.active {
  font-weight: 500;

  border-bottom: 2px solid var(--Gold-800, #baa173);
}
.detailsHolder.hideDetails {
  display: none;
}
.hideMyPay {
  margin-top: 16px;
}
.hideMyPay > div {
  display: inline-flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}
.hideMyPay > div > span {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}
.divider {
  height: 16px;
  border: 1px solid var(--gold-900);
}
@media (min-width: 768px) {
}
@media (max-width: 431px) {
  .divider {
    display: none;
  }
}
@media (min-width: 1100px) {
  .cardContainerInner {
    padding: 16px 40px 16px 40px;
  }
  .hideMyPay {
    margin-top: 24px;
  }
  .linkSection {
    display: flex;
    flex-wrap: wrap;
    /* margin-top: 40px; */
    /* flex-direction: column; */
    align-items: center;
    padding: 16px 0px 0px 0px;
     
  }
  
}
@media (max-width: 1300px) {
  .locationDropdown {
    margin-top: 24px;
  }
  .linkSection {
    display: flex;
    flex-wrap: wrap;
    /* margin-top: 40px; */
    /* flex-direction: column; */
    align-items: baseline;
    padding: 40px 0px 40px 0px;
    gap: 16px;
  }
  /*
  .linkSection {
   flex-direction: column;
  }
  .linkSection a:first-child {
    flex: 0 0 45%;
  }
  */
}
