.centerModal {
  width: 50%;
  max-width: 800px;
}

.modalContent {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex-direction: column;
  padding-bottom: 24px;
}
.modalRedirect:hover{
  color: var(--blue-500);
}
.modalDivParent{
  display: flex;
  justify-content: space-between;

}
.modalDivChild{
  display: flex;
  width:50%;
  justify-content: space-between;
  padding-right: 16px;
}
.modalSubtitle {
  color: var(--Blue-900, #00012B);
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  /* margin: 10px 0; */
}

.modalRedirect {
  color: var(--Blue-900, #00012B);
  text-align: right;
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  /* text-decoration-line: */
}

.modalHandler {
  color: var(--Blue-900, #00012B);
  font-family: Calibre-R;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

.modalHandle {
  color: var(--Blue-900, #00012B);
  font-family: Calibre-R;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

.modalBody {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

/* .imgLinkedIn img {
  
  border-radius: 8px;
  flex:0 0 60%;
  max-width: 60%;

} */

/* .imgLinkedIn p {
  overflow-y: auto;
  padding-bottom:16px;
  flex:0 0 40%;
  max-width: 40%;
} */

.imageContainer {
  flex: 1;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
}
.imageContainer img {
  width: 100%;
  height: 458px;
  /* aspect-ratio: 360 / 458; */
  object-fit: cover;
  border-radius: 8px; /* Optional: for rounded corners */
}
.textContent {
  flex: 1 1;
  max-width: 50%;
  /* padding: 20px; */
  overflow-y: auto;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.imgLinkedIn {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: stretch;
  max-height: 458px;
  width:100%;
}

.modalrightRail {
  display: flex;

}


.modalrightRail>p {
  white-space: nowrap;
  margin-top: 10px;
  color: var(--Blue-900, #00012B);
  font-family: Calibre-R;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.centerModal>div:nth-child(2){
  padding:0px 24px !important
}
.modalBg{
  background: var(--Gray-25, #F5F5F5);
}
@media (max-width: 1024px) {
  .imgLinkedIn {
    display: flex;
    gap: 16px;
    max-height: 184px;
    flex-direction: column;
  }

  .imgLinkedIn img {
    /* max-width: 100px; */
    border-radius: 8px;
    width: 100% !important;    
    max-width: 100%;

  }

  .imgLinkedIn p {
    /* overflow-y: unset; */
    padding-bottom:16px;
    max-width: 100%;
  }
  .modalContent {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    flex-direction: column;
    padding-bottom: 16px;
  }
  .modalrightRail {
    display: flex;
    gap:4px;
  
  }
  
}