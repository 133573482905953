.fiftyFiftyPhoto .fiftyFiftyInner {
  margin: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.fiftyFiftyPhoto .cardContainer .fiftyFiftyInner {
  margin: 0;
}
.fiftyFiftyPhoto .fiftyFiftyInner > div {
  flex: 0 0 48%;
}
.fiftyFiftyPhoto .cardContainer .fiftyFiftyInner > div:nth-child(2) {
  padding: 32px 48px 32px 32px;
}
.fiftyFiftyPhoto img {
  width: 100%;
  pointer-events: auto;
  border-radius: 8px;
}
.fiftyFiftyPhoto h3 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  margin-bottom: 16px;
}
.fiftyFiftyPhoto p,
.fiftyFiftyPhoto li,
.fiftyFiftyPhoto span {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  margin: 0;
}
.fiftyFiftyPhoto .buttonHolder {
  margin-top: 24px;
}

.slideDescription {
  text-align: left;
  overflow-y: auto !important;
  /* overflow-y: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */
}

@media (min-width: 768px) {
  .FFcontainer {
    padding-right: 2rem;
  }
}
@media (min-width: 768px) {
  .fiftyFiftyPhoto .fiftyFiftyInner {
    margin: 48px 0;
    flex-direction: row;
    gap: 2rem;
  }
  .fiftyFiftyPhoto .cardContainer .fiftyFiftyInner {
    margin: 0;
  }
  .fiftyFiftyPhoto .cardContainer .fiftyFiftyInner > div:nth-child(2) {
    padding: 32px 48px 32px 0;
  }
}
