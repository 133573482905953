.taskcontainer {
  background-color: var(--white);
  border: 1px solid var(--gray-100);
  border-radius: 8px;
  padding: 24px;
  position: relative;
  width: 100%;
}

.taskheader {
  color: var(--blue-900);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.tasksubheader {
  color: var(--gray-700, #464646);
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  margin-top: 8px;
}
.taskDetailsSection {
  margin-top: 16px;
  max-height: none;
  overflow-y: hidden;
  padding-right: 0px;
}
.taskDetailsSection::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.taskDetailsSection::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
.taskDetailsSection::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--gray-100);
}

.taskval {
  color: var(--blue-900);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.taskdate_default {
  color: var(--gray-600);
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  padding-top: 6px;
}
.taskdate_overdue {
  color: var(--alerts-red-500);
  padding-left: 0;
  margin-bottom: 0;
  padding-top: 6px;
  list-style: none;
}
.taskdate_overdue li {
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.taskdate_overdue li:before {
  content: "•";
  font-size: 170%;
  padding-right: 8px;
  top: 3px;
  top: 2px;
  position: relative;
}
.taskwrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  border-top: 1px solid var(--gold-500);
  text-decoration: none;
}
.tasklink {
  display: flex;
  justify-content: center;
  align-items: center;
}
.taskfixed {
  position: relative;
  top: 0px;
}
.taskshowtext {
  justify-content: center;
  display: flex;
  text-decoration: underline;
  color: var(--blue-700);
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.taskuparrow {
  transform: rotate(0deg);
}
.taskdownarrow {
  transform: rotate(180deg);
}
.readmoresection {
  display: flex;
  justify-content: center;
  gap: 4px;
  /* margin-top: 14px; */
}
.showmore {
  max-height: none;
  overflow: hidden;
}
.showless {
  max-height: 240px;
  overflow: hidden;
}
@media (width>992px) {
  .taskcontainer {
    padding: 40px 30px 40px 40px;
    width: 50%;
  }
  .taskDetailsSection {
    max-height: 280px;
    overflow-y: auto;
    padding-right: 10px;
    /* box-shadow:inset 0 -12px 20px -15px lightgrey; */
  }
  .readmoresection {
    display: none;
  }
}

@media (width>1024px) {
  .taskfixed {
    position: sticky;
    top: 0px;
  }
}
