.quoteHolder {
  margin-bottom: 32px;
  background: var(--Gray-50, #e8e8e8);
  padding: 32px;
  margin-left: -16px;
  margin-right: -16px;
}
.quoteHolder.center {
  text-align: center;
}
.quoteHolder .iconHolder {
  max-width: 40px;
  max-height: 40px;
  margin-bottom: 10px;
}
.quoteHolder .iconHolder * {
  max-width: 100%;
}
.quoteHolder .iconHolder img {
  width: 48px;
  height: 48px;
}
.quoteHolder h2 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  margin-bottom: 16px;
}
.quoteHolder p {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  margin-bottom: 24px;
}
.quoteHolder .buttonHolder {
  display: inline-flex;
  gap: 26px;
}
.author {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: var(--gray-600);
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .quoteHolder {
    padding: 48px;
    margin-bottom: 40px;
    margin-left: -48px;
    margin-right: -48px;
  }
}
