.chatWrapper {
  /* position: relative; */
}

.customNetomiChat {
  position: absolute;
  /* bottom: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* top: 20px; */
  top: calc(87vh);
  right: 60px;
  display: flex;
  border: 2px solid white !important;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  /* box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1); */
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.16);
  background-color: var(--blue-900);
  color: white;
  cursor: pointer;
  transform: translateY(0);
  transition: opacity 1s ease-in, transform 1s ease-in;
  z-index: 2147483648;
}

.customNetomiChatHide {
  position: absolute;
  /* bottom: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* top: 20px; */
  right: 40px;
  top: calc(100vh - 160px);
  display: flex;
  border: 2px solid white !important;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  /* box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1); */
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.16);
  background-color: var(--blue-900);
  color: white;
  cursor: pointer;
  transition: opacity 1s ease-in, transform 1s ease-in;
  transform: translateY(500px);
  z-index: 2147483648;
}

.chatIcon {
  height: 75px;
  width: 75px;
  border: 2px solid var(--blue-900);
  border-radius: 50%;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.16);
}

@media (max-width: 1024px) {
  .customNetomiChat {
    right: 40px;
    top: calc(100vh - 170px);
    z-index: 1001;
  }
  .customNetomiChatHide {
    right: 40px;
    top: calc(100vh - 170px);
    z-index: 1001;
  }
  .chatIcon {
    height: 70px;
    width: 70px;
  }
}

