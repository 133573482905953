.footer {
  /*display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1440px;*/
  background-color: var(--blue-900);
  padding: 32px 16px 66px;
  margin-right: 0;
  
  .popularFlex {
    display: flex;
  }
  .popularFlex ul {
    list-style: none;
    /*width: 50%;
    max-width: 220px;*/
  }
  .anchor {
    color: var(--gold-200);
  }
  .copyRight a {
    display: inline-block;
  }
  
  .copyRight img {
    /*width: 167px;*/
    width:100%;
  }
  .copyRight .copyPara {
    margin: 32px 0 24px 0;
  }
  .copyRight .copyPara p {
    font-size: 14px;
    color: var(--gold-25);
  }
  .copyRight li {
    display: inline-block;
    margin-bottom: 0;
  }
  .copyRight li:first-child::after {
    content: "|";
    margin: 0 16px;
    display: inline-block;
    color: var(--gold-25);
  }
}

.para {
  color: var(--gold-900);
  margin: 0 0 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
}

.copyRightImg
  {
    width:100%;
  }
  .modalBody{
  overflow-y: auto;
  max-height:300px;
  }
  .modalBody p
  {
    padding-right: 8px;
  }
  .modalContents{
   display:flex; 
   flex-direction: column;
    overflow-y:hidden;
  }
.footer select {
  cursor: pointer;
  border-radius: 8px;
  padding: 12px 16px;
  width: 221px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: var(--white);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  background-image: url("../../assets/images/chevron-down.svg");
  background-repeat: no-repeat;
  background-position: right 12px center;
}
.footer > div {
  margin: 0 0 32px;
}
.footer ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
.footer ul li {
  margin-bottom: 16px;
}
.footer ul li:last-child {
  margin-bottom: 0;
}
.footer .textLink {
  padding: 0;
  color:var(--white);
}
.footer .textLink:hover{
  color:var(--white);
}
.loginFooter {
  text-align: center;
  padding: 40px;
}
.loginFooter p {
  color: var(--gold-25);
  margin: 24px 0 0 0;
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.lookingAssist ul li {
  list-style: none;
  display: block;
}
.cancelBgColour {
  margin-right: 10px;
}
.buttonBgColour {
 background:  var(--blue-900, #00012b) !important;
}
.chooseLang
{
  /* css block */
}
@media (min-width: 820px) {
  .copyRight .copyPara {
    margin: 16px 0 24px 0 !important;
  }
}
@media (min-width: 786px) {

.popularResources
{
  /* css block */
  padding-left: 100px;
}
.lookingAssist
{
  padding-left: 40px;
}
}
@media (min-width: 426px) {
  .footer select {
    width:100%;
  }
}
@media (min-width: 1024px) {
  .chooseLang
{
  padding-left: 0px;
  /* css block */
}
  .footer {
    /*flex-direction: row;
    gap: 1%;*/
    /* padding: 40px 32px 82px; */
    margin: 0 auto;
    max-width: 1440px;
    padding: 40px 36px;
    position: relative;
    /* padding-left: 0px; */
  }
  .footer .para {
    font-size: 14px;
  }
  .footer a {
    font-size: 14px;
  }
  /*.footer > div:nth-child(1) {
    flex: 0 0 21%;
  }
  .footer > div:nth-child(2) {
    flex: 0 0 31%;
  }
  .footer > div:nth-child(3) {
    flex: 0 0 14%;
  }
  .footer > div:nth-child(4) {
    flex: 0 0 25%;
  }
  */
}
