.contentCardPlain {
  margin: 32px 0;
}
.contentCardPlain h2 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  margin-bottom: 16px;
}
.contentCardPlain li,
.contentCardPlain p {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}
.contentCardPlain li {
  margin: 0;
  padding: 0;
}
.contentCardPlain .buttonHolder {
  margin-top: 24px;
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .contentCardPlain {
    margin: 40px 0;
  }
}
