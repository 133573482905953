.managerTaskTodoContainer {
  border-radius: 8px;
  margin: 0;
  padding: 40px;
  height: 100%;
}
@media (min-width: 1025px) {
  .managerTaskTodoContainer {
    display: table-cell;
    width: 47% !important;
  }
  .buffer {
    display: table-cell;
    width: 48px;
  }
}
.cardContainerInner {
  display: flex;
  background-color: var(--white);
  border-bottom: 1px solid var(--gray-100);
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  flex-grow: 1;
  /* flex-direction: column; */
}
.managerTaskTodoContainer [data-testid="tab"] {
  padding: 15px 32px 15px 0px;
}
.managerTaskTodoContainer [data-testid="card-container"] {
  padding-left: 20px;
}
.cardContainerShowmore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  border-bottom: 1px solid var(--gray-100);
}
.tabHolder {
  position: relative;
  color: #666;
  font-weight: normal;
}
.tabHolder.inactive {
  color: #d1d1d1;
  font-weight: normal;
}
.tabHolder.active  button{
  color: #000;
  font-weight: bold !important;
}
.active::after {
  position: absolute;
  background: var(--Gold-900, #b19661);
  border-radius: 4px 4px 0px 0px;
  content: "";
  bottom: 0;
  left: 0%;
  right: 26%;
  height: 2px;
}
.showMoreLabel {
  padding: 16px 0px 16px 0px;
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.viewAll {
  display: flex;
  align-items: center;
  color: var(--blue-900);
  font-size: 14px;
  font-style: normal;
  font-family: Calibre-R;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
}
.tasskItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px 16px 0px;
  border-bottom: 1px solid var(--gray-100);
  transition: background-color 0.3s ease;
  cursor: pointer;
}
.celebrationTask{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px 16px 0px;
  border-bottom: 1px solid var(--gray-100);
  transition: background-color 0.3s ease;
}
.celebrationTask:hover{
  /*background-color: var(--Gray-25, #F5F5F5);*/
}
.tasskItem:hover {
  background-color: var(--Gray-25, #F5F5F5);
}
.taskTitle {
  color: #00012b;
  font-size: 16px;
  font-style: normal;
  font-family: Calibre-R;
  font-weight: 500;
  line-height: 135%;
}
.taskName {
  color: #5d5d5d;
  font-size: 12px;
  font-style: normal;
  font-family: Calibre-R;
  font-weight: 500;
  line-height: 135%;
}
.taskDuration {
  color: #5d5d5d;
  font-size: 12px;
  font-style: normal;
  font-family: Calibre-R;
  font-weight: 400;
  line-height: 135%;
}
.taskMeta {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 24px;
}
.taskDate {
  color: #5d5d5d;
  font-size: 12px;
  font-style: normal;
  font-family: Calibre-R;
  font-weight: 400;
  line-height: 135%;
  white-space: nowrap;
}
.taskMenu {
  /* margin-bottom: 5px; */
}
.menuIcon {
  padding: 0px;
  color: rgba(0, 1, 43, 1);
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* height: 100%; */
}
.managerTaskTodoContainer [data-testid="modal-header"] {
  padding: 24px 48px 16px 16px;
}
/* .managerTaskTodoContainer [data-testid="modal-content"] {
  padding-bottom: 40px;
} */
.centerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  width:100%;
  text-align: center;
  
}
.centerContent h2 {
  color: var(--Blue-900, #00012b);
  /* Additional styles/med/text-sm-med */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.centerContent p {
  color: var(--Gray-500, #747474);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.mobilenav select {
  color: var(--Blue-900, #00012b);
  /* Additional styles/sb/text-base-sb */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  width: 100%;
  padding: 15px;
  margin-bottom: 32px;
  border-radius: 8px;
  border: 1px solid var(--gray-100);
  background: var(--white);
  box-shadow: 2px 2px 8px 0px rgba(55, 58, 229, 0.1);
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../../../assets/images/Chevron-dropdown.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
}
.mobileHeading h2 {
  padding: 12px 0px 10px 0px;
  border-bottom: 1px solid var(--gray-100);
  color: var(--Blue-900, #00012B);
/* Additional styles/med/text-base-med */
font-family: Calibre-R;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 135%; /* 21.6px */
}
@media (max-width: 768px) {
  .centerContent {
    min-height: 30vh;
  }
  .managerTaskTodoContainer {
    padding:16px;
  }
}
