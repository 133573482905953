.iconSize {
  max-height: 100%;
  margin: 0 4px;
}

.textLinkContainer {
  display: inline-flex;
  width: 100%;
  align-items: center;
  /* gap: 4px; */
}