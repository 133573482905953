.accordionArrowMob {
  border-radius: 8px 0 0;
  list-style: none;
  padding: 12px 16px;
  border-radius: 60px;
  background: var(--Blue-900, #00012b);
  /* box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1); */
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.16);

  color: #ffffff;
  right: 30px;
  /* gap: 10px; */
  align-items: center;
  position: fixed;
  z-index: 100;
  /* bottom: calc(100vh - 97vh); */
  /* top: 89vh; */
  top: calc(100vh - 95px);
  border: 2px solid var(--White, #FFF);
  display: grid;
  grid-template-columns: 70% 30%;
  grid-auto-flow: column;
  transition: opacity 1s ease-in, transform 1s ease-in;
  transform: translateY(500px);
  opacity: 1;
}
.accordionArrowMob.visible {
  opacity: 1;
  transform: translateY(0);
}

.globalFlyoutSectionMob {
  min-width: 300px;
  background: var(--White, #fff);
  border-top: 1px solid var(--Gray-100, #d1d1d1);
  /* box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1); */
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.16);

  list-style: none;
  display: flex;
  min-width: 390px;
  height: 54px;
  padding-top: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
}

.flyoutMenuMob {
  position: fixed;
  bottom: 0;
  width: 100%;
  transform: translateY(130%);
  transition-timing-function: ease-in;
  transition: 0.2s;
  z-index: 1001;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .4); 
  z-index: 100; 
}

.start {
  transition: 1s;
  transition-timing-function: ease-out;
  transform: translateY(0);
  opacity: 1;
}

.end
{
  transition: 1s;
  transition-timing-function: ease-out;
  transform: translateY(130%);
  opacity: 1;
}

.menuIconsMob {
  transform: rotateZ(270deg);
  display: flex;
  width: 32px;
  height: 32px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.menuIconsForActionsMob {
  transform: rotateZ(360deg);
  transition: all 0.4s ease;
  display: flex;
  width: 32px;
  height: 32px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.openMob {
  transform: rotateZ(0deg);
  /* transition: transform 1s linear; */
}

.globalFlyoutSectionMob a,
.globalFlyoutSectionMob a:hover,
.globalFlyoutSectionMob a:focus {
  text-decoration: none;
  color: #00012b;
  display: flex;
  align-items: center;

  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  padding: 0;
  display: inline-grid;
  grid-auto-flow: column;
}

.globalFlyoutSectionMob a img,
.globalFlyoutSectionMob a svg {
  width: 20px;
  height: 20px;
  margin: 0;
}

.globalFlyoutSectionMob a span {
  margin-left: 16px;
  color: var(--blue-900, "#00012B");
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* Additional styles/reg/text-base-reg */
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  /* max-width: 300px; */
}

.globalIconContainerMob {
  border-radius: 8px 0px 0px 8px;
  background: var(--White, #fff);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
}
.actionPanelContainer {
  display: flex;
  padding: 16px;
  align-items: flex-start;
  flex-shrink: 0;
}
.actionPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--Blue-900, #00012b);
  /* Additional styles/med/text-xl-med */
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 27px */
}

.actionPanel img {
  color: #000000;
}

.globalFlyoutContainerMob ul {
  padding: 0;
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  background: var(--White, #fff);
  margin: 0px;
  position: fixed;
  z-index: 101;
}

.globalFlyoutContainerMob li {
  padding: 16px;
  /* border-radius: 8px 0px 0px 8px; */
  background: var(--White, #fff);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
}

.globalFlyoutContainerMob li:first-child {
  border-radius: 16px 16px 0 0;
}

.globalIconSectionMob {
  /* max-width: 300px; */
  /* width: 50px;
  visibility:hidden;
  overflow:hidden;
  background-color: cadetblue; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* padding: 20px 0; */
  justify-content: flex-end;
  min-width: 50px;
  min-height: 24px;
  align-items: center;
  /* width: 52px; */
  height: 100%;
  transition: height 3s cubic-bezier(0.77, 0.2, 0.05, 1), visibility 3s;
}

.feedbackBtnMob {
  color: #ffffff !important;
  padding: 8px 15px 4px !important;
  border-radius: 0 0 6px 6px !important;
  background: var(--Alerts-Green-800, #006732) !important;
  border: 1px solid transparent !important;
  /* rotate: 270deg */
  /* transform: rotate(-270deg);
  margin-right: -48px; */
}

.feedbackFormContainerMob {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
}
