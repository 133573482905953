.volunteer {
  display: flex;
  flex: 50%;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid var(--gray-100);
  /* margin-bottom: 20px; */
}

.volunteerOpportunitiesContainer {
  display: grid;
  /* grid-auto-flow: row; */
  grid-gap: 16px;
}

.showMoreLabel {
  color: var(--Gray-600, #5d5d5d);
  text-align: center;
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  /* margin-left:47%; */
}

.volunteerWrapper {
  display: grid;
}

.locationNotes {
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 21.6px */
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;
  align-items: center;
}

.locState {
  color: var(--Blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

.toptextholder {
  justify-content: center;
  margin-bottom: 16px;
}
.subTextHolder {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

.offerTitle {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
}
.volunteerLink {
  /* position: absolute; */
  color: var(--blue-900, #00012b);
  text-align: right;
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  right: 0;
  text-transform: capitalize;
}
.volunteerLink a {
  color: var(--blue-900, #00012b);
  font-weight: 400;
  padding-right: 0;
  align-items: center;
  gap: 8px;
}

.volunteerLink a svg {
  margin: 0;
}

.volunteerContainer {
  /* display: flex;
  gap: 35px; */
  display: inline-grid;
  gap: 16px;
  grid-auto-flow: column;
}

@media (min-width: 768px) {
  .volunteer {
    min-height: 90px;
  }
}

@media (min-width: 768px) {
  .volunteer {
    margin-bottom: 0;
  }

  .volunteerOpportunitiesContainer {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: minmax(49%, 50%) minmax(49%, 50%);
    grid-gap: 16px;
    overflow: hidden;
  }
}
