/* common */
.recogBlock1 {
  color: #00012b;
}
.recogBlock1 button {
  background: var(--blue-900);
}
.recogBlock1 button:hover {
  background: var(--blue-800);
}
.recogBlock1 h2 {
  font-size: 24px;
  font-weight: 500;
}
.recogBlock1 h3 {
  font-size: 18px;
  font-weight: 500;
}
h5 {
  /* .recogBlock1 h5 { */
  font-size: 16px;
  color: var(--Blue-900, #00012b);
}
.recogBlock1 p {
  font-size: 14px;
}
.textUnderline {
  text-decoration: underline;
}
.boxStyling {
  border: 1px solid var(--Gray-100, #d1d1d1);
  background-color: #fff;
  border-radius: 8px;
}

.recogImp p {
  /* tocheck: comment this and restyle - text from CS */
  margin-top: 10px;
}
.recogBlock1 p a {
  /*tocheck: recheck the styling from figma and it will not be generic - text from*/
  font-size: 14px;
}

/*  */
.recogBlock1 {
  display: flex;
  flex-direction: column;
}

/* recogImp - recogImpSession */
.recogImp {
  width: 100%;
}

/* myRecogStatusSession */
.myRecogStatus {
  width: 100%;
  margin-top: 32px;
  /* height: 250px; */
}

/* giveRecognizeBox */
.giveRecognizeBox {
  display: flex;
  align-items: center;

  margin-top: 36px;

  text-align: center;
  flex-direction: column;
  padding: 24px 57px;
}
.giveRecognizeBox .chatIcon {
  width: 54px;
  height: 54px;
  /* margin-right: 24px; */
  margin-bottom: 9px;
}
.giveRecognizeBox p {
  margin-top: 4px;
}
.giveRecognizeBox button.recogSomeoneButton {
  /* margin: auto 0; */
  white-space: nowrap;

  /* width: 162px; */
  /* width - might change for mobile */
}
.giveRecognizeBox .giveRBoxContent {
  /* margin-right: 10px; */
  /* padding-right: 20px; */
  margin-bottom: 26px;
}

.giveRecognizeBox .giveRBoxContent h3 {
  margin-bottom: 8px;
}
/* recogTileContainer */
.recogTileContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

/* RecogTiles - component */
.rTiles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  /* width: 120px; */
  width: calc(50% - 8px);
  /* height: 120px; - replace the height with aspect ratio*/
  aspect-ratio: 1.1;

  background-color: #e8e8e8;
  border-radius: 8px;
  padding: 16px;

  margin: 8px;
  min-height: 120px;
  max-height: 140px;
}
.rTiles:nth-child(2n + 1) {
  margin-left: 0px;
}
.rTiles:nth-child(2n) {
  margin-right: 0px;
}
.rTiles p {
  color: #5d5d5d;
  font-size: 12px;
  text-align: center;
}
.rTiles p.number {
  font-size: 18px;
  font-weight: 500;
}
.rTiles .rTileIcon {
  width: 56px;
  height: 56px;
  margin-bottom: 16px;
}

.giveRBoxContentOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 769px) {
  .rTiles .rTileIcon {
    width: 36px;
    height: 36px;
    margin-bottom: 0;
  }
  .rTiles p.number {
    line-height: 14px;
  }
  .giveRecognizeBox button.recogSomeoneButton {
    white-space: unset;
  }

  .myRecog {
    margin-right: 40px;
  }
  .recogBlock1 {
    flex-direction: row;
    /* padding-right: 10px; */
    /* i think not require */
  }
  .giveRecognizeBox .chatIcon {
    margin-bottom: 0;
    margin-right: 24px;
  }
  .giveRecognizeBox {
    flex-direction: row;
    text-align: left;
    /* justify-content: space-between; */
    padding: 24px;
    min-height: 102px;
  }
  .giveRecognizeBox .giveRBoxContent {
    margin-bottom: 0;
    padding-right: 20px;
  }
  .giveRecognizeBox .giveRBoxContent h3 {
    margin-bottom: 0;
  }
  .myRecogStatus {
    margin-top: 0;
    width: 266px;
  }
  .recogTileContainer {
    margin-top: 4px;
  }
  .giveRBoxContentOuter {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .myRecogStatus {
    width: unset;
    max-width: 386px;
  }
}

@media (min-width: 1200px) {
  .myRecogStatus {
    width: 266px;
  }
}

@media (min-width: 1440px) {
  .giveRecognizeBox button.recogSomeoneButton {
    white-space: nowrap;
  }
}
