.featuresTiles {
  display: flex;
  flex-direction: row;
  grid-gap: 1.5rem;
  width: 1192px;
}

.featuresContainer {
  overflow: hidden;
}

h4,.link {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: var(--blue-900)
}

.upcomingHeader {
  border-bottom: 1px solid var(--gold-500);
  padding: 1rem 0;
}

.link {
  padding-left: 0 !important;
}