.wrapper
{
  margin-top:16px;
}
.timesheetDetailsContainer {
  display: flex;
  flex-direction: column-reverse;
}

.timesheetDetailsHeader {
  display: flex;
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--gold-900);
  font-size: 14px;
}

.totalHoursContainer {
  padding: 10px 0px 24px 0px;
}

.totalHoursNum {
  color: var(--Blue-900, #00012b);
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
}

.totalHoursText {
  color: var(--Blue-900, #00012b);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.date {
  min-width: 30%;
}

.in {
  min-width: 20%;
}

.out {
  min-width: 20%;
}

.tips {
  min-width: 20%;
}

.hours {
  min-width: 10%;
  text-align: end;
}

.iconsContainer {
  display: flex;
  flex-direction: row;
  padding-top: 30px;
  padding-bottom: 30px;
}

.iconsContainer span {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 20px;
}

.iconsContainer span img {
  margin-right: 7px;
}
@media (max-width: 426px) {
  .tips {
    min-width: 18%;
  }
  
  .hours {
    min-width: 17%;
    
  }
  
  
}
@media (max-width: 1023px) {
  .timesheetDetailsHeader
  {
    align-items: center;
    padding-bottom: 8px;
    align-items: end;
    /*label for 1.5 
    height:40px;
    */
  }
  
}
@media (max-width: 528px) {
  .hours {
    min-width: 17%;
  }
}
@media (max-width: 1023px) {
  .transfer {
    visibility: hidden;
    min-width: 5%;
  }
  .hideDate {
    visibility: hidden;
    min-width: 20%;
  }
  .hideInOut {
    visibility: hidden;
    min-width: 20%;
  }
  .iconsContainerM {
    display: flex;
    flex-direction: row;
    padding: 15px 0px;
  }
  .iconsContainerM span {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-right: 20px;
    font-size: 12px;
    color: var(--Gray-600, #5D5D5D);
  }
  .totalHoursContainer {
    padding: 10px 0px 0px 0px;
  }
}
