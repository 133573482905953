.cardContainerInner1 > div:first-child {
  display: flex;
}
.trendingCarousel > div:nth-child(2) {
  overflow-x: hidden;
}
.trendingCarousel > div:nth-child(1) h3 {
  /* display: none; */
  color: var(--blue-900);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  padding: 0;
  margin-top: 10px;
}

.trendingCarousel .mobile {
  margin-top: 24px;
}

.cardContainerInner > div:first-child {
  display: flex;
}

/* .cardContainerInner {
  width: 100%;
  padding-left: unset;
  padding: 14px 24px 16px 24px;
  margin-top: 24px;
} */
.trendingTopicContainer {
  display: flex;
  overflow-x: scroll;
}

@media (max-width: 1399px) {
  .cardContainerInner {
    width: 100%;
    margin-top: 24px;
    padding-left: unset;
    padding-right: unset;
  }
  .cardContainerInner > div {
    display: flex;
    overflow-x: scroll;
  }
}

.cardHeader {
  color: var(--Blue-900, #00012b);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

@media (max-width: 768px) {
  .trendingCarousel > div:nth-child(2) > div {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: fit-content;
  }
}
