.headersection {
  display: flex;
  justify-content: space-between;
  gap:3px;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--gold-800);
  /* margin-top: 32px;
  margin-bottom: 16px */
}
.header {
  color: var(--blue-title);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  width: -webkit-fill-available;
}
.viewall {
  color: var(--blue-900);
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
  /* align-self: center; */
  width: 80px !important;
  text-align: right !important;
}
.taskcontainer {
  background-color: var(--white);
  border: 1px solid var(--gray-100);
  border-radius: 8px;
  padding: 40px;
  position: relative;
}
.taskheader {
  color: var(--blue-900);
  /* font-size: 1.25rem; */
  /* font-weight: 500; */
  font-size: 1rem;
  font-style: normal;
  line-height: 135%;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
}
/* .eventwrapper {
  border-top: 1px solid var(--gold-500);
  padding: 16px 0;
} */
.eventWrapper {
  /* padding: 0 10px 10px 10px; */
}
.eventWrapper > p {
  color: var(--gray-600, #5d5d5d);
}

.occupysection {
  display: flex;
  justify-content: space-evenly;
  border-top: 1px solid var(--gold-500);
  padding: 8px 16px;
  margin-top: 16px;
  display: none;
}
.divider {
  display: flex;
  width: 1px;
  border-right: 1px solid var(--gray-400);
}
.divider:last-of-type {
  display: none;
}
.occupydetails {
  padding: 8px 1.5rem;
}
.eventname {
  color: var(--blue-900);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.9px;
}
.eventday {
  color: var(--blue-title, "#282827");
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.occupyheader {
  color: var(--blue-900);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 27px */
  margin-top: 16px;
  display: none;
}
.location {
  color: var(--gray-400);
  text-align: center;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.occupancy {
  color: var(--blue-900);
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

.noEvents {
  min-height: 5rem;
  padding: 1rem 0;
}

@media (width>992px) {
  .occupydetails {
    padding: 8px 1rem;
  }
  .occupysection {
    padding: 16px 0 0;
  }
}

.deailsSection {
  display: flex;
  border-bottom: 1px solid var(--gold-800);
  /* border-top: 1px solid var(--gold-800); */
  padding: 10px 8px;
}

.deailsSection:hover,
.deailsSection:focus,
.deailsSection:focus-visible {
  /* background-color: var(--Gray-25, "#F5F5F5"); */
  background-color: var(--gray-50, "#E8E8E8");
}

.eventContainer {
  width: 48px;
  height: 48px;
  /* margin-left: 16px; */
  margin-right: 16px; 
  /* margin-bottom: 16px; */
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--gold-f2, "#F2F2F2");
  padding: 8px;
}
.eventDayN{
  color: #282827;
  text-align: center;

  /* Additional styles/reg/text-xs-reg */
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 16.2px */
}

.eventDate{
  color: #282827;
  text-align: center;

  /* Additional styles/sb/text-xs-sb */
  font-family: Calibre-R;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 16.2px */
}
