.loginFooter {
  text-align: center;
  padding: 40px;
}
.loginFooter p {
  color: var(--gold-25);
  margin: 24px 0 0 0;
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}
.loginFooter img {
  max-width: 100%;
}
