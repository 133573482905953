.container {
  padding: 10px;
  min-width: 300px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  background: #fff;
  position: absolute;
  z-index: 1;
}
.container > h2 {
  font-size: 14px;
}
.container > p {
  font-size: 11px;
  margin: 7px 0;
}
.container > hr {
  border: 1px solid #99845d;
}
/* tab */
.container > .button {
  padding: 3px 0;
  width: 100%;
  display: flex;
}

.btnContainer {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-auto-flow: column;
  grid-gap: 1rem;
}
.btnContainer>button:nth-child(2){
  background: var(--blue-900);
}

.btnContainer>button:nth-child(2):hover{
  background: var(--blue-800);
}
/* 
.container > .button {
  background-color: #0f0;
  font-size: 13px;
  margin: 20px 0;
  width: 100%;
  display: flex;
} */
.container > button:last-child {
  margin: 10px 0;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 150;
  background-color: rgba(0, 0, 0, 0.25);
}
.modal {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 200;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  text-align: center;
}
.modal > div {
  display: flex;
  flex-direction: column;
  pointer-events: all;
  /* width: 90%; */
  width: 390px;
  background-color: var(--gold-25);
  padding: 24px;
  border-radius: 14px;
}
.modal .modalHeader {
  gap: 24px;
}
.modal .closeButton {
  flex: 0 0 32px;
}
.modalHeader h3 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  color: var(--blue-900, #00012b);
  text-align: center;
}
.modal .content {
  overflow-y: auto;
}
.modal .content h4 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
}
.modal .content li,
.modal .content p {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 24.3px */
}

.imgHolder {
  text-align: center;
}
.imgHolder img {
  height: 46px;
  width: 46px;
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .modal > div {
    width: 530px;
  }

  .modal {
    /*z-index: 30;*/
  }
}
