/* recReceivedContent */
.recReceivedContent {
  /* rbigtile is moved here */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-top: 14px;
  /* padding: 0 14px 14px 14px; */
  padding: 0 14px 24px 14px;
}

.rTilesBigFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 16px;
}
.rTilesBigFooter img {
  width: 38px;
  height: 38px;
  margin-right: 6px;
}
.footerRight {
  display: flex;
  align-items: center;
}
.footerRightText {
  font-size: 12px;
  color: var(--Gray-600, #5d5d5d);
}
.rTileIcon {
  margin-right: 16px;
  height: 48px;
  width: 48px;
}
.threePeople {
  background-color: #b19661;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  /* background-color: #000; */
  display: flex;
  box-sizing: border-box;
}
.threePeopleImg {
  width: 24px;
  height: 24px;
  display: flex;
  /* align-items: center;
    justify-content: center; */
  /* font-style: inherit;
    font-weight: inherit; */
  color: #000;
}
.InitialsAvatar{
background-color: var(--gold-900);
border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    height: 48px;
    width: 48px;

}
.InitialsAvatar div {
  align-items: center;
  color: var(--black, #000);;
  display: flex;
  font-style: inherit;
  font-weight: inherit;
  height: 100%;
  justify-content: center;
}
.subHeading {
  color: var(--Gray-600, #5d5d5d);
  font-size: 14px;
}
.recReceivedContent .content {
  margin-top: 24px;
  min-height: 150px;
  max-height: 400px;
  overflow-y: auto;
}
