.offersCard {
  overflow: hidden;
  margin-bottom: 24px;
}
.offersCard:hover .image img {
  transform: scale(1.1);
}
.offersCard .image {
  overflow: hidden;
  border-radius: 8px;
  min-width: 324px;
  height: 183px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.offersCard .image img {
  transition: all 0.2s ease-in-out;
  max-width: 100%;
}
.offersCard .content h2 {
  margin: 16px 0 0 0;
  color: var(--gray-600, #5d5d5d);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.offersCard .content h3 {
  margin-top: 4px;
  color: var(--blue-900, #00012b);
  white-space: nowrap;
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
}
.offersCard .content p,
.offersCard .content span {
  color: var(--gray-600);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.offersCard .content button {
  margin-top: 16px;
}
