.cardContainerInner {
  padding: 16px 16px;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 24px; */
}

.tabHolder {
  margin-left: 10px;
  margin: 0;
  margin-bottom: 30px;
  /*label for 1.5 
  margin-left: 0px;
  */
}
.tabHolder .tabItem {
  display: inline-block;
  color: var(--blue-900);
  line-height: 135%;
  text-decoration: none;
  padding: 16px 2px;
  margin: 0 15px;
  border-radius: 0;
}
.tabHolder .tabItem:hover {
  /* color: var(--blue-500); */
  color: var(--Blue-900, #00012b);
}
.tabHolder .tabItem.active {
  /* color: var(--blue-500); */
  font-weight: 500;
  border-bottom: 2px solid var(--Gold-800, #baa173);
}
.tabHolder .css-jegp3o
{
  height:fit-content !important;
}
@media (min-width: 1100px) {
  .cardContainerInner {
    padding: 40px 40px;
  }
}
@media (max-width: 1300px) {
  .cardContainerInner a{
    padding:4px 0px;
  }
}
.locationDropdown {
  margin: 0 0 32px 0;
}
.locationDropdown select {
  width: 100%;
  padding: 15px;
  color: var(--blue-900);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  border-radius: 8px;
  background: var(--white);
  box-shadow: 2px 2px 8px 0px rgba(55, 58, 229, 0.1);
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../../../assets//images/chevron-down.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
  /*label for 1.5 
  border:1px solid var(--gray-100);
  */
}

@media (min-width: 992px) {
  .tabHolder {
    margin: 0;
    border-bottom: 1px solid var(--gray-100);
    margin-left: 10px;
    margin-bottom: 30px;
  }
}