.wrapper {
  margin-top: 32px;
  border-radius: 8px;
  border: 1px solid var(--gray-100);
  background: var(--white);
  display: flex;
}

.displayNone {
  display: none;
}

@media (width>992px) {
  .timesheetcontainer {
    display: grid;
    grid-gap: 40px;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
  }
  .newscontainer {
    display: grid;
    grid-gap: 40px;
    grid-auto-flow: column;
  }
  .timesheetcontainerNoNews
  {
    display: flex;
    margin-top:40px;
  }
}
@media (width<992px) {
  .timesheetcontainerNoNews
  {
    display: flex;
    margin-top:40px;
    padding:16px;
  }
}
