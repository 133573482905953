.heroText {
  margin-bottom: 32px;
}
.heroText .titleSection {
  border-bottom: 1px solid var(--gold-500);
}
.heroText h2 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 40.5px */
  margin-bottom: 16px;
}
.heroText ul,
.heroText span,
.heroText p {
  margin-bottom: 16px;
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.heroText li {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.heroText .innerHtml {
  margin-bottom: 24px;
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.heroText strong {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.heroText .buttonHolder {
  margin: 32px 0 0 0;
  white-space: nowrap;
}
.heroText .buttonHolder button {
  background: var(--blue-900);
}
.heroText .buttonHolder button:hover {
  background: var(--blue-800);
}
.heroText .show {
  opacity: 1;
}
.heroText .hide {
  opacity: 0;
  transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
}

.heroText .snackbar > div {
  position: fixed;
  background: var(--Alerts-Green-900, #004724);
  height: auto;
  z-index: 99;
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .heroText {
    border-bottom: 1px solid var(--gold-500);
    display: flex;
    justify-content: space-between;
    align-items: end;
    /* gap:8px; */
  }
  .heroText .titleSection {
    border-bottom: none;
    margin-right: 8px;
  }
  .heroText .buttonHolder {
    margin: 0 0 24px 0;
  }
  .heroText .snackbar > div {
    transform: translateX(-50%);
    left: 50%;
  }
}
