  .fmlaCardContainer {
      display: flex;
      flex-direction: column;    
      margin-top: 15px;
      gap: 16px;
      border-radius: 8px;
      border: 1px solid var(--Gray-100, #D1D1D1);
      background: var(--White, #FFF);
      padding: 24px;
      color: var(--Blue-900, #00012b);
      font-family: Calibre-R;    
      width: 100%;
      height: 151px;
      min-height: fit-content;
      margin-bottom: 40px;
  }
  .FMLAMain{
    display:flex;
    justify-content: space-between;
  }
  .FMLAMain>a{
    text-wrap: nowrap;
    color:#00012B;
    position: relative;
    top: -16px;
  }
  .FMLAMain>a:hover{
    text-wrap: nowrap;
    color:#00012B;
    font-weight:500;
    position: relative;
    top: -16px;
  }
  .FMLABorder{
    border-bottom: 1px solid var(--gray-200);
  }
  .FMLALeftText{
    display: flex;
    flex-direction: column;
  }
  .FMLARightText{
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .FMLATitle{
    color: var(--Blue-900, #00012B);
  /* Additional styles/med/text-2xl-med */
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  }
  .FMLADate{
    color: var(--Gray-700, #464646);
  /* Additional styles/reg/text-sm-reg */
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  }
  .FMLAHours{
    color: var(--Blue-900, #00012B);
    /* Additional styles/med/text-3xl-med */
    font-family: Calibre-R;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; 
  }
  .FMLAHoursText{
    color: var(--Gray-600, #5D5D5D);
  font-family: Calibre-R;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  /* text-wrap: nowrap; */
  }
 
  @media(max-width:1022px) {
      .FMLAMain {
          display: flex;
          flex-direction: column;
          gap: 24px;
      }

      .fmlaCardContainer {
          display: flex;
          flex-direction: column;
          margin-top: 15px;
          gap: 16px;
          border-radius: 8px;
          border: 1px solid var(--Gray-100, #D1D1D1);
          background: var(--White, #FFF);
          padding: 24px;
          color: var(--Blue-900, #00012b);
          font-family: Calibre-R;
          width: 100%;
          height: 286px;
          min-height: fit-content;
          margin-bottom: 40px;
      }

      .FMLARightText {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0px;
      }
  }