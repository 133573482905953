.mainNav {
  padding: 24px;
  background-color: var(--white);
  width: 100%;
}
.mainNav > ul {
  margin: 0;
}
.navList {
  padding: 0;
}
.navList a {
  text-decoration: none;
  font-family: Calibre-R;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}
.navList > li {
  margin-bottom: 8px;
}
.navList > li:last-child {
  margin-bottom: 0;
}
.navList > li > a {
  color: var(--blue-900);
  font-size: 16px;
  padding: 10px 1px;
  /* display: flex;
  align-items: center;
  gap: 12px; */
  border-radius: 8px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 12px;
  grid-template-columns: 20px auto;
}
.navList > li > a.hasChild {
  background-image: url("../../assets/images/chevron-down.svg");
  background-repeat: no-repeat;
  background-position: right center;
}
.navList li a .hasChildOpen,
.navList li a .onHover,
.navList li a .onActive {
  display: none;
}
.navList > li > a.navActive,
.navList > li > a:active {
  font-weight: 600;
}
.navList > li > a.navActive .onHover,
.navList > li > a:active .onHover {
  display: inherit;
}
.navList > li > a.navActive .onNormal,
.navList > li > a:active .onNormal {
  display: none;
}
.navList > li > a.navActive.hasChild,
.navList > li > a.hasChild:active {
  background-image: url("../../assets/images/chevron-up-black.svg");
  background-size: 16px;
}
.navList img {
  width: 20px;
  height: 20px;
}
.navList li {
  list-style: none;
  padding: 0;
}
.subNavList {
  display: none;
  padding: 0 0 0 10px;
  margin: 10px 0;
  border-left: var(--blue-900) solid 1px;
}
.subNavList li {
  padding: 0;
}
.subNavList > li a {
  display: block;
  color: var(--blue-900);
  font-size: 16px;
  padding: 10px 12px;
  border-radius: 8px;
}
.subNavActive,
.subNavList > li > a.subNavActive,
.subNavList > li > a:active {
  font-weight: 600;
  color: var(--white);
  background-color: var(--blue-900);
}
.show {
  display: block;
}
.mainNav .navHide,
.thirdLevelNav .navHide {
  display: none;
}
.thirdLevelNav .thirdNavTitle {
  border-bottom: var(--gray-100) solid 1px;
}
.thirdLevelNav .thirdNavTitle a {
  text-decoration: none;
  margin: 0 0 24px;
  display: inline-block;
  padding-left: 40px;
  font-family: Calibre-R;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
  color: var(--blue-900);
  background-image: url("../../assets/images/chevron-left.svg");
  background-repeat: no-repeat;
  background-position: left center;
}
.thirdLevelNav ul {
  padding: 0;
  margin: 16px 0;
}
.thirdLevelNav ul li {
  list-style: none;
}
.thirdLevelNav ul li a {
  color: var(--blue-900);
  border-radius: 8px;
  text-decoration: none;
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  padding: 10px 12px;
  display: block;
  margin-bottom: 5px;
}
.thirdLevelNav ul li a.subNavActiveL2 {
  background: var(--blue-900);
  color: var(--gold-25);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 21.6px */
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .mainNav {
    position: sticky;
    padding: 32px 10px 32px 20px;
    overflow-x: initial;
    /*max-height: calc(100vh - 80px);*/
    overflow-y: auto;
    scrollbar-gutter: stable;
    scrollbar-width: thin;
  }
  .navList > li > a {
    padding: 10px 16px;
  }
  .navList > li > a.hasChild {
    background-image: none;
  }
  .navList li a:hover {
    background-color: var(--gray-50);
  }
  .subNavList > li > a.subNavActive:hover {
    background-color: var(--blue-900);
  }
  .navList li a:hover .onHover {
    display: inherit;
  }
  .navList li a:hover .onNormal,
  .navList li a:hover .onActive {
    display: none;
  }
  .navList > li > a.navActive,
  .navList > li > a:active {
    font-family: Calibre-R;
    background-color: var(--blue-900);
    color: var(--white);
    font-weight: 600;
  }
  .navList > li > a.navActive .onNormal,
  .navList > li > a:active .onNormal,
  .navList > li > a.navActive .onHover,
  .navList > li > a:active .onHover {
    display: none;
  }
  .navList > li > a.navActive .onActive,
  .navList > li > a:active .onActive {
    display: inherit;
  }
  .navList > li > a.hasChild {
    background-position: right 16px center;
    background-image: url("../../assets/images/chevron-down.svg");
  }
  .navList li a.hasChild:hover {
    background-image: url("../../assets/images/chevron-down.svg");
  }
  .navList > li > a.navActive.hasChild,
  .navList > li > a.hasChild:active {
    background-image: url("../../assets/images/chevron-up-blue.svg");
    background-color: var(--white);
    color: var(--blue-900);
  }
  .navList > li > a.navActive.hasChild .hasChildOpen,
  .navList > li > a.hasChild:active .hasChildOpen {
    display: inherit;
  
  }
  .navList > li > a.navActive.hasChild .onActive,
  .navList > li > a.hasChild:active .onActive {
    display: none;
  }
  .subNavList {
    margin-left: 25px;
  }
  .thirdLevelNav ul li a:hover {
    background-color: var(--gray-50);
  }
  .thirdLevelNav ul li a.subNavActiveL2:hover {
    background: var(--blue-900);
  }
}
