.twoColumn {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.twoColumn > div:nth-child(1) {
  flex: 0 0 58%;
}
.twoColumn > div:nth-child(2) {
  flex: 0 0 38%;
}
.twoColumn.type_50_50 > div:nth-child(1) {
  flex: 0 0 48%;
}
.twoColumn.type_50_50 > div:nth-child(2) {
  flex: 0 0 48%;
}
.twoColumn.type_40_60 > div:nth-child(1) {
  flex: 0 0 38%;
}
.twoColumn.type_40_60 > div:nth-child(2) {
  flex: 0 0 58%;
}
.twoColumn.type_60_40 > div:nth-child(1) {
  flex: 0 0 58%;
}
.twoColumn.type_60_40 > div:nth-child(2) {
  flex: 0 0 38%;
}
.twoColumn.type_75_25 > div:nth-child(1) {
  flex: 0 0 73%;
}
.twoColumn.type_75_25 > div:nth-child(2) {
  flex: 0 0 23%;
}
.twoColumn.type_25_75 > div:nth-child(1) {
  flex: 0 0 23%;
}
.twoColumn.type_25_75 > div:nth-child(2) {
  flex: 0 0 73%;
}
.twoColumn .imageHolder img {
  width: 100%;
}
.twoColumn h3 {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 32.4px */
  margin-bottom: 16px;
}
.twoColumn p,
.twoColumn li,
.twoColumn span {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  margin: 0;
}
.twoColumn .buttonHolder {
  margin-top: 24px;
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .twoColumn {
    flex-direction: row;
    gap: 4%;
  }
}
