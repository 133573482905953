.coText > div[name="inner-html-parent"] > p {
  color: var(--Gray-600, #5d5d5d);
  font-size: 14px;
}
.coAckText > div[name="inner-html-parent"] > p {
  color: var(--Gray-600, #5d5d5d);
  font-size: 14px;
}
.formLabel {
  margin-bottom: 10px;
}
.formText>p:first-child{
  margin-bottom: 0px;
}
.formFieldSpacing {
  margin-top: 24px;
  width: 99%;
}
.formFieldSpacing:first-child {
  margin-top: 10px;
}
.formFieldSpacing p {
  margin-bottom: 16px;
}
.radioField {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
}
/* 
.classNameCalenderIcon {
  display: flex;
  padding-left: 40px;
  margin-left: 40px;
} */

.react-datepicker-popper {
  z-index: 3;
  /* background-image: url("../../../assets/images/calendar-basic.svg"); */
}

.dateMe {
  height: 56px;
  border: none;
  border-bottom: 1px solid #000;
  width: 100%;
  z-index: 1000;
}
.dateWrapper {
  width: 100%;
}
.dateWrapper svg {
  margin-top: 10px;
}
.dateWrapper input {
  /* margin-top: 10px; */
  padding-left: 40px;
  border-radius: 8px;
  /* padding-right: 40px; */
}

.textArea>div>div:nth-child(2)>p{
  display: none;
}
.textAreaSection textarea {
  margin-top: 0;
}
.textArea>p:first-child{
  margin-bottom:0px;
}
.textareaField {
  width: 100%;
  border-radius: 10px;
  padding: 8px 15px;
  border-top: #fff;
  border-left: #fff;
  border-right: #fff;
  border-bottom: 0.5px solid var(--Gray-400, #8b8b8b);
}

.formFieldSpacing p.formErrorF {
  color: var(--Alerts-Red-500, #f70800);
  font-size: 14px;
  /* todo: change the color */
}

.coButton {
  height: 56px;
  padding: 0 16px;
  font-size: 16px;
}
button.submitBtn:disabled {
  background: var(--Gray-500, #747474);
  color: #fff;
}
button.submitBtn {
  background: var(--Blue-900, #00012b);
  color: #fff;
}
button.submitBtn:hover:not([disabled]) {
  background: var(--blue-800);
}
.cancelBtn {
  border: 1px solid var(--Blue-900, #00012b) !important;
  color: var(--Blue-900, #00012b) !important;
  background: var(--white) !important;
}
.shiftTime {
  padding: 8px 0 !important;
}

.coStarField {
  color: var(--Alerts-Red-500, #f70800);
  margin-left: 2px;
}

/* this is fix for Radio button - appears distorted n shakky */
.callingOutForm input[type="radio"] {
  width: inherit;
  height: inherit;
}

/* calender */
.calendar {
  position: relative;
  margin-top: 16px;
}
.calendarBox {
  position: absolute;
  z-index: 3;
  padding: 8px;
  border-radius: 0 0 8px 8px;
  right: 0;
  background: var(--white, #fff);
  box-shadow: 0px 2px 16px 0px rgba(177, 150, 97, 0.2);
  margin-top: -6px;
  width: 350px;
}
.calendarBox.hide {
  display: none;
}

/* dropdown */
.shiftDropdown {
  display: flex;
  gap: 20px;
  /* align-items: end; */
  flex-direction: column;
  width: 100%;
  margin-bottom: 7px;
}

/* do some updates */
.dd {
  width: 170px;
}
.ddOuter {
  width: 100%;
}

.ddOuter1 {
  width: 100%;
  position: relative;
  z-index: 2;
}
.description {
  font-family: Calibre-R;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  text-align: left;
  color: var(--gray-600);
  margin-bottom: 16px;
}
.labelProp {
  font-family: Calibre-R;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  color: var(--blue-900);
}
.ddPropertyOuter {
  width: 100%;
  margin-bottom: 16px;
}
/* .ddItems{
  z-index: 10000000;
}
.ddItems1{
  z-index: 100;
} */
.ampm
{
  width:200px;
}
@media (min-width: 500px) {
  .shiftDropdown {
    flex-direction: row;
    align-items: flex-start;
  }
  .shiftDropdownDiv {
    width: 200px;
  }
  .dd {
    width: 220px;
  }
  .ddOuter {
    width: 100%;
    margin-top: 36px;
  }
  .ddOuter1 {
    width: 100%;
  }
}

/* calender Fix */
.calendarFieldWrapper {
  margin: 0;
}
.calendarFieldContentAlign {
  padding: 0;
}
.calendarFieldContentAlign input {
  padding: 0;
}

.calendarBox tbody > tr > td[aria-selected="true"] div {
  background: var(--Blue-900, #00012b);
  border-radius: 8px;
}
/* calender Fix - end */

/* Dropdown Styling */
.customDropdownN button[class^="customSelectDropdownType2_"],
.customDropdownN button[class^=" customSelectDropdownType2_"] {
  grid-template-columns: calc(100% - 30px);
}
.customDropdownN ul[class^="customSelectDropdownType2_customList__"] {
  display: block;
  border: none;
  border-radius: 0 0 8px 8px;
  margin: 4px 0 0 0;
  filter: drop-shadow(1px 0px 8px var(--gray-100));
  max-height: 160px;
  height: fit-content;
  width: 100%;
  overflow-y: auto;
}
.customDropdownN
  ul[class^="customSelectDropdownType2_customList__"]
  > li:last-child[aria-selected="true"],
.customDropdownN
  ul[class^="customSelectDropdownType2_customList__"]
  > li:last-child:hover {
  border-radius: 0;
}
/* Dropdown Styling - end */
