hr {
  /* #3199: Fixed | Accordion Golden Color */
  border-top: 1px solid var(--gold-500);
  opacity: 1;
}
.accordionGroupHolder {
  margin-bottom: 24px;
}
.cardContainerInner {
  padding: 0 16px;
  border-bottom: 1px solid var(--gray-50);
}
.accordionParent {
  padding: 24px;
  overflow: hidden;
}
.accordionParent h2 {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
}
.cardContainerInner.setFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.accordionGroup .accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  height: 56px;
}
.accordionGroup .accordionHeader h3 {
  display: flex;
  gap: 24px;
  align-items: center;
}
.accordionGroup .accordionHeader img {
  width:24px;
  height:24px;
}
.accordionGroup:hover {
  background: var(--Gray-50, #e8e8e8);
  /* #3199: Fixed | Accordion - Square corners */
}
.accordionGroup .accordionBody {
  height: 0px;
  visibility: hidden;
}
.accordionGroup .accordionBody.open {
  height: auto;
  visibility: visible;
}
.accordionGroup h3 {
  color: var(--blue-900, #00012b);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 24.3px */
}
.accordionGroup ul,
.accordionGroup p {
  margin-bottom: 16px;
}
.accordionGroup > *,
.accordionGroup ul,
.accordionGroup li .accordionGroup p,
.accordionGroup td {
  color: var(--blue-900);
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
}
.accordionGroup th,
.accordionGroup strong {
  font-weight: 500;
}
.accordionGroup th,
.accordionGroup td {
  padding-right: 24px;
}
.accordionGroup .ellipsis > * {
  display: none;
}
.accordionGroup .ellipsis > p:first-child {
  height: 105px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.accordionGroup .links {
  display: flex;
  flex-direction: column;
}
.accordionGroup .links.setMargin {
  margin: 16px 0 24px 0;
}
.accordionGroup .links.zeroMargin {
  margin: 0;
}
.accordionGroup .links a {
  display: block;
  padding-left: 0;
  margin-top: 16px;
}
.cardContainerInner .imageHolder img {
  width: 30px;
}

@media (min-width:425px) and (max-width: 500px) {
  .accordionGroup .accordionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    height: fit-content;
    margin-top: 16px;
    margin-bottom:16px
  }
  .titletext{
    width: 200px;
  }

}
@media (max-width: 424px) {
  .titletext{
    width: 180px;
  }
}

@media (min-width: 1024px) {
  .accordionGroupHolder {
    margin-bottom: 40px;
  }
  .cardContainerInner {
    padding: 0 16px;
  }
  .accordionParent {
    /*padding: 40px 0;*/
  }
  .innerMargin {
    /* padding: 0 40px; */
  }
  .titletext{
    width: 80%;
  }
  .cardContainerInner.setFlex {
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;
  }
  .cardContainerInner .imageHolder img {
    width: 30px;
    max-width: 221px;
    margin: 12px 0;
  }
  .accordionGroup .ellipsis > p:first-child {
    height: 45px;
    -webkit-line-clamp: 2;
  }
  .accordionGroup .links {
    gap: 48px;
    flex-direction: row;
  }
  .accordionGroup .links > div {
    flex: 1 0 0;
  }
}
.expandAllLink {
  text-decoration-line: underline;
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;
}
